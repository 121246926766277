interface FlatToNestedOptions {
  /**
   * The name of the `id` field in the flat structure.
   * Default to `id`.
   */
  id?: string;
  /**
   * The name of the `parent` field in the flat structure.
   * Default to `parentId`.
   */
  parent?: string;
  /**
   * The name of the `children` field that will hold children nodes in the nested structure.
   * Default to `children`.
   */
  children?: string;
}

export class FlatToNested {
  private readonly id: string;
  private readonly parent: string;
  private readonly children: string;

  constructor(options?: FlatToNestedOptions) {
    this.id = options?.id || 'id';
    this.parent = options?.parent || 'parentId';
    this.children = options?.children || 'children';
  }

  convert<D extends Record<string, any>>(data: D[]) {
    const normalizedData = data.filter((v) => !!v);
    const modifiedData = [...normalizedData];

    for (const [index, item] of modifiedData.entries()) {
      if (!item.hasOwnProperty(this.parent)) continue;
      // Has a parent, find it.
      const parent = this.findParent(modifiedData, item[this.parent]);
      // Parent not found, continue to the next iteration.
      if (!parent) continue;

      // Parent found, move current item to the parent as a child.
      if (!parent.children) {
        (parent as any).children = [];
      }
      parent.children.push(item);

      // Delete current item from the data after added to the parent.
      delete modifiedData[index];
    }

    return modifiedData.filter((v) => !!v);
  }

  private findParent<D extends Record<string, any>>(
    data: D[],
    parentValue: string | number
  ): D | undefined {
    let result: D | undefined;
    const normalizedData = data.filter((v) => !!v);

    for (const item of normalizedData) {
      // Parent found, stop the iteration and return the result.
      if (item[this.id] === parentValue) {
        result = item;
        break;
      }

      // If parent is not found, check the children.
      // If don't have a children or parent is not found in the children,
      // continue to the next iteration.
      if (item[this.children]?.length) {
        const parent = this.findParent(item[this.children] as D[], parentValue);
        if (!parent) continue;

        // Parent found on the children, stop the iteration and return the result.
        result = parent;
        break;
      }
    }

    return result;
  }
}
