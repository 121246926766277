import {
  Tree as AntdTree,
  TreeProps as AntdTreeProps,
  TreeNodeProps as AntdTreeNodeProps,
} from 'antd';
import { DataNode } from 'antd/lib/tree';
import { BasicDataNode } from 'rc-tree';

export interface TreeDataItem extends BasicDataNode {
  id: string;
  name: string;
  children?: Record<string, any>[];
}

export interface TreeProps
  extends Omit<AntdTreeProps, 'fieldNames' | 'treeData'> {
  treeData?: TreeDataItem[];
}

export interface TreeNodeProps extends AntdTreeNodeProps {}

export const Tree: React.FC<TreeProps> = ({ treeData, ...props }) => {
  return (
    <AntdTree
      {...props}
      fieldNames={{ key: 'id', title: 'name' }}
      treeData={treeData as unknown as DataNode[]}
    />
  );
};
