import { useCallback, useMemo, useState } from 'react';
import { compareStringArrays } from 'shared/utils/compare-string-arrays';

export interface NestedCheckboxItem {
  id: string;
  parentId?: string;
  children?: NestedCheckboxItem[];
  context?: any;
}

export function useCustomReportNestedCheckbox<Item extends NestedCheckboxItem>(
  dataSource: Item[],
  initialSelectedItems: Item[] = []
) {
  const [selectedItems, setSelectedItems] =
    useState<Item[]>(initialSelectedItems);

  const selectedItemIds = useMemo(
    () => selectedItems.map((i) => i.id),
    [selectedItems]
  );

  const parentItems = useMemo(
    () => flattenParentItems(dataSource),
    [dataSource]
  );

  const parentItemIds = useMemo(
    () => parentItems.map((item) => item.id),
    [parentItems]
  );

  const indeterminateItems = useMemo(() => {
    return parentItems.filter((item) => {
      const childrenIds = item.children?.map((i) => i.id);

      const allSelected = childrenIds?.every((i) =>
        selectedItemIds.includes(i)
      );
      if (allSelected) return false;

      const someSelected = childrenIds?.some((i) =>
        selectedItemIds.includes(i)
      );
      if (someSelected) return true;

      return false;
    });
  }, [parentItems, selectedItemIds]);

  const onChangeItem = useCallback(
    (item: Item, checked: boolean) => {
      const itemExists = selectedItems.some((i) => i.id === item.id);

      const typeAdd = checked && !itemExists;
      const typeRemove = !checked && itemExists;

      let type: 'add' | 'remove' | 'unknown' = 'unknown';
      if (typeAdd) type = 'add';
      if (typeRemove) type = 'remove';

      if (type === 'unknown') return;

      let result = handleChangeItem(selectedItems, item, type, parentItems);
      if (!result) return;

      result = filterUniqueItems(result);
      const resultIds = result.map((i) => i.id);

      const resultDiffers = compareStringArrays(resultIds, selectedItemIds);
      if (!resultDiffers) return;

      return setSelectedItems(result);
    },
    [selectedItems, selectedItemIds, parentItems]
  );

  const removeItem = (item: Item) => onChangeItem(item, false);

  const checkItemIsChecked = (item: Item) =>
    selectedItems.some((i) => i.id === item.id);

  const checkItemIsIndeterminate = useCallback(
    (item: Item) => {
      return indeterminateItems.some((i) => i.id === item.id);
    },
    [indeterminateItems]
  );

  const getParentsFromCurrentChildren = useCallback(
    (items: Item[]) => {
      if (!items.length) return [];

      const currentParents = parentItems.filter((p) => {
        const childrenParents = items.map((item) => item.parentId);
        return childrenParents.includes(p.context.originalId);
      });

      return [...currentParents, ...items];
    },
    [parentItems]
  );

  const setInitialItems = useCallback(
    (value) => setSelectedItems(getParentsFromCurrentChildren(value)),
    [getParentsFromCurrentChildren]
  );

  return {
    selectedItems,
    selectedItemIds,
    parentItems,
    parentItemIds,
    setSelectedItems,
    onChangeItem,
    removeItem,
    checkItemIsChecked,
    checkItemIsIndeterminate,
    setInitialItems,
  };
}

function flattenParentItems<Item extends NestedCheckboxItem>(
  items: Item[]
): Item[] {
  return items.reduce((prev: Item[], curr) => {
    if (!curr.children?.length) return prev;
    const children = flattenParentItems(curr.children as Item[]);
    return [...prev, curr, ...children];
  }, []);
}

function handleParentChange<Item extends NestedCheckboxItem>(
  prevItems: Item[],
  item: Item,
  type: 'add' | 'remove',
  parentItems: Item[],
  hasParent: boolean
) {
  let result = [...prevItems];

  if (type === 'add') {
    // Add current item to the selected items.
    result = [...result, item];

    const nestedResult = handleNestedChildrenOnParentChange(
      result,
      parentItems,
      item,
      'add'
    );

    result = [...result, ...nestedResult];
  }

  if (type === 'remove') {
    // Remove current item from selected items.
    result = result.filter((i) => i.id !== item.id);

    const nestedResult = handleNestedChildrenOnParentChange(
      result,
      parentItems,
      item,
      'remove'
    );

    // Get all the children and remove it from the selected items.
    if (hasParent) {
      result = nestedResult;
    } else {
      const childrenIds = nestedResult.map((i) => i.id);
      result = result.filter((i) => !childrenIds.includes(i.id));
    }
  }

  return result;
}

function handleChildChange<Item extends NestedCheckboxItem>(
  prevItems: Item[],
  item: Item,
  type: 'add' | 'remove',
  parentItems: Item[]
) {
  let result = [...prevItems];

  if (type === 'add') {
    result = [...result, item];
    const nestedParentResult = handleNestedParentOnChildChange(
      result,
      parentItems,
      item
    );
    result = nestedParentResult;
  }

  if (type === 'remove') {
    result = result.filter((i) => i.id !== item.id);
    const nestedParentResult = handleNestedParentOnChildChange(
      result,
      parentItems,
      item
    );
    result = nestedParentResult;
  }

  return result;
}

function doesAllChildrenChecked<Item extends NestedCheckboxItem>(
  prevItems: Item[],
  item: Item
) {
  const prevItemIds = prevItems.map((i) => i.id);
  return (item.children || []).every((i) => prevItemIds.includes(i.id));
}

function handleNestedParentOnChildChange<Item extends NestedCheckboxItem>(
  prevItems: Item[],
  parentItems: Item[],
  item: Item
) {
  let result = [...prevItems];

  if (!item.parentId) return result;
  const parent = parentItems.find(
    (i) => i.context.originalId === item.parentId
  );

  if (!parent) return result;

  // Check if last child of the parent is checked.
  const childrenChecked = doesAllChildrenChecked(result, parent);

  if (childrenChecked) result = [...result, parent];
  else result = result.filter((i) => i.id !== parent.id);

  if (!parent.parentId) return result;

  // Handle nested parents
  const nestedParentResult = handleNestedParentOnChildChange(
    result,
    parentItems,
    parent
  );
  result = nestedParentResult;

  return result;
}

function handleNestedChildrenOnParentChange<Item extends NestedCheckboxItem>(
  prevItems: Item[],
  parentItems: Item[],
  item: Item,
  type: 'add' | 'remove'
) {
  if (type === 'add') {
    let result = [...prevItems];
    const children: Item[] = ((item.children as Item[]) || []).reduce(
      (prev: Item[], curr: Item) => {
        const nestedChildren = curr.children?.length
          ? handleNestedChildrenOnParentChange(result, parentItems, curr, 'add')
          : [];
        return [...prev, curr, ...nestedChildren];
      },
      []
    );
    result = [...result, ...children];
    return result;
  }

  if (type === 'remove') {
    let result: Item[] = [];
    const children: Item[] = ((item.children as Item[]) || []).reduce(
      (prev: Item[], curr: Item) => {
        const nestedChildren = curr.children?.length
          ? handleNestedChildrenOnParentChange([], parentItems, curr, 'remove')
          : [];
        return [...prev, curr, ...nestedChildren];
      },
      []
    );

    result = [...result, ...children];
    return result;
  }

  return [];
}

function handleChangeItem<Item extends NestedCheckboxItem>(
  prevItems: Item[],
  item: Item,
  type: 'add' | 'remove',
  parentItems: Item[]
) {
  const isParent = !!item.children;
  const hasParent = !!item.parentId;

  if (type === 'add') {
    if (isParent && hasParent) {
      const parentResult = handleParentChange(
        prevItems,
        item,
        'add',
        parentItems,
        hasParent
      );
      const itemResult = handleChildChange(prevItems, item, 'add', parentItems);
      return [...parentResult, ...itemResult];
    }

    if (isParent) {
      return handleParentChange(prevItems, item, 'add', parentItems, hasParent);
    }

    return handleChildChange(prevItems, item, 'add', parentItems);
  }

  if (type === 'remove') {
    if (isParent && hasParent) {
      const parentResult = handleParentChange(
        prevItems,
        item,
        'remove',
        parentItems,
        hasParent
      );

      const itemResult = handleChildChange(
        prevItems,
        item,
        'remove',
        parentItems
      );

      const parentResultIds = parentResult.map((i) => i.id);
      const result = itemResult.filter((i) => !parentResultIds.includes(i.id));

      return result;
    }

    if (isParent) {
      return handleParentChange(
        prevItems,
        item,
        'remove',
        parentItems,
        hasParent
      );
    }

    return handleChildChange(prevItems, item, 'remove', parentItems);
  }
}

function filterUniqueItems<Item extends NestedCheckboxItem>(items: Item[]) {
  return items.reduce((prev: Item[], curr) => {
    const exists = prev.some((i) => i.id === curr.id);
    if (exists) return prev;
    return [...prev, curr];
  }, []);
}
