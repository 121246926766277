import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { ScreenLoading } from 'shared/components/screen-loading';

interface ContextProps {
  accessToken?: string;
  isLoading: boolean;
  refetch: () => void;
  isAuthenticated: boolean;
  setToken: (token: string) => void;
  removeToken: () => void;
}

const AuthContext = createContext<ContextProps>({
  isLoading: true,
  refetch: () => {},
  isAuthenticated: false,
  setToken: () => {},
  removeToken: () => {},
});

export const AuthProvider = ({ children }: any) => {
  const [accessToken, setAccessToken] = useState<string | undefined>();
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const fetchAccessTokenFromStorage = useCallback(() => {
    // Get access token from local storage.
    // If exists, set the accessToken state.
    const accessTokenFromStorage = sessionStorage.getItem('authToken');
    if (!!accessTokenFromStorage) {
      setAccessToken(accessTokenFromStorage);
    }
    setIsLoading(false);
  }, []);

  useEffect(() => {
    fetchAccessTokenFromStorage();
  }, [fetchAccessTokenFromStorage]);

  const refetch = () => fetchAccessTokenFromStorage();
  const isAuthenticated = useMemo(() => !!accessToken, [accessToken]);

  const setToken = (token: string) => {
    sessionStorage.setItem('authToken', token);
    refetch();
  };

  const removeToken = () => {
    sessionStorage.removeItem('authToken');
    refetch();
  };

  if (isLoading) {
    return <ScreenLoading />;
  }

  return (
    <AuthContext.Provider
      value={{
        accessToken,
        isLoading,
        refetch,
        isAuthenticated,
        setToken,
        removeToken,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export function useAuth() {
  return useContext(AuthContext);
}
