import { ErrorResponseDto, GenerateReportDto, ReportsApi } from 'api-client';
import axios from 'axios';
import { useMutation } from 'react-query';
import { apiConfig } from 'shared/utils/api-config';

export function useGenerateReport() {
  const reportsApi = new ReportsApi(apiConfig());

  return useMutation<number, ErrorResponseDto, GenerateReportDto>(
    (createReportDto: GenerateReportDto) =>
      reportsApi
        .generateReport(createReportDto)
        .then((res) => res.data)
        .catch((error) => {
          if (axios.isAxiosError(error)) {
            throw error.response!.data;
          }
          throw error;
        })
  );
}
