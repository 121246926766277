import { CircularProgress } from '@mui/material';
import classNames from 'classnames';
import React from 'react';
import styles from './index.module.css';

type ButtonAttributes = React.ButtonHTMLAttributes<HTMLButtonElement>;

export interface ButtonDefaultProps {
  title: string;
  className?: string;
  type?: ButtonAttributes['type'];
  onClick?: ButtonAttributes['onClick'];
  size?: 'small' | 'normal' | 'smallest';
  fullWidth?: boolean;
  disabled?: boolean;
  loading?: boolean;
  imgSrc?: string;
}

export const ButtonDefault: React.FC<ButtonDefaultProps> = ({
  title,
  className,
  onClick,
  size = 'normal',
  fullWidth,
  disabled,
  loading,
  imgSrc,
  ...restProps
}) => {
  const handleButtonClick: ButtonAttributes['onClick'] = (props) => {
    if (disabled || loading || !onClick) return;
    return onClick(props);
  };

  return (
    <button
      className={classNames(
        styles.button,
        {
          [styles.small]: size === 'small',
          [styles.smallest]: size === 'smallest',
          [styles.fullWidth]: fullWidth,
          [styles.disabled]: disabled || loading,
        },
        className
      )}
      onClick={handleButtonClick}
      {...restProps}
    >
      {!!imgSrc && <img className={styles.img} src={imgSrc} alt="btn-icon" />}
      <span className={classNames({ [styles.hide]: loading })}>{title}</span>
      <span className={classNames(styles.loader, { [styles.hide]: !loading })}>
        <CircularProgress size={20} />
      </span>
    </button>
  );
};
