import { useMemo } from 'react';
import { ButtonPrimary } from 'shared/components/buttons/button-primary';
import { BaseDialog } from '../base-dialog';
import { useTranslation } from 'core/context/i18n.context';
import styles from './index.module.css';
import { InputWrapper } from 'shared/components/input-wrapper';
import { InputLabel } from 'shared/components/input-label';
import { useUserImageLog } from 'dashboard/hooks/use-user-image-log';

interface ViewImageDialogData {
  datetime: string;
  deviceName: string;
  dialogTitle: string;
  image: any;
  user: any;
}

interface ViewImageDialogProps {
  open: boolean;
  onClose: () => void;
  data: ViewImageDialogData;
}

export const ViewImageDialog: React.FC<ViewImageDialogProps> = ({
  open,
  onClose,
  data,
}) => {
  const { t } = useTranslation();
  const getImageLog = useUserImageLog(data.image?.image_data);
  const imgLogData = useMemo(() => getImageLog.data, [getImageLog]);
  const imgSrc = `data:image/jpeg;base64,${(imgLogData as any)?.imageData}`;

  const saveImage = () => {
    const getDate = data.datetime.split(' ')[0];
    const getTime = data.datetime.split(' ')[1];
    const formatDate = getDate.split('/').join('_');

    const imageFilename = `ImageLog_${formatDate}_${getTime}_${data.dialogTitle}_${data.deviceName}_${data.user.name}.jpg`;
    const link = document.createElement('a');
    link.href = imgSrc;
    link.setAttribute('download', decodeURI(imageFilename));
    document.body.appendChild(link);
    link.click();
    link.parentNode?.removeChild(link);
  };

  const DialogActions = () => {
    return (
      <>
        <ButtonPrimary
          size="small"
          title={t('reportApp.save')}
          onClick={saveImage}
          className={styles.saveBtn}
        ></ButtonPrimary>
      </>
    );
  };

  return (
    <BaseDialog
      onClose={onClose}
      open={open}
      title={data.dialogTitle}
      actions={<DialogActions />}
      minWidth={394}
      minHeight={820}
      disableBackdropClick
      disableEscapeKeyDown
    >
      <div className={styles.contentWrapper}>
        <div className={styles.imageWrapper}>
          <img src={imgSrc} className={styles.imgLog} alt="imageLog" />
        </div>
        <div className={styles.userInfoWrapper}>
          <InputWrapper className={styles.inputWrapper}>
            <InputLabel className={styles.inputLabel} showBullet={true}>
              {t('reportApp.user')}
            </InputLabel>
            <span className={styles.value}>{data.user?.name}</span>
          </InputWrapper>
          <InputWrapper className={styles.inputWrapper}>
            <InputLabel className={styles.inputLabel} showBullet={true}>
              {t('reportApp.date')}
            </InputLabel>
            <span className={styles.value}>{data.datetime}</span>
          </InputWrapper>
          <InputWrapper className={styles.inputWrapper}>
            <InputLabel className={styles.inputLabel} showBullet={true}>
              {t('reportApp.device')}
            </InputLabel>
            <span className={styles.value}>{data.deviceName}</span>
          </InputWrapper>
        </div>
      </div>
    </BaseDialog>
  );
};
