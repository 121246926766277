import { useLogin } from 'auth/hooks/use-login';
import { useAuth } from 'core/context/auth.context';
import React, { useCallback, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useErrorDialog } from 'shared/contexts/error-dialog.context';

export const LoginPage: React.FC = () => {
  const loginMutation = useLogin();
  const { showErrorDialog } = useErrorDialog();
  const navigate = useNavigate();
  const auth = useAuth();

  // catch login redirect parameter
  const location = useLocation();
  const urlSearchParams = new URLSearchParams(location.search);
  const redirectPath = urlSearchParams.get('redirect');

  const onLogin = useCallback(async () => {
    try {
      const data = await loginMutation;
      auth.setToken(data.accessToken);
      if (redirectPath === 'dashboard') {
        navigate('/dashboard', { replace: true });
      } else {
        navigate('/report/generate-report', { replace: true });
      }
    } catch (error: any) {
      showErrorDialog({ alertType: 'error', message: error.message });
    }
  }, [auth, loginMutation, navigate, showErrorDialog, redirectPath]);

  useEffect(() => {
    onLogin();
  }, []); // eslint-disable-line

  return <></>;
};
