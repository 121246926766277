import { Reducer, useMemo, useReducer } from 'react';

export type DataFilterState = Record<number, any[]>;
type Action = {
  type: 'onChange';
  payload: {
    stepIndex: number;
    value: any;
  };
};

const initialState = {};

const reducer: Reducer<DataFilterState, Action> = (state, action) => {
  switch (action.type) {
    case 'onChange':
      return {
        ...state,
        [action.payload.stepIndex]: action.payload.value,
      };
    default:
      throw new Error('Unknown stepIndex');
  }
};

export function useFilterReducer() {
  const [data, setData] = useReducer(reducer, initialState);

  const dataAsArray = useMemo(() => Object.values(data), [data]);

  return [dataAsArray, setData] as [typeof dataAsArray, typeof setData];
}
