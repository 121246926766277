import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  ModalProps,
} from '@mui/material';
import classNames from 'classnames';
import styles from './index.module.css';

export interface BaseDialogProps {
  title: string;
  open: boolean;
  onClose: () => void;
  disableBackdropClick?: boolean;
  disableEscapeKeyDown?: boolean;
  hideCloseIcon?: boolean;
  actions?: React.ReactElement<any>;
  minWidth?: number;
  minHeight?: number;
  contentCustomStyles?: any;
}

export const BaseDialog: React.FC<BaseDialogProps> = ({
  title,
  open,
  onClose,
  children,
  disableBackdropClick,
  disableEscapeKeyDown,
  hideCloseIcon,
  actions,
  minWidth = 350,
  minHeight = 250,
  contentCustomStyles,
}) => {
  const handleCloseDialog: ModalProps['onClose'] = (event, reason) => {
    if (reason === 'backdropClick' && disableBackdropClick) return;
    if (reason === 'escapeKeyDown' && disableEscapeKeyDown) return;
    onClose();
  };

  return (
    <Dialog
      onClose={handleCloseDialog}
      open={open}
      PaperProps={{
        className: styles.paper,
        style: {
          minWidth: `${minWidth}px`,
          minHeight: `${minHeight}px`,
          overflowY: 'visible',
        },
      }}
    >
      <DialogTitle className={styles.title}>
        {title}
        {!hideCloseIcon && (
          <button className={styles.buttonIconClose} onClick={onClose} />
        )}
      </DialogTitle>

      <DialogContent
        className={classNames(styles.mainContent, contentCustomStyles)}
      >
        {children}
      </DialogContent>

      {actions && (
        <DialogActions className={styles.actions}>{actions}</DialogActions>
      )}
    </Dialog>
  );
};
