import classNames from 'classnames';
import redAlert from 'core/assets/icon_alert_red.png';
import { MainMenuItem, mainMenuItems } from 'core/constants/main-menu.constant';
import { useTranslation } from 'core/context/i18n.context';
import { useEffect, useMemo } from 'react';
import { NavLink } from 'react-router-dom';
import { useGetSchedules } from 'schedule/hooks/use-get-schedules';
import { useGetCredential } from 'settings/components/bs-admin/hooks/use-get-credential';
import { useValidCredential } from 'settings/components/bs-admin/hooks/use-valid-credential';
import styles from './index.module.css';
import { useGetCurrentUser } from 'core/hooks/use-get-current-user';

export const MenuBar: React.FC = () => {
  const { t } = useTranslation();
  const scheduleReport = useGetSchedules();
  const adminCredential = useGetCredential({ suspense: false });
  const validCredential = useValidCredential({ suspense: false });
  const currentUser = useGetCurrentUser();

  const showRedBlink = useMemo(
    () =>
      !!scheduleReport.data?.length &&
      (!adminCredential.data || !validCredential.data?.isValid),
    [
      adminCredential.data,
      scheduleReport.data?.length,
      validCredential.data?.isValid,
    ]
  );

  const navToSettings = (item: MainMenuItem) => (
    <NavLink key={item.key} className={styles.link} to={item.path}>
      {t(`reportApp.mainMenu.${item.key}`)}
      <img
        src={redAlert}
        alt="red alert"
        className={classNames(styles.redAlertIcon, {
          [styles.showRedAlert]: showRedBlink,
        })}
      />
    </NavLink>
  );

  useEffect(() => {
    if (!!adminCredential.data && !!scheduleReport.data?.length) {
      validCredential.refetch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [adminCredential.data, scheduleReport.data?.length]);

  return (
    <div className={styles.menuBar}>
      <div className={styles.navMenu}>
        {mainMenuItems.map((item) => {
          if (item.key === 'settings') {
            if (currentUser.data?.userId === '1') {
              return navToSettings(item);
            }

            return null;
          }

          return (
            <NavLink key={item.key} className={styles.link} to={item.path}>
              {t(`reportApp.mainMenu.${item.key}`)}
            </NavLink>
          );
        })}
      </div>
    </div>
  );
};
