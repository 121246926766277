import { Box } from '@mui/system';
import closeLockLarge from 'core/assets/close-lock-large.png';
import closeLockMedium from 'core/assets/close-lock-medium.png';
import closeLockSmall from 'core/assets/close-lock-small.png';
import closeUnlockLarge from 'core/assets/close-unlock-large.png';
import closeUnlockMedium from 'core/assets/close-unlock-medium.png';
import closeUnlockSmall from 'core/assets/close-unlock-small.png';
import iconDeleted from 'core/assets/delete-icon.png';
import openLockLarge from 'core/assets/open-lock-large.png';
import openLockMedium from 'core/assets/open-lock-medium.png';
import openLockSmall from 'core/assets/open-lock-small.png';
import openUnlockLarge from 'core/assets/open-unlock-large.png';
import openUnlockMedium from 'core/assets/open-unlock-medium.png';
import openUnlockSmall from 'core/assets/open-unlock-small.png';
import { useTranslation } from 'core/context/i18n.context';
import { UserSelectionDialog } from 'dashboard/components/user-selection-dialog';
import { useDialog } from 'dashboard/hooks/use-dialog';
import { useDoorClearAlarm } from 'dashboard/hooks/use-door-clear-alarm';
import { useDoorClearAPB } from 'dashboard/hooks/use-door-clear-apb';
import { useDoorControl } from 'dashboard/hooks/use-door-control';
import { useDoorLock } from 'dashboard/hooks/use-door-lock';
import { useDoorNormalize } from 'dashboard/hooks/use-door-normalize';
import { useDoorOpen } from 'dashboard/hooks/use-door-open';
import { useDoorUnlock } from 'dashboard/hooks/use-door-unlock';
import { useGetDoorsAndGroups } from 'dashboard/hooks/use-get-doors-and-groups';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useErrorDialog } from 'shared/contexts/error-dialog.context';
import { useSuccessDialog } from 'shared/contexts/success-dialog.context';
import { useDatetimePreference } from 'shared/hooks/use-datetime-preference';
import { useNormalizedDatetime } from 'shared/hooks/use-normalized-datetime';
import { useSqueezeText } from 'shared/hooks/use-squeeze-text';
import { ButtonDropdown } from '../buttons/button-dropdown';
import styles from './index.module.css';

const alarmEventColors = {
  forced: '#ff0000',
  held: '#ff4200',
  apb: '#ff005a',
};

enum DoorActionIds {
  Open = 'open',
  Normalize = 'normalize',
  Lock = 'lock',
  Unlock = 'unlock',
  ClearAlarm = 'clearAlarm',
  ClearAPB = 'clearAPB',
}

export const WidgetDoorControl: React.FC<any> = ({
  data,
  index,
  width,
  height,
  handleDeletedDoor,
  isSettings,
}) => {
  const { t } = useTranslation();
  const {
    event,
    allEvents,
    startDoorEvents,
    currentDoorStatus,
    fetchDoorStatus,
    clearEvents,
    forcedOpen,
    heldOpen,
    apbViolation,
  } = useDoorControl(data.widgetValue);
  const getDoorsAndGroups = useGetDoorsAndGroups();
  const doorOpen = useDoorOpen();
  const doorLock = useDoorLock();
  const doorUnlock = useDoorUnlock();
  const doorNormalize = useDoorNormalize();
  const doorClearAlarm = useDoorClearAlarm();
  const doorClearAPB = useDoorClearAPB();
  const { showSuccessDialog } = useSuccessDialog();
  const { showErrorDialog } = useErrorDialog();
  const userSelection = useDialog();
  const normalizedDatetime = useNormalizedDatetime();
  const datetimePreference = useDatetimePreference();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isManualLock, setIsManualLock] = useState<boolean>(false);
  const [isManualUnlock, setIsManualUnlock] = useState<boolean>(false);

  const forcedOpenCodes = ['21504', '4', '12', '13', '14'];
  const heldOpenCodes = ['21760', '8', '12', '13', '14'];
  const apbViolationCodes = ['24577', '24578', '16'];
  const hadForcedAndOpenEvents = forcedOpenCodes.filter((code) =>
    heldOpenCodes.includes(code)
  );

  const checkDoorIsExisted = useMemo(() => {
    if (!getDoorsAndGroups.isLoading && !!getDoorsAndGroups.isSuccess) {
      const doorsAndGroups = getDoorsAndGroups.data;
      const doorIsExisted = doorsAndGroups.some(
        (door) => door.lastChild && door.originalId === data.widgetValue
      );

      return doorIsExisted;
    }

    return true;
  }, [data.widgetValue, getDoorsAndGroups]);

  const displayData = useMemo(
    () => [forcedOpen, heldOpen, apbViolation],
    [apbViolation, forcedOpen, heldOpen]
  );

  const onApplyClearAPB = (users: any) => {
    setIsLoading(true);
    doorClearAPB.mutate(
      { doorId: data.widgetValue, users },
      {
        onSuccess: () => {
          setIsLoading(false);
          showSuccessDialog({
            message: t('reportApp.doorControl.actions.successMessage', [
              t(`reportApp.doorControl.actions.${DoorActionIds.ClearAPB}`),
            ]),
          });
          fetchDoorStatus();
          userSelection.closeDialog();
        },
        onError: () => {
          setIsLoading(false);
          showErrorDialog({
            alertType: 'error',
            message: t('reportApp.doorControl.actions.failedMessage', [
              t(`reportApp.doorControl.actions.${DoorActionIds.ClearAPB}`),
            ]),
          });
        },
      }
    );
  };

  const containFireAlarmCodes = (array: any[]): boolean => {
    const has24066 = array.some((element) => element.eventCode === '24066');
    const has23554 = array.some((element) => element.eventCode === '23554');

    return has24066 && !has23554;
  };

  const doorActions = [
    {
      name: t(`reportApp.doorControl.actions.${DoorActionIds.Open}`),
      id: DoorActionIds.Open,
      onClick: () => {
        // check if fire alarm codes exist
        const fireAlarmError = containFireAlarmCodes(allEvents);
        if (fireAlarmError) {
          return showErrorDialog({
            alertType: 'warning',
            message: t('reportApp.doorControl.actions.doorOpenRequestFail', [
              `${t('eventCategory.fireAlarm')}: ${t(
                'door.statusType.emergencyUnlock'
              )}`,
            ]),
          });
        }

        if (isManualLock || isManualUnlock) {
          return showErrorDialog({
            alertType: 'warning',
            message: t('reportApp.doorControl.actions.doorOpenRequestFail', [
              `${data.widgetName}: ${
                isManualLock
                  ? t('reportApp.doorControl.actions.operatorLock')
                  : t('reportApp.doorControl.actions.operatorUnlock')
              }`,
            ]),
          });
        }

        return doorOpen.mutate(data.widgetValue, {
          onSuccess: () => {
            showSuccessDialog({
              message: t('reportApp.doorControl.actions.successMessage', [
                t(`reportApp.doorControl.actions.${DoorActionIds.Open}`),
              ]),
            });
            fetchDoorStatus();
          },
          onError: () => {
            showErrorDialog({
              alertType: 'error',
              message: t('reportApp.doorControl.actions.failedMessage', [
                t(`reportApp.doorControl.actions.${DoorActionIds.Open}`),
              ]),
            });
          },
        });
      },
    },
    {
      name: t(`reportApp.doorControl.actions.${DoorActionIds.Normalize}`),
      id: DoorActionIds.Normalize,
      onClick: () =>
        doorNormalize.mutate(data.widgetValue, {
          onSuccess: () => {
            setIsManualLock(false);
            setIsManualUnlock(false);
            showSuccessDialog({
              message: t('reportApp.doorControl.actions.successMessage', [
                t(`reportApp.doorControl.actions.${DoorActionIds.Normalize}`),
              ]),
            });
            fetchDoorStatus();
          },
          onError: () => {
            showErrorDialog({
              alertType: 'error',
              message: t('reportApp.doorControl.actions.failedMessage', [
                t(`reportApp.doorControl.actions.${DoorActionIds.Normalize}`),
              ]),
            });
          },
        }),
    },
    {
      name: t(`reportApp.doorControl.actions.${DoorActionIds.Lock}`),
      id: DoorActionIds.Lock,
      onClick: () =>
        doorLock.mutate(data.widgetValue, {
          onSuccess: () => {
            setIsManualLock(true);
            showSuccessDialog({
              message: t('reportApp.doorControl.actions.successMessage', [
                t(`reportApp.doorControl.actions.${DoorActionIds.Lock}`),
              ]),
            });
            fetchDoorStatus();
          },
          onError: () => {
            showErrorDialog({
              alertType: 'error',
              message: t('reportApp.doorControl.actions.failedMessage', [
                t(`reportApp.doorControl.actions.${DoorActionIds.Lock}`),
              ]),
            });
          },
        }),
    },
    {
      name: t(`reportApp.doorControl.actions.${DoorActionIds.Unlock}`),
      id: DoorActionIds.Unlock,
      onClick: () =>
        doorUnlock.mutate(data.widgetValue, {
          onSuccess: () => {
            setIsManualUnlock(true);
            showSuccessDialog({
              message: t('reportApp.doorControl.actions.successMessage', [
                t(`reportApp.doorControl.actions.${DoorActionIds.Unlock}`),
              ]),
            });
            fetchDoorStatus();
          },
          onError: () => {
            showErrorDialog({
              alertType: 'error',
              message: t('reportApp.doorControl.actions.failedMessage', [
                t(`reportApp.doorControl.actions.${DoorActionIds.Unlock}`),
              ]),
            });
          },
        }),
    },
    {
      name: t(`reportApp.doorControl.actions.${DoorActionIds.ClearAlarm}`),
      id: DoorActionIds.ClearAlarm,
      onClick: () =>
        doorClearAlarm.mutate(data.widgetValue, {
          onSuccess: () => {
            showSuccessDialog({
              message: t('reportApp.doorControl.actions.successMessage', [
                t(`reportApp.doorControl.actions.${DoorActionIds.ClearAlarm}`),
              ]),
            });
            clearEvents();
            fetchDoorStatus();
          },
          onError: () => {
            showErrorDialog({
              alertType: 'error',
              message: t('reportApp.doorControl.actions.failedMessage', [
                t(`reportApp.doorControl.actions.${DoorActionIds.ClearAlarm}`),
              ]),
            });
          },
        }),
    },
    {
      name: t(`reportApp.doorControl.actions.${DoorActionIds.ClearAPB}`),
      id: DoorActionIds.ClearAPB,
      onClick: () => userSelection.openDialog(),
    },
  ];

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const getAlarmByCode = (code: string = '0') => {
    const forcedOpenEvent: string = t('reportApp.alarmEvent.forced');
    const heldOpenEvent: string = t('reportApp.alarmEvent.held');
    const apbViolationEvent: string = t('reportApp.alarmEvent.apb');

    if (hadForcedAndOpenEvents.includes(code)) {
      let arrayOfEvents = [forcedOpenEvent, heldOpenEvent];
      if (apbViolationCodes.includes(code)) {
        arrayOfEvents = [...arrayOfEvents, apbViolationEvent];
        return arrayOfEvents;
      }

      return arrayOfEvents;
    }

    if (forcedOpenCodes.includes(code)) {
      return forcedOpenEvent;
    }

    if (heldOpenCodes.includes(code)) {
      return heldOpenEvent;
    }

    if (apbViolationCodes.includes(code)) {
      return apbViolationEvent;
    }

    return '';
  };

  const getAlarmEventColor = (alarm: string = '0') => {
    if (forcedOpenCodes.includes(alarm)) {
      return alarmEventColors.forced;
    }

    if (heldOpenCodes.includes(alarm)) {
      return alarmEventColors.held;
    }

    if (apbViolationCodes.includes(alarm)) {
      return alarmEventColors.apb;
    }

    return '';
  };

  const getDoorStatus = useMemo(() => {
    const doorStatusCloseLocked = 'closeLocked';
    const doorStatusCloseUnlocked = 'closeUnlocked';
    const doorStatusOpenUnlocked = 'openUnlocked';
    const doorStatusOpenLocked = 'openLocked';

    if (!!event) {
      if (allEvents.length > 1) {
        const prevEvents =
          allEvents.length > 4 ? allEvents.slice(-4) : allEvents;
        const prevEventsCodes = prevEvents.map((e) => e.eventCode);
        const openIdx = prevEventsCodes.indexOf('20992');
        const isUnlockedBeforeOpen = prevEventsCodes[openIdx - 1] === '20480';
        const includeForcedOpen = prevEvents.some((e) =>
          forcedOpenCodes.includes(e.eventCode)
        );
        const includeHeldOpen = prevEvents.some((e) =>
          heldOpenCodes.includes(e.eventCode)
        );

        if (event.eventCode === '20992' && !isUnlockedBeforeOpen) {
          return doorStatusOpenLocked;
        }

        if (includeForcedOpen) {
          if (event.eventCode === '21248') {
            return doorStatusCloseLocked;
          }
        }

        if (includeHeldOpen) {
          if (event.eventCode === '20992') {
            return doorStatusOpenLocked;
          }
          if (event.eventCode === '21248') {
            return doorStatusCloseLocked;
          }
        }
      }

      switch (event.eventCode) {
        case '20480':
        case '21248':
        case '24068':
          return doorStatusCloseUnlocked; // close unlocked
        case '20992':
        case '24578':
          return doorStatusOpenUnlocked; // open unlocked
        case '20736':
        case '23812':
        case '24577':
          return doorStatusCloseLocked; // close locked
        case '21504':
        case '21760':
          return doorStatusOpenLocked; // open locked
      }
    }

    return !!currentDoorStatus && currentDoorStatus?.opened === 'true'
      ? doorStatusOpenUnlocked
      : doorStatusCloseLocked;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [event, currentDoorStatus, allEvents]);

  const imgSrc: Record<string, any> = useMemo(
    () => ({
      small: {
        closeLocked: closeLockSmall,
        closeUnlocked: closeUnlockSmall,
        openUnlocked: openUnlockSmall,
        openLocked: openLockSmall,
      },
      medium: {
        closeLocked: closeLockMedium,
        closeUnlocked: closeUnlockMedium,
        openUnlocked: openUnlockMedium,
        openLocked: openLockMedium,
      },
      large: {
        closeLocked: closeLockLarge,
        closeUnlocked: closeUnlockLarge,
        openUnlocked: openUnlockLarge,
        openLocked: openLockLarge,
      },
    }),
    []
  );

  const getDoorImgSize = useMemo(() => {
    const doorStatus: string = getDoorStatus;
    if (width < 272) {
      if (height >= 320) {
        return imgSrc['medium'][`${doorStatus}`];
      }

      return imgSrc['small'][`${doorStatus}`];
    }

    if (width >= 272 && width <= 353) {
      if (height < 320) {
        return imgSrc['small'][`${doorStatus}`];
      }

      return imgSrc['medium'][`${doorStatus}`];
    }

    if (height < 320) {
      return imgSrc['small'][`${doorStatus}`];
    }

    if (height < 485) {
      return imgSrc['medium'][`${doorStatus}`];
    }

    return imgSrc['large'][`${doorStatus}`];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getDoorStatus, height, width]);

  const getAlarmEventWidth = useMemo(() => {
    if (width < 272) {
      if (height >= 320) {
        return '73px';
      }
      return '50px';
    }

    if (width >= 272 && width <= 353) {
      if (height < 320) {
        return '50px';
      }
      return '73px';
    }

    return '110px';
  }, [width, height]);

  const getAlarmEventHeight = useMemo(() => {
    if (width < 272) {
      if (height >= 320) {
        return '51px';
      }
      return '31px';
    }

    if (width >= 272 && width <= 353) {
      if (height < 320) {
        return '31px';
      }
      return '51px';
    }

    return '71px';
  }, [height, width]);

  const getFontSize = useMemo(() => {
    if (width < 272) {
      return 10;
    }

    if (width >= 272 && width <= 353) {
      return 14;
    }

    return 20;
  }, [width]);

  const titleId = `title-wrapper-${index}`;
  const squeezeText = useSqueezeText(titleId);

  const getDatetimeFormat: any = useMemo(() => {
    if (datetimePreference?.data?.datetimeFormat.includes('hh:mm a')) {
      return 'HH:mm:ss a';
    }

    if (datetimePreference?.data?.datetimeFormat.includes('a hh:mm')) {
      return 'a HH:mm:ss';
    }

    return 'HH:mm:ss';
  }, [datetimePreference?.data?.datetimeFormat]);

  const datetimeFormat = useCallback(
    (datetime: any) => {
      return normalizedDatetime(datetime, getDatetimeFormat);
    },
    [getDatetimeFormat, normalizedDatetime]
  );

  useEffect(() => {
    squeezeText();
  }, [squeezeText]);

  const renderAlarmEvents = useMemo(() => {
    let timeFontSize: number;
    if (!!displayData.length) {
      return displayData.map((e, index) => {
        if (!!e) {
          const formattedTime = datetimeFormat(e.datetime);
          timeFontSize =
            getDatetimeFormat !== 'HH:mm:ss' ? getFontSize - 2 : getFontSize;
          return (
            <div
              key={`${e.eventCode}_${index}`}
              className={styles.alarmWrapper}
            >
              <div
                style={{
                  fontSize: `${timeFontSize}px`,
                  color: getAlarmEventColor(e.eventCode),
                }}
              >
                {formattedTime}
              </div>
              <div
                className={styles.alarmEvent}
                style={{
                  fontSize: `${getFontSize}px`,
                  backgroundColor: getAlarmEventColor(e.eventCode),
                  width: getAlarmEventWidth,
                  height: getAlarmEventHeight,
                }}
              >
                {e.alarmEvent}
              </div>
            </div>
          );
        }

        return <div key={index}></div>;
      });
    }

    const latestAlarm = getAlarmByCode(currentDoorStatus?.alarm);
    timeFontSize =
      !!currentDoorStatus && currentDoorStatus?.lastOpenTime.length > 10
        ? getFontSize - 2
        : getFontSize;
    if (!!Array.isArray(latestAlarm)) {
      return latestAlarm.map((alarm) => {
        return (
          <div
            key={`${alarm}_${index}`}
            className={styles.alarmWrapper}
            style={{
              width: getAlarmEventWidth,
              height: getAlarmEventHeight,
            }}
          >
            <div
              style={{
                fontSize: `${timeFontSize}px`,
                color: getAlarmEventColor(alarm),
              }}
            >
              {currentDoorStatus?.lastOpenTime}
            </div>
            <div
              className={styles.alarmEvent}
              style={{
                fontSize: `${getFontSize}px`,
                backgroundColor: getAlarmEventColor(alarm),
                width: getAlarmEventWidth,
                height: getAlarmEventHeight,
              }}
            >
              {alarm}
            </div>
          </div>
        );
      });
    }

    if (!!latestAlarm) {
      return (
        <div className={styles.alarmWrapper} style={{}}>
          <div
            style={{
              fontSize: `${timeFontSize}px`,
              color: getAlarmEventColor(currentDoorStatus?.alarm),
            }}
          >
            {currentDoorStatus?.lastOpenTime}
          </div>
          <div
            className={styles.alarmEvent}
            style={{
              fontSize: `${getFontSize}px`,
              backgroundColor: getAlarmEventColor(latestAlarm),
              width: getAlarmEventWidth,
              height: getAlarmEventHeight,
            }}
          >
            {latestAlarm}
          </div>
        </div>
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    displayData,
    getAlarmByCode,
    currentDoorStatus,
    getFontSize,
    datetimeFormat,
    getDatetimeFormat,
    getAlarmEventWidth,
    getAlarmEventHeight,
    index,
  ]);

  const renderWidgetContent = () => {
    const imgSrc = getDoorImgSize;
    if (!getDoorsAndGroups.isLoading) {
      if (!checkDoorIsExisted) {
        return (
          <div className={styles.widgetContent}>
            <div className={styles.deletedInfoWrapper}>
              <img
                src={iconDeleted}
                alt="icon-deleted"
                className={styles.iconDeleted}
              />
              <span className={styles.deletedInfo}>
                <b>{t('reportApp.doorControl.deletedInfoMessage')}​</b>
              </span>
            </div>
          </div>
        );
      }

      return (
        <div className={styles.widgetContent}>
          <div className={styles.alarmEventWrapper}>{renderAlarmEvents}</div>
          <div className={styles.doorStatusWrapper}>
            <img src={imgSrc} className={styles.door} alt="" />
          </div>
          <ButtonDropdown
            data={doorActions}
            customClassName={styles.button}
            title={t('reportApp.doorControl.actionsBtn')}
            height={height}
          ></ButtonDropdown>
        </div>
      );
    }
  };

  useEffect(() => {
    if (!!checkDoorIsExisted && !isSettings) {
      startDoorEvents();
    }

    if (!checkDoorIsExisted && !!handleDeletedDoor) {
      handleDeletedDoor(data.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkDoorIsExisted, isSettings, startDoorEvents]);

  return (
    <Box position="relative" height="100%" id={`${data.widgetType}-${index}`}>
      <div className={styles.widgetTitleWrapper}>
        <h2 className={styles.widgetTitle} id={titleId}>
          {data.widgetName}
        </h2>
      </div>
      {!isSettings && renderWidgetContent()}
      {isSettings && (
        <Box
          position="absolute"
          top="50%"
          left="50%"
          sx={{ transform: 'translate(-50%, -50%)' }}
          fontSize="16px"
          color="var(--neutral-color-4)"
        >
          {t('reportApp.noData')}
        </Box>
      )}
      <UserSelectionDialog
        open={userSelection.isOpen}
        onClose={userSelection.closeDialog}
        doorId={data.doorId}
        onApply={onApplyClearAPB}
        loading={isLoading}
      />
    </Box>
  );
};
