import { BsAdminApi } from 'api-client';
import { useQuery } from 'react-query';
import { apiConfig } from 'shared/utils/api-config';
import { decrypt } from 'shared/utils/encrypt-decrypt';

export function useGetCredential(options: any = true) {
  const api = new BsAdminApi(apiConfig());

  return useQuery(
    ['adminCredential'],
    async () => {
      const { data } = await api.getAdminCredential();
      if (!data) {
        return undefined;
      }

      return {
        user: data.user,
        password: decrypt(data.password),
      };
    },
    {
      suspense: options?.suspense,
    }
  );
}
