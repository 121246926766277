import { CircularProgress } from '@mui/material';
import { useTranslation } from 'core/context/i18n.context';
import { useEffect, useMemo, useState } from 'react';
import { ButtonPrimary } from 'shared/components/buttons/button-primary';
import { BaseDialog } from 'shared/components/dialogs/base-dialog';
import { useErrorDialog } from 'shared/contexts/error-dialog.context';
import styles from './index.module.css';
import {
  FilterEvent,
  FilterObjectDataItem,
} from 'shared/components/filter-conditions/filter-event';
import { useEventType } from 'generate-report/hooks/use-event-type';
import { useRegenerateCustomReport } from 'generate-report/hooks/use-regenerate-custom-report';
import { useEventCategory } from 'generate-report/hooks/use-event-category';
import { RegenerateReportDto } from 'api-client';
import { ButtonDefault } from 'shared/components/buttons/button-default';

interface GenerateCustomReportProps {
  open: boolean;
  onClose: () => void;
  onSuccess: (id: number) => void;
  value: any;
  reportId?: number;
}

export const UpdateEventFilter: React.FC<GenerateCustomReportProps> = ({
  open,
  onClose,
  value,
  reportId,
  onSuccess,
}) => {
  const { t } = useTranslation();
  const eventCategories = useEventCategory();
  const eventTypes = useEventType();

  const isLoading = useMemo(
    () => eventCategories.isLoading || eventTypes.isLoading,
    [eventCategories.isLoading, eventTypes.isLoading]
  );

  const regenerateCustomReportMutation = useRegenerateCustomReport(reportId!);
  const { showErrorDialog } = useErrorDialog();

  const [selectedEventTypes, setSelectedEventTypes] = useState<any[]>(value);

  useEffect(() => {
    setSelectedEventTypes(value);
  }, [value]);

  const validations = useMemo(() => {
    const errors = [];
    if (!selectedEventTypes?.length) {
      errors.push('required');
    }
    return errors;
  }, [selectedEventTypes]);

  const haveErrors = useMemo(() => {
    return !!validations.length;
  }, [validations]);

  const handleRegenerateReport = () => {
    if (haveErrors) {
      return showErrorDialog({
        alertType: 'error',
        message: t('reportApp.filterConditionRequired'),
      });
    }

    const payload: RegenerateReportDto = {
      data: {
        events: selectedEventTypes,
      },
    };

    regenerateCustomReportMutation.mutate(payload, {
      onError: (error) =>
        showErrorDialog({
          alertType: 'error',
          message: error.message,
        }),
      onSuccess: (data) => onSuccess(data.id),
    });
  };

  const DialogActions = () => {
    return (
      <>
        <ButtonPrimary
          title={t('reportApp.regenerate')}
          size="small"
          className={styles.dialogActionButton}
          onClick={handleRegenerateReport}
          loading={regenerateCustomReportMutation.isLoading}
          disabled={isLoading}
        ></ButtonPrimary>
        <ButtonDefault
          title={t('reportApp.cancel')}
          onClick={onClose}
          size="small"
          className={styles.dialogActionButton}
        ></ButtonDefault>
      </>
    );
  };

  const renderLoading = () => {
    return (
      <div className={styles.loadingWrapper}>
        <CircularProgress />
      </div>
    );
  };

  const renderStepError = () => {
    const errors = validations;
    const hasError = !!errors?.length;
    const firstError = hasError ? errors[0] : null;

    if (!hasError) return <></>;

    if (firstError === 'required') {
      return (
        <p className={styles.filterConditionRequired}>
          *{t('reportApp.filterConditionRequired')}
        </p>
      );
    }
  };

  return (
    <BaseDialog
      onClose={onClose}
      open={open}
      title={t('reportApp.filterCondition')}
      disableBackdropClick
      disableEscapeKeyDown
      actions={<DialogActions />}
      minWidth={900}
      minHeight={550}
    >
      {isLoading && renderLoading()}
      {!isLoading && (
        <>
          <FilterEvent
            dataSource={eventCategories.data as FilterObjectDataItem[]}
            value={selectedEventTypes}
            onChange={setSelectedEventTypes}
            multiple={true}
            eventTypeDataSource={eventTypes.data || []}
          />
          {renderStepError()}
        </>
      )}
    </BaseDialog>
  );
};
