/* tslint:disable */
/* eslint-disable */
/**
 * BioStar 2 Report API
 * API Documentation for BioStar 2 Report
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @interface ExportConfigDto
 */
export interface ExportConfigDto {
    /**
     * 
     * @type {string}
     * @memberof ExportConfigDto
     */
    context: ExportConfigDtoContext;
    /**
     * 
     * @type {string}
     * @memberof ExportConfigDto
     */
    name: ExportConfigDtoName;
    /**
     * 
     * @type {string | boolean}
     * @memberof ExportConfigDto
     */
    value?: string | boolean;
}

/**
    * @export
    * @enum {string}
    */
export enum ExportConfigDtoContext {
    ExportReport = 'exportReport'
}
/**
    * @export
    * @enum {string}
    */
export enum ExportConfigDtoName {
    ShowTitleOnEveryPage = 'showTitleOnEveryPage',
    ShowHeader = 'showHeader',
    ShowHeaderOnEveryPage = 'showHeaderOnEveryPage',
    ShowPageNumber = 'showPageNumber',
    PageSize = 'pageSize',
    FileFormat = 'fileFormat'
}



