import { useMemo } from 'react';
import { PlainSearchField } from 'shared/components/inputs/plain-search-field';
import { TreeProps, Tree } from 'shared/components/tree';
import { ObjectFilterDataNode } from './type';
import styles from './index.module.css';

interface FilterObjectViewProps {
  dataSource?: ObjectFilterDataNode[];
  checkedKeys?:
    | React.Key[]
    | { checked: React.Key[]; halfChecked: React.Key[] };
  checkedItems?: ObjectFilterDataNode[];
  onCheck?: TreeProps['onCheck'];
  onSearchChange?: React.ChangeEventHandler<HTMLInputElement>;
  onUserGroupSearchChange?: React.ChangeEventHandler<HTMLInputElement>;
  onEmailSearchChange?: React.ChangeEventHandler<HTMLInputElement>;
  onDepartmentSearchChange?: React.ChangeEventHandler<HTMLInputElement>;
  onTitleSearchChange?: React.ChangeEventHandler<HTMLInputElement>;
  onRemoveCheckedItem?: (item: ObjectFilterDataNode) => void;
  enableAdditionalFilters?: boolean;
  userGroupSearchValue?: string;
  departmentSearchValue?: string;
  titleSearchValue?: string;
  emailSearchValue?: string;
}

export const FilterObjectView: React.FC<FilterObjectViewProps> = ({
  dataSource = [],
  checkedKeys = [],
  checkedItems = [],
  onCheck,
  onSearchChange,
  onRemoveCheckedItem,
  enableAdditionalFilters,
  onUserGroupSearchChange,
  onEmailSearchChange,
  onDepartmentSearchChange,
  onTitleSearchChange,
  userGroupSearchValue,
  departmentSearchValue,
  titleSearchValue,
  emailSearchValue,
}) => {
  const haveDataSource = useMemo(() => !!dataSource?.length, [dataSource]);

  const removeCheckedItem = (item: ObjectFilterDataNode) => () => {
    onRemoveCheckedItem?.(item);
  };

  return (
    <div className={styles.mainWrapper}>
      <div className={styles.boxWrapper}>
        <div>
          <PlainSearchField
            handleSearch={onSearchChange}
            handleUserGroupSearch={onUserGroupSearchChange}
            handleEmailSearch={onEmailSearchChange}
            handleDepartmentSearch={onDepartmentSearchChange}
            handleTitleSearch={onTitleSearchChange}
            enableAdditionalFilters={enableAdditionalFilters}
            userGroupSearchValue={userGroupSearchValue}
            departmentSearchValue={departmentSearchValue}
            titleSearchValue={titleSearchValue}
            emailSearchValue={emailSearchValue}
          />
          <div className={styles.horizontalDivider}></div>
        </div>
        {haveDataSource && (
          <Tree
            height={270}
            treeData={dataSource}
            checkable
            selectable={false}
            defaultExpandAll
            checkedKeys={checkedKeys}
            onCheck={onCheck}
          />
        )}
      </div>

      <div className={styles.spacer}></div>

      <div className={styles.boxWrapper}>
        <ul className={styles.boxResult}>
          {checkedItems.map((item) => (
            <li key={item.id} className={styles.selectedItem}>
              <span className={styles.selectedItemText}>{item.name}</span>
              <button
                className={styles.btnDelete}
                onClick={removeCheckedItem(item)}
              ></button>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};
