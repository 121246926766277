type MainMenuItemKey =
  | 'generate'
  | 'custom'
  | 'schedule'
  | 'settings'
  | 'history';

export type MainMenuItem = {
  key: MainMenuItemKey;
  path: string;
};

export const mainMenuItems: MainMenuItem[] = [
  { key: 'generate', path: '/report/generate-report' },
  { key: 'schedule', path: '/report/schedule' },
  { key: 'settings', path: '/report/settings' },
  // TODO: uncomment when those features are ready
  // { key: 'custom', path: '/custom' },
  // { key: 'history', path: '/history' },
];
