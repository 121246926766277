import { CircularProgress } from '@mui/material';
import { useTranslation } from 'core/context/i18n.context';
import { useEffect, useMemo, useState } from 'react';
import { ButtonPrimary } from 'shared/components/buttons/button-primary';
import { BaseDialog } from 'shared/components/dialogs/base-dialog';
import { FilterObjectDataItem } from 'shared/components/filter-conditions/filter-object-custom-report';
import { useErrorDialog } from 'shared/contexts/error-dialog.context';
import { WizardStepProps } from 'shared/components/wizard-event/index.type';
import styles from './index.module.css';
import { useFilterCondition } from 'shared/components/wizard-event/hooks/use-filter-condition';
import { useRegenerateCustomReport } from 'generate-report/hooks/use-regenerate-custom-report';
import { FilterTab } from 'shared/components/filter-conditions/filter-tab';
import { ButtonDefault } from 'shared/components/buttons/button-default';

interface GenerateCustomReportProps {
  open: boolean;
  onClose: () => void;
  onSuccess: (id: number) => void;
  step?: WizardStepProps;
  value: any[];
  reportId?: number;
}

export const UpdateUserDoorDeviceFilters: React.FC<GenerateCustomReportProps> =
  ({ open, onClose, step, value, reportId, onSuccess }) => {
    const { t } = useTranslation();
    const filterCondition = useFilterCondition([...(step ? [step] : [])]);
    const regenerateCustomReportMutation = useRegenerateCustomReport(reportId!);
    const { showErrorDialog } = useErrorDialog();

    const [filtersValue, setFiltersValue] = useState<any[]>([]);

    useEffect(() => {
      if (value && value.length) {
        setFiltersValue(value);
      }
    }, [value]);

    const isMultiple = useMemo(() => step?.type === 'objectMultiple', [step]);

    const validations = useMemo(() => {
      const errors = [];
      if (step?.rules?.includes('required') && !filtersValue?.length) {
        errors.push('required');
      }
      return errors;
    }, [filtersValue, step?.rules]);

    const haveErrors = useMemo(() => {
      return !!validations.length;
    }, [validations]);

    const handleRegenerateReport = () => {
      if (haveErrors) {
        return showErrorDialog({
          alertType: 'warning',
          message: t('reportApp.filterConditionRequired'),
        });
      }

      const [users, doors, devices] = filtersValue;
      const payload = {
        data: {
          users: users.map((data: any) => data.originalId),
          doors: doors.map((data: any) => data.originalId),
          devices: devices.map((data: any) => data.originalId),
        },
      };

      regenerateCustomReportMutation.mutate(payload, {
        onError: (error) =>
          showErrorDialog({
            alertType: 'warning',
            message: error.message,
          }),
        onSuccess: (data) => onSuccess(data.id),
      });
    };

    const DialogActions = () => {
      return (
        <>
          <ButtonPrimary
            title={t('reportApp.regenerate')}
            size="small"
            className={styles.dialogActionButton}
            onClick={handleRegenerateReport}
            loading={regenerateCustomReportMutation.isLoading}
            disabled={filterCondition.isLoading}
          ></ButtonPrimary>
          <ButtonDefault
            title={t('reportApp.cancel')}
            onClick={onClose}
            size="small"
            className={styles.dialogActionButton}
          ></ButtonDefault>
        </>
      );
    };

    const renderLoading = () => {
      return (
        <div className={styles.loadingWrapper}>
          <CircularProgress />
        </div>
      );
    };

    const renderStepError = () => {
      const errors = validations;
      const hasError = !!errors?.length;
      const firstError = hasError ? errors[0] : null;

      if (!hasError) return <></>;

      if (firstError === 'required') {
        return (
          <p className={styles.filterConditionRequired}>
            *{t('reportApp.filterConditionRequired')}
          </p>
        );
      }
    };

    return (
      <BaseDialog
        onClose={onClose}
        open={open}
        title={t('reportApp.filterCondition')}
        disableBackdropClick
        disableEscapeKeyDown
        actions={<DialogActions />}
      >
        {filterCondition.isLoading && renderLoading()}
        {!!filterCondition.steps.length && !filterCondition.isLoading && (
          <>
            <FilterTab
              dataSource={
                filterCondition.steps[0].data as FilterObjectDataItem[]
              }
              value={filtersValue}
              onChange={setFiltersValue}
              multiple={isMultiple}
            />
            {renderStepError()}
          </>
        )}
      </BaseDialog>
    );
  };
