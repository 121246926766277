import { Checkbox, CircularProgress, FormControlLabel } from '@mui/material';
import {
  ExportReportDtoFileFormat,
  PdfOptionsDto,
  PdfOptionsDtoPageSize,
} from 'api-client';
import classNames from 'classnames';
import bulletItem from 'core/assets/bl_item.png';
import { useTranslation } from 'core/context/i18n.context';
import React, { useEffect, useMemo, useState } from 'react';
import { ButtonDefault } from 'shared/components/buttons/button-default';
import { SelectField } from 'shared/components/inputs/select-field';
import { TextField } from 'shared/components/inputs/text-field';
import { useExportReport } from 'shared/hooks/use-export-report';
import { useExportReportConfig } from 'shared/hooks/use-export-report-config';
import { ButtonPrimary } from '../../buttons/button-primary';
import { BaseDialog } from '../base-dialog';
import styles from './index.module.css';

interface ExportDialogProps {
  open: boolean;
  onClose: () => void;
  report: {
    id: string;
    title: string;
  };
  onSuccess: () => void;
}

export const ExportDialog: React.FC<ExportDialogProps> = ({
  open,
  onClose,
  report,
  onSuccess,
}) => {
  const { t } = useTranslation();
  const {
    exportReportMutation,
    supportedFileFormatsQuery: supportedFileFormats,
    supportedPageSizesQuery: supportedPageSizes,
  } = useExportReport();
  const lastConfig = useExportReportConfig();

  const [exportFilename, setExportFilename] = useState<string>(report.title);
  const [fileFormat, setFileFormat] = useState<string>(
    ExportReportDtoFileFormat.Pdf
  );
  const [pageSize, setPageSize] = useState<PdfOptionsDtoPageSize>(
    PdfOptionsDtoPageSize.A4
  );
  const [exportOptions, setExportOptions] = useState<PdfOptionsDto>({
    showTitleOnEveryPage: false,
    showHeader: false,
    showHeaderOnEveryPage: false,
    showPageNumber: false,
    pageSize: pageSize,
  });

  const handleChangeFileFormat = (e: { id: string; name: string }) =>
    setFileFormat(e.id);

  const handleChangePageSize = (e: {
    id: PdfOptionsDtoPageSize;
    name: string;
  }) => setPageSize(e.id);

  const handleChangeShowTitle = (e: React.BaseSyntheticEvent) =>
    setExportOptions((prevItems: any) => ({
      ...prevItems,
      showTitleOnEveryPage: e.target.checked,
    }));

  const handleChangeShowHeader = (e: React.BaseSyntheticEvent) =>
    setExportOptions((prevItems: any) => ({
      ...prevItems,
      showHeader: e.target.checked,
      showHeaderOnEveryPage: !e.target.checked
        ? false
        : prevItems.showHeaderOnEveryPage,
    }));

  const handleChangeShowHeaderOnEveryPage = (e: React.BaseSyntheticEvent) =>
    setExportOptions((prevItems: any) => ({
      ...prevItems,
      showHeaderOnEveryPage: e.target.checked,
    }));

  const handleChangeShowPageNumber = (e: React.BaseSyntheticEvent) =>
    setExportOptions((prevItems: any) => ({
      ...prevItems,
      showPageNumber: e.target.checked,
    }));

  const handleChangeExportFileName = (e: React.BaseSyntheticEvent) =>
    setExportFilename(e.target.value);

  const exportReport = () => {
    exportReportMutation.mutate(
      {
        reportId: parseInt(report.id),
        title: report.title,
        fileName: exportFilename,
        fileFormat: fileFormat as ExportReportDtoFileFormat,
        options: exportOptions,
      },
      {
        onSuccess: onSuccess,
      }
    );
  };

  useEffect(() => {
    if (lastConfig.data && supportedFileFormats.data) {
      const { fileFormat: currentFileFormat, ...config } = lastConfig.data
        .map((config) => ({
          [config.name]: config.value,
        }))
        .reduce((prev, curr) => ({ ...prev, ...curr }), {});

      setFileFormat(
        (currentFileFormat as string) || ExportReportDtoFileFormat.Pdf
      );
      setExportOptions((prev) => ({ ...prev, ...config }));
    }
  }, [lastConfig.data, supportedFileFormats.data]);

  const showHeaderEveryPageDisabled = useMemo(
    () => !exportOptions.showHeader,
    [exportOptions]
  );

  const fileFormats = useMemo(() => {
    return supportedFileFormats.data?.map((val) => ({
      id: val,
      name: val,
    }));
  }, [supportedFileFormats.data]);

  const pageSizes = useMemo(() => {
    return supportedPageSizes.data?.map((val) => ({
      id: val,
      name: val,
    }));
  }, [supportedPageSizes.data]);

  const DialogActions = () => {
    return (
      <>
        {/* check whether report title is empty or not */}
        <ButtonPrimary
          title={t('reportApp.export')}
          size="small"
          loading={exportReportMutation.isLoading}
          onClick={exportReport}
          className={styles.buttonAction}
          disabled={!exportFilename}
        ></ButtonPrimary>

        <ButtonDefault
          title={t('reportApp.cancel')}
          size="small"
          onClick={onClose}
          className={styles.buttonAction}
        ></ButtonDefault>
      </>
    );
  };

  const renderDialogContent = () => {
    return (
      <>
        <div className={styles.container}>
          <img src={bulletItem} className={styles.bulletItem} alt="" />
          <span className={styles.label}>{t('reportApp.fileFormat')}</span>
          <SelectField
            name="fileFormat"
            dataSource={fileFormats}
            selectProps={{
              value: fileFormat,
              style: { width: 414, textTransform: 'uppercase' },
            }}
            onChange={handleChangeFileFormat}
            styleType="emboss"
            hidePlaceholder={true}
          ></SelectField>
        </div>
        <div className={styles.container}>
          <img src={bulletItem} className={styles.bulletItem} alt="" />
          <span className={classNames(styles.label, styles.titleLabel)}>
            {t('reportApp.fileName')}
          </span>
          <TextField
            name="reportTitle"
            inputProps={{
              type: 'text',
              value: exportFilename,
              style: { width: 414 },
              onChange: handleChangeExportFileName,
            }}
            required
          />
        </div>
        {fileFormat === 'pdf' && (
          <>
            <div className={styles.container}>
              <img src={bulletItem} className={styles.bulletItem} alt="" />
              <span className={styles.label}>{t('reportApp.title')}</span>
              <FormControlLabel
                control={
                  <Checkbox
                    sx={{
                      color: '#8c8282',
                      '&.Mui-checked': { color: '#8c8282' },
                    }}
                    size="small"
                  />
                }
                label={
                  <span className={styles.formControlLabel}>
                    {t('reportApp.showTitle')}
                  </span>
                }
                className={styles.checkboxTitle}
                onChange={handleChangeShowTitle}
                checked={exportOptions.showTitleOnEveryPage}
              />
            </div>
            <div className={styles.container}>
              <img src={bulletItem} className={styles.bulletItem} alt="" />
              <span className={styles.label}>{t('reportApp.header')}</span>
              <FormControlLabel
                control={
                  <Checkbox
                    sx={{
                      color: '#8c8282',
                      '&.Mui-checked': { color: '#8c8282' },
                    }}
                    size="small"
                  />
                }
                label={
                  <span className={styles.formControlLabel}>
                    {t('reportApp.showHeader')}
                  </span>
                }
                className={styles.checkboxHeader}
                onChange={handleChangeShowHeader}
                checked={exportOptions.showHeader}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    sx={{
                      color: '#8c8282',
                      '&.Mui-checked': { color: '#8c8282' },
                    }}
                    size="small"
                  />
                }
                label={
                  <span className={styles.formControlLabel}>
                    {t('reportApp.onEveryPage')}
                  </span>
                }
                className={styles.checkboxEveryPage}
                onChange={handleChangeShowHeaderOnEveryPage}
                checked={exportOptions.showHeaderOnEveryPage}
                disabled={showHeaderEveryPageDisabled}
              />
            </div>
            <div className={styles.container}>
              <img src={bulletItem} className={styles.bulletItem} alt="" />
              <span className={styles.label}>{t('reportApp.footer')}</span>
              <FormControlLabel
                control={
                  <Checkbox
                    sx={{
                      color: '#8c8282',
                      '&.Mui-checked': { color: '#8c8282' },
                    }}
                    size="small"
                  />
                }
                label={
                  <span className={styles.formControlLabel}>
                    {t('reportApp.pageNumber')}
                  </span>
                }
                onChange={handleChangeShowPageNumber}
                checked={exportOptions.showPageNumber}
              />
            </div>
            <div className={styles.container}>
              <img src={bulletItem} className={styles.bulletItem} alt="" />
              <span className={styles.label}>{t('reportApp.pageSize')}</span>
              <SelectField
                name="pageSize"
                dataSource={pageSizes}
                selectProps={{
                  value: PdfOptionsDtoPageSize.A4,
                  style: { width: 414, textTransform: 'uppercase' },
                }}
                onChange={handleChangePageSize}
                styleType="emboss"
                hidePlaceholder={true}
              ></SelectField>
            </div>
          </>
        )}
      </>
    );
  };

  return (
    <BaseDialog
      onClose={onClose}
      open={open}
      title={t('reportApp.exportReport')}
      disableBackdropClick
      disableEscapeKeyDown
      actions={<DialogActions />}
    >
      <div className={styles.containerWrapper}>
        {lastConfig.isLoading && <CircularProgress />}
        {lastConfig.isFetched && renderDialogContent()}
      </div>
    </BaseDialog>
  );
};
