import { WidgetConditionDto } from 'api-client';
import { useTranslation } from 'core/context/i18n.context';
import {
  endOfDay,
  endOfWeek,
  startOfDay,
  startOfWeek,
  subDays,
  subWeeks,
} from 'date-fns';

export interface EventData {
  id: string;
  name: string;
  data: WidgetConditionDto[];
}

export function useChartEvents(): EventData[] {
  const today = new Date();
  const { t } = useTranslation();

  const getPastWeeks = (week: number) => {
    const pastWeeks = subWeeks(today, week);
    const startWeekDay = startOfWeek(pastWeeks);
    const endWeekDay = endOfWeek(pastWeeks);

    return {
      startWeekDay,
      endWeekDay,
    };
  };

  const getPastDays = (days: number) => {
    const pastDays = subDays(today, days);

    return {
      startDay: startOfDay(pastDays),
      endDay: endOfDay(pastDays),
    };
  };

  return [
    {
      id: 'dailyAccessGranted',
      name: t('reportApp.dailyAccessGranted'),
      data: [
        {
          events: [
            '4096',
            '4097',
            '4098',
            '4099',
            '4100',
            '4101',
            '4102',
            '4103',
            '4104',
            '4105',
            '4106',
            '4107',
            '4108',
            '4109',
            '4110',
            '4111',
            '4112',
            '4113',
            '4114',
            '4115',
            '4118',
            '4119',
            '4120',
            '4121',
            '4122',
            '4123',
            '4128',
            '4129',
            '4133',
            '4134',
            '4135',
            '4136',
            '4137',
            '4138',
            '4139',
            '4140',
            '4864',
            '4865',
            '4866',
            '4867',
            '4868',
            '4869',
            '4870',
            '4871',
            '4872',
            '5632',
            '5632',
            '6912',
            '6913',
            '6914',
            '6915',
          ],
          period: {
            startTimeBack: getPastDays(4).startDay as any,
            endTimeBack: getPastDays(4).endDay as any,
          },
        },
        {
          events: [
            '4096',
            '4097',
            '4098',
            '4099',
            '4100',
            '4101',
            '4102',
            '4103',
            '4104',
            '4105',
            '4106',
            '4107',
            '4108',
            '4109',
            '4110',
            '4111',
            '4112',
            '4113',
            '4114',
            '4115',
            '4118',
            '4119',
            '4120',
            '4121',
            '4122',
            '4123',
            '4128',
            '4129',
            '4133',
            '4134',
            '4135',
            '4136',
            '4137',
            '4138',
            '4139',
            '4140',
            '4864',
            '4865',
            '4866',
            '4867',
            '4868',
            '4869',
            '4870',
            '4871',
            '4872',
            '5632',
            '5632',
            '6912',
            '6913',
            '6914',
            '6915',
          ],
          period: {
            startTimeBack: getPastDays(3).startDay as any,
            endTimeBack: getPastDays(3).endDay as any,
          },
        },
        {
          events: [
            '4096',
            '4097',
            '4098',
            '4099',
            '4100',
            '4101',
            '4102',
            '4103',
            '4104',
            '4105',
            '4106',
            '4107',
            '4108',
            '4109',
            '4110',
            '4111',
            '4112',
            '4113',
            '4114',
            '4115',
            '4118',
            '4119',
            '4120',
            '4121',
            '4122',
            '4123',
            '4128',
            '4129',
            '4133',
            '4134',
            '4135',
            '4136',
            '4137',
            '4138',
            '4139',
            '4140',
            '4864',
            '4865',
            '4866',
            '4867',
            '4868',
            '4869',
            '4870',
            '4871',
            '4872',
            '5632',
            '5632',
            '6912',
            '6913',
            '6914',
            '6915',
          ],
          period: {
            startTimeBack: getPastDays(2).startDay as any,
            endTimeBack: getPastDays(2).endDay as any,
          },
        },
        {
          events: [
            '4096',
            '4097',
            '4098',
            '4099',
            '4100',
            '4101',
            '4102',
            '4103',
            '4104',
            '4105',
            '4106',
            '4107',
            '4108',
            '4109',
            '4110',
            '4111',
            '4112',
            '4113',
            '4114',
            '4115',
            '4118',
            '4119',
            '4120',
            '4121',
            '4122',
            '4123',
            '4128',
            '4129',
            '4133',
            '4134',
            '4135',
            '4136',
            '4137',
            '4138',
            '4139',
            '4140',
            '4864',
            '4865',
            '4866',
            '4867',
            '4868',
            '4869',
            '4870',
            '4871',
            '4872',
            '5632',
            '5632',
            '6912',
            '6913',
            '6914',
            '6915',
          ],
          period: {
            startTimeBack: getPastDays(1).startDay as any,
            endTimeBack: getPastDays(1).endDay as any,
          },
        },
        {
          events: [
            '4096',
            '4097',
            '4098',
            '4099',
            '4100',
            '4101',
            '4102',
            '4103',
            '4104',
            '4105',
            '4106',
            '4107',
            '4108',
            '4109',
            '4110',
            '4111',
            '4112',
            '4113',
            '4114',
            '4115',
            '4118',
            '4119',
            '4120',
            '4121',
            '4122',
            '4123',
            '4128',
            '4129',
            '4133',
            '4134',
            '4135',
            '4136',
            '4137',
            '4138',
            '4139',
            '4140',
            '4864',
            '4865',
            '4866',
            '4867',
            '4868',
            '4869',
            '4870',
            '4871',
            '4872',
            '5632',
            '5632',
            '6912',
            '6913',
            '6914',
            '6915',
          ],
          period: {
            startTimeBack: getPastDays(0).startDay as any,
            endTimeBack: getPastDays(0).endDay as any,
          },
        },
      ],
    },
    {
      id: 'dailyAccessDenied',
      name: t('reportApp.dailyAccessDenied'),
      data: [
        {
          events: [
            '4352',
            '4353',
            '4354',
            '4355',
            '4356',
            '4357',
            '4358',
            '4359',
            '4360',
            '4361',
            '4362',
            '4363',
            '4364',
            '5120',
            '5123',
            '5124',
            '5125',
            '5126',
            '5127',
            '5888',
            '5889',
            '5890',
            '6144',
            '6145',
            '6146',
            '6147',
            '6148',
            '6149',
            '6400',
            '6401',
            '6402',
            '6403',
            '6404',
            '6405',
            '6406',
            '6407',
            '6408',
            '6409',
            '6410',
            '6411',
            '6412',
            '6414',
            '6415',
            '6418',
            '6419',
            '6420',
            '6421',
            '6422',
            '6656',
            '7168',
            '7169',
            '7170',
            '7188',
            '36864',
          ],
          period: {
            startTimeBack: getPastDays(4).startDay as any,
            endTimeBack: getPastDays(4).endDay as any,
          },
        },
        {
          events: [
            '4352',
            '4353',
            '4354',
            '4355',
            '4356',
            '4357',
            '4358',
            '4359',
            '4360',
            '4361',
            '4362',
            '4363',
            '4364',
            '5120',
            '5123',
            '5124',
            '5125',
            '5126',
            '5127',
            '5888',
            '5889',
            '5890',
            '6144',
            '6145',
            '6146',
            '6147',
            '6148',
            '6149',
            '6400',
            '6401',
            '6402',
            '6403',
            '6404',
            '6405',
            '6406',
            '6407',
            '6408',
            '6409',
            '6410',
            '6411',
            '6412',
            '6414',
            '6415',
            '6418',
            '6419',
            '6420',
            '6421',
            '6422',
            '6656',
            '7168',
            '7169',
            '7170',
            '7188',
            '36864',
          ],
          period: {
            startTimeBack: getPastDays(3).startDay as any,
            endTimeBack: getPastDays(3).endDay as any,
          },
        },
        {
          events: [
            '4352',
            '4353',
            '4354',
            '4355',
            '4356',
            '4357',
            '4358',
            '4359',
            '4360',
            '4361',
            '4362',
            '4363',
            '4364',
            '5120',
            '5123',
            '5124',
            '5125',
            '5126',
            '5127',
            '5888',
            '5889',
            '5890',
            '6144',
            '6145',
            '6146',
            '6147',
            '6148',
            '6149',
            '6400',
            '6401',
            '6402',
            '6403',
            '6404',
            '6405',
            '6406',
            '6407',
            '6408',
            '6409',
            '6410',
            '6411',
            '6412',
            '6414',
            '6415',
            '6418',
            '6419',
            '6420',
            '6421',
            '6422',
            '6656',
            '7168',
            '7169',
            '7170',
            '7188',
            '36864',
          ],
          period: {
            startTimeBack: getPastDays(2).startDay as any,
            endTimeBack: getPastDays(2).endDay as any,
          },
        },
        {
          events: [
            '4352',
            '4353',
            '4354',
            '4355',
            '4356',
            '4357',
            '4358',
            '4359',
            '4360',
            '4361',
            '4362',
            '4363',
            '4364',
            '5120',
            '5123',
            '5124',
            '5125',
            '5126',
            '5127',
            '5888',
            '5889',
            '5890',
            '6144',
            '6145',
            '6146',
            '6147',
            '6148',
            '6149',
            '6400',
            '6401',
            '6402',
            '6403',
            '6404',
            '6405',
            '6406',
            '6407',
            '6408',
            '6409',
            '6410',
            '6411',
            '6412',
            '6414',
            '6415',
            '6418',
            '6419',
            '6420',
            '6421',
            '6422',
            '6656',
            '7168',
            '7169',
            '7170',
            '7188',
            '36864',
          ],
          period: {
            startTimeBack: getPastDays(1).startDay as any,
            endTimeBack: getPastDays(1).endDay as any,
          },
        },
        {
          events: [
            '4352',
            '4353',
            '4354',
            '4355',
            '4356',
            '4357',
            '4358',
            '4359',
            '4360',
            '4361',
            '4362',
            '4363',
            '4364',
            '5120',
            '5123',
            '5124',
            '5125',
            '5126',
            '5127',
            '5888',
            '5889',
            '5890',
            '6144',
            '6145',
            '6146',
            '6147',
            '6148',
            '6149',
            '6400',
            '6401',
            '6402',
            '6403',
            '6404',
            '6405',
            '6406',
            '6407',
            '6408',
            '6409',
            '6410',
            '6411',
            '6412',
            '6414',
            '6415',
            '6418',
            '6419',
            '6420',
            '6421',
            '6422',
            '6656',
            '7168',
            '7169',
            '7170',
            '7188',
            '36864',
          ],
          period: {
            startTimeBack: getPastDays(0).startDay as any,
            endTimeBack: getPastDays(0).endDay as any,
          },
        },
      ],
    },
    {
      id: 'dailyCommunicationIssue',
      name: t('reportApp.dailyCommunicationIssue'),
      data: [
        {
          events: [
            '13056',
            '13312',
            '13568',
            '15104',
            '15120',
            '15376',
            '15360',
            '15616',
            '15872',
          ],
          period: {
            startTimeBack: getPastDays(4).startDay as any,
            endTimeBack: getPastDays(4).endDay as any,
          },
        },
        {
          events: [
            '13056',
            '13312',
            '13568',
            '15104',
            '15120',
            '15376',
            '15360',
            '15616',
            '15872',
          ],
          period: {
            startTimeBack: getPastDays(3).startDay as any,
            endTimeBack: getPastDays(3).endDay as any,
          },
        },
        {
          events: [
            '13056',
            '13312',
            '13568',
            '15104',
            '15120',
            '15376',
            '15360',
            '15616',
            '15872',
          ],
          period: {
            startTimeBack: getPastDays(2).startDay as any,
            endTimeBack: getPastDays(2).endDay as any,
          },
        },
        {
          events: [
            '13056',
            '13312',
            '13568',
            '15104',
            '15120',
            '15376',
            '15360',
            '15616',
            '15872',
          ],
          period: {
            startTimeBack: getPastDays(1).startDay as any,
            endTimeBack: getPastDays(1).endDay as any,
          },
        },
        {
          events: [
            '13056',
            '13312',
            '13568',
            '15104',
            '15120',
            '15376',
            '15360',
            '15616',
            '15872',
          ],
          period: {
            startTimeBack: getPastDays(0).startDay as any,
            endTimeBack: getPastDays(0).endDay as any,
          },
        },
      ],
    },
    {
      id: 'dailyAPBViolation',
      name: t('reportApp.dailyAPBViolation'),
      data: [
        {
          events: ['6408', '6405'],
          period: {
            startTimeBack: getPastDays(4).startDay as any,
            endTimeBack: getPastDays(4).endDay as any,
          },
        },
        {
          events: ['6408', '6405'],
          period: {
            startTimeBack: getPastDays(3).startDay as any,
            endTimeBack: getPastDays(3).endDay as any,
          },
        },
        {
          events: ['6408', '6405'],
          period: {
            startTimeBack: getPastDays(2).startDay as any,
            endTimeBack: getPastDays(2).endDay as any,
          },
        },
        {
          events: ['6408', '6405'],
          period: {
            startTimeBack: getPastDays(1).startDay as any,
            endTimeBack: getPastDays(1).endDay as any,
          },
        },
        {
          events: ['6408', '6405'],
          period: {
            startTimeBack: getPastDays(0).startDay as any,
            endTimeBack: getPastDays(0).endDay as any,
          },
        },
      ],
    },
    {
      id: 'weeklyAccessGranted',
      name: t('reportApp.weeklyAccessGranted'),
      data: [
        {
          events: [
            '4096',
            '4097',
            '4098',
            '4099',
            '4100',
            '4101',
            '4102',
            '4103',
            '4104',
            '4105',
            '4106',
            '4107',
            '4108',
            '4109',
            '4110',
            '4111',
            '4112',
            '4113',
            '4114',
            '4115',
            '4118',
            '4119',
            '4120',
            '4121',
            '4122',
            '4123',
            '4128',
            '4129',
            '4133',
            '4134',
            '4135',
            '4136',
            '4137',
            '4138',
            '4139',
            '4140',
            '4864',
            '4865',
            '4866',
            '4867',
            '4868',
            '4869',
            '4870',
            '4871',
            '4872',
            '5632',
            '5632',
            '6912',
            '6913',
            '6914',
            '6915',
          ],
          period: {
            startTimeBack: getPastWeeks(4).startWeekDay,
            endTimeBack: getPastWeeks(4).endWeekDay,
          },
        },
        {
          events: [
            '4096',
            '4097',
            '4098',
            '4099',
            '4100',
            '4101',
            '4102',
            '4103',
            '4104',
            '4105',
            '4106',
            '4107',
            '4108',
            '4109',
            '4110',
            '4111',
            '4112',
            '4113',
            '4114',
            '4115',
            '4118',
            '4119',
            '4120',
            '4121',
            '4122',
            '4123',
            '4128',
            '4129',
            '4133',
            '4134',
            '4135',
            '4136',
            '4137',
            '4138',
            '4139',
            '4140',
            '4864',
            '4865',
            '4866',
            '4867',
            '4868',
            '4869',
            '4870',
            '4871',
            '4872',
            '5632',
            '5632',
            '6912',
            '6913',
            '6914',
            '6915',
          ],
          period: {
            startTimeBack: getPastWeeks(3).startWeekDay,
            endTimeBack: getPastWeeks(3).endWeekDay,
          },
        },
        {
          events: [
            '4096',
            '4097',
            '4098',
            '4099',
            '4100',
            '4101',
            '4102',
            '4103',
            '4104',
            '4105',
            '4106',
            '4107',
            '4108',
            '4109',
            '4110',
            '4111',
            '4112',
            '4113',
            '4114',
            '4115',
            '4118',
            '4119',
            '4120',
            '4121',
            '4122',
            '4123',
            '4128',
            '4129',
            '4133',
            '4134',
            '4135',
            '4136',
            '4137',
            '4138',
            '4139',
            '4140',
            '4864',
            '4865',
            '4866',
            '4867',
            '4868',
            '4869',
            '4870',
            '4871',
            '4872',
            '5632',
            '5632',
            '6912',
            '6913',
            '6914',
            '6915',
          ],
          period: {
            startTimeBack: getPastWeeks(2).startWeekDay,
            endTimeBack: getPastWeeks(2).endWeekDay,
          },
        },
        {
          events: [
            '4096',
            '4097',
            '4098',
            '4099',
            '4100',
            '4101',
            '4102',
            '4103',
            '4104',
            '4105',
            '4106',
            '4107',
            '4108',
            '4109',
            '4110',
            '4111',
            '4112',
            '4113',
            '4114',
            '4115',
            '4118',
            '4119',
            '4120',
            '4121',
            '4122',
            '4123',
            '4128',
            '4129',
            '4133',
            '4134',
            '4135',
            '4136',
            '4137',
            '4138',
            '4139',
            '4140',
            '4864',
            '4865',
            '4866',
            '4867',
            '4868',
            '4869',
            '4870',
            '4871',
            '4872',
            '5632',
            '5632',
            '6912',
            '6913',
            '6914',
            '6915',
          ],
          period: {
            startTimeBack: getPastWeeks(1).startWeekDay,
            endTimeBack: getPastWeeks(1).endWeekDay,
          },
        },
        {
          events: [
            '4096',
            '4097',
            '4098',
            '4099',
            '4100',
            '4101',
            '4102',
            '4103',
            '4104',
            '4105',
            '4106',
            '4107',
            '4108',
            '4109',
            '4110',
            '4111',
            '4112',
            '4113',
            '4114',
            '4115',
            '4118',
            '4119',
            '4120',
            '4121',
            '4122',
            '4123',
            '4128',
            '4129',
            '4133',
            '4134',
            '4135',
            '4136',
            '4137',
            '4138',
            '4139',
            '4140',
            '4864',
            '4865',
            '4866',
            '4867',
            '4868',
            '4869',
            '4870',
            '4871',
            '4872',
            '5632',
            '5632',
            '6912',
            '6913',
            '6914',
            '6915',
          ],
          period: {
            startTimeBack: getPastWeeks(0).startWeekDay,
            endTimeBack: getPastWeeks(0).endWeekDay,
          },
        },
      ],
    },
    {
      id: 'weeklyAccessDenied',
      name: t('reportApp.weeklyAccessDenied'),
      data: [
        {
          events: [
            '4352',
            '4353',
            '4354',
            '4355',
            '4356',
            '4357',
            '4358',
            '4359',
            '4360',
            '4361',
            '4362',
            '4363',
            '4364',
            '5120',
            '5123',
            '5124',
            '5125',
            '5126',
            '5127',
            '5888',
            '5889',
            '5890',
            '6144',
            '6145',
            '6146',
            '6147',
            '6148',
            '6149',
            '6400',
            '6401',
            '6402',
            '6403',
            '6404',
            '6405',
            '6406',
            '6407',
            '6408',
            '6409',
            '6410',
            '6411',
            '6412',
            '6414',
            '6415',
            '6418',
            '6419',
            '6420',
            '6421',
            '6422',
            '6656',
            '7168',
            '7169',
            '7170',
            '7188',
            '36864',
          ],
          period: {
            startTimeBack: getPastWeeks(4).startWeekDay,
            endTimeBack: getPastWeeks(4).endWeekDay,
          },
        },
        {
          events: [
            '4352',
            '4353',
            '4354',
            '4355',
            '4356',
            '4357',
            '4358',
            '4359',
            '4360',
            '4361',
            '4362',
            '4363',
            '4364',
            '5120',
            '5123',
            '5124',
            '5125',
            '5126',
            '5127',
            '5888',
            '5889',
            '5890',
            '6144',
            '6145',
            '6146',
            '6147',
            '6148',
            '6149',
            '6400',
            '6401',
            '6402',
            '6403',
            '6404',
            '6405',
            '6406',
            '6407',
            '6408',
            '6409',
            '6410',
            '6411',
            '6412',
            '6414',
            '6415',
            '6418',
            '6419',
            '6420',
            '6421',
            '6422',
            '6656',
            '7168',
            '7169',
            '7170',
            '7188',
            '36864',
          ],
          period: {
            startTimeBack: getPastWeeks(3).startWeekDay,
            endTimeBack: getPastWeeks(3).endWeekDay,
          },
        },
        {
          events: [
            '4352',
            '4353',
            '4354',
            '4355',
            '4356',
            '4357',
            '4358',
            '4359',
            '4360',
            '4361',
            '4362',
            '4363',
            '4364',
            '5120',
            '5123',
            '5124',
            '5125',
            '5126',
            '5127',
            '5888',
            '5889',
            '5890',
            '6144',
            '6145',
            '6146',
            '6147',
            '6148',
            '6149',
            '6400',
            '6401',
            '6402',
            '6403',
            '6404',
            '6405',
            '6406',
            '6407',
            '6408',
            '6409',
            '6410',
            '6411',
            '6412',
            '6414',
            '6415',
            '6418',
            '6419',
            '6420',
            '6421',
            '6422',
            '6656',
            '7168',
            '7169',
            '7170',
            '7188',
            '36864',
          ],
          period: {
            startTimeBack: getPastWeeks(2).startWeekDay,
            endTimeBack: getPastWeeks(2).endWeekDay,
          },
        },
        {
          events: [
            '4352',
            '4353',
            '4354',
            '4355',
            '4356',
            '4357',
            '4358',
            '4359',
            '4360',
            '4361',
            '4362',
            '4363',
            '4364',
            '5120',
            '5123',
            '5124',
            '5125',
            '5126',
            '5127',
            '5888',
            '5889',
            '5890',
            '6144',
            '6145',
            '6146',
            '6147',
            '6148',
            '6149',
            '6400',
            '6401',
            '6402',
            '6403',
            '6404',
            '6405',
            '6406',
            '6407',
            '6408',
            '6409',
            '6410',
            '6411',
            '6412',
            '6414',
            '6415',
            '6418',
            '6419',
            '6420',
            '6421',
            '6422',
            '6656',
            '7168',
            '7169',
            '7170',
            '7188',
            '36864',
          ],
          period: {
            startTimeBack: getPastWeeks(1).startWeekDay,
            endTimeBack: getPastWeeks(1).endWeekDay,
          },
        },
        {
          events: [
            '4352',
            '4353',
            '4354',
            '4355',
            '4356',
            '4357',
            '4358',
            '4359',
            '4360',
            '4361',
            '4362',
            '4363',
            '4364',
            '5120',
            '5123',
            '5124',
            '5125',
            '5126',
            '5127',
            '5888',
            '5889',
            '5890',
            '6144',
            '6145',
            '6146',
            '6147',
            '6148',
            '6149',
            '6400',
            '6401',
            '6402',
            '6403',
            '6404',
            '6405',
            '6406',
            '6407',
            '6408',
            '6409',
            '6410',
            '6411',
            '6412',
            '6414',
            '6415',
            '6418',
            '6419',
            '6420',
            '6421',
            '6422',
            '6656',
            '7168',
            '7169',
            '7170',
            '7188',
            '36864',
          ],
          period: {
            startTimeBack: getPastWeeks(0).startWeekDay,
            endTimeBack: getPastWeeks(0).endWeekDay,
          },
        },
      ],
    },
    {
      id: 'weeklyCommunicationIssue',
      name: t('reportApp.weeklyCommunicationIssue'),
      data: [
        {
          events: [
            '13056',
            '13312',
            '13568',
            '15104',
            '15120',
            '15376',
            '15360',
            '15616',
            '15872',
          ],
          period: {
            startTimeBack: getPastWeeks(4).startWeekDay,
            endTimeBack: getPastWeeks(4).endWeekDay,
          },
        },
        {
          events: [
            '13056',
            '13312',
            '13568',
            '15104',
            '15120',
            '15376',
            '15360',
            '15616',
            '15872',
          ],
          period: {
            startTimeBack: getPastWeeks(3).startWeekDay,
            endTimeBack: getPastWeeks(3).endWeekDay,
          },
        },
        {
          events: [
            '13056',
            '13312',
            '13568',
            '15104',
            '15120',
            '15376',
            '15360',
            '15616',
            '15872',
          ],
          period: {
            startTimeBack: getPastWeeks(2).startWeekDay,
            endTimeBack: getPastWeeks(2).endWeekDay,
          },
        },
        {
          events: [
            '13056',
            '13312',
            '13568',
            '15104',
            '15120',
            '15376',
            '15360',
            '15616',
            '15872',
          ],
          period: {
            startTimeBack: getPastWeeks(1).startWeekDay,
            endTimeBack: getPastWeeks(1).endWeekDay,
          },
        },
        {
          events: [
            '13056',
            '13312',
            '13568',
            '15104',
            '15120',
            '15376',
            '15360',
            '15616',
            '15872',
          ],
          period: {
            startTimeBack: getPastWeeks(0).startWeekDay,
            endTimeBack: getPastWeeks(0).endWeekDay,
          },
        },
      ],
    },
    {
      id: 'weeklyAPBViolation',
      name: t('reportApp.weeklyAPBViolation'),
      data: [
        {
          events: ['6408', '6405'],
          period: {
            startTimeBack: getPastWeeks(4).startWeekDay,
            endTimeBack: getPastWeeks(4).endWeekDay,
          },
        },
        {
          events: ['6408', '6405'],
          period: {
            startTimeBack: getPastWeeks(3).startWeekDay,
            endTimeBack: getPastWeeks(3).endWeekDay,
          },
        },
        {
          events: ['6408', '6405'],
          period: {
            startTimeBack: getPastWeeks(2).startWeekDay,
            endTimeBack: getPastWeeks(2).endWeekDay,
          },
        },
        {
          events: ['6408', '6405'],
          period: {
            startTimeBack: getPastWeeks(1).startWeekDay,
            endTimeBack: getPastWeeks(1).endWeekDay,
          },
        },
        {
          events: ['6408', '6405'],
          period: {
            startTimeBack: getPastWeeks(0).startWeekDay,
            endTimeBack: getPastWeeks(0).endWeekDay,
          },
        },
      ],
    },
  ];
}
