import { SchedulesApi } from 'api-client';
import { useQuery } from 'react-query';
import { useMemo } from 'react';
import { apiConfig } from 'shared/utils/api-config';

export function useGetSchedule(scheduleId: string) {
  const api = new SchedulesApi(apiConfig());

  const id = useMemo(() => {
    if (!scheduleId) return undefined;

    const value = parseInt(scheduleId);
    if (isNaN(value)) return undefined;

    return value;
  }, [scheduleId]);

  const fetchSchedule = async () => {
    const { data } = await api.getSchedule(id!);
    return data;
  };

  return useQuery(['schedules', id], fetchSchedule, { enabled: !!id });
}
