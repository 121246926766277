import { WidgetsApi } from 'api-client';
import { useQuery } from 'react-query';
import { apiConfig } from 'shared/utils/api-config';

export function useGetWidgets() {
  const api = new WidgetsApi(apiConfig());

  return useQuery(
    ['widgets'],
    async () => {
      const { data } = await api.getWidgets();
      return data;
    },
    { enabled: false }
  );
}
