import { PrintApi, PrintReportDto } from 'api-client';
import axios from 'axios';
import { useMutation, useQuery } from 'react-query';
import { apiConfig } from 'shared/utils/api-config';

export function usePrintReport() {
  const printApi = new PrintApi(apiConfig());

  const printReportMutation = useMutation((printReportDto: PrintReportDto) =>
    printApi
      .printReport(printReportDto)
      .then((res) => res.data)
      .catch((error) => {
        if (axios.isAxiosError(error)) {
          throw error.response!.data;
        }
        throw error;
      })
  );

  const fetchSupportedPageSizes = async () => {
    const { data } = await printApi.getSupportedPageSizes();
    return data;
  };

  const supportedPageSizesQuery = useQuery(
    'pageSizesPrint',
    fetchSupportedPageSizes
  );

  return {
    printReportMutation,
    supportedPageSizesQuery,
  };
}
