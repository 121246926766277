import { useCallback, useEffect, useState } from 'react';
import { createPortal } from 'react-dom';

export const Portal: React.FC = ({ children }) => {
  const [element] = useState(() => {
    const el = document.createElement('div');
    el.id = 'portal';
    return el;
  });

  const checkMuiDialogExist = useCallback(() => {
    const results = document.body.getElementsByClassName('MuiModal-root');
    return !!results.length;
  }, []);

  useEffect(() => {
    document.body.appendChild(element);
    document.body.style.setProperty('overflow', 'hidden');

    return () => {
      document.body.removeChild(element);

      // Remove overflow on body if there is no portal anymore.
      if (!checkMuiDialogExist()) {
        document.body.style.removeProperty('overflow');
      }
    };
  }, [element, checkMuiDialogExist]);

  return createPortal(children, element);
};
