/* tslint:disable */
/* eslint-disable */
/**
 * BioStar 2 Report API
 * API Documentation for BioStar 2 Report
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { CustomReportDataDto } from './custom-report-data-dto';
import { PredefinedReportDataDto } from './predefined-report-data-dto';

/**
 * 
 * @export
 * @interface GenerateReportDto
 */
export interface GenerateReportDto {
    /**
     * 
     * @type {string}
     * @memberof GenerateReportDto
     */
    type: GenerateReportDtoType;
    /**
     * 
     * @type {PredefinedReportDataDto | CustomReportDataDto}
     * @memberof GenerateReportDto
     */
    data: PredefinedReportDataDto | CustomReportDataDto;
}

/**
    * @export
    * @enum {string}
    */
export enum GenerateReportDtoType {
    PredefinedReport = 'predefinedReport',
    CustomReport = 'customReport'
}



