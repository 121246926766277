import { DashboardApi } from 'api-client';
import { useMemo } from 'react';
import { useQuery } from 'react-query';
import { apiConfig } from 'shared/utils/api-config';

export function useRefreshIntervalValue() {
  const api = new DashboardApi(apiConfig());

  const refreshInterval = useQuery(['currentRefreshInterval'], async () => {
    const { data } = await api.getCurrentRefreshInterval();
    return data;
  });

  const value = useMemo(
    () => refreshInterval.data || 60,
    [refreshInterval.data]
  );

  return value;
}
