import { DoorStatusDto } from 'api-client';
import { useTranslation } from 'core/context/i18n.context';
import { format, utcToZonedTime } from 'date-fns-tz';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDatetimePreference } from 'shared/hooks/use-datetime-preference';
import { useDoorStatus } from './use-door-status';
import { useGetDoorsAndGroups } from './use-get-doors-and-groups';
import { useIpAddress } from './use-ip-address';
import { useStartEvents } from './use-start-events';

interface BiostarWebsocketEvent {
  datetime: string;
  device_id: Record<string, string>;
  door_id_list: Record<string, string>[];
  event_type_id: Record<string, string>;
  id: string;
  image_id: Record<string, string>;
  index: string;
  is_dst: string;
  parameter: string;
  server_datetime: string;
  temperature: string;
  timezone: Record<string, string>;
  tna_key: string;
  user_id: Record<string, string>;
  elevator_id: Record<string, string>;
}

export interface DoorControlEvent {
  alarmEvent: string;
  doorStatus: string;
  eventCode: string;
  device: string;
  deviceId: string;
  id: string;
  index: string;
  door: string;
  user: string;
  datetime: string | Date;
  serverDatetime: string;
  timezone: Record<string, string>;
  temperature: string;
  elevator: string;
}

export function useDoorControl(doorId: string) {
  const { t } = useTranslation();
  const sessionId = sessionStorage.getItem('session');
  const datetimePreference = useDatetimePreference();
  const startEvents = useStartEvents();
  const ipAddress = useIpAddress();
  const getDoorStatus = useDoorStatus();
  const getDoorsAndGroups = useGetDoorsAndGroups();
  const alarmEvents = ['21504', '21760', '24577', '24578'];
  const doorStatus = ['20992', '21248', '23812', '20736', '20480'];
  const doorAlarms = ['21504', '4', '8', '12', '13', '14', '16'];

  const [currentDoorStatus, setCurrentDoorStatus] = useState<DoorStatusDto>();

  const checkDoorIsExisted = useMemo(() => {
    if (!getDoorsAndGroups.isLoading && !!getDoorsAndGroups.isSuccess) {
      const doorsAndGroups = getDoorsAndGroups.data;
      const doorIsExisted = doorsAndGroups.some(
        (door) => door.lastChild && door.originalId === doorId
      );

      return doorIsExisted;
    }
  }, [doorId, getDoorsAndGroups]);

  const isAlarmEvent = (code: string) => alarmEvents.includes(code);
  const isDoorStatus = (code: string) => doorStatus.includes(code);
  const getAlarmEventByCode = (code: string) => {
    if (code === '21504') {
      return t('reportApp.alarmEvent.forced');
    }

    if (code === '21760') {
      return t('reportApp.alarmEvent.held');
    }

    if (code === '24577' || code === '24578') {
      return t('reportApp.alarmEvent.apb');
    }

    return '';
  };

  const getDoorStatusByEventCode = (code: string) => {
    if (isAlarmEvent(code)) {
      return t('eventType.20992');
    }

    return t(`eventType.${code}`);
  };

  const [realTimeEventsStart, setRealTimeEventsStart] =
    useState<boolean>(false);
  const [currentEvent, setCurrentEvent] = useState<DoorControlEvent>();
  const [allEvents, setAllEvents] = useState<any[]>([]);
  const [forcedOpen, setForcedOpen] = useState<DoorControlEvent>();
  const [heldOpen, setHeldOpen] = useState<DoorControlEvent>();
  const [apbViolation, setAPBViolation] = useState<DoorControlEvent>();

  // const getIpAddress = useMemo(() => {
  //   if (!ipAddress.isLoading && ipAddress.status === 'success') {
  //     return ipAddress.data.hostname;
  //   }

  //   return 'localhost';
  // }, [ipAddress]);

  const getHostname = useMemo(() => window.location.hostname, []);

  const getPort = useMemo(() => {
    if (!ipAddress.isLoading && ipAddress.status === 'success') {
      return ipAddress.data.port;
    }

    return '443';
  }, [ipAddress]);

  const wsURL = useMemo(
    () => `wss://${getHostname}:${getPort}/wsapi`,
    [getHostname, getPort]
  );

  const getCurrentDoor = (data: DoorStatusDto[]) => {
    const filtered = data.find((d) => d.doorId === doorId);
    const lastOpenTime = parseInt(filtered?.lastOpenTime!);
    const transformed = {
      ...filtered,
      lastOpenTime:
        !!filtered?.lastOpenTime &&
        filtered.lastOpenTime !== '0' &&
        filtered.alarm !== '0'
          ? formatDatetime(lastOpenTime)
          : undefined,
    } as DoorStatusDto;

    if (doorAlarms.includes(transformed.alarm))
      setCurrentDoorStatus(transformed);
  };

  const clearEvents = () => {
    setCurrentEvent(undefined);
    setForcedOpen(undefined);
    setHeldOpen(undefined);
    setAPBViolation(undefined);
  };

  const fetchDoorStatus = () => {
    getDoorStatus.mutate(undefined, {
      onSuccess: (data: DoorStatusDto[]) => getCurrentDoor(data),
    });
  };

  const responseType = useCallback((event: any) => {
    if (event.Response) {
      return 'response';
    }

    if (event.Alert) {
      return 'alert';
    }

    return 'event';
  }, []);

  const datetimePreferenceFormat = useMemo(() => {
    if (!datetimePreference.isLoading && datetimePreference.isSuccess) {
      return datetimePreference.data.datetimeFormat;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [datetimePreference.isLoading, datetimePreference.isSuccess]);

  const getDatetimeFormat = useMemo(() => {
    if (datetimePreferenceFormat?.includes('hh:mm a')) {
      return 'HH:mm:ss a';
    }

    if (datetimePreferenceFormat?.includes('a hh:mm')) {
      return 'a HH:mm:ss';
    }

    if (datetimePreferenceFormat?.includes('hh:mm')) {
      return 'HH:mm:ss';
    }

    return 'HH:mm:ss';
  }, [datetimePreferenceFormat]);

  const checkDoorFromEvent = (data: any) => {
    const filtered = data.filter((d: any) => d.id === doorId);
    return !!filtered.length;
  };

  const transformEvents = (data: BiostarWebsocketEvent) => ({
    alarmEvent: getAlarmEventByCode(data.event_type_id.code),
    doorStatus: getDoorStatusByEventCode(data.event_type_id.code),
    eventCode: data.event_type_id.code,
    device: data.device_id.name,
    deviceId: data.device_id.id,
    id: data.id,
    index: data.index,
    door: (data.door_id_list || []).map((door: any) => door.name).join(', '),
    user: !!data.user_id ? data.user_id.name : '',
    datetime: data.datetime,
    serverDatetime: data.server_datetime,
    timezone: data.timezone,
    temperature: data.temperature,
    elevator: !!data.elevator_id ? data.elevator_id.name : '',
  });

  const formatDatetime = useCallback(
    (datetime: any) => {
      let paramDatetime = datetime;
      if (typeof datetime === 'number') {
        paramDatetime = datetime * 1000;
      }

      const zonedDatetime = utcToZonedTime(
        paramDatetime,
        datetimePreference?.data?.timezone!
      );
      const resultDatetime = format(zonedDatetime, getDatetimeFormat);

      return resultDatetime;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [getDatetimeFormat]
  );

  const startDoorEvents = useCallback(() => {
    let ws: any;
    if (!!wsURL) {
      ws = new WebSocket(wsURL);
    }

    if (!ipAddress.isLoading && !!ws) {
      ws.onopen = async (event: any) => {
        ws.send(`bs-session-id=${sessionId}`);
        if (!realTimeEventsStart) {
          setRealTimeEventsStart(true);
          await startEvents.mutateAsync();
        }
      };

      ws.onmessage = (event: any) => {
        if (!!event) {
          const eventData = JSON.parse(event.data);
          const type = responseType(eventData);
          if (type === 'event') {
            const { Event: currentEvent } = eventData;
            if (!!currentEvent) {
              if (currentEvent.event_type_id.code === '4867') {
                if (allEvents.length > 0) setAllEvents([]);
              }
              if (
                !!currentEvent.door_id_list &&
                Array.isArray(currentEvent.door_id_list)
              ) {
                if (checkDoorFromEvent(currentEvent.door_id_list)) {
                  const transformed = transformEvents(currentEvent);
                  setAllEvents((prev) => [...prev, transformed]);
                  if (isAlarmEvent(currentEvent.event_type_id.code)) {
                    if (transformed.eventCode === '21504') {
                      setForcedOpen(transformed);
                    }

                    if (transformed.eventCode === '21760') {
                      setHeldOpen(transformed);
                    }

                    if (
                      transformed.eventCode === '24577' ||
                      transformed.eventCode === '24578'
                    ) {
                      setAPBViolation(transformed);
                    }
                  }

                  if (
                    isAlarmEvent(currentEvent.event_type_id.code) ||
                    isDoorStatus(currentEvent.event_type_id.code)
                  ) {
                    setCurrentEvent(transformed);
                  }
                }
              }
            }
          }
        }
      };

      ws.onclose = (event: any) => {
        setTimeout(() => {
          startDoorEvents();
        }, 5000);
      };

      ws.onerror = (error: any) => {
        setTimeout(() => {
          startDoorEvents();
        }, 5000);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [wsURL, ipAddress.isLoading]);

  useEffect(() => {
    if (checkDoorIsExisted) {
      fetchDoorStatus();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    startDoorEvents,
    event: currentEvent,
    allEvents,
    realTimeEventsStart,
    isAlarmEvent,
    isDoorStatus,
    currentDoorStatus,
    fetchDoorStatus,
    clearEvents,
    checkDoorIsExisted,
    forcedOpen,
    heldOpen,
    apbViolation,
  };
}
