import {
  ErrorResponseDto,
  RegenerateReportDto,
  ReportDetailDto,
  ReportsApi,
} from 'api-client';
import axios from 'axios';
import { useMutation } from 'react-query';
import { apiConfig } from 'shared/utils/api-config';

export function useRegenerateReport() {
  const api = new ReportsApi(apiConfig());

  return useMutation<
    ReportDetailDto,
    ErrorResponseDto,
    { id: number; regenerateReportDto: RegenerateReportDto }
  >((data: { id: number; regenerateReportDto: RegenerateReportDto }) =>
    api
      .regenerateReport(data.id, data.regenerateReportDto)
      .then((res) => res.data)
      .catch((error) => {
        if (axios.isAxiosError(error)) {
          throw error.response!.data;
        }
        throw error;
      })
  );
}
