import {
  CustomReportConditionDto,
  CustomReportConditionDtoEvents,
  CustomReportDataDto,
  GenerateReportDtoType,
  PredefinedReportFilterConditionDto,
} from 'api-client';
import { useTranslation } from 'core/context/i18n.context';
import { useGenerateReport } from 'generate-report/hooks/use-generate-report';
import { useReportTemplate } from 'generate-report/hooks/use-report-template';
import { useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { ScreenLoading } from 'shared/components/screen-loading';
import { useErrorDialog } from 'shared/contexts/error-dialog.context';

interface GenerateReportByTemplateProps {
  reportTemplateId: string;
}

export const GenerateReportByTemplate: React.FC<GenerateReportByTemplateProps> =
  ({ reportTemplateId }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { showErrorDialog } = useErrorDialog();

    const reportTemplate = useReportTemplate(reportTemplateId);
    const generateReportMutation = useGenerateReport();

    const backToGenerateReportPage = useCallback(
      () => navigate('..'),
      [navigate]
    );

    const handleSuccessGeneratePredefinedReport = useCallback(
      (id: number) => navigate(`../reports/${id}`, { replace: true }),
      [navigate]
    );
    const handleSuccessGenerateCustomReport = useCallback(
      (id: number) =>
        navigate(`/report/generate-report/reports/${id}`, { replace: true }),
      [navigate]
    );

    const generateReport = useCallback(() => {
      if (!reportTemplate.data) return;

      const parsedReportTemplateValue = reportTemplate.data.value
        ? JSON.parse(reportTemplate.data.value)
        : undefined;

      const isPredefinedReport =
        !!parsedReportTemplateValue?.predefinedReportId;

      if (isPredefinedReport) {
        generatePredefinedReport({
          predefinedReportId: parsedReportTemplateValue.predefinedReportId,
          title: reportTemplate.data.name,
          filterConditions: parsedReportTemplateValue.filterConditions,
          columns: parsedReportTemplateValue.columns,
        });
      } else {
        generateCustomReport({
          title: reportTemplate.data.name,
          filter: parsedReportTemplateValue.filterConditions,
          columns: parsedReportTemplateValue.columns,
        });
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [reportTemplate.data]);

    const generatePredefinedReport = (data: {
      predefinedReportId: number;
      title: string;
      filterConditions: PredefinedReportFilterConditionDto[];
      columns: any[];
    }) => {
      generateReportMutation.mutate(
        {
          type: GenerateReportDtoType.PredefinedReport,
          data: {
            id: data.predefinedReportId,
            title: data.title,
            filterConditions: data.filterConditions,
            columns: data.columns,
          },
        },
        {
          onSuccess: (data: any) => handleSuccessGeneratePredefinedReport(data),
          onError: () =>
            showErrorDialog({
              alertType: 'error',
              message: t('reportApp.reportTemplateNotFound'),
              onClose: () => backToGenerateReportPage,
            }),
        }
      );
    };

    const generateCustomReport = (data: {
      title: string;
      filter: any;
      columns: any;
    }) => {
      const normalizedFilters: CustomReportConditionDto = {
        events: data.filter
          .events as unknown as CustomReportConditionDtoEvents[],
        period: data.filter.period,
        users: data.filter.users,
        doors: data.filter.doors,
        devices: data.filter.devices,
      };

      generateReportMutation.mutate(
        {
          type: GenerateReportDtoType.CustomReport,
          data: {
            title: data.title,
            condition: normalizedFilters,
            columns: data.columns,
          } as CustomReportDataDto,
        },
        {
          onSuccess: (data: any) => handleSuccessGenerateCustomReport(data),
          onError: () =>
            showErrorDialog({
              alertType: 'error',
              message: t('reportApp.reportTemplateNotFound'),
              onClose: () => backToGenerateReportPage,
            }),
        }
      );
    };

    useEffect(() => {
      if (reportTemplate.data) {
        generateReport();
      }
    }, [reportTemplate.data, generateReport]);

    if (reportTemplate.isLoading || generateReportMutation.isLoading) {
      return <ScreenLoading />;
    }

    return <></>;
  };
