/* tslint:disable */
/* eslint-disable */
/**
 * BioStar 2 Report API
 * API Documentation for BioStar 2 Report
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { PdfOptionsDto } from './pdf-options-dto';

/**
 * 
 * @export
 * @interface ExportReportDto
 */
export interface ExportReportDto {
    /**
     * 
     * @type {number}
     * @memberof ExportReportDto
     */
    reportId: number;
    /**
     * 
     * @type {string}
     * @memberof ExportReportDto
     */
    fileFormat: ExportReportDtoFileFormat;
    /**
     * 
     * @type {string}
     * @memberof ExportReportDto
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof ExportReportDto
     */
    fileName: string;
    /**
     * 
     * @type {PdfOptionsDto}
     * @memberof ExportReportDto
     */
    options?: PdfOptionsDto;
}

/**
    * @export
    * @enum {string}
    */
export enum ExportReportDtoFileFormat {
    Pdf = 'pdf',
    Csv = 'csv'
}



