import { ReportTemplatesApi } from 'api-client';
import { useQuery } from 'react-query';
import { apiConfig } from 'shared/utils/api-config';

/**
 * Get report templates that only have dynamic period.
 */
export function useGetReportTemplates() {
  const api = new ReportTemplatesApi(apiConfig());

  return useQuery(
    ['reportTemplatesOnSchedule'],
    async () => {
      const { data } = await api.getReportTemplates();
      const filteredData = data.filter((item) => {
        const val = !!item.value ? JSON.parse(item.value) : item.value;
        return val?.filterConditions?.period?.type === 'dynamic';
      });
      return filteredData;
    },
    { suspense: true }
  );
}
