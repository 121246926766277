import React, { useMemo } from 'react';
import { FilterObject } from 'shared/components/filter-conditions/filter-object';
import { FilterPeriod } from 'shared/components/filter-conditions/filter-period';
import { useTranslation } from 'core/context/i18n.context';
import { WizardStepProps } from './index.type';
import { useSteps } from 'shared/hooks/use-steps';
import { ButtonPrimary } from 'shared/components/buttons/button-primary';
import { useFilterCondition } from './hooks/use-filter-condition';
import { useFilterReducer } from './hooks/use-filter-reducer';
import { useGenerateReport } from 'generate-report/hooks/use-generate-report';
import { useErrorDialog } from 'shared/contexts/error-dialog.context';
import { BaseDialog } from 'shared/components/dialogs/base-dialog';
import {
  GenerateReportDtoType,
  PredefinedReportDto,
  PredefinedReportFilterConditionDto,
} from 'api-client';
import styles from './index.module.css';
import { CircularProgress } from '@mui/material';
import {
  FilterCheckbox,
  FilterCheckboxDataItem,
} from 'shared/components/filter-conditions/filter-checkbox';
import classNames from 'classnames';
import {
  FilterInputNumber,
  FilterInputNumberDataItem,
} from 'shared/components/filter-conditions/filter-input-number';
import { ButtonDefault } from '../buttons/button-default';
import { ObjectFilterDataNode } from '../filter-conditions/filter-object/type';

interface WizardProps {
  open: boolean;
  onClose: () => void;
  onSuccess: (id: number) => void;
  steps: WizardStepProps[];
  reportType?: PredefinedReportDto;
}

export const Wizard: React.FC<WizardProps> = ({
  steps,
  open,
  onClose,
  onSuccess,
  reportType,
}) => {
  const { t } = useTranslation();
  const wizardSteps = useSteps(steps);
  const filterCondition = useFilterCondition(steps);
  const generateReportMutation = useGenerateReport();
  const { showErrorDialog } = useErrorDialog();

  const [dataFilter, dispatchFilter] = useFilterReducer();

  const onChangeFilterObject =
    (stepIndex: number) => (data: ObjectFilterDataNode[]) => {
      return dispatchFilter({
        type: 'onChange',
        payload: { stepIndex, value: data },
      });
    };

  const onChangeFilterCheckbox =
    (stepIndex: number) => (data: FilterCheckboxDataItem[]) =>
      dispatchFilter({
        type: 'onChange',
        payload: { stepIndex, value: data },
      });

  const onChangeFilterPeriod = (stepIndex: number) => (data: any) => {
    return dispatchFilter({
      type: 'onChange',
      payload: { stepIndex, value: data },
    });
  };

  const onChangeFilterInputNumber =
    (stepIndex: number) => (data: FilterInputNumberDataItem[]) => {
      const val = data[0].value ? data : undefined;
      dispatchFilter({ type: 'onChange', payload: { stepIndex, value: val } });
    };

  const validations = useMemo(() => {
    return wizardSteps.orderedSteps.map((step, idx) => {
      const errors = [];
      if (step.rules.includes('required') && !dataFilter[idx]?.length) {
        errors.push('required');
      }

      if (step.rules.includes('limit') && !!dataFilter[idx]?.length) {
        const daysValidation =
          dataFilter[idx][0]?.name === 'days' &&
          (parseInt(dataFilter[idx][0]?.value) < 0 ||
            parseInt(dataFilter[idx][0]?.value) > 365);
        const monthsValidation =
          dataFilter[idx][0]?.name === 'months' &&
          (parseInt(dataFilter[idx][0]?.value) < 1 ||
            parseInt(dataFilter[idx][0]?.value) > 60);

        if (daysValidation || monthsValidation) {
          errors.push('limit');
        }
      }

      return errors;
    });
  }, [dataFilter, wizardSteps.orderedSteps]);

  const errorRequired = useMemo(() => {
    return validations[wizardSteps.activeStepIndex].includes('required');
  }, [validations, wizardSteps.activeStepIndex]);

  const errorLimit = useMemo(() => {
    return validations.some((v) => v.includes('limit'));
  }, [validations]);

  const generateReport = () => {
    if (!reportType) return;

    if (errorRequired) {
      return showErrorDialog({
        alertType: 'warning',
        message: t('reportApp.filterConditionRequired'),
      });
    }

    if (errorLimit) {
      const { name } = dataFilter[1][0];
      const minLimit = name === 'days' ? 0 : 1;
      const maxLimit = name === 'days' ? 365 : 60;
      const preferenceLang = localStorage.getItem('language');

      if (preferenceLang === 'ko') {
        return showErrorDialog({
          alertType: 'warning',
          message: `${t(`reportApp.${name}`)}${t('reportApp.limitNumber', [
            minLimit,
            maxLimit,
          ])}`,
        });
      }

      return showErrorDialog({
        alertType: 'warning',
        message: t('reportApp.limitNumber', [name, minLimit, maxLimit]),
      });
    }

    const payload = {
      type: GenerateReportDtoType.PredefinedReport,
      data: {
        id: reportType.id,
        filterConditions:
          reportType.filterConditions?.map<PredefinedReportFilterConditionDto>(
            (fc) => ({
              filterConditionId: fc.id,
              value: dataFilter[fc.defaultItemOrder - 1].map((d) =>
                !!d.originalId ? d.originalId : d
              ),
            })
          ),
      },
    };

    generateReportMutation.mutate(payload, {
      onSuccess: (data: any) => onSuccess(data),
      onError: (error) =>
        showErrorDialog({ alertType: 'error', message: error.message }),
    });
  };

  const activeTitle = useMemo(
    () => wizardSteps.activeStep?.title || t('reportApp.filterCondition'),
    [t, wizardSteps.activeStep]
  );

  const renderStep = (step: WizardStepProps) => {
    let component: React.ReactNode = <></>;

    if (['objectSingle', 'objectMultiple'].includes(step.type)) {
      component = (
        <FilterObject
          dataSource={step.data}
          defaultValues={dataFilter[step.stepIndex]}
          onChange={onChangeFilterObject(step.stepIndex)}
          multiple={step.type === 'objectMultiple'}
          enableAdditionalFilters={step.value === 'usersAndGroups'}
          isUserGroup={step.value === 'userGroups'}
          dataSourceType={step.value}
          hideDevices={step.value === 'devicesAndGroups'}
        />
      );
    }

    if (step.type === 'period') {
      component = (
        <FilterPeriod
          value={[]}
          onChange={onChangeFilterPeriod(step.stepIndex)}
        />
      );
    }

    if (step.type === 'checkbox') {
      component = (
        <FilterCheckbox
          dataSource={step.data}
          value={dataFilter[step.stepIndex]}
          onChange={onChangeFilterCheckbox(step.stepIndex)}
          isActive={wizardSteps.activeStepIndex === step.stepIndex}
        />
      );
    }

    if (step.type === 'number') {
      component = (
        <FilterInputNumber
          dataSource={step.data}
          value={dataFilter[step.stepIndex]}
          onChange={onChangeFilterInputNumber(step.stepIndex)}
          predefinedReportKeyName={reportType?.keyName}
        />
      );
    }

    return component;
  };

  const renderStepError = (step: WizardStepProps) => {
    const errors = validations[step.stepIndex];
    const hasError = !!errors.length;
    const firstError = hasError ? errors[0] : null;

    if (!hasError) return <></>;

    if (firstError === 'required') {
      return (
        <p className={styles.filterConditionRequired}>
          *{t('reportApp.filterConditionRequired')}
        </p>
      );
    }
  };

  const DialogActions = () => {
    return (
      <>
        {!wizardSteps.isFirstStep && (
          <ButtonPrimary
            title={t('reportApp.previous')}
            size="small"
            className={styles.dialogActionButton}
            onClick={wizardSteps.prevStep}
            disabled={filterCondition.isLoading}
          ></ButtonPrimary>
        )}
        {!wizardSteps.isLastStep && (
          <ButtonPrimary
            title={t('reportApp.next')}
            size="small"
            className={styles.dialogActionButton}
            onClick={wizardSteps.nextStep}
            disabled={errorRequired || filterCondition.isLoading}
          ></ButtonPrimary>
        )}
        {wizardSteps.isLastStep && (
          <ButtonPrimary
            title={t('reportApp.generate')}
            onClick={generateReport}
            loading={generateReportMutation.isLoading}
            disabled={errorRequired || filterCondition.isLoading}
            size="small"
            className={styles.dialogActionButton}
          ></ButtonPrimary>
        )}
        <ButtonDefault
          title={t('reportApp.cancel')}
          onClick={onClose}
          size="small"
          className={styles.dialogActionButton}
        ></ButtonDefault>
      </>
    );
  };

  const renderLoading = () => {
    return (
      <div className={styles.loadingWrapper}>
        <CircularProgress />
      </div>
    );
  };

  const dialogMinWidth = useMemo(() => {
    switch (wizardSteps.activeStep?.type) {
      case 'number':
      case 'checkbox':
        return 350;
      default:
        return 700;
    }
  }, [wizardSteps.activeStep?.type]);

  const dialogMinHeight = useMemo(() => {
    switch (wizardSteps.activeStep?.type) {
      case 'number':
      case 'checkbox':
        return 300;
      default:
        return 481;
    }
  }, [wizardSteps.activeStep?.type]);

  return (
    <BaseDialog
      onClose={onClose}
      open={open}
      title={activeTitle}
      disableBackdropClick
      disableEscapeKeyDown
      actions={<DialogActions />}
      minWidth={dialogMinWidth}
      minHeight={dialogMinHeight}
    >
      {filterCondition.isLoading && renderLoading()}

      {!!filterCondition.steps.length &&
        !filterCondition.isLoading &&
        filterCondition.steps.map((step) => (
          <div
            key={step.stepIndex}
            className={classNames(styles.step, {
              [styles.activeStep]:
                step.stepIndex === wizardSteps.activeStepIndex,
            })}
          >
            {renderStep(step)}
            {renderStepError(step)}
          </div>
        ))}
    </BaseDialog>
  );
};
