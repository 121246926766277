import { PredefinedReportsApi } from 'api-client';
import { useMemo } from 'react';
import { useQuery } from 'react-query';
import { apiConfig } from 'shared/utils/api-config';

export function useReportType(reportTypeId?: string) {
  const reportTypesApi = new PredefinedReportsApi(apiConfig());

  const id = useMemo(() => {
    if (!reportTypeId) return undefined;

    const value = parseInt(reportTypeId);
    if (isNaN(value)) return undefined;

    return value;
  }, [reportTypeId]);

  const fetchReportType = async () => {
    if (!id) return;
    const { data } = await reportTypesApi.getReportType(id!);
    return data;
  };

  return useQuery(['reportType', id], fetchReportType, { enabled: !!id });
}
