import { Box } from '@mui/system';
import { useTranslation } from 'core/context/i18n.context';
import { useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { InputError } from 'shared/components/input-error';
import { InputLabel } from 'shared/components/input-label';
import { InputWrapper } from 'shared/components/input-wrapper';
import {
  SelectField,
  SelectFieldDataSourceItem,
} from 'shared/components/inputs/select-field';
import { Chart } from 'shared/components/chart';
import { constructChartProps } from 'dashboard/helpers/construct-chart-props.helper';
import { WidgetItem } from 'dashboard/types/widget-item.type';
import { ChartProps } from 'react-chartjs-2';
import styles from 'dashboard/components/widget-dialog/chart-type-section.module.css';

interface ChartTypeSectionProps {
  form: ReturnType<typeof useForm>;
  detailForm: ReturnType<typeof useForm>;
  datasets?: ChartProps['data']['datasets'];
}

export const availableChartColors = [
  '#CB00FF,#CB3398,#EE3E3A,#FBBD10,#FE930C',
  '#8734B4,#4A51F5,#1A7F89,#23B9C5,#6ADCBA',
  '#FBBD10,#FE930C,#23B9C5,#4A51F5,#8734B4',
  '#FBBD10,#F26841,#CB3398,#6ADCBA,#1A7F89',
  '#4A51F5,#8089FF,#A7ADF9,#C0C4F9,#D4D8F9',
];

export const ChartTypeSection: React.FC<ChartTypeSectionProps> = ({
  form,
  detailForm,
  datasets,
}) => {
  const { t } = useTranslation();
  const {
    register,
    getValues,
    setValue,
    watch,
    formState: { errors },
  } = form;

  const chartColor = watch('chartColor');

  const chartTypes = useMemo<SelectFieldDataSourceItem[]>(
    () => [
      { id: 'verticalBar', name: t('reportApp.chartTypes.verticalBar') },
      { id: 'horizontalBar', name: t('reportApp.chartTypes.horizontalBar') },
      { id: 'line', name: t('reportApp.chartTypes.line') },
      { id: 'pie', name: t('reportApp.chartTypes.pie') },
    ],
    [t]
  );

  const chartColorSchemes = useMemo<SelectFieldDataSourceItem[]>(
    () => [
      {
        id: '#CB00FF,#CB3398,#EE3E3A,#FBBD10,#FE930C',
        name: t('reportApp.chartColorSchemes.colorScheme1'),
      },
      {
        id: '#8734B4,#4A51F5,#1A7F89,#23B9C5,#6ADCBA',
        name: t('reportApp.chartColorSchemes.colorScheme2'),
      },
      {
        id: '#FBBD10,#FE930C,#23B9C5,#4A51F5,#8734B4',
        name: t('reportApp.chartColorSchemes.colorScheme3'),
      },
      {
        id: '#FBBD10,#F26841,#CB3398,#6ADCBA,#1A7F89',
        name: t('reportApp.chartColorSchemes.colorScheme4'),
      },
      {
        id: '#4A51F5,#8089FF,#A7ADF9,#C0C4F9,#D4D8F9',
        name: t('reportApp.chartColorSchemes.colorScheme5'),
      },
    ],
    [t]
  );

  const chartTypeDefaultValue = useMemo(
    () => getValues('chartType') || '',
    [getValues]
  );

  const chartColorDefaultValue = useMemo(
    () => getValues('chartColor') || '',
    [getValues]
  );

  const chartTypeValue = watch('chartType');

  const detailFormValues = detailForm.watch();
  const formValues = form.watch();

  const chartProps = useMemo(() => {
    return constructChartProps({
      ...detailFormValues,
      ...formValues,
      datasets,
    } as WidgetItem);
  }, [detailFormValues, formValues, datasets]);

  const onChangeSelectField = (value: any, fieldName: string) => {
    setValue(fieldName, value.id);
  };

  useEffect(() => {
    if (!chartColor) {
      setValue('chartColor', '#CB00FF,#CB3398,#EE3E3A,#FBBD10,#FE930C');
    }
  }, [chartColor, setValue]);

  return (
    <Box display="flex" flexDirection="column" flex="1">
      <Box display="flex" flexDirection="column" mb="24px">
        <InputWrapper sx={{ height: '35px' }}>
          <InputLabel className={styles.inputLabel} showBullet>
            {t('reportApp.chartType')}
          </InputLabel>
          <Box maxWidth="400px" width="100%" position="relative" zIndex="3">
            <SelectField
              name="chartType"
              selectProps={{
                ...register('chartType', {
                  required: {
                    value: true,
                    message: t('reportApp.chartTypeRequired'),
                  },
                }),
                defaultValue: chartTypeDefaultValue,
                value: getValues('chartType'),
              }}
              onChange={(e) => onChangeSelectField(e, 'chartType')}
              placeholder={t('reportApp.chooseChartType')}
              dataSource={chartTypes}
            ></SelectField>
          </Box>
        </InputWrapper>
        <InputWrapper sx={{ height: '35px' }}>
          <InputLabel className={styles.inputLabel} showBullet>
            {t('reportApp.chartColorSchemes')}
          </InputLabel>
          <Box maxWidth="400px" width="100%">
            <SelectField
              name="chartColor"
              selectProps={{
                ...register('chartColor'),
                defaultValue: chartColorDefaultValue,
                value: getValues('chartColor'),
              }}
              onChange={(e) => onChangeSelectField(e, 'chartColor')}
              placeholder={t('reportApp.chooseColorScheme')}
              dataSource={chartColorSchemes}
            ></SelectField>
          </Box>
        </InputWrapper>
        <InputError>{errors.chartType?.message}</InputError>
      </Box>

      <Box
        flex="1"
        border="1px solid var(--neutral-color-4)"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        {!chartTypeValue && (
          <Box fontSize="16px" sx={{ opacity: 0.5 }}>
            {t('reportApp.preview')}
          </Box>
        )}

        {!!chartTypeValue && (
          <Box flex="1" height="250px">
            <Chart {...chartProps} redraw></Chart>
          </Box>
        )}
      </Box>
    </Box>
  );
};
