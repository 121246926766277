import { DashboardSettingDto } from 'api-client';
import { useEffect, useMemo } from 'react';
import { useGetDashboardSetting } from './use-get-dashboard-setting';
import { useSaveDashboardSetting } from './use-save-dashboard-setting';

type ReturnReqBody = Pick<DashboardSettingDto, 'id' | 'refreshInterval'>;

export function useDashboardSetting() {
  const dashboardSetting = useGetDashboardSetting();
  const saveSettingMutation = useSaveDashboardSetting();

  const transformBodyDataToForm = (data?: DashboardSettingDto) => {
    if (!data) return;
    return {
      autoRefreshInterval: data.refreshInterval,
    };
  };

  const transformFormDataToReqBody = (
    formData: Record<string, any>
  ): ReturnReqBody => {
    const reqBodyData: Partial<ReturnReqBody> = {
      refreshInterval: parseInt(formData.autoRefreshInterval),
    };

    if (dashboardSetting.data) {
      reqBodyData.id = dashboardSetting.data.id;
    }

    return reqBodyData as ReturnReqBody;
  };

  const saveSetting = (generalFormValue: Record<string, any>) => {
    const settingValue = transformFormDataToReqBody(generalFormValue);
    return saveSettingMutation.mutateAsync(settingValue);
  };

  const dashboardSettingFormData = useMemo(
    () => transformBodyDataToForm(dashboardSetting.data),
    [dashboardSetting.data]
  );

  useEffect(() => {
    dashboardSetting.refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { dashboardSettingFormData, saveSetting };
}
