import { BiostarInformationApi } from 'api-client';
import { useQuery } from 'react-query';
import { apiConfig } from 'shared/utils/api-config';

export function useIpAddress() {
  const biostarInformationApi = new BiostarInformationApi(apiConfig());

  async function getIpAddress() {
    const { data }: any = await biostarInformationApi.getServerInfo();
    return data;
  }

  return useQuery('currentIpAddress', getIpAddress);
}
