/* tslint:disable */
/* eslint-disable */
/**
 * BioStar 2 Report API
 * API Documentation for BioStar 2 Report
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { SaveWidgetAreaDto } from './save-widget-area-dto';
import { SaveWidgetDataItemDto } from './save-widget-data-item-dto';

/**
 * 
 * @export
 * @interface SaveWidgetDto
 */
export interface SaveWidgetDto {
    /**
     * 
     * @type {number}
     * @memberof SaveWidgetDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof SaveWidgetDto
     */
    name: string;
    /**
     * 
     * @type {SaveWidgetAreaDto}
     * @memberof SaveWidgetDto
     */
    widgetArea: SaveWidgetAreaDto;
    /**
     * 
     * @type {string}
     * @memberof SaveWidgetDto
     */
    widgetBase: SaveWidgetDtoWidgetBase;
    /**
     * 
     * @type {string}
     * @memberof SaveWidgetDto
     */
    widgetType: SaveWidgetDtoWidgetType;
    /**
     * 
     * @type {string}
     * @memberof SaveWidgetDto
     */
    chartType?: SaveWidgetDtoChartType;
    /**
     * 
     * @type {string}
     * @memberof SaveWidgetDto
     */
    chartColor?: string;
    /**
     * 
     * @type {Array<SaveWidgetDataItemDto>}
     * @memberof SaveWidgetDto
     */
    dataItems: Array<SaveWidgetDataItemDto>;
    /**
     * 
     * @type {object}
     * @memberof SaveWidgetDto
     */
    widgetValue?: object;
}

/**
    * @export
    * @enum {string}
    */
export enum SaveWidgetDtoWidgetBase {
    Event = 'event',
    NonEvent = 'nonEvent'
}
/**
    * @export
    * @enum {string}
    */
export enum SaveWidgetDtoWidgetType {
    Chart = 'chart',
    Counter = 'counter',
    Text = 'text',
    Webpage = 'webpage',
    SystemUsage = 'systemUsage',
    RealTimeEvents = 'realTimeEvents',
    Checkpoint = 'checkpoint',
    DoorControl = 'doorControl'
}
/**
    * @export
    * @enum {string}
    */
export enum SaveWidgetDtoChartType {
    VerticalBar = 'verticalBar',
    HorizontalBar = 'horizontalBar',
    Line = 'line',
    Pie = 'pie',
    Doughnut = 'doughnut'
}



