import memberPhoto from 'core/assets/member_photo.png';
import styles from './index.module.css';
import classNames from 'classnames';

export const WidgetDisplayImage: React.FC<any> = ({
  widgetData,
  isImageLog = false,
}) => {
  return (
    <div
      className={classNames(styles.imageWrapper, {
        [styles.loading]: !widgetData,
      })}
    >
      <img
        className={styles.checkpointImage}
        alt={isImageLog ? 'Image Log' : 'Profile Picture'}
        src={widgetData ? `data:image/jpeg;base64,${widgetData}` : memberPhoto}
        loading="lazy"
      />
    </div>
  );
};
