import { SearchField } from '../search-field';
import { useTranslation } from 'core/context/i18n.context';
import styles from './index.module.css';
import { useEffect, useMemo, useState } from 'react';
import { useFirstRender } from 'shared/hooks/use-first-render';
import { PaginationProps } from '../pagination';

const defaultRowsPerPageOptions = [25, 50, 100, 150];

export const TableRowsSelector: React.FC<PaginationProps> = ({
  count = 0,
  page = 1,
  rowsPerPageOptions = defaultRowsPerPageOptions,
  rowsPerPage,
  onRowsPerPageChange,
  search,
  onSearchChange,
}) => {
  const { t } = useTranslation();
  const isFirstRender = useFirstRender();
  const preferenceLang = localStorage.getItem('language');

  const [currentRowsPerPage, setCurrentRowsPerPage] = useState(rowsPerPage);
  const [currentSearch, setCurrentSearch] = useState(search);

  const activeRowsPerPage = useMemo(() => {
    if (!rowsPerPageOptions.length) return 0;

    const value = rowsPerPageOptions.find(
      (value: any) => value === currentRowsPerPage
    );
    // Use the first value of the rowsPerPageOptions,
    // if the rowsPerPage value is not exists on the options.
    if (!value) return rowsPerPageOptions[1];

    return value;
  }, [rowsPerPageOptions, currentRowsPerPage]);

  const totalPage = useMemo(() => {
    return Math.ceil(count / activeRowsPerPage);
  }, [count, activeRowsPerPage]);

  const shouldHavePagination = useMemo(() => totalPage > 0, [totalPage]);

  const handleRowsPerPage: React.ChangeEventHandler<HTMLSelectElement> = (
    event
  ) => {
    const value = parseInt(event.target.value);
    setCurrentRowsPerPage(value);
  };

  const handleSearchSubmit = (value: string) => {
    setCurrentSearch(value);
  };

  useEffect(() => {
    if (!isFirstRender && onRowsPerPageChange) {
      onRowsPerPageChange(activeRowsPerPage);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeRowsPerPage]);

  useEffect(() => {
    if (!isFirstRender && onSearchChange) {
      onSearchChange(currentSearch);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentSearch]);

  return (
    <div className={styles.wrapper}>
      {shouldHavePagination && (
        <select
          name="rows"
          onChange={handleRowsPerPage}
          value={activeRowsPerPage}
          className={styles.selectRowsPerPage}
        >
          {rowsPerPageOptions.map((row: any) => (
            <option key={row} value={row}>
              {preferenceLang === 'ko'
                ? `${row} ${t('reportApp.rows')}`
                : t('reportApp.rows', [row])}
            </option>
          ))}
        </select>
      )}
      <SearchField value={currentSearch} onSubmit={handleSearchSubmit} />
    </div>
  );
};
