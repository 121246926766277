import { RouteObjectExtended } from 'core/types';
import { FormSchedulePage } from './pages/form-schedule';
import { ScheduleReportPage } from './pages/schedule-report';

export const scheduleReportRoutes: RouteObjectExtended[] = [
  {
    path: '',
    element: <ScheduleReportPage />,
  },
  {
    path: 'new',
    element: <FormSchedulePage />,
  },
  {
    path: ':scheduleId',
    element: <FormSchedulePage />,
  },
];
