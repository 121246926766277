import { RouteObjectExtended } from 'core/types';
import { DashboardHomePage } from './pages/dashboard-home';
import { DashboardSettingsPage } from './pages/dashboard-settings';

export const dashboardRoutes: RouteObjectExtended[] = [
  {
    path: '',
    element: <DashboardHomePage />,
  },
  {
    path: 'settings',
    element: <DashboardSettingsPage />,
  },
];
