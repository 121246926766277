import { MenuItem } from '@mui/material';
import styles from './index.module.css';

interface ContextMenuItemProps {
  onClick: () => void;
}

export const ContextMenuItem: React.FC<ContextMenuItemProps> = ({
  children,
  onClick,
}) => {
  return (
    <MenuItem onClick={onClick} className={styles.menuItem}>
      {children}
    </MenuItem>
  );
};
