import { useTranslation } from 'core/context/i18n.context';
import { Suspense, useCallback, useMemo, useState } from 'react';
import { Actions } from 'schedule/components/actions';
import { useDeleteSchedules } from 'schedule/hooks/use-delete-schedules';
import { useGetSchedules } from 'schedule/hooks/use-get-schedules';
import { useValidCredential } from 'settings/components/bs-admin/hooks/use-valid-credential';
import { ConfirmDialog } from 'shared/components/dialogs/confirm-dialog';
import { LayoutContent } from 'shared/components/layouts/layout-content';
import { StandardLayout } from 'shared/components/layouts/standard-layout';
import { OverlayLoading } from 'shared/components/overlay-loading';
import { PageHeader } from 'shared/components/page-header';
import {
  TableSchedule,
  TableScheduleProps,
} from 'shared/components/table-schedule';
import { useErrorDialog } from 'shared/contexts/error-dialog.context';
import { useNormalizedDatetime } from 'shared/hooks/use-normalized-datetime';

const ScheduleReport: React.FC = () => {
  const { t } = useTranslation();
  const schedules = useGetSchedules();
  const validCredential = useValidCredential({ suspense: false });
  const deleteSchedules = useDeleteSchedules();
  const { showErrorDialog } = useErrorDialog();
  const bsAdminLoggedIn = useMemo(
    () => validCredential.data?.isValid,
    [validCredential.data]
  );
  const normalizedDatetime = useNormalizedDatetime();

  const [selectedRowIds, setSelectedRowIds] = useState<string[]>([]);

  const [confirmDeleteDialog, setConfirmDeleteDialog] =
    useState<boolean>(false);

  const tableColumns: TableScheduleProps['columns'] = useMemo(
    () => [
      {
        Header: t('reportApp.scheduleName'),
        accessor: 'name',
      },
      {
        Header: t('reportApp.reportType'),
        accessor: 'reportTemplate.name',
      },
      {
        Header: t('reportApp.generateTime'),
        accessor: 'generateTime',
        Cell: ({ value }) => {
          const datetime = normalizedDatetime(value, 'HH:mm');
          return datetime;
        },
      },
      {
        Header: t('reportApp.frequency'),
        accessor: 'frequency',
        Cell: ({ value }) => {
          return t('reportApp.frequency.' + value);
        },
      },
      {
        Header: t('reportApp.outputType'),
        accessor: 'outputType',
        Cell: ({ value }) => {
          return t('reportApp.outputType.' + value);
        },
      },
      {
        Header: t('reportApp.latestGenerated'),
        accessor: 'latestGenerated',
        Cell: ({ value }) => {
          if (!value) return '-';
          return normalizedDatetime(value);
        },
      },
      {
        Header: t('reportApp.status'),
        accessor: 'status',
        Cell: ({ value }) => {
          return t('reportApp.status.' + value);
        },
      },
    ],
    [normalizedDatetime, t]
  );

  const schedulesData = useMemo(() => schedules.data || [], [schedules.data]);

  const onSelectedRowIdsChange = useCallback((rowIds: string[]) => {
    setSelectedRowIds(rowIds);
  }, []);

  const disableAddBtn = useMemo(() => {
    if (schedulesData.length !== 10) return false;
    return true;
  }, [schedulesData.length]);

  const disabledDeleteBtn = useMemo(
    () => !selectedRowIds.length,
    [selectedRowIds.length]
  );

  const onDeleteSchedules = () => {
    setConfirmDeleteDialog(true);
  };

  const handleCloseConfirmDialog = () => setConfirmDeleteDialog(false);

  const handleDeleteSchedules = async () => {
    try {
      await deleteSchedules.mutateAsync({ scheduleIds: selectedRowIds });
      await schedules.refetch();
      handleCloseConfirmDialog();
    } catch (error) {
      showErrorDialog({
        alertType: 'error',
        message: (error as Error).message,
      });
    }
  };

  return (
    <>
      <StandardLayout>
        <LayoutContent>
          <PageHeader
            title={t('reportApp.schedulePageTitle')}
            hideBackButton={true}
          />
          <Actions
            bsAdminLoggedIn={bsAdminLoggedIn}
            disabledDeleteBtn={disabledDeleteBtn}
            disabledAddBtn={disableAddBtn}
            onDelete={onDeleteSchedules}
          />
          <TableSchedule
            columns={tableColumns}
            data={schedulesData}
            loading={schedules.isLoading}
            onSelectedRowIdsChange={onSelectedRowIdsChange}
          />
        </LayoutContent>
      </StandardLayout>

      <ConfirmDialog
        message={t('reportApp.confirmContinueDeleting')}
        open={confirmDeleteDialog}
        onClose={handleCloseConfirmDialog}
        onOk={handleDeleteSchedules}
        disableBackdropClick
        disableEscapeKeyDown
      />
    </>
  );
};

export const ScheduleReportPage = () => (
  <Suspense fallback={<OverlayLoading />}>
    <ScheduleReport />
  </Suspense>
);
