import { CircularProgress } from '@mui/material';
import classNames from 'classnames';
import { useTranslation } from 'core/context/i18n.context';
import React, { useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTable, Column, useFlexLayout, useRowSelect } from 'react-table';
import { ItemNotFound } from '../item-not-found';
import styles from './index.module.css';
import { SelectionCheckbox } from './selection-checkbox';
import { useValidCredential } from 'settings/components/bs-admin/hooks/use-valid-credential';
import { useErrorDialog } from 'shared/contexts/error-dialog.context';

export interface TableScheduleProps {
  columns: Column[];
  data: {}[];
  loading?: boolean;
  onSelectedRowIdsChange?: (rowIds: string[]) => void;
}

export const TableSchedule: React.FC<TableScheduleProps> = ({
  columns,
  data,
  loading,
  onSelectedRowIdsChange,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const validCredential = useValidCredential({ suspense: false });
  const { showErrorDialog } = useErrorDialog();

  const defaultColumn = React.useMemo(
    () => ({
      // When using the useFlexLayout:
      minWidth: 40, // minWidth is only used as a limit for resizing
      width: 150, // width is used for both the flex-basis and flex-grow
      maxWidth: 280, // maxWidth is only used as a limit for resizing
    }),
    []
  );

  const selectionColumn = {
    id: 'selection',
    Header: ({ getToggleAllRowsSelectedProps }: any) => (
      <SelectionCheckbox {...getToggleAllRowsSelectedProps()} />
    ),
    Cell: ({ row }: any) => (
      <SelectionCheckbox {...row.getToggleRowSelectedProps()} />
    ),
    width: 40,
  };

  const getRowId = React.useCallback((row) => row.id, []);

  const {
    getTableProps,
    headerGroups,
    rows,
    prepareRow,
    state: { selectedRowIds },
  } = useTable(
    {
      columns,
      data,
      defaultColumn,
      autoResetSelectedRows: true,
      getRowId,
    } as any,
    useFlexLayout,
    useRowSelect,
    (hooks) => {
      hooks.visibleColumns.push((columns) => [selectionColumn, ...columns]);
    }
  ) as any;

  // `selectedRowIds` are an object,
  // convert it to an array od id.
  const checkedRowIds = useMemo(
    () => Object.keys(selectedRowIds),
    [selectedRowIds]
  );

  useEffect(() => {
    onSelectedRowIdsChange?.(checkedRowIds);
  }, [checkedRowIds, onSelectedRowIdsChange]);

  const navigateToEditSchedule = (id: any) => {
    if (!validCredential.data?.isValid) {
      return showErrorDialog({
        alertType: 'error',
        message: t('reportApp.errorAddSchedule'),
      });
    }

    return navigate(`/report/schedule/${id}`);
  };

  const renderLoading = () => {
    return (
      <div className={styles.infoWrapper}>
        <div className={styles.loading}>
          <CircularProgress />
        </div>
      </div>
    );
  };

  const renderNoResult = () => {
    return (
      <div className={styles.infoWrapper}>
        <div className={styles.noResult}>
          <ItemNotFound message={t('reportApp.noResult')} lite />
        </div>
      </div>
    );
  };

  const renderData = () => {
    return rows.map((row: any) => {
      prepareRow(row);
      return (
        <div {...row.getRowProps()} className={styles.tbodyRow}>
          {row.cells.map((cell: any) => {
            return (
              <div
                {...cell.getCellProps()}
                className={classNames(styles.tbodyData)}
                onClick={
                  cell.column.id === 'selection'
                    ? undefined
                    : () => navigateToEditSchedule(row.id)
                }
              >
                {cell.render('Cell')}
              </div>
            );
          })}
        </div>
      );
    });
  };

  return (
    <div {...getTableProps()} className={styles.table}>
      <div className={styles.thead}>
        {headerGroups.map((headerGroup: any) => (
          <div
            {...headerGroup.getHeaderGroupProps()}
            className={styles.theadRow}
          >
            {headerGroup.headers.map((column: any) => (
              <div {...column.getHeaderProps()} className={styles.theadData}>
                <div className={styles.theadDataValue}>
                  {column.render('Header')}
                </div>
              </div>
            ))}
          </div>
        ))}
      </div>
      <div className={styles.tbody}>
        {loading && renderLoading()}
        {!rows.length && !loading && renderNoResult()}
        {!!rows.length && !loading && renderData()}
      </div>
    </div>
  );
};
