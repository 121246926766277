import { useTranslation } from 'core/context/i18n.context';
import { useEffect } from 'react';
import { TextField } from 'shared/components/inputs/text-field';
import styles from './index.module.css';
import { useFormContext } from 'react-hook-form';
import { useGetCredential } from './hooks/use-get-credential';
import { Card } from 'shared/components/card';
import { CardTitle } from 'shared/components/card-title';
import { Box } from '@mui/system';
import { InputWrapper } from 'shared/components/input-wrapper';
import { InputLabel } from 'shared/components/input-label';
import { Info } from 'shared/components/info';
import { useGetSchedules } from 'schedule/hooks/use-get-schedules';
import { InputRow } from 'shared/components/input-row';

type FormData = {
  user: string;
  password: string;
};

export const BSAdmin: React.FC = () => {
  const { t } = useTranslation();
  const scheduleReport = useGetSchedules();
  const {
    register,
    setValue,
    formState: { errors },
  } = useFormContext<FormData>();

  const adminCredential = useGetCredential();

  // Set initial form value if admin credentials exists.
  useEffect(() => {
    if (adminCredential.data) {
      setValue('user', adminCredential.data.user);
      setValue('password', adminCredential.data.password);
    }
  }, [adminCredential.data, setValue]);

  return (
    <Card>
      <Box>
        <CardTitle>
          {t('reportApp.biostarAdminLogin')}
          <Box ml="4px">
            <Info message={t('reportApp.adminCredentialInfo')} />
          </Box>
        </CardTitle>
      </Box>

      <Box p="24px" display="flex" width="100%" paddingLeft="120px">
        <InputWrapper flex="1" flexDirection="column" alignItems="flex-start">
          <InputRow>
            <InputLabel display="flex" width="150px" showBullet={true}>
              {t('reportApp.biostarAdminUserId')}
            </InputLabel>
            <Box maxWidth="400px" width="100%">
              <TextField
                name="user"
                placeholder={t('reportApp.biostarAdminUserId')}
                inputProps={{
                  ...register('user', {
                    required: {
                      value: !!scheduleReport.data?.length,
                      message: t('reportApp.fieldRequired'),
                    },
                  }),
                  autoComplete: 'off',
                }}
                error={errors.user?.message}
              ></TextField>
            </Box>
          </InputRow>
        </InputWrapper>
        <InputWrapper flex="1" flexDirection="column" alignItems="flex-start">
          <InputRow>
            <InputLabel className={styles.inputLabel} showBullet={true}>
              {t('reportApp.password')}
            </InputLabel>
            <Box maxWidth="400px" width="100%">
              <TextField
                name="password"
                placeholder={t('reportApp.password')}
                inputProps={{
                  type: 'password',
                  ...register('password', {
                    required: {
                      value: !!scheduleReport.data?.length,
                      message: t('reportApp.fieldRequired'),
                    },
                  }),
                  autoComplete: 'off',
                }}
                error={errors.password?.message}
              ></TextField>
            </Box>
          </InputRow>
        </InputWrapper>
      </Box>
    </Card>
  );
};
