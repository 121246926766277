import { Tab } from '@mui/material';
import { TabPanel } from 'shared/components/tab-panel';
import { useEffect, useState } from 'react';
import { useTranslation } from 'core/context/i18n.context';
import styles from './index.module.css';
import { useFilterReducer } from 'shared/components/wizard-event/hooks/use-filter-reducer';
import { FilterObjectDataItem } from '../filter-object-custom-report';
import { FilterObject } from '../filter-object';
import { ObjectFilterDataNode } from '../filter-object/type';
import { CustomTabs } from 'shared/components/tabs';

export interface FilterObjectProps {
  dataSource: FilterObjectDataItem[];
  value?: any[];
  onChange?: (data: FilterObjectDataItem[] | FilterObjectDataItem[][]) => void;
  multiple?: boolean;
}

export const FilterTab: React.FC<FilterObjectProps> = ({
  dataSource = [],
  value = [],
  onChange,
  multiple,
}) => {
  const { t } = useTranslation();
  const [currentTab, setCurrentTab] = useState(0);
  const [dataFilter, dispatchFilter] = useFilterReducer();

  const handleChangeTab = (event: React.SyntheticEvent, newValue: number) => {
    setCurrentTab(newValue);
  };

  const onChangeFilterObject =
    (stepIndex: number) => (data: FilterObjectDataItem[]) => {
      return dispatchFilter({
        type: 'onChange',
        payload: { stepIndex, value: data },
      });
    };

  useEffect(() => {
    if (onChange && dataFilter.length) {
      const flattenDataFilter = dataFilter.flat();
      const users = flattenDataFilter.filter((data) =>
        data.id.startsWith('user')
      );
      const doors = flattenDataFilter.filter((data) =>
        data.id.startsWith('door')
      );
      const devices = flattenDataFilter.filter((data) =>
        data.id.startsWith('device')
      );

      if (currentTab === 0) value[currentTab] = [...users];
      if (currentTab === 1) value[currentTab] = [...doors];
      if (currentTab === 2) value[currentTab] = [...devices];

      onChange(value);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataFilter]);

  return (
    <div>
      <CustomTabs
        className={styles.tabsWrapper}
        value={currentTab}
        onChange={handleChangeTab}
      >
        <Tab className={styles.tab} label={t('reportApp.users')}></Tab>
        <Tab className={styles.tab} label={t('reportApp.doors')}></Tab>
        <Tab className={styles.tab} label={t('reportApp.devices')}></Tab>
      </CustomTabs>
      <TabPanel value={currentTab} index={0}>
        <FilterObject
          dataSource={dataSource[0] as unknown as ObjectFilterDataNode[]}
          defaultValues={value[0] as unknown as FilterObjectDataItem[]}
          onChange={onChangeFilterObject(0)}
          multiple={multiple}
          enableAdditionalFilters={true}
          dataSourceType="usersAndGroups"
        />
      </TabPanel>
      <TabPanel value={currentTab} index={1}>
        <FilterObject
          dataSource={dataSource[1] as unknown as ObjectFilterDataNode[]}
          defaultValues={
            !!value.length
              ? (value[1] as unknown as FilterObjectDataItem[])
              : dataFilter[1]
          }
          onChange={onChangeFilterObject(1)}
          multiple={multiple}
          dataSourceType="doorsAndGroups"
        />
      </TabPanel>
      <TabPanel value={currentTab} index={2}>
        <FilterObject
          dataSource={dataSource[2] as unknown as ObjectFilterDataNode[]}
          defaultValues={
            !!value.length
              ? (value[2] as unknown as FilterObjectDataItem[])
              : dataFilter[2]
          }
          onChange={onChangeFilterObject(2)}
          multiple={multiple}
          dataSourceType="devicesAndGroups"
        />
      </TabPanel>
    </div>
  );
};
