import classNames from 'classnames';
import React from 'react';
import { ButtonDefault, ButtonDefaultProps } from '../button-default';
import styles from './index.module.css';

export interface ButtonPrimaryProps extends ButtonDefaultProps {}

export const ButtonPrimary: React.FC<ButtonPrimaryProps> = ({
  title,
  className,
  ...props
}) => {
  return (
    <ButtonDefault
      className={classNames(styles.btnPrimary, className)}
      title={title}
      {...props}
    />
  );
};
