import { Box } from '@mui/material';
import { Tooltip } from 'antd';
import { ReactComponent as InfoIcon } from 'core/assets/information.svg';
import styles from './index.module.css';

interface InfoProps {
  message: string;
}

const styledDiv = (message: any) => {
  return <div className={styles.tooltip}>{message}</div>;
};

export const Info: React.FC<InfoProps> = ({ message }) => {
  return (
    <Tooltip title={styledDiv(message)} color="#000">
      <Box display="flex">
        <InfoIcon className={styles.icon} width={20} height={20} />
      </Box>
    </Tooltip>
  );
};
