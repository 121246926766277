import styles from './index.module.css';

interface ViewImageBtnProps {
  onClick: () => void;
}

export const ViewImageBtn: React.FC<any> = (props: ViewImageBtnProps) => {
  return (
    <div className={styles.viewColumnContentWrapper}>
      <button onClick={props.onClick} className={styles.viewImageBtn} />
    </div>
  );
};
