import { Box, CircularProgress } from '@mui/material';
import { ErrorResponseDto } from 'api-client';
import classnames from 'classnames';
import { useTranslation } from 'core/context/i18n.context';
import { DashboardOptionItem } from 'dashboard/components/dashboard-option-item';
import {
  GridLayout,
  renderGridLayoutItem,
} from 'dashboard/components/grid-layout';
import { WidgetElement } from 'dashboard/components/widget-element';
import { useAutoRefreshWidgets } from 'dashboard/hooks/use-auto-refresh-widgets';
import { useRefreshIntervalValue } from 'dashboard/hooks/use-refresh-interval-value';
import { useRefreshWidgets } from 'dashboard/hooks/use-refresh-widgets';
import { useWidget } from 'dashboard/hooks/use-widget';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { OverlayLoading } from 'shared/components/overlay-loading';
import { Title } from 'shared/components/title';
import { useErrorDialog } from 'shared/contexts/error-dialog.context';
import { useInterval } from 'shared/hooks/use-interval';
import { apiEventSource } from 'shared/utils/api-event-source';
import { NoWidget } from '../no-widget';
import styles from './index.module.css';
import { useSystemUsage } from 'dashboard/hooks/use-system-usage';
import { useGetDoorsAndGroups } from 'dashboard/hooks/use-get-doors-and-groups';
import { useGetDevices } from 'dashboard/hooks/use-get-devices';

export const DashboardHomePage: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const widget = useWidget();
  const refreshWidgetMutation = useRefreshWidgets();
  const autoRefreshWidgetMutation = useAutoRefreshWidgets();
  const systemUsage = useSystemUsage();
  const doorsAndGroups = useGetDoorsAndGroups();
  const devices = useGetDevices();
  const { showErrorDialog } = useErrorDialog();
  const refreshIntervalValue = useRefreshIntervalValue();

  const navigateTo = (path: string) => () => navigate(path);

  const handleRefetchWidgets = () => {
    widget.refetchWidgets();
  };

  const refreshWidgets = async () => {
    try {
      localStorage.setItem('refresh', 'true');
      await refreshWidgetMutation.mutateAsync();
      systemUsage.refetch();
      doorsAndGroups.refetch();
      devices.refetch();
      localStorage.setItem('refresh', 'false');
    } catch (error: any) {
      localStorage.setItem('refresh', 'false');
      showErrorDialog({
        alertType: 'error',
        message:
          !(error as ErrorResponseDto).code || error.code === 'BIOSTAR_API'
            ? t('reportApp.refreshFailed')
            : t('reportApp.' + (error as ErrorResponseDto).code),
      });
    }
  };

  const renderLoading = () => {
    return (
      <div className={styles.infoWrapper}>
        <div className={styles.loadingWrapper}>
          <CircularProgress />
        </div>
      </div>
    );
  };

  const renderWidgets = () => {
    if (widget.noWidget) {
      return (
        <Box
          flex="1"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <NoWidget />
        </Box>
      );
    }

    if (widget.isLoading && !widget.widgets.length) {
      return renderLoading();
    }

    if (!widget.isLoading && widget.widgets.length) {
      return (
        <Box
          pb="0"
          mb="0px"
          boxSizing="border-box"
          style={{
            overflowX: 'hidden',
          }}
          height="inherit"
          className={classnames(styles.dashboardHomeBox)}
        >
          <GridLayout
            style={{
              minWidth: '1819px',
              overflow: 'auto',
            }}
            isDraggable={false}
            isDroppable={false}
            isResizable={false}
          >
            {widget.widgets.map((w, i) =>
              renderGridLayoutItem({
                key: w.area.i,
                dataGrid: w.area,
                element: <WidgetElement data={w} index={i} />,
              })
            )}
          </GridLayout>
        </Box>
      );
    }
  };

  // const reactGridLayoutParentWidth = document
  //   .querySelector('.react-grid-layout')
  //   ?.closest('div.MuiBox-root')?.clientWidth;

  // const reactGridLayoutParentHeight = document
  //   .querySelector('.react-grid-layout')
  //   ?.closest('div.MuiBox-root')?.clientHeight;

  // const reactGridItems =
  //   document.querySelectorAll<HTMLElement>('.react-grid-item');

  // const biggestNumberInArray = (arr: any) => {
  //   const max = Math.max(...arr);
  //   return max;
  // };

  // const widthDifference = useMemo(() => {
  //   let getDifference = 0;
  //   reactGridItems.forEach(function (widget) {
  //     const transformValue = new WebKitCSSMatrix(
  //       window.getComputedStyle(widget).transform
  //     );
  //     if (reactGridLayoutParentWidth !== 0) {
  //       getDifference = reactGridLayoutParentWidth
  //         ? reactGridLayoutParentWidth - transformValue.m41
  //         : 0;
  //     }
  //   });
  //   return getDifference;
  // }, [reactGridLayoutParentWidth, reactGridItems]);

  // const heightDifference = useMemo(() => {
  //   let getDifference = 0;
  //   let getReactGridItemsHeight: any = [];
  //   reactGridItems.forEach(function (widget) {
  //     getReactGridItemsHeight.push(widget.clientHeight);
  //   });
  //   if (
  //     reactGridLayoutParentHeight !== 0 &&
  //     (biggestNumberInArray(getReactGridItemsHeight) !==
  //       Number.NEGATIVE_INFINITY ||
  //       biggestNumberInArray(getReactGridItemsHeight) !==
  //         Number.POSITIVE_INFINITY ||
  //       biggestNumberInArray(getReactGridItemsHeight) !== undefined)
  //   ) {
  //     getDifference = reactGridLayoutParentHeight
  //       ? reactGridLayoutParentHeight -
  //         biggestNumberInArray(getReactGridItemsHeight)
  //       : 0;
  //   }
  //   return getDifference;
  // }, [reactGridLayoutParentHeight, reactGridItems]);

  useEffect(() => {
    // set document title for dashboard
    document.title = t('reportApp.pageTitle', [t('reportApp.dashboard')]);

    const eventSource = apiEventSource('widgets/sse');
    eventSource.addEventListener('widgetsApplied', handleRefetchWidgets);
    eventSource.addEventListener('widgetsRefreshed', handleRefetchWidgets);
    return () => {
      eventSource.removeEventListener('widgetsApplied', handleRefetchWidgets);
      eventSource.removeEventListener('widgetsRefreshed', handleRefetchWidgets);
      eventSource.close();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Auto refresh widgets based on interval.
  // Subtract interval value by 5 second to give a time for the backend to refresh the widgets.
  useInterval(() => {
    autoRefreshWidgetMutation.mutateAsync();
    systemUsage.refetch();
    doorsAndGroups.refetch();
    devices.refetch();
  }, refreshIntervalValue * 1000);

  return (
    <>
      <Box
        px="40px"
        py="24px"
        boxSizing="border-box"
        display="flex"
        flexDirection="column"
      >
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Title>{t('reportApp.dashboard')}</Title>

          <Box display="flex" mx="-4px" alignItems="center">
            <DashboardOptionItem tooltip={t('reportApp.refresh')}>
              <button
                className={classnames(styles.button, styles.reloadButton)}
                onClick={refreshWidgets}
              ></button>
            </DashboardOptionItem>
            <DashboardOptionItem
              tooltip={t('reportApp.settings')}
              onClick={navigateTo('settings')}
            >
              <button
                className={classnames(styles.button, styles.settingsButton)}
              ></button>
            </DashboardOptionItem>
          </Box>
        </Box>

        {renderWidgets()}
      </Box>

      {refreshWidgetMutation.isLoading && <OverlayLoading />}
    </>
  );
};
