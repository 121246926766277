import classNames from 'classnames';
import { useTranslation } from 'core/context/i18n.context';
import { useClickOutside } from 'generate-report/hooks/use-click-outside';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import styles from './index.module.css';

export interface SelectFieldDataSourceItem {
  id: string;
  name: string;
}

interface SelectFieldProps {
  placeholder?: string;
  name: string;
  required?: boolean;
  hint?: string;
  error?: string;
  wrapperProps?: React.HTMLAttributes<HTMLDivElement>;
  selectProps?: React.InputHTMLAttributes<HTMLInputElement>;
  dataSource?: SelectFieldDataSourceItem[];
  styleType?: 'normal' | 'emboss';
  className?: string;
  hidePlaceholder?: boolean;
  onChange: (value: any, name?: string, e?: React.MouseEvent) => void;
  onIsActiveChange?: (isActive: boolean) => void;
}

export const SelectField: React.FC<SelectFieldProps> = ({
  placeholder,
  name,
  required,
  hint,
  error,
  wrapperProps,
  selectProps,
  dataSource = [],
  styleType = 'emboss',
  className,
  hidePlaceholder = false,
  onChange,
  onIsActiveChange,
}) => {
  const { t } = useTranslation();
  const [isActive, setIsActive] = useState(false);
  const [selected, setSelected] = useState<any>('');
  const containerRef = useRef<any>(null);

  const displayPlaceholder = useMemo(
    () => (!placeholder ? t('reportApp.none') : placeholder),
    [placeholder, t]
  );
  const onClick = () => setIsActive((prev) => !prev);
  const handleCloseDropdown = () => setIsActive(false);
  const onSelect = (item: SelectFieldDataSourceItem, e?: React.MouseEvent) => {
    onChange(item, name, e);
    setSelected(item.name);
    handleCloseDropdown();
  };

  useClickOutside(containerRef, handleCloseDropdown);

  useEffect(() => {
    if (onIsActiveChange) {
      onIsActiveChange(isActive);
    }
  }, [isActive, onIsActiveChange]);

  useEffect(() => {
    if (!name.startsWith('categories.')) {
      const defaultValue = !selectProps?.value
        ? selectProps?.defaultValue
        : selectProps.value;
      if (!!defaultValue) {
        const data = dataSource.filter((d) => {
          const value =
            typeof defaultValue === 'number'
              ? defaultValue.toString()
              : defaultValue;
          return d.id === value;
        });
        onSelect(data[0] as any);
      }

      if (!defaultValue) {
        setSelected('');
      }
    } else {
      const value = selectProps?.value as any;
      if (!!value) {
        const data = dataSource.filter((d) => d.id === value);
        setSelected(data[0]?.name);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectProps?.value, selectProps?.defaultValue]);

  return (
    <div
      ref={containerRef}
      {...wrapperProps}
      className={classNames(
        styles.fieldWrapper,
        wrapperProps?.className,
        className
      )}
    >
      <div className={styles.spanFieldWrapper}>
        <div
          {...selectProps}
          className={classNames(styles.field, selectProps?.className, {
            [styles.disabled]: selectProps?.disabled,
            [styles.emboss]: styleType === 'emboss',
            [styles.customZIndex]: isActive,
          })}
          onClick={selectProps?.disabled ? undefined : onClick}
        >
          <div className={styles.displaySelected}>
            {hidePlaceholder && !selected ? displayPlaceholder : selected}
          </div>
        </div>
      </div>
      {isActive && (
        <div className={styles.dropdownContent} style={selectProps?.style}>
          <ul
            className={classNames(styles.optionList, {
              [styles.optionMaxHeight]: dataSource.length > 6,
            })}
          >
            {dataSource.map((data) => (
              <li
                key={data.id}
                value={data.id}
                className={styles.option}
                onClick={(e: React.MouseEvent) => onSelect(data, e)}
              >
                {data.name}
              </li>
            ))}
          </ul>
        </div>
      )}
      <div
        className={classNames(styles.customSelectField, {
          [styles.arrowDisabled]: selectProps?.disabled,
          [styles.arrowActive]: isActive,
          [styles.customZIndex]: isActive,
        })}
        onClick={onClick}
      />
      {!!hint && !error && <span className={styles.hint}>{hint}</span>}
      {!!error && (
        <span className={classNames(styles.hint, styles.error)}>{error}</span>
      )}
    </div>
  );
};
