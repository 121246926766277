import { Box } from '@mui/system';
import { WidgetDtoWidgetType } from 'api-client';
import classNames from 'classnames';
import { constructChartProps } from 'dashboard/helpers/construct-chart-props.helper';
import { WidgetAreaItem, WidgetItem } from 'dashboard/types/widget-item.type';
import { useEffect, useMemo, useRef, useState } from 'react';
import { Chart } from 'shared/components/chart';
import { WidgetCheckpoint } from 'shared/components/widget-checkpoint';
import { WidgetRealTimeEvents } from 'shared/components/widget-real-time-events';
import { WidgetText } from 'shared/components/widget-text';
import { useLocation } from 'react-router-dom';
import { WidgetDoorControl } from 'shared/components/widget-door-control';
import styles from './index.module.css';

interface WidgetElementProps {
  data: WidgetAreaItem;
  showActions?: boolean;
  onClickDelete?: (areaId: string) => void;
  onClickEdit?: (areaId: string) => void;
  index?: number;
  handleDeletedDoor?: (id: number) => void;
  handleDeletedDevice?: (id: number) => void;
  widgetsWithDeletedDoor?: number[];
  widgetsWithDeletedDevice?: number[];
}

export const WidgetElement: React.FC<WidgetElementProps> = ({
  data,
  showActions,
  onClickDelete,
  onClickEdit,
  index,
  handleDeletedDoor,
  handleDeletedDevice,
  widgetsWithDeletedDoor,
  widgetsWithDeletedDevice,
}) => {
  const ref = useRef<any>(null);
  const location = useLocation();
  const [widgetIdToEdit, setWidgetIdToEdit] = useState<number>();
  const [width, setWidth] = useState<number>(0);
  const [height, setHeight] = useState<number>(0);

  const isDashboardSettingsPage = useMemo(
    () =>
      location.pathname.includes('dashboard') &&
      location.pathname.includes('settings'),
    [location.pathname]
  );

  const handleDelete = () => {
    onClickDelete?.(data.area.i);
  };

  const handleEdit = () => {
    setWidgetIdToEdit(data.id);
    onClickEdit?.(data.area.i);
  };

  const showEditButton = useMemo(() => {
    if (
      data.widgetType !== WidgetDtoWidgetType.DoorControl &&
      data.widgetType !== WidgetDtoWidgetType.Checkpoint
    )
      return true;

    return (
      (data.widgetType === WidgetDtoWidgetType.DoorControl &&
        !widgetsWithDeletedDoor?.includes(data.id!)) ||
      (data.widgetType === WidgetDtoWidgetType.Checkpoint &&
        !widgetsWithDeletedDevice?.includes(data.id!))
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    data.id,
    data.widgetType,
    widgetsWithDeletedDoor,
    widgetsWithDeletedDevice,
  ]);

  const isRemoveBg = useMemo(() => {
    if (data.widgetType === WidgetDtoWidgetType.Text) {
      const parseValue = JSON.parse(data.widgetValue);
      return parseValue.removeBg;
    }

    return undefined;
  }, [data.widgetValue, data.widgetType]);

  const background = useMemo(
    () =>
      isRemoveBg && !isDashboardSettingsPage
        ? 'none'
        : 'var(--background-color-3)',
    [isDashboardSettingsPage, isRemoveBg]
  );

  const boxShadow = useMemo(
    () =>
      isRemoveBg && !isDashboardSettingsPage
        ? 'none'
        : '0 1px 5px rgba(0,0,0,0.2)',
    [isDashboardSettingsPage, isRemoveBg]
  );

  useEffect(() => {
    if (!ref?.current?.offsetWidth || !ref?.current?.offsetHeight) {
      return;
    }

    setWidth(ref.current.offsetWidth);
    setHeight(ref.current.offsetHeight);
  }, [ref?.current?.offsetHeight, ref?.current?.offsetWidth]);

  return (
    <Box
      height="100%"
      position="relative"
      px={
        data.widgetType === WidgetDtoWidgetType.Chart ||
        data.widgetType === WidgetDtoWidgetType.SystemUsage
          ? '0'
          : '20px'
      }
      overflow="overlay"
      ref={ref}
      sx={{
        background,
        boxShadow,
      }}
      id={`${data.widgetType}-${index}`}
    >
      {showActions && (
        <Box
          position="absolute"
          right="5px"
          top="3px"
          display="flex"
          zIndex="5"
        >
          {showEditButton && (
            <button
              className={classNames(styles.buttonStyles, styles.editButton)}
              onClick={handleEdit}
            ></button>
          )}
          <Box width="5px"></Box>
          <button
            className={classNames(styles.buttonStyles, styles.deleteButton)}
            onClick={handleDelete}
          />
        </Box>
      )}

      {(data.widgetType === WidgetDtoWidgetType.Chart ||
        data.widgetType === WidgetDtoWidgetType.SystemUsage) && (
        <WidgetChartElement
          data={data}
          redrawAfterUpdate={widgetIdToEdit === data.id}
          widgetType={data.widgetType}
          isSettings={isDashboardSettingsPage}
        />
      )}

      {(data.widgetType === WidgetDtoWidgetType.Counter ||
        data.widgetType === WidgetDtoWidgetType.Text) && (
        <WidgetTextElement
          data={data}
          index={index}
          widgetType={data.widgetType}
          isSettings={
            isDashboardSettingsPage &&
            data.widgetType === WidgetDtoWidgetType.Counter
          }
        />
      )}

      {data.widgetType === WidgetDtoWidgetType.RealTimeEvents && (
        <WidgetRealTimeEventsElement
          data={data}
          index={index}
          isSettings={isDashboardSettingsPage}
        />
      )}

      {data.widgetType === WidgetDtoWidgetType.Checkpoint && (
        <WidgetCheckpointElement
          data={data}
          index={index}
          widgetType={data.widgetType}
          key={index}
          handleDeletedDevice={handleDeletedDevice}
          isSettings={isDashboardSettingsPage}
        />
      )}

      {data.widgetType === WidgetDtoWidgetType.DoorControl && (
        <WidgetDoorControlElement
          data={data}
          index={index}
          widgetType={data.widgetType}
          width={width}
          height={height}
          handleDeletedDoor={handleDeletedDoor}
          isSettings={isDashboardSettingsPage}
        />
      )}

      {data.widgetType === WidgetDtoWidgetType.Counter && (
        <div className={styles.decorative} />
      )}
    </Box>
  );
};

interface WidgetChartElementProps {
  data: WidgetItem;
  redrawAfterUpdate?: boolean;
  widgetType: string;
  isSettings: boolean;
}

interface WidgetElementContentProps {
  data: WidgetItem;
  index?: number;
  widgetType?: string;
  isSettings: boolean;
}

const WidgetChartElement: React.FC<WidgetChartElementProps> = ({
  data,
  redrawAfterUpdate,
  widgetType,
  isSettings,
}) => {
  const chartProps = useMemo(() => constructChartProps(data), [data]);
  return (
    <Chart
      {...chartProps}
      redraw={redrawAfterUpdate}
      widgetType={widgetType}
      isSettings={isSettings}
    ></Chart>
  );
};

const WidgetTextElement: React.FC<WidgetElementContentProps> = ({
  data,
  index,
  widgetType,
  isSettings,
}) => {
  return (
    <WidgetText
      data={data}
      index={index}
      widgetType={widgetType}
      isSettings={isSettings}
    />
  );
};

const WidgetRealTimeEventsElement: React.FC<WidgetElementContentProps> = ({
  data,
  index,
  isSettings,
}) => {
  return (
    <WidgetRealTimeEvents data={data} index={index} isSettings={isSettings} />
  );
};

const WidgetCheckpointElement: React.FC<any> = ({
  data,
  index,
  widgetType,
  handleDeletedDevice,
  isSettings,
}) => {
  return (
    <WidgetCheckpoint
      data={data}
      index={index}
      widgetType={widgetType}
      handleDeletedDevice={handleDeletedDevice}
      isSettings={isSettings}
    />
  );
};

const WidgetDoorControlElement: React.FC<any> = ({
  data,
  index,
  widgetType,
  width,
  height,
  handleDeletedDoor,
  isSettings,
}) => {
  return (
    <WidgetDoorControl
      data={data}
      index={index}
      widgetType={widgetType}
      width={width}
      height={height}
      handleDeletedDoor={handleDeletedDoor}
      isSettings={isSettings}
    />
  );
};
