/* tslint:disable */
/* eslint-disable */
/**
 * BioStar 2 Report API
 * API Documentation for BioStar 2 Report
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { CardTypeFilterDto } from '../models';
// @ts-ignore
import { CredentialFilterDto } from '../models';
// @ts-ignore
import { DeviceAndGroupFilterDto } from '../models';
// @ts-ignore
import { DoorAndGroupFilterDto } from '../models';
// @ts-ignore
import { ErrorResponseDto } from '../models';
// @ts-ignore
import { EventCategoryFilterDto } from '../models';
// @ts-ignore
import { EventTypeFilterDto } from '../models';
// @ts-ignore
import { UserAndGroupFilterDto } from '../models';
// @ts-ignore
import { UserDto } from '../models';
/**
 * FilterConditionsApi - axios parameter creator
 * @export
 */
export const FilterConditionsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get filter list of card types
         * @param {boolean} [withAllOption] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCardTypes: async (withAllOption?: boolean, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/report/filter-conditions/card-types`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (withAllOption !== undefined) {
                localVarQueryParameter['withAllOption'] = withAllOption;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get filter list of credentials
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCredentials: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/report/filter-conditions/credentials`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get filter list of devices
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDevices: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/report/filter-conditions/devices`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get filter list of devices and device groups
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDevicesAndGroups: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/report/filter-conditions/devices-and-groups`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get filter list of doors and door groups
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDoorsAndGroups: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/report/filter-conditions/doors-and-groups`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get filter list of event categories
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEventCategories: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/report/filter-conditions/event-categories`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get filter list of event types
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEventTypes: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/report/filter-conditions/event-types`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get user
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUser: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getUser', 'id', id)
            const localVarPath = `/api/report/filter-conditions/user/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get filter list of user groups
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserGroups: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/report/filter-conditions/user-groups`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get filter list of users
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUsers: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/report/filter-conditions/users`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get filter list of users and user groups
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUsersAndGroups: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/report/filter-conditions/users-and-groups`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FilterConditionsApi - functional programming interface
 * @export
 */
export const FilterConditionsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = FilterConditionsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get filter list of card types
         * @param {boolean} [withAllOption] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCardTypes(withAllOption?: boolean, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CardTypeFilterDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCardTypes(withAllOption, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get filter list of credentials
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCredentials(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CredentialFilterDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCredentials(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get filter list of devices
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDevices(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DeviceAndGroupFilterDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDevices(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get filter list of devices and device groups
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDevicesAndGroups(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DeviceAndGroupFilterDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDevicesAndGroups(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get filter list of doors and door groups
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDoorsAndGroups(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DoorAndGroupFilterDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDoorsAndGroups(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get filter list of event categories
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getEventCategories(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<EventCategoryFilterDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getEventCategories(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get filter list of event types
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getEventTypes(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<EventTypeFilterDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getEventTypes(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get user
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUser(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<UserDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUser(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get filter list of user groups
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserGroups(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<UserAndGroupFilterDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUserGroups(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get filter list of users
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUsers(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<UserAndGroupFilterDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUsers(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get filter list of users and user groups
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUsersAndGroups(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<UserAndGroupFilterDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUsersAndGroups(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * FilterConditionsApi - factory interface
 * @export
 */
export const FilterConditionsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = FilterConditionsApiFp(configuration)
    return {
        /**
         * 
         * @summary Get filter list of card types
         * @param {boolean} [withAllOption] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCardTypes(withAllOption?: boolean, options?: any): AxiosPromise<Array<CardTypeFilterDto>> {
            return localVarFp.getCardTypes(withAllOption, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get filter list of credentials
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCredentials(options?: any): AxiosPromise<Array<CredentialFilterDto>> {
            return localVarFp.getCredentials(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get filter list of devices
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDevices(options?: any): AxiosPromise<Array<DeviceAndGroupFilterDto>> {
            return localVarFp.getDevices(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get filter list of devices and device groups
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDevicesAndGroups(options?: any): AxiosPromise<Array<DeviceAndGroupFilterDto>> {
            return localVarFp.getDevicesAndGroups(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get filter list of doors and door groups
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDoorsAndGroups(options?: any): AxiosPromise<Array<DoorAndGroupFilterDto>> {
            return localVarFp.getDoorsAndGroups(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get filter list of event categories
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEventCategories(options?: any): AxiosPromise<Array<EventCategoryFilterDto>> {
            return localVarFp.getEventCategories(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get filter list of event types
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEventTypes(options?: any): AxiosPromise<Array<EventTypeFilterDto>> {
            return localVarFp.getEventTypes(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get user
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUser(id: string, options?: any): AxiosPromise<Array<UserDto>> {
            return localVarFp.getUser(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get filter list of user groups
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserGroups(options?: any): AxiosPromise<Array<UserAndGroupFilterDto>> {
            return localVarFp.getUserGroups(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get filter list of users
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUsers(options?: any): AxiosPromise<Array<UserAndGroupFilterDto>> {
            return localVarFp.getUsers(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get filter list of users and user groups
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUsersAndGroups(options?: any): AxiosPromise<Array<UserAndGroupFilterDto>> {
            return localVarFp.getUsersAndGroups(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * FilterConditionsApi - object-oriented interface
 * @export
 * @class FilterConditionsApi
 * @extends {BaseAPI}
 */
export class FilterConditionsApi extends BaseAPI {
    /**
     * 
     * @summary Get filter list of card types
     * @param {boolean} [withAllOption] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FilterConditionsApi
     */
    public getCardTypes(withAllOption?: boolean, options?: any) {
        return FilterConditionsApiFp(this.configuration).getCardTypes(withAllOption, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get filter list of credentials
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FilterConditionsApi
     */
    public getCredentials(options?: any) {
        return FilterConditionsApiFp(this.configuration).getCredentials(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get filter list of devices
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FilterConditionsApi
     */
    public getDevices(options?: any) {
        return FilterConditionsApiFp(this.configuration).getDevices(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get filter list of devices and device groups
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FilterConditionsApi
     */
    public getDevicesAndGroups(options?: any) {
        return FilterConditionsApiFp(this.configuration).getDevicesAndGroups(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get filter list of doors and door groups
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FilterConditionsApi
     */
    public getDoorsAndGroups(options?: any) {
        return FilterConditionsApiFp(this.configuration).getDoorsAndGroups(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get filter list of event categories
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FilterConditionsApi
     */
    public getEventCategories(options?: any) {
        return FilterConditionsApiFp(this.configuration).getEventCategories(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get filter list of event types
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FilterConditionsApi
     */
    public getEventTypes(options?: any) {
        return FilterConditionsApiFp(this.configuration).getEventTypes(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get user
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FilterConditionsApi
     */
    public getUser(id: string, options?: any) {
        return FilterConditionsApiFp(this.configuration).getUser(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get filter list of user groups
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FilterConditionsApi
     */
    public getUserGroups(options?: any) {
        return FilterConditionsApiFp(this.configuration).getUserGroups(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get filter list of users
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FilterConditionsApi
     */
    public getUsers(options?: any) {
        return FilterConditionsApiFp(this.configuration).getUsers(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get filter list of users and user groups
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FilterConditionsApi
     */
    public getUsersAndGroups(options?: any) {
        return FilterConditionsApiFp(this.configuration).getUsersAndGroups(options).then((request) => request(this.axios, this.basePath));
    }
}
