import { CircularProgress, Stepper } from '@mui/material';
import {
  DeviceAndGroupFilterDto,
  DoorAndGroupFilterDto,
  FilterConditionDtoType,
  RealTimeEventsColumnDto,
  UserAndGroupFilterDto,
  WidgetDtoWidgetType,
} from 'api-client';
import { useTranslation } from 'core/context/i18n.context';
import { useRealTimeEventFilterCondition } from 'dashboard/hooks/use-real-time-events-filter-condition';
import { RealTimeEventWizardStepProps } from 'dashboard/types/real-time-events.type';
import { useEventType } from 'generate-report/hooks/use-event-type';
import { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { ButtonDefault } from 'shared/components/buttons/button-default';
import { ButtonPrimary } from 'shared/components/buttons/button-primary';
import { ChartProps } from 'shared/components/chart';
import { BaseDialog } from 'shared/components/dialogs/base-dialog';
import {
  FilterEvent,
  FilterObjectDataItem,
} from 'shared/components/filter-conditions/filter-event';
import { FilterObjectCustomReport } from 'shared/components/filter-conditions/filter-object-custom-report';
import { FilterTab } from 'shared/components/filter-conditions/filter-tab';
import { useFilterReducer } from 'shared/components/wizard-event/hooks/use-filter-reducer';
import { WidgetDialogProps } from '.';
import { ChartTypeSection } from './chart-type-section';
import { DetailSection } from './detail-section';
import styles from './index.module.css';
import { useWidgetStep } from './use-widget-step';

export const WidgetDialogContent: React.FC<WidgetDialogProps> = ({
  open,
  onClose,
  onApply,
  value,
  isEdit,
}) => {
  const { t } = useTranslation();
  const [loadingApply, setLoadingApply] = useState(false);

  const detailForm = useForm<Record<string, any>>({
    defaultValues: {
      widgetBase: 'event',
      widgetType: 'chart',
    },
  });
  const chartTypeForm = useForm<Record<string, any>>({
    defaultValues: {
      chartType: '',
      chartColor: '#CB00FF,#CB3398,#EE3E3A,#FBBD10,#FE930C',
    },
  });
  const widgetTextForm = useForm<Record<string, any>>();

  const eventTypes = useEventType();
  const eventTypesData = useMemo(() => eventTypes.data, [eventTypes]);
  const eventBaseWidgets = [
    WidgetDtoWidgetType.Chart,
    WidgetDtoWidgetType.Counter,
  ];

  useEffect(() => {
    if (!!value) {
      detailForm.setValue('widgetType', value.widgetType);
      detailForm.setValue('widgetBase', value.widgetBase);

      if (isEdit) {
        detailForm.setValue('area', value.area);
        detailForm.setValue('datasets', value.datasets);
        detailForm.setValue('widgetName', value.widgetName);
        detailForm.setValue('widgetValue', value.widgetValue);
        detailForm.setValue('categories', value.categories);
        chartTypeForm.setValue('chartType', value.chartType);
        chartTypeForm.setValue('chartColor', value.chartColor);
      }

      if (value.widgetType === WidgetDtoWidgetType.Text && isEdit) {
        const parsedWidgetValue = JSON.parse(value.widgetValue);
        widgetTextForm.setValue('textValue', parsedWidgetValue.value);
        widgetTextForm.setValue('removeBg', parsedWidgetValue.removeBg);
      }
    }
  }, [value, chartTypeForm, detailForm, widgetTextForm, isEdit]);

  const [datasets, setDatasets] = useState<ChartProps['data']['datasets']>([]);

  const widgetType = detailForm.watch('widgetType');
  const widgetValue = detailForm.watch('widgetValue');

  const widgetsTotalStep = useMemo(() => {
    switch (widgetType) {
      case WidgetDtoWidgetType.RealTimeEvents:
        return 4;
      case WidgetDtoWidgetType.Counter:
      case WidgetDtoWidgetType.Text:
      case WidgetDtoWidgetType.Webpage:
      case WidgetDtoWidgetType.Checkpoint:
      case WidgetDtoWidgetType.DoorControl:
      case WidgetDtoWidgetType.SystemUsage:
        return 1;
      default:
        return 2;
    }
  }, [widgetType]);
  const widgetStep = useWidgetStep(widgetsTotalStep);

  const steps: RealTimeEventWizardStepProps[] = useMemo(
    () => [
      {
        stepIndex: 0,
        type: FilterConditionDtoType.ObjectMultiple,
        value: 'event',
        id: 0,
        rules: ['required'],
        title: t('customReport.dialog.events'),
      },
      {
        stepIndex: 1,
        type: FilterConditionDtoType.ObjectMultiple,
        value: 'userDoorDevice',
        id: 0,
        rules: [],
        title: t('customReport.dialog.filters'),
      },
      {
        stepIndex: 2,
        type: FilterConditionDtoType.ObjectMultiple,
        value: 'column',
        id: 0,
        rules: ['required'],
        title: t('customReport.dialog.columns'),
      },
    ],
    [t]
  );

  const filterCondition = useRealTimeEventFilterCondition(steps);
  const [dataFilter, dispatchFilter] = useFilterReducer();
  const eventsData = useMemo(
    () => filterCondition.steps[0].data,
    [filterCondition]
  );
  const columnData = useMemo(
    () =>
      filterCondition.steps[2].data?.map((d: any) => ({
        ...d,
        name: t(`realTimeEventWidget.column.${d.keyName}`),
      })),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [filterCondition]
  );

  const onChangeFilterObject =
    (stepIndex: number) =>
    (data: FilterObjectDataItem[] | FilterObjectDataItem[][]) =>
      dispatchFilter({
        type: 'onChange',
        payload: { stepIndex, value: data },
      });

  const onChangeFilterEvent = (stepIndex: number) => (data: string[]) =>
    dispatchFilter({
      type: 'onChange',
      payload: { stepIndex, value: data },
    });
  const validations = useMemo(() => {
    return steps.map((step, idx) => {
      const errors = [];
      if (step.rules.includes('required') && !dataFilter[idx]?.length) {
        errors.push('required');
      }
      return errors;
    });
  }, [dataFilter, steps]);

  const [isInitColumnData, setIsInitColumnData] = useState<boolean>(false);

  useEffect(() => {
    if (
      !!isEdit &&
      !!value &&
      value.widgetType === WidgetDtoWidgetType.RealTimeEvents
    ) {
      const filterCondition = JSON.parse(value.widgetValue);

      onChangeFilterEvent(0)(filterCondition.events);
      onChangeFilterObject(1)([
        filterCondition.users,
        filterCondition.doors,
        filterCondition.devices,
      ]);
      onChangeFilterObject(2)(filterCondition.columns);
      setIsInitColumnData(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEdit, value]);

  const validateDetailForm = (cb: (data: Record<string, any>) => void) => {
    if (!eventBaseWidgets.includes(widgetType)) {
      detailForm.setValue('widgetBase', 'nonEvent');
      detailForm.setValue('categories', []);
    }

    const textWidgetNoValueConditions =
      widgetType === WidgetDtoWidgetType.Text &&
      !widgetTextForm.getValues('textValue');

    if (textWidgetNoValueConditions) {
      detailForm.setError('widgetValue', {
        type: 'required',
        message: t('reportApp.widgetTypeTextRequired'),
      });

      setLoadingApply(false);
      return;
    }

    if (widgetType === WidgetDtoWidgetType.SystemUsage) {
      detailForm.setValue('widgetValue', widgetValue);
    }

    if (widgetType === WidgetDtoWidgetType.Text) {
      detailForm.clearErrors('widgetValue');
      const widgetValue = {
        value: widgetTextForm.getValues('textValue'),
        removeBg: widgetTextForm.getValues('removeBgText'),
      };
      detailForm.setValue('widgetValue', JSON.stringify(widgetValue));
      detailForm.setValue('widgetName', '');
    }

    return detailForm.handleSubmit(
      (data) => {
        // This data is for previewing the chart
        setDatasets([
          {
            data: data.categories.map(() =>
              parseInt((Math.random() * 10).toFixed(0))
            ),
          },
        ]);
        cb(data);
      },
      () => setLoadingApply(false)
    )();
  };

  const validateChartTypeForm = (cb: (data: Record<string, any>) => void) => {
    const chartType = chartTypeForm.getValues('chartType');
    if (!chartType) {
      setLoadingApply(false);
    } else {
      chartTypeForm.clearErrors('chartType');
    }

    return chartTypeForm.handleSubmit((data) => {
      cb(data);
    });
  };

  const handleValidateNext = () =>
    validateDetailForm(() => widgetStep.handleNext());

  const handleApply = () => {
    setLoadingApply(true);
    if (widgetStep.totalSteps === 1) {
      return validateDetailForm(async (data) => {
        if (onApply) {
          await onApply({ ...data, datasets });
          setLoadingApply(false);
          handleClose();
        }
      });
    } else if (widgetType === WidgetDtoWidgetType.RealTimeEvents) {
      const [events, userDoorDevice, columns] = dataFilter;
      const filterConditionPayload = {
        events,
        users: (userDoorDevice[0] || []).map((user: UserAndGroupFilterDto) =>
          !!user.id ? user.id : user
        ),
        doors: (userDoorDevice[1] || []).map((door: DoorAndGroupFilterDto) =>
          !!door.id ? door.id : door
        ),
        devices: (userDoorDevice[2] || []).map(
          (device: DeviceAndGroupFilterDto) =>
            !!device.id ? device.id : device
        ),
        columns,
      };
      detailForm.setValue(
        'widgetValue',
        JSON.stringify(filterConditionPayload)
      );

      return validateDetailForm(async (data) => {
        if (onApply) {
          await onApply({ ...data, datasets });
          setLoadingApply(false);
          handleClose();
        }
      });
    } else {
      return validateChartTypeForm(async (data) => {
        if (onApply) {
          await onApply({ ...detailForm.getValues(), ...data, datasets });
          setLoadingApply(false);
          handleClose();
        }
      })();
    }
  };

  const handleClose = () => {
    detailForm.reset();
    if (widgetType === WidgetDtoWidgetType.Chart) {
      chartTypeForm.reset();
    }

    if (widgetType === WidgetDtoWidgetType.RealTimeEvents) {
      onChangeFilterEvent(0)([]);
      onChangeFilterObject(1)([]);
      onChangeFilterObject(2)([]);
    }
    onClose();
    widgetStep.resetStep();
    setLoadingApply(false);
  };

  useEffect(() => {
    if (!eventTypes.isFetched) {
      eventTypes.refetch();
    }

    if (!isInitColumnData && !!columnData?.length && !isEdit) {
      const defaultSelectedColumns = (
        (columnData || []) as RealTimeEventsColumnDto[]
      ).filter((item) => item.showColumn);

      onChangeFilterEvent(0)([]);
      onChangeFilterObject(1)([]);
      onChangeFilterObject(2)(
        defaultSelectedColumns as unknown as FilterObjectDataItem[]
      );
      setIsInitColumnData(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eventTypes, columnData, isInitColumnData]);

  const generalTitle = useMemo(() => {
    if (isEdit) {
      return t('reportApp.editWidget');
    }

    return t('reportApp.addNewWidget');
  }, [isEdit, t]);

  const rteDialogTitle = useMemo(() => {
    if (widgetStep.activeStep > 0) {
      return steps[widgetStep.activeStep - 1].title!;
    }

    return generalTitle;
  }, [generalTitle, steps, widgetStep.activeStep]);

  const dialogTitle = useMemo(
    () =>
      widgetType !== WidgetDtoWidgetType.RealTimeEvents
        ? generalTitle
        : rteDialogTitle,
    [generalTitle, rteDialogTitle, widgetType]
  );

  const errorRequired = useMemo(() => {
    if (
      widgetType === WidgetDtoWidgetType.RealTimeEvents &&
      widgetStep.activeStep > 0 &&
      !!validations.length
    ) {
      return validations[widgetStep.activeStep - 1].includes('required');
    }
    return false;
  }, [validations, widgetStep.activeStep, widgetType]);

  const renderStepError = (step: any) => {
    const errors = validations[step.activeStep - 1];
    const hasError = !!errors.length;
    const firstError = hasError ? errors[0] : null;

    if (!hasError) return <></>;

    if (firstError === 'required') {
      return (
        <p className={styles.filterConditionRequired}>
          *{t('reportApp.filterConditionRequired')}
        </p>
      );
    }
  };

  const renderFilterEvent = () => {
    return !!eventsData?.length && !!eventTypesData?.length ? (
      <>
        <FilterEvent
          dataSource={eventsData as FilterObjectDataItem[]}
          value={dataFilter[widgetStep.activeStep - 1]}
          onChange={onChangeFilterEvent(widgetStep.activeStep - 1)}
          multiple={true}
          eventTypeDataSource={eventTypesData}
        />
        {renderStepError(widgetStep)}
      </>
    ) : (
      <CircularProgress
        sx={{
          position: 'absolute',
          top: '40%',
          left: '47%',
        }}
      />
    );
  };

  const dialogMinHeight = useMemo(
    () =>
      (widgetType === WidgetDtoWidgetType.Chart &&
        widgetStep.activeStep === 1) ||
      widgetType === WidgetDtoWidgetType.Text
        ? 425
        : 300,
    [widgetStep.activeStep, widgetType]
  );

  return (
    <BaseDialog
      onClose={onClose}
      open={open}
      title={dialogTitle}
      disableBackdropClick
      disableEscapeKeyDown
      minWidth={620}
      minHeight={dialogMinHeight}
      actions={
        <>
          {widgetStep.havePreviousSteps && (
            <ButtonPrimary
              title={t('reportApp.previous')}
              size="small"
              onClick={widgetStep.handleBack}
              className={styles.dialogActionButton}
              disabled={loadingApply}
            ></ButtonPrimary>
          )}
          {widgetStep.haveNextSteps && (
            <ButtonPrimary
              title={t('reportApp.next')}
              size="small"
              onClick={handleValidateNext}
              className={styles.dialogActionButton}
              disabled={errorRequired || loadingApply}
            ></ButtonPrimary>
          )}
          {widgetStep.isLastStep && (
            <ButtonPrimary
              title={t('reportApp.apply')}
              size="small"
              onClick={handleApply}
              className={styles.dialogActionButton}
              loading={loadingApply}
              disabled={errorRequired}
            ></ButtonPrimary>
          )}
          <ButtonDefault
            title={t('reportApp.cancel')}
            onClick={handleClose}
            size="small"
            className={styles.dialogActionButton}
          ></ButtonDefault>
        </>
      }
    >
      <Stepper activeStep={widgetStep.activeStep}></Stepper>

      {widgetStep.activeStep === 0 && (
        <DetailSection
          form={detailForm}
          widgetTextForm={widgetTextForm}
          isEdit={isEdit}
        ></DetailSection>
      )}
      {widgetType === WidgetDtoWidgetType.Chart &&
        widgetStep.activeStep === 1 && (
          <ChartTypeSection
            form={chartTypeForm}
            detailForm={detailForm}
            datasets={datasets}
          ></ChartTypeSection>
        )}
      {widgetType === WidgetDtoWidgetType.RealTimeEvents &&
        widgetStep.activeStep === 1 &&
        renderFilterEvent()}
      {widgetType === WidgetDtoWidgetType.RealTimeEvents &&
        widgetStep.activeStep === 2 && (
          <FilterTab
            dataSource={
              filterCondition.steps[widgetStep.activeStep - 1]
                .data as FilterObjectDataItem[]
            }
            value={dataFilter[widgetStep.activeStep - 1]}
            onChange={onChangeFilterObject(widgetStep.activeStep - 1)}
            multiple={true}
          ></FilterTab>
        )}
      {widgetType === WidgetDtoWidgetType.RealTimeEvents &&
        widgetStep.activeStep === 3 && (
          <>
            <FilterObjectCustomReport
              dataSource={columnData as FilterObjectDataItem[]}
              value={dataFilter[2]}
              onChange={onChangeFilterObject(2)}
              multiple={true}
            />
            {renderStepError(widgetStep)}
          </>
        )}
    </BaseDialog>
  );
};
