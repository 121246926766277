import { useMemo, useState } from 'react';

export function useWidgetStep(totalSteps: number = 1) {
  const [activeStep, setActiveStep] = useState(0);

  const havePreviousSteps = useMemo(() => activeStep >= 1, [activeStep]);

  const haveNextSteps = useMemo(
    () => activeStep < totalSteps - 1,
    [totalSteps, activeStep]
  );

  const isLastStep = useMemo(
    () => activeStep === totalSteps - 1,
    [totalSteps, activeStep]
  );

  const handleNext = () => {
    if (haveNextSteps) {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };

  const handleBack = () => {
    if (havePreviousSteps) {
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
    }
  };

  const resetStep = () => setActiveStep(0);

  return {
    activeStep,
    handleNext,
    handleBack,
    havePreviousSteps,
    haveNextSteps,
    isLastStep,
    resetStep,
    totalSteps,
  };
}
