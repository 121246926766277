import { FilterConditionsApi } from 'api-client';
import { EVENT_CODE } from 'dashboard/constants/event-code.constant';
import { useCallback, useMemo, useState } from 'react';
import { apiConfig } from 'shared/utils/api-config';
import { useIpAddress } from './use-ip-address';
import { useStartEvents } from './use-start-events';
import { useStopEvents } from './use-stop-events';

export const useCheckpointEvents = (selectedDevice: any) => {
  const api = new FilterConditionsApi(apiConfig());
  const widgetDevice = selectedDevice.replace('device-', '');
  const sessionId = sessionStorage.getItem('session');
  const startEvents = useStartEvents();
  const stopEvents = useStopEvents();
  const ipAddress = useIpAddress();
  const [realTimeEventsStart, setRealTimeEventsStart] =
    useState<boolean>(false);
  const [wsMessage, setWsMessage] = useState<any>([]);
  const [haltedMessage, setHaltedMessage] = useState<any>([]);
  const [isPause, setIsPause] = useState<boolean>(false);

  // const getIpAddress = useMemo(() => {
  //   if (!ipAddress.isLoading && ipAddress.status === 'success') {
  //     return ipAddress.data.hostname;
  //   }

  //   return 'localhost';
  // }, [ipAddress]);

  const getHostname = useMemo(() => window.location.hostname, []);

  const getPort = useMemo(() => {
    if (!ipAddress.isLoading && ipAddress.status === 'success') {
      return ipAddress.data.port;
    }

    return '443';
  }, [ipAddress]);

  const wsURL = useMemo(() => {
    return `wss://${getHostname}:${getPort}/wsapi`;
  }, [getHostname, getPort]);

  const responseType = useCallback((event: any) => {
    if (event.Response) {
      return 'response';
    }

    if (event.Alert) {
      return 'alert';
    }

    if (event.Device) {
      return 'device';
    }

    return 'event';
  }, []);

  const fetchUserData = async (userId: string) => {
    const result = await api.getUser(userId);
    const getUserData = result.data as any;
    return getUserData;
  };

  const transformEvent = async (data: any) => {
    const user = await fetchUserData(data.user_id.user_id);

    return {
      device: data.device_id.name,
      user: !!data.user_id
        ? `${data.user_id.name} (${data.user_id.user_id})`
        : '',
      datetime: data.datetime,
      image: user.photo,
      index: data.index,
    };
  };

  const startCheckpointEvents = useCallback(() => {
    let ws: any;
    if (!!wsURL) {
      ws = new WebSocket(wsURL);
    }

    if (!ipAddress.isLoading && !!ws) {
      ws.onopen = async () => {
        ws.send(`bs-session-id=${sessionId}`);
        if (!realTimeEventsStart) {
          setRealTimeEventsStart(true);
          await startEvents.mutateAsync();
        }
      };

      let getResponse: any;

      ws.onmessage = async (event: any) => {
        if (!!event) {
          const eventData = JSON.parse(event.data);
          const type = responseType(eventData);
          if (type === 'event') {
            getResponse = Object.values(JSON.parse(event?.data))[0];
            if (
              !!getResponse['device_id'] &&
              getResponse['device_id']['id'] === widgetDevice
            ) {
              if (EVENT_CODE.includes(getResponse['event_type_id']['code'])) {
                const transformedEvent = await transformEvent(getResponse);
                setWsMessage((prev: any) => [transformedEvent, ...prev]);
              }
            }
          }
        }
      };

      ws.onclose = (event: any) => {
        setTimeout(() => {
          startCheckpointEvents();
        }, 5000);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [wsURL, ipAddress.isLoading]);

  const onClickPauseButton = () => {
    setIsPause(true);
  };

  const onClickPlayButton = () => {
    setIsPause(false);
  };

  const handleClearCheckpoint = () => {
    setHaltedMessage([]);
    setWsMessage([]);
  };

  const stopCheckpointEvents = useCallback(async () => {
    let ws: any;
    if (!!wsURL) {
      ws = new WebSocket(wsURL);
    }

    if (realTimeEventsStart) {
      if (!ipAddress.isLoading && !!ws) {
        setRealTimeEventsStart(false);
        await stopEvents.mutateAsync();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [realTimeEventsStart]);

  return {
    startCheckpointEvents,
    stopCheckpointEvents,
    wsMessage,
    haltedMessage,
    onClickPauseButton,
    onClickPlayButton,
    handleClearCheckpoint,
    isPause,
  };
};
