import React, { useEffect, useMemo, useState } from 'react';
import { TreeDataItem, TreeProps } from 'shared/components/tree';
import { useFirstRender } from 'shared/hooks/use-first-render';
import { useSearch } from 'shared/hooks/use-search';
import { FlatToNested } from 'shared/utils/flat-to-nested';
import { flattenNestedChildren } from 'shared/utils/flatten-nested-children';
import { FilterObjectView } from './index.view';
import { ObjectFilterDataNestedNode, ObjectFilterDataNode } from './type';

export interface FilterObjectProps {
  dataSource: ObjectFilterDataNestedNode[];
  dataSourceType?: string;
  defaultValues?: ObjectFilterDataNode[];
  onChange?: (items: ObjectFilterDataNode[]) => void;
  /**
   * By default, multiple is `false`.
   * That means, only one of the last child (not a parent/group) that can be checked.
   */
  multiple?: boolean;
  enableAdditionalFilters?: boolean;
  isUserGroup?: boolean;
  hideDevices?: boolean;
}

const flatToNested = new FlatToNested();

export const FilterObject: React.FC<FilterObjectProps> = ({
  dataSource = [],
  dataSourceType,
  defaultValues = [],
  onChange,
  multiple,
  enableAdditionalFilters,
  isUserGroup,
  hideDevices,
}) => {
  const isFirstRender = useFirstRender();
  const excludedDataSourceType = ['cardTypes', 'credential', 'userGroups'];
  const excludedDevices = [11, 33, 36];
  const prefix: any = {
    usersAndGroups: {
      item: 'user-',
      itemGroups: 'userGroup-',
    },
    devicesAndGroups: {
      item: 'device-',
      itemGroups: 'deviceGroup-',
    },
    doorsAndGroups: {
      item: 'door-',
      itemGroups: 'doorGroup-',
    },
  };

  const defaultValueKeys = useMemo(
    () =>
      defaultValues.map((item) => {
        if (typeof item === 'string') {
          return `${item}`;
        }

        return item.id;
      }),
    [defaultValues]
  );

  const [checkedKeys, setCheckedKeys] = useState<React.Key[]>(defaultValueKeys);

  /**
   * It will return timestamp if data attributes need to modify.
   * Otherwise, return false.
   *
   * The reason to use timestamp is that we can tell the references
   * if they need to be updated or not. Instead of return true, if any deps changes,
   * it might still return true that caused the references not getting update.
   */
  const watchModifyDataAttributes = useMemo(
    () => (multiple ? false : Date.now()),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [multiple, checkedKeys.length]
  );

  const getParentIds = (items: any[], data: any[]): any[] => {
    const parentIds = [];
    for (const item of items) {
      const parentId = data.find((d) => d.id === item)?.parentId;
      if (!!parentId) {
        parentIds.push(parentId);
        parentIds.push(...getParentIds([parentId], data)); // Recursively add parentIds
      }
    }

    return [...new Set(parentIds)];
  };

  const dataFlatten: ObjectFilterDataNode[] = useMemo(() => {
    const filteredDataSource =
      dataSourceType === 'devicesAndGroups' && !!hideDevices
        ? dataSource.filter((d: any) => !excludedDevices.includes(d.typeId?.id))
        : dataSource;
    let data = flattenNestedChildren(
      filteredDataSource
    ) as ObjectFilterDataNode[];

    let disabledItemGroups: any[] = [];
    if (!!multiple && !excludedDataSourceType.includes(dataSourceType!)) {
      const items = data.filter((d) =>
        d.id.startsWith(prefix[dataSourceType!].item as string)
      );
      const itemGroups = data.filter((d) =>
        d.id.startsWith(prefix[dataSourceType!].itemGroups as string)
      );
      const itemGroupIds = itemGroups.map((d) => d.id);
      const itemMasters =
        dataSourceType === 'devicesAndGroups'
          ? data.filter(
              (d) =>
                d.id.startsWith(prefix[dataSourceType!].item as string) &&
                d.name.toLocaleLowerCase().includes('master')
            )
          : [];
      const itemParents = [...itemGroups, ...itemMasters];

      const itemGroupWithItems = itemGroupIds.filter((id) =>
        items.map((item) => item.parentId).includes(id)
      );
      const itemGroupWithoutItems = itemGroupIds.filter(
        (id) => !items.map((item) => item.parentId).includes(id)
      );
      const itemGroupWithoutItemGroups = itemGroupIds.filter(
        (id) => !itemParents.map((item) => item.parentId).includes(id)
      );
      const itemGroupWithItemGroupsNoUsers = itemGroupIds.filter(
        (id) =>
          itemGroupWithoutItems.includes(id) &&
          !itemGroupWithoutItemGroups.includes(id)
      );
      const itemGroupWithoutItemsAndItemGroups = itemGroupWithoutItems.filter(
        (ug) => itemGroupWithoutItemGroups.includes(ug)
      );
      const enabledParents = getParentIds(itemGroupWithItems, data);
      const disabledParents = itemGroupWithItemGroupsNoUsers.filter(
        (id) => !enabledParents.includes(id)
      );

      disabledItemGroups = [
        ...disabledParents,
        ...itemGroupWithoutItemsAndItemGroups,
      ];
    }

    return data.map((item) => {
      let replicatedItem = item;
      if (!!disabledItemGroups.includes(item.id)) {
        replicatedItem = {
          ...replicatedItem,
          disableCheckbox: true,
        };
      }
      const disabledGroup = !multiple && !item.lastChild;

      const disabledMultiple =
        !multiple && checkedKeys.length >= 1 && !checkedKeys.includes(item.id);

      replicatedItem = {
        ...replicatedItem,
        disabled: disabledGroup || disabledMultiple,
      };
      return replicatedItem;
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataSource, watchModifyDataAttributes]);

  const dataNested: ObjectFilterDataNestedNode[] = useMemo(
    () => flatToNested.convert(dataFlatten),
    [dataFlatten]
  );

  const { data: dataSearched, setSearchValue } = useSearch(dataNested, {
    fieldNames: { text: 'name' },
  });

  const {
    setDepartmentSearchValue,
    departmentSearchValue,
    setUserGroupSearchValue,
    userGroupSearchValue,
    setEmailSearchValue,
    emailSearchValue,
    setTitleSearchValue,
    titleSearchValue,
    filterData,
  } = useSearch(dataNested);

  const checkedItems = useMemo(
    () => dataFlatten.filter((item) => checkedKeys.includes(item.id)),
    [dataFlatten, checkedKeys]
  );

  const checkedItemsWithoutGroups = useMemo(
    () => checkedItems.filter((item) => !!item.lastChild),
    [checkedItems]
  );

  const checkedKeysWithoutGroups = useMemo(
    () => checkedItemsWithoutGroups.map((item) => item.id),
    [checkedItemsWithoutGroups]
  );

  /**
   * Remove the parent from being checked if all children are not checked.
   */
  const normalizeCheckedKeys = (
    checkedItems: ObjectFilterDataNode[],
    checkedKeys: React.Key[]
  ) => {
    // Find available parents
    const availableParents = checkedItems.filter((item) => !item.lastChild);

    // Check each parent if the children are not all checked
    const excludedParents = availableParents.filter((item) => {
      // Find children
      const filtered = dataFlatten.filter(
        (dataItem) => dataItem.parentId === item.parentId
      );
      const children = filtered.map((data: any) => data.children);

      // Check if all children checked
      const allChildrenChecked = children.every((child) => {
        if (!child) return false;
        return checkedKeys.includes(child.id);
      });
      // Exclude parent if all children not checked
      return !allChildrenChecked;
    });
    const excludedParentKeys = excludedParents.map((item) => item.id);

    // Exclude parent keys from checked keys
    const normalizedCheckedKeys = checkedKeys.filter(
      (item) => !excludedParentKeys.includes(item.toString())
    );
    return normalizedCheckedKeys;
  };

  const onSearchChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    const { value } = e.target;
    setSearchValue(value);
  };

  const onUserGroupSearchChange: React.ChangeEventHandler<HTMLInputElement> = (
    e
  ) => {
    const { value } = e.target;
    setUserGroupSearchValue(value);
  };

  const onEmailSearchChange: React.ChangeEventHandler<HTMLInputElement> = (
    e
  ) => {
    const { value } = e.target;
    setEmailSearchValue(value);
  };

  const onDepartmentSearchChange: React.ChangeEventHandler<HTMLInputElement> = (
    e
  ) => {
    const { value } = e.target;
    setDepartmentSearchValue(value);
  };

  const onTitleSearchChange: React.ChangeEventHandler<HTMLInputElement> = (
    e
  ) => {
    const { value } = e.target;
    setTitleSearchValue(value);
  };

  const checkChange: TreeProps['onCheck'] = (checked, node) => {
    const keysChecked = Array.isArray(checked) ? checked : checked.checked;

    if (!!checkedKeys.length) {
      if (!!node.checked) {
        const differentData = keysChecked.filter(
          (id) => !checkedKeys.includes(id)
        );

        return setCheckedKeys([...differentData, ...checkedKeys]);
      }

      if (!node.checked && checkedKeys.includes(node.node.key)) {
        if ((node.node.key as string).startsWith('userGroup-')) {
          if (!!filterData?.length && checkedKeys.includes('userGroup-1')) {
            return setCheckedKeys([]);
          }
          const children = dataFlatten.filter(
            (item) => item.parentId === node.node.key
          );
          const flattenChildren = flattenNestedChildren(
            children as ObjectFilterDataNestedNode[]
          );
          const childrenIds = flattenChildren.map((item) => item.id);
          const parentAndChildrenId = [node.node.key, ...childrenIds];

          return setCheckedKeys(
            checkedKeys.filter(
              (id) => !parentAndChildrenId.includes(id as string)
            )
          );
        }

        const uncheckedKeys = [node.node.key, ...node.halfCheckedKeys!];

        if (!node.checkedNodes.length) {
          return setCheckedKeys([]);
        }

        return setCheckedKeys(
          checkedKeys.filter((id) => !uncheckedKeys.includes(id))
        );
      }
    }

    if (
      keysChecked.length !== dataFlatten.length &&
      keysChecked.includes('userGroup-1')
    ) {
      return setCheckedKeys(keysChecked.filter((id) => id !== 'userGroup-1'));
    }

    return setCheckedKeys(keysChecked);
  };

  const removeCheckedItem = (item: TreeDataItem) => {
    setCheckedKeys((prev) => {
      const checkedKeys = prev.filter((prevKey) => prevKey !== item.id);
      return normalizeCheckedKeys(checkedItems, checkedKeys);
    });
  };

  useEffect(() => {
    if (!isFirstRender && !dataSearched) {
      setCheckedKeys(checkedKeysWithoutGroups);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataSearched]);

  useEffect(() => {
    if (!isFirstRender && !!onChange) {
      const items = isUserGroup ? checkedItems : checkedItemsWithoutGroups;
      onChange(items);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkedItems, checkedItemsWithoutGroups, isUserGroup]);

  return (
    <FilterObjectView
      dataSource={dataSearched || filterData || dataNested}
      checkedItems={checkedItemsWithoutGroups}
      checkedKeys={checkedKeys}
      onSearchChange={onSearchChange}
      onUserGroupSearchChange={onUserGroupSearchChange}
      onEmailSearchChange={onEmailSearchChange}
      onDepartmentSearchChange={onDepartmentSearchChange}
      onTitleSearchChange={onTitleSearchChange}
      onCheck={checkChange}
      onRemoveCheckedItem={removeCheckedItem}
      enableAdditionalFilters={enableAdditionalFilters}
      userGroupSearchValue={userGroupSearchValue}
      departmentSearchValue={departmentSearchValue}
      titleSearchValue={titleSearchValue}
      emailSearchValue={emailSearchValue}
    />
  );
};
