import classNames from 'classnames';
import React from 'react';
import styles from './index.module.css';

interface TextAreaProps {
  placeholder?: string;
  name: string;
  required?: boolean;
  hint?: string;
  error?: string;
  wrapperProps?: React.HTMLAttributes<HTMLDivElement>;
  inputProps?: React.InputHTMLAttributes<HTMLTextAreaElement>;
}

export const Textarea: React.FC<TextAreaProps> = ({
  placeholder,
  name,
  required,
  hint,
  error,
  wrapperProps,
  inputProps,
}) => {
  return (
    <div
      {...wrapperProps}
      className={classNames(styles.fieldWrapper, wrapperProps?.className)}
    >
      <textarea
        {...inputProps}
        className={classNames(styles.field, inputProps?.className)}
        placeholder={placeholder}
        name={name}
        required={required}
      />
      {!!hint && !error && <span className={styles.hint}>{hint}</span>}
      {!!error && (
        <span className={classNames(styles.hint, styles.error)}>{error}</span>
      )}
    </div>
  );
};
