import { PredefinedReportDtoKeyName } from 'api-client';
import { useTranslation } from 'core/context/i18n.context';
import { useState } from 'react';
import { TextField } from 'shared/components/inputs/text-field';
import styles from './index.module.css';

export interface FilterInputNumberDataItem {
  name: string;
  value?: string;
}

export interface FilterInputNumberProps {
  dataSource: FilterInputNumberDataItem[];
  value?: FilterInputNumberDataItem[];
  onChange?: (data: FilterInputNumberDataItem[]) => void;
  predefinedReportKeyName?: string;
}

export const FilterInputNumber: React.FC<FilterInputNumberProps> = ({
  dataSource = [],
  value = [],
  onChange,
  predefinedReportKeyName,
}) => {
  const { t } = useTranslation();
  const [val, setVal] = useState<any>(value);

  const handleChange =
    (name: string): React.ChangeEventHandler<HTMLInputElement> =>
    (event) => {
      setVal(event.target.value);
      if (onChange) {
        onChange([{ name, value: event.target.value }]);
      }
    };

  const renderInput = (item: FilterInputNumberDataItem) => {
    return (
      <TextField
        name={item.name}
        inputProps={{
          type: 'number',
          min: 1,
          onChange: handleChange(item.name),
          value: val,
          style: { width: 64 },
        }}
        required
      />
    );
  };

  /**
   * Input that have labels on prefix and suffix
   */
  const renderParticularItem = (data: {
    labelPrefix: string;
    labelSuffix: string;
  }) => {
    const item = dataSource[0];
    return (
      <div key={item.name} className={styles.fieldWrapper}>
        <span className={styles.inputPrefix}>{data.labelPrefix}</span>
        {renderInput(item)}
        <span>{data.labelSuffix}</span>
      </div>
    );
  };

  if (
    predefinedReportKeyName ===
    PredefinedReportDtoKeyName.IdleUsersForTheLastNMonths
  ) {
    return renderParticularItem({
      labelPrefix: t('reportApp.idleUsersInputPrefix'),
      labelSuffix: t('reportApp.idleUsersInputSuffix'),
    });
  }

  if (
    predefinedReportKeyName ===
    PredefinedReportDtoKeyName.UsersToBeExpiredInNDays
  ) {
    return renderParticularItem({
      labelPrefix: t('reportApp.usersToBeExpiredInputPrefix'),
      labelSuffix: t('reportApp.usersToBeExpiredInputSuffix'),
    });
  }

  return (
    <div>
      {dataSource.map((item) => (
        <div key={item.name} className={styles.fieldWrapper}>
          {renderInput(item)}
          <span>{t('predefinedReport.condition.' + item.name)}</span>
        </div>
      ))}
    </div>
  );
};
