import { AuthApi, CreateAuthTokenDto } from 'api-client';
import axios from 'axios';
import { useLocation } from 'react-router-dom';
import { apiConfig } from 'shared/utils/api-config';

export function useLogin() {
  const authApi = new AuthApi(apiConfig());
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  let userId = params.get('user_id');
  let session = params.get('bs_session_id');

  const createAuthTokenDto: CreateAuthTokenDto = {
    userId: userId || '',
    session: session || '',
  };

  sessionStorage.setItem('userId', userId!);
  sessionStorage.setItem('session', session!);

  return authApi
    .createAuthToken(createAuthTokenDto)
    .then((res) => res.data)
    .catch((error) => {
      if (axios.isAxiosError(error)) {
        throw error.response!.data;
      }
      throw error;
    });
}
