import classNames from 'classnames';
import { useEffect, useMemo, useState } from 'react';
import { useFirstRender } from 'shared/hooks/use-first-render';
import styles from './index.module.css';

export interface PaginationProps {
  count?: number;
  page?: number;
  onPageChange?: (value: number) => void;
  rowsPerPageOptions?: number[];
  rowsPerPage?: number;
  onRowsPerPageChange?: (value: number) => void;
  search?: string;
  onSearchChange?: (value?: string) => void;
}

const defaultRowsPerPageOptions = [25, 50, 100, 150];

export const Pagination: React.FC<PaginationProps> = ({
  count = 0,
  page = 1,
  onPageChange,
  rowsPerPageOptions = defaultRowsPerPageOptions,
  rowsPerPage,
}) => {
  const isFirstRender = useFirstRender();

  const [temporaryPage, setTemporaryPage] = useState<string>(page.toString());
  const [currentPage, setCurrentPage] = useState<number>(page);

  const activeRowsPerPage = useMemo(() => {
    if (!rowsPerPageOptions.length) return 0;

    const value = rowsPerPageOptions.find((value) => value === rowsPerPage);
    // Use the first value of the rowsPerPageOptions,
    // if the rowsPerPage value is not exists on the options.
    if (!value) return rowsPerPageOptions[0];

    return value;
  }, [rowsPerPageOptions, rowsPerPage]);

  const totalPage = useMemo(() => {
    return Math.ceil(count / activeRowsPerPage);
  }, [count, activeRowsPerPage]);

  const firstPage = useMemo(() => (totalPage > 0 ? 1 : 0), [totalPage]);
  const lastPage = useMemo(() => totalPage, [totalPage]);

  const isFirstPage = useMemo(
    () => currentPage === firstPage,
    [currentPage, firstPage]
  );
  const isLastPage = useMemo(
    () => currentPage === lastPage,
    [currentPage, lastPage]
  );

  const shouldHavePagination = useMemo(() => totalPage > 0, [totalPage]);

  const handlePageChange = (value: number) => {
    setCurrentPage(value);
  };

  const handlePageInput: React.ChangeEventHandler<HTMLInputElement> = (
    event
  ) => {
    // Force input text field to be numeric only.
    let valueString = event.target.value.replace(/[^0-9]/g, '');

    const valueInt = parseInt(valueString, 10);
    // The minimum value should be 1.
    if (valueInt < 1) valueString = '1';
    // The maximum value should be the total page.
    if (valueInt > totalPage) valueString = totalPage.toString();

    setTemporaryPage(valueString);
  };

  const handlePageInputOnBlur: React.FocusEventHandler = () => {
    if (!temporaryPage) setTemporaryPage('1');
  };

  const handleSubmitPageInput: React.ChangeEventHandler<HTMLFormElement> = (
    event
  ) => {
    event.preventDefault();
    if (!temporaryPage) return;
    handlePageChange(parseInt(temporaryPage));
  };

  const canNavigateToFirstPage = useMemo(
    () => shouldHavePagination && !isFirstPage,
    [shouldHavePagination, isFirstPage]
  );
  const handlePreviousNavigation = () => {
    if (canNavigateToFirstPage) handlePageChange(currentPage - 1);
  };
  const handleFirstNavigation = () => {
    if (canNavigateToFirstPage) handlePageChange(firstPage);
  };

  const canNavigateToLastPage = useMemo(
    () => shouldHavePagination && !isLastPage,
    [shouldHavePagination, isLastPage]
  );
  const handleNextNavigation = () => {
    if (canNavigateToLastPage) handlePageChange(currentPage + 1);
  };
  const handleLastNavigation = () => {
    if (canNavigateToLastPage) handlePageChange(totalPage);
  };

  useEffect(() => {
    if (!isFirstRender && onPageChange) {
      onPageChange(currentPage);
      setTemporaryPage(currentPage.toString());
    }

    if (currentPage > totalPage) {
      handleFirstNavigation();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, totalPage]);

  return (
    <div className={styles.containerWrapper}>
      {shouldHavePagination && (
        <div className={styles.paginationWrapper}>
          <button
            disabled={!canNavigateToFirstPage}
            className={classNames(styles.btn, styles.btnGoFirst)}
            onClick={handleFirstNavigation}
          />

          <button
            disabled={!canNavigateToFirstPage}
            className={classNames(styles.btn, styles.btnPrev)}
            onClick={handlePreviousNavigation}
          />

          <form className={styles.pagingInfo} onSubmit={handleSubmitPageInput}>
            <input
              className={styles.pageInput}
              type="text"
              value={temporaryPage}
              onChange={handlePageInput}
              onBlur={handlePageInputOnBlur}
            />
            <span className={styles.pagingInfoSeparator}>/</span>
            <span className={styles.totalPage}>{totalPage}</span>
          </form>

          <button
            disabled={!canNavigateToLastPage}
            className={classNames(styles.btn, styles.btnNext)}
            onClick={handleNextNavigation}
          />

          <button
            disabled={!canNavigateToLastPage}
            className={classNames(styles.btn, styles.btnGoLast)}
            onClick={handleLastNavigation}
          />
        </div>
      )}
    </div>
  );
};
