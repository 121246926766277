import { useReducer } from 'react';
import { FilterCheckboxDataItem } from '.';

interface Action {
  type: string;
  name?: string;
  value?: FilterCheckboxDataItem[];
}

const initialState: FilterCheckboxDataItem[] = [];

function reducer(state: FilterCheckboxDataItem[], action: Action) {
  switch (action.type) {
    case 'CHECK':
      if (!action.name) return state;
      return [...state, { name: action.name }];
    case 'UNCHECK':
      return state.filter(
        (item: FilterCheckboxDataItem) => item.name !== action.name
      );
    case 'BULK_CHECK':
      if (!action.value) return state;
      return [...state, ...action.value];
    default:
      throw new Error('Action type not recognized');
  }
}

export function useFilterCheckbox() {
  const [state, dispatch] = useReducer(reducer, initialState);

  const check = (value: string) => {
    dispatch({ type: 'CHECK', name: value });
  };

  const uncheck = (value: string) => {
    dispatch({ type: 'UNCHECK', name: value });
  };

  const setSelectedItems = (value: FilterCheckboxDataItem[]) => {
    dispatch({ type: 'BULK_CHECK', value });
  };

  const isChecked = (name: string) => {
    return state.some((item: FilterCheckboxDataItem) => item.name === name);
  };

  return { selectedItems: state, check, uncheck, setSelectedItems, isChecked };
}
