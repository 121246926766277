/* tslint:disable */
/* eslint-disable */
/**
 * BioStar 2 Report API
 * API Documentation for BioStar 2 Report
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @interface FilterConditionDto
 */
export interface FilterConditionDto {
    /**
     * 
     * @type {number}
     * @memberof FilterConditionDto
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof FilterConditionDto
     */
    keyName: FilterConditionDtoKeyName;
    /**
     * 
     * @type {string}
     * @memberof FilterConditionDto
     */
    name: string;
    /**
     * 
     * @type {number}
     * @memberof FilterConditionDto
     */
    defaultItemOrder: number;
    /**
     * 
     * @type {string}
     * @memberof FilterConditionDto
     */
    type: FilterConditionDtoType;
}

/**
    * @export
    * @enum {string}
    */
export enum FilterConditionDtoKeyName {
    UsersAndGroups = 'usersAndGroups',
    Device = 'device',
    DevicesAndGroups = 'devicesAndGroups',
    ShowPhoto = 'showPhoto',
    Credential = 'credential',
    CardTypes = 'cardTypes',
    ShowOnlyAssignedCard = 'showOnlyAssignedCard',
    UserGroups = 'userGroups',
    Days = 'days',
    Months = 'months'
}
/**
    * @export
    * @enum {string}
    */
export enum FilterConditionDtoType {
    ObjectSingle = 'objectSingle',
    ObjectMultiple = 'objectMultiple',
    Period = 'period',
    Checkbox = 'checkbox',
    Number = 'number'
}



