import {
  ConditionPeriodDto,
  ConditionPeriodDynamic,
  ConditionPeriodStatic,
  CustomReportFilterConditionDto,
  FilterItemResponseDto,
  HeaderDto,
  HeaderDtoKeyName,
  LayoutDtoKeyName,
  PredefinedReportDtoKeyName,
  ReportObjectFilterDto,
} from 'api-client';
import { useTranslation } from 'core/context/i18n.context';
import { add, format } from 'date-fns';
import {
  EditTitleDialog,
  EditTitleFormData,
} from 'generate-report/components/edit-title-dialog';
import { UpdateCheckboxCondition } from 'generate-report/components/update-checkbox-condition';
import { UpdateEventFilter } from 'generate-report/components/update-event-filter';
import { UpdateInputNumber } from 'generate-report/components/update-input-number';
import { UpdateObjectCondition } from 'generate-report/components/update-object-condition';
import { UpdatePeriodFilter } from 'generate-report/components/update-period-filter';
import { UpdateUserDoorDeviceFilters } from 'generate-report/components/update-user-door-device-filters';
import { useEditFilterHeader } from 'generate-report/hooks/use-edit-filter-header';
import { useEditFilterHeaderCustomReport } from 'generate-report/hooks/use-edit-filter-header-custom-report';
import { useReport } from 'generate-report/hooks/use-report';
import { useReportContent } from 'generate-report/hooks/use-report-content';
import { useReportMenu } from 'generate-report/hooks/use-report-menu';
import { useReportUpdate } from 'generate-report/hooks/use-report-update';
import { useSaveReportTemplate } from 'generate-report/hooks/use-save-report-template';
import React, { useCallback, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ButtonSecondary } from 'shared/components/buttons/button-secondary';
import { ColumnSettingDialog } from 'shared/components/dialogs/column-setting-dialog';
import { ExportDialog } from 'shared/components/dialogs/export-dialog';
import { PrintDialog } from 'shared/components/dialogs/print-dialog';
import { FilterCheckboxDataItem } from 'shared/components/filter-conditions/filter-checkbox';
import { FilterInputNumberDataItem } from 'shared/components/filter-conditions/filter-input-number';
import { ObjectFilterDataNode } from 'shared/components/filter-conditions/filter-object/type';
import { ItemNotFound } from 'shared/components/item-not-found';
import { PageHeader } from 'shared/components/page-header';
import { Pagination } from 'shared/components/pagination';
import { ScreenLoading } from 'shared/components/screen-loading';
import { Table, TableProps } from 'shared/components/table';
import { TableParticular } from 'shared/components/table-particular';
import { TableParticularTwo } from 'shared/components/table-particular-2';
import { TableRowsSelector } from 'shared/components/table-rows-selector';
import { useErrorDialog } from 'shared/contexts/error-dialog.context';
import { useSuccessDialog } from 'shared/contexts/success-dialog.context';
import { useColumnSetting } from 'shared/hooks/use-column-setting';
import { useDatetimePreference } from 'shared/hooks/use-datetime-preference';
import { PaginationOptions, usePagination } from 'shared/hooks/use-pagination';
import { useSortBy } from 'shared/hooks/use-sort-by';
import { normalizeConditionResult } from 'utility';
import styles from './index.module.css';
import { useNormalizedDatetime } from 'shared/hooks/use-normalized-datetime';

export interface TableData {
  name: string;
}

export const GenerateReportDetailPage: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { reportId } = useParams();
  const { showErrorDialog } = useErrorDialog();
  const { showSuccessDialog } = useSuccessDialog();

  const datetimePreference = useDatetimePreference();
  const report = useReport(reportId);
  const normalizedDatetime = useNormalizedDatetime();
  const isPredefinedReport = useMemo(
    () => !!report.data?.predefinedReportId,
    [report.data?.predefinedReportId]
  );

  const layout = useMemo(
    () => report.data?.predefinedReport?.layout.keyName,
    [report.data?.predefinedReport?.layout.keyName]
  );
  const isLayoutStandard = useMemo(
    () => layout === LayoutDtoKeyName.Standard,
    [layout]
  );
  const isLayoutParticular1 = useMemo(
    () => layout === LayoutDtoKeyName.Particular1,
    [layout]
  );
  const isLayoutParticular2 = useMemo(
    () => layout === LayoutDtoKeyName.Particular2,
    [layout]
  );

  const paginationOptions = useMemo<PaginationOptions>(() => {
    if (isLayoutParticular1) {
      return { rowsPerPageOptions: [2] };
    }
    if (isLayoutParticular2) {
      return { rowsPerPageOptions: [16] };
    }
    return { rowsPerPage: 50 };
  }, [isLayoutParticular1, isLayoutParticular2]);

  const shouldUseSortBy = useMemo(
    () => !isLayoutParticular1 || !isLayoutParticular2,
    [isLayoutParticular1, isLayoutParticular2]
  );

  const pagination = usePagination(paginationOptions);
  const sortBy = useSortBy();
  const reportContent = useReportContent(
    reportId!,
    pagination,
    shouldUseSortBy ? sortBy : undefined
  );

  const shouldShowColumnSettings = !isPredefinedReport || isLayoutStandard;

  const filterHeaderPredefinedReport = useEditFilterHeader(
    report.data?.predefinedReport?.filterConditions,
    report.data?.headers
  );

  const filterHeaderCustomReport = useEditFilterHeaderCustomReport(
    report?.data?.filters
  );

  const filterHeader: any = useMemo(() => {
    if (!isPredefinedReport) return filterHeaderCustomReport;
    return filterHeaderPredefinedReport;
  }, [
    filterHeaderCustomReport,
    filterHeaderPredefinedReport,
    isPredefinedReport,
  ]);

  const saveReportTemplateMutation = useSaveReportTemplate();
  const reportMenu = useReportMenu();

  const savedReports = useMemo(() => {
    const savedTemplates = reportMenu.data?.items[0].children?.length;
    if (!!savedTemplates) {
      return savedTemplates >= 100;
    }
    return false;
  }, [reportMenu]);

  const reportUpdate = useReportUpdate();

  const onRegenerateSuccess = () => {
    filterHeader.closeDialog();
    window.location.reload();
  };

  const [exportDialogOpen, setExportDialogOpen] = useState(false);
  const handleClickExportDialog = () => setExportDialogOpen(true);
  const handleCloseExportDialog = () => setExportDialogOpen(false);

  const [printDialogOpen, setPrintDialogOpen] = useState(false);
  const handleClickPrintDialog = () => setPrintDialogOpen(true);
  const handleClosePrintDialog = () => setPrintDialogOpen(false);

  const handleSuccessPrintDialog = (uri: string) => {
    handleClosePrintDialog();
    window.open(uri, '_blank');
  };

  const handleBackPage = () => navigate('..');

  const tableColumns: TableProps['columns'] = useMemo(() => {
    return (report.data?.columns || [])
      .map((col) => {
        return {
          Header: col.name,
          accessor: col.keyName,
          initialOrder: sortBy?.values?.find((v) => v.fieldName === col.keyName)
            ?.order,
          showColumn: col.showColumn,
        };
      })
      .filter((v) => v.showColumn);
  }, [report.data?.columns, sortBy?.values]);

  const particularTableColumns: TableProps['columns'] = useMemo(() => {
    return (report.data?.columns || []).map((col) => ({
      Header: col.name,
      accessor: col.keyName,
      initialOrder: sortBy?.values?.find((v) => v.fieldName === col.keyName)
        ?.order,
      showColumn: col.showColumn,
    }));
  }, [report.data?.columns, sortBy?.values]);

  const formattedDate = (days: number, hours: number, minutes: number) => {
    const date = add(new Date(), { days: !!days ? -days : days });
    const dateTime = new Date(
      date.getFullYear(),
      date.getMonth(),
      date.getDate(),
      hours,
      minutes
    );

    return format(
      dateTime,
      datetimePreference?.data?.datetimeFormat! || 'yyyy/MM/dd HH:mm'
    );
  };

  const modifiedCustomReportFilters = useMemo(() => {
    if (isPredefinedReport) return;
    const customReportFilters = report.data
      ?.filters as CustomReportFilterConditionDto;

    const combinedFilters = {
      users: [...(customReportFilters?.users || [])],
      doors: [...(customReportFilters?.doors || [])],
      devices: [...(customReportFilters?.devices || [])],
    };
    return {
      createdDate: report.data?.createdDate,
      createdBy: report.data?.createdBy,
      events: customReportFilters?.events,
      period: customReportFilters?.period,
      filters: combinedFilters,
    };
  }, [
    isPredefinedReport,
    report.data?.createdBy,
    report.data?.createdDate,
    report.data?.filters,
  ]);

  const renderHeaderCreatedDate = (header: any) => {
    if (!datetimePreference.data) return '';

    const datetime = normalizedDatetime(header.value);

    return `${header.name}: ${datetime}`;
  };

  const renderHeaderCreatedBy = (header: HeaderDto) => {
    const value = header.value as string;
    return `${header.name}: ${value}`;
  };

  const renderHeaderObject = (header: HeaderDto) => {
    const normalizedValue = normalizeConditionResult(
      (header.value as ReportObjectFilterDto[]) || []
    );
    const obj = normalizedValue.map((v) => v.name).join(', ');
    return `${header.name}: ${obj}`;
  };

  const renderHeaderInput = (header: HeaderDto) => {
    if (
      header.keyName === HeaderDtoKeyName.Days &&
      report.data?.predefinedReport?.keyName ===
        PredefinedReportDtoKeyName.UsersToBeExpiredInNDays
    ) {
      return t('reportApp.usersToBeExpiredWithValue', [header.value as string]);
    }

    if (
      header.keyName === HeaderDtoKeyName.Months &&
      report.data?.predefinedReport?.keyName ===
        PredefinedReportDtoKeyName.IdleUsersForTheLastNMonths
    ) {
      const preferenceLang = localStorage.getItem('language');

      if (preferenceLang === 'ko') {
        return `${header.value} ${t('reportApp.idleUsersWithValue')}`;
      }

      return t('reportApp.idleUsersWithValue', [header.value as number]);
    }

    return `${header.name}: ${header.value}`;
  };

  const renderHeaderText = (header: HeaderDto) => {
    return `${header.name}: ${header.value}`;
  };

  const renderHeaderCheckbox = (header: HeaderDto) => {
    if (!!header.value) {
      return header.name;
    }
  };

  const renderButtonPredefinedReport = (header: HeaderDto) => {
    if (!header.value) return;
    switch (header.keyName) {
      case HeaderDtoKeyName.UsersAndGroups:
      case HeaderDtoKeyName.UserGroups:
      case HeaderDtoKeyName.Days:
      case HeaderDtoKeyName.CardTypes:
      case HeaderDtoKeyName.Device:
      case HeaderDtoKeyName.DevicesAndGroups:
      case HeaderDtoKeyName.ShowOnlyAssignedCard:
      case HeaderDtoKeyName.Months:
        return (
          <span className={styles.iconEditWrapper}>
            <button
              className={styles.iconEdit}
              onClick={() => filterHeader.openDialog(header.keyName)}
            />
          </span>
        );
      default:
        break;
    }
  };

  const renderButtonCustomReport = (idx: number) => {
    return (
      <span className={styles.iconEditWrapper}>
        <button
          className={styles.iconEdit}
          onClick={filterHeader.openDialog(idx)}
        />
      </span>
    );
  };

  const supportedHeaderObjects = useMemo(
    () => [
      HeaderDtoKeyName.Device,
      HeaderDtoKeyName.DevicesAndGroups,
      HeaderDtoKeyName.UsersAndGroups,
      HeaderDtoKeyName.UserGroups,
      HeaderDtoKeyName.CardTypes,
    ],
    []
  );

  const supportedHeaderInputs = useMemo(
    () => [HeaderDtoKeyName.Days, HeaderDtoKeyName.Months],
    []
  );

  const supportedHeaderCheckboxes = useMemo(
    () => [HeaderDtoKeyName.ShowOnlyAssignedCard],
    []
  );

  const renderHeaderPredefinedReport = (header: HeaderDto) => {
    if (header.keyName === HeaderDtoKeyName.CreatedDatetime) {
      return renderHeaderCreatedDate(header);
    } else if (header.keyName === HeaderDtoKeyName.CreatedBy) {
      return renderHeaderCreatedBy(header);
    } else if (supportedHeaderObjects.includes(header.keyName)) {
      return renderHeaderObject(header);
    } else if (supportedHeaderInputs.includes(header.keyName)) {
      return renderHeaderInput(header);
    } else if (header.keyName === HeaderDtoKeyName.TotalUsers) {
      return renderHeaderText(header);
    } else if (supportedHeaderCheckboxes.includes(header.keyName)) {
      return renderHeaderCheckbox(header);
    } else {
      return header.name;
    }
  };

  const renderHeaderEvents = (events: any[]) => {
    const eventNames = events.map((event) => event.name);
    return `${t('customReport.header.events')}: ${eventNames.join(', ')}`;
  };

  const renderHeaderPeriod = (data: ConditionPeriodDto) => {
    if (data.type === 'dynamic') {
      const { startDaysBack, endDaysBack, startTimeBack, endTimeBack } =
        data.value as ConditionPeriodDynamic;
      const startHours = new Date(startTimeBack).getHours();
      const startMinutes = new Date(startTimeBack).getMinutes();
      const endHours = new Date(endTimeBack).getHours();
      const endMinutes = new Date(endTimeBack).getMinutes();

      return `${t('customReport.header.period')}: ${formattedDate(
        startDaysBack,
        startHours,
        startMinutes
      )} ~ ${formattedDate(endDaysBack, endHours, endMinutes)}`;
    }

    if (data.type === 'static') {
      const { startDatetime, endDatetime } =
        data.value as ConditionPeriodStatic;
      const formattedStartDatetime = normalizedDatetime(
        new Date(startDatetime)
      );
      const formattedEndDatetime = normalizedDatetime(new Date(endDatetime));
      return `${t(
        'customReport.header.period'
      )}: ${formattedStartDatetime} ~ ${formattedEndDatetime}`;
    }
  };

  const renderHeaderFilters = (filters: any) => {
    const userNames = filters.users.map(
      (user: FilterItemResponseDto) => user.name
    );
    const doorNames = filters.doors.map(
      (door: FilterItemResponseDto) => door.name
    );
    const deviceNames = filters.devices.map(
      (device: FilterItemResponseDto) => device.name
    );
    const combinedFilters = [...userNames, ...doorNames, ...deviceNames];
    return `${t('customReport.header.filters')}: ${combinedFilters.join(', ')}`;
  };

  const renderHeaderCustomReport = (key: string) => {
    if (key === 'createdBy') {
      return renderHeaderCreatedBy({
        name: t('customReport.header.' + key),
        value: modifiedCustomReportFilters?.createdBy?.name,
      } as any);
    }

    if (key === 'createdDate') {
      return renderHeaderCreatedDate({
        name: t('customReport.header.' + key),
        value: modifiedCustomReportFilters?.createdDate,
      } as any);
    }

    if (key === 'events') {
      return renderHeaderEvents(modifiedCustomReportFilters?.events as any[]);
    }

    if (key === 'period') {
      return renderHeaderPeriod(
        modifiedCustomReportFilters?.period as ConditionPeriodDto
      );
    }

    if (key === 'filters') {
      return renderHeaderFilters(modifiedCustomReportFilters?.filters);
    }
  };

  const isFilterHeaderObjectActive = useMemo(
    () =>
      supportedHeaderObjects.includes(
        filterHeader.headerKeyName as HeaderDtoKeyName
      ),
    [filterHeader.headerKeyName, supportedHeaderObjects]
  );

  const isFilterHeaderInputActive = useMemo(
    () =>
      supportedHeaderInputs.includes(
        filterHeader.headerKeyName as HeaderDtoKeyName
      ),
    [filterHeader.headerKeyName, supportedHeaderInputs]
  );

  const isFilterHeaderCheckboxActive = useMemo(
    () =>
      supportedHeaderCheckboxes.includes(
        filterHeader.headerKeyName as HeaderDtoKeyName
      ),
    [filterHeader.headerKeyName, supportedHeaderCheckboxes]
  );

  const isFilterHeaderEventsActive = useMemo(
    () => filterHeader.headerIndex === 0,
    [filterHeader.headerIndex]
  );

  const isFilterHeaderPeriodActive = useMemo(
    () => filterHeader.headerIndex === 1,
    [filterHeader.headerIndex]
  );

  const isFilterHeaderFiltersActive = useMemo(
    () => filterHeader.headerIndex === 2,
    [filterHeader.headerIndex]
  );

  const filterHeaderCheckboxValue = useMemo(() => {
    if (!isFilterHeaderCheckboxActive) return [];
    return !!filterHeader.conditionResult
      ? [{ name: filterHeader.headerKeyName }]
      : [];
  }, [
    isFilterHeaderCheckboxActive,
    filterHeader.conditionResult,
    filterHeader.headerKeyName,
  ]);

  const filterHeaderInputNumberValue = useMemo(() => {
    if (!isFilterHeaderInputActive) return [];

    return !!filterHeader.conditionResult
      ? [
          {
            name: filterHeader.headerKeyName,
            value: filterHeader.conditionResult,
          },
        ]
      : [];
  }, [
    filterHeader.conditionResult,
    filterHeader.headerKeyName,
    isFilterHeaderInputActive,
  ]);

  const [editTitleDialogOpen, setEditTitleDialogOpen] =
    useState<boolean>(false);

  const openEditTitleDialog = () => setEditTitleDialogOpen(true);
  const closeEditTitleDialog = () => setEditTitleDialogOpen(false);
  const onSaveEditTitle = async (data: EditTitleFormData) => {
    try {
      await reportUpdate.mutateAsync({
        reportId: report.data!.id,
        updateReportDto: {
          title: data.title,
        },
      });
      await report.refetch();
      closeEditTitleDialog();
    } catch (error: any) {
      if (error.code === 'DUPLICATE_TITLE') {
        showErrorDialog({
          alertType: 'error',
          message: t('error.duplicateTitle'),
        });
      } else {
        showErrorDialog({
          alertType: 'error',
          message: (error as any).message,
        });
      }
    }
  };

  const handleColumnSortChange = useCallback(
    (sortByState: { id: string; desc: boolean }[]) => {
      sortBy.onSortByChange(sortByState);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const [columnSettingDialogOpen, setColumnSettingDialogOpen] =
    useState<boolean>(false);
  const columnSetting = useColumnSetting(report.data?.id);

  const openColumnSettingDialog = () => setColumnSettingDialogOpen(true);
  const closeColumnSettingDialog = () => setColumnSettingDialogOpen(false);
  const handleSaveColumnSettings = async (data: any) => {
    const payloads = data.map((d: any) => ({
      id: d.columnId,
      name: d.name,
      order: d.defaultColumnOrder,
      showColumn: d.showColumn,
      newItemOrder: d.newItemOrder + 1,
      selectedItem: d.selectedItem,
    }));

    try {
      await columnSetting.mutation.mutateAsync({ columns: payloads });
      await Promise.all([report.refetch()]);
      closeColumnSettingDialog();
    } catch (error) {
      showErrorDialog({
        alertType: 'error',
        message: t('reportApp.' + (error as any).code),
      });
    }
  };

  const renderTitleAction = () => {
    return (
      <>
        <span className={styles.iconEditWrapper}>
          <button className={styles.iconEdit} onClick={openEditTitleDialog} />
        </span>
        <EditTitleDialog
          open={editTitleDialogOpen}
          onClose={closeEditTitleDialog}
          onSave={onSaveEditTitle}
          titleValue={report.data!.title}
        ></EditTitleDialog>
      </>
    );
  };

  if (report.isLoading || report.isLoading) {
    return <ScreenLoading />;
  }

  if (!report.data || !report.data) {
    return <ItemNotFound />;
  }

  const saveReportTemplate = () => {
    if (!report) return;

    let payload;
    if (!report.data.predefinedReportId) {
      const events = (
        report.data.filters as CustomReportFilterConditionDto
      ).events.map((event) => event.code);
      const users = (
        report.data.filters as CustomReportFilterConditionDto
      ).users?.map((user) => user.originalId);
      const doors = (
        report.data.filters as CustomReportFilterConditionDto
      ).doors?.map((door) => door.originalId);
      const devices = (
        report.data.filters as CustomReportFilterConditionDto
      ).devices?.map((device) => device.originalId);

      payload = {
        name: report.data.title,
        value: JSON.stringify({
          predefinedReportId: report.data.predefinedReportId,
          filterConditions: {
            ...report.data.filters,
            events,
            users,
            doors,
            devices,
          },
          columns: report.data.columns,
        }),
      };
    } else {
      payload = {
        name: report.data.title,
        value: JSON.stringify({
          predefinedReportId: report.data.predefinedReportId,
          filterConditions: report.data.filters,
          columns: report.data.columns,
        }),
      };
    }

    saveReportTemplateMutation.mutate(payload, {
      onSuccess: () => {
        reportMenu.refetch();
        showSuccessDialog({ message: t('reportApp.saveSuccess.message') });
      },
      onError: (error) => {
        let message = error.message;
        if (error.code === 'DUPLICATE_NAME') {
          message = t('reportApp.duplicateReportTemplateName');
        }
        showErrorDialog({
          alertType: 'error',
          message,
        });
      },
    });
  };

  const renderTable = () => {
    if (isLayoutParticular1) {
      return (
        <TableParticular
          columns={particularTableColumns}
          data={reportContent.data?.results || []}
          loading={reportContent.isLoading}
        ></TableParticular>
      );
    }

    if (isLayoutParticular2) {
      return (
        <TableParticularTwo
          columns={particularTableColumns}
          data={reportContent.data?.results || []}
          loading={reportContent.isLoading}
        ></TableParticularTwo>
      );
    }

    return (
      <Table
        columns={tableColumns}
        data={reportContent.data?.results || []}
        loading={reportContent.isLoading}
        onColumnSortChange={handleColumnSortChange}
      ></Table>
    );
  };

  return (
    <>
      <div className={styles.headerTitle}>
        <PageHeader
          title={report.data.title}
          titleActionComponent={renderTitleAction}
          onBackButtonClick={handleBackPage}
        />
        <TableRowsSelector
          {...pagination}
          count={reportContent.data?.unfilteredCount}
        ></TableRowsSelector>
      </div>

      {isPredefinedReport && isFilterHeaderObjectActive && (
        <UpdateObjectCondition
          open={filterHeader.dialogOpen}
          onClose={filterHeader.closeDialog}
          onSuccess={onRegenerateSuccess}
          step={filterHeader.filterStep}
          value={filterHeader.conditionResult as ObjectFilterDataNode[]}
          reportId={report.data.id}
        />
      )}

      {isPredefinedReport && isFilterHeaderCheckboxActive && (
        <UpdateCheckboxCondition
          open={filterHeader.dialogOpen}
          onClose={filterHeader.closeDialog}
          onSuccess={onRegenerateSuccess}
          step={filterHeader.filterStep}
          value={filterHeaderCheckboxValue as FilterCheckboxDataItem[]}
          reportId={report.data.id}
        />
      )}

      {isPredefinedReport && isFilterHeaderInputActive && (
        <UpdateInputNumber
          open={filterHeader.dialogOpen}
          onClose={filterHeader.closeDialog}
          onSuccess={onRegenerateSuccess}
          step={filterHeader.filterStep}
          reportKeyName={report.data?.predefinedReport?.keyName}
          value={filterHeaderInputNumberValue as FilterInputNumberDataItem[]}
          reportId={report.data.id}
        />
      )}

      {!isPredefinedReport && isFilterHeaderEventsActive && (
        <UpdateEventFilter
          open={filterHeader.dialogOpen}
          onClose={filterHeader.closeDialog}
          onSuccess={onRegenerateSuccess}
          value={filterHeader.filterResult}
          reportId={report.data?.id}
        ></UpdateEventFilter>
      )}

      {!isPredefinedReport && isFilterHeaderPeriodActive && (
        <UpdatePeriodFilter
          open={filterHeader.dialogOpen}
          onClose={filterHeader.closeDialog}
          onSuccess={onRegenerateSuccess}
          value={filterHeader.filterResult}
          reportId={report.data?.id}
        ></UpdatePeriodFilter>
      )}

      {!isPredefinedReport && isFilterHeaderFiltersActive && (
        <UpdateUserDoorDeviceFilters
          open={filterHeader.dialogOpen}
          onClose={filterHeader.closeDialog}
          onSuccess={onRegenerateSuccess}
          step={filterHeader.filterStep}
          value={filterHeader.filterResult}
          reportId={report.data?.id}
        ></UpdateUserDoorDeviceFilters>
      )}

      <ExportDialog
        open={exportDialogOpen}
        onClose={handleCloseExportDialog}
        report={{ id: reportId!, title: report.data.title }}
        onSuccess={handleCloseExportDialog}
      ></ExportDialog>

      <PrintDialog
        open={printDialogOpen}
        onClose={handleClosePrintDialog}
        report={{ id: reportId!, title: report.data.title }}
        onSuccess={handleSuccessPrintDialog}
      ></PrintDialog>

      <ColumnSettingDialog
        open={columnSettingDialogOpen}
        onClose={closeColumnSettingDialog}
        data={report.data.columns || []}
        onApply={handleSaveColumnSettings}
      ></ColumnSettingDialog>

      <div className={styles.headerWrapper}>
        {isPredefinedReport &&
          report.data?.headers?.map((header) => (
            <React.Fragment key={header.keyName}>
              <div className={styles.headerListItemWrapper}>
                <span className={styles.headerListItem}>
                  {renderHeaderPredefinedReport(header)}
                </span>
                {renderButtonPredefinedReport(header)}
              </div>
            </React.Fragment>
          ))}

        {!isPredefinedReport &&
          !!report.data?.filters &&
          Object.keys(modifiedCustomReportFilters!).map((key) => (
            <React.Fragment key={key}>
              <div className={styles.headerListItemWrapper}>
                <span className={styles.headerListItem}>
                  {renderHeaderCustomReport(key)}
                </span>
                {key === 'events' && renderButtonCustomReport(0)}
                {key === 'period' && renderButtonCustomReport(1)}
                {key === 'filters' && renderButtonCustomReport(2)}
              </div>
            </React.Fragment>
          ))}

        <div className={styles.buttonActions}>
          <ButtonSecondary
            type="submit"
            title={t('reportApp.saveReport')}
            size="small"
            onClick={saveReportTemplate}
            loading={saveReportTemplateMutation.isLoading}
            className={styles.actionButton}
            disabled={savedReports}
          ></ButtonSecondary>
          <div className={styles.buttonActionsRight}>
            <ButtonSecondary
              type="button"
              title={t('reportApp.export')}
              size="small"
              onClick={handleClickExportDialog}
              className={styles.actionButton}
            ></ButtonSecondary>
            <ButtonSecondary
              type="button"
              title={t('reportApp.print')}
              size="small"
              onClick={handleClickPrintDialog}
              className={styles.actionButton}
            ></ButtonSecondary>

            {/* TOTO: Waiting for client confirmation */}
            {/* <ButtonSecondary
              type="button"
              title={t('reportApp.send')}
              size="small"
            className={styles.actionButton}
            ></ButtonSecondary>
            <ButtonSecondary
              type="button"
              title={t('common.schedule')}
              size="small"
            className={styles.actionButton}
            ></ButtonSecondary> */}

            {shouldShowColumnSettings && (
              <ButtonSecondary
                type="button"
                title={t('reportApp.columnSetting')}
                size="small"
                onClick={openColumnSettingDialog}
                className={styles.actionButton}
              ></ButtonSecondary>
            )}
          </div>
        </div>
      </div>

      {renderTable()}
      <Pagination
        {...pagination}
        count={reportContent.data?.unfilteredCount}
      ></Pagination>
    </>
  );
};
