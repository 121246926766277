import { FilterConditionsApi } from 'api-client';
import { useMemo } from 'react';
import { useQueries } from 'react-query';
import { FilterCheckboxDataItem } from 'shared/components/filter-conditions/filter-checkbox';
import { FilterInputNumberDataItem } from 'shared/components/filter-conditions/filter-input-number';
import { apiConfig } from 'shared/utils/api-config';
import { WizardStepProps } from '../index.type';

export function useFilterCondition(
  wizardSteps: (WizardStepProps | undefined)[]
) {
  const normalizedWizardSteps = useMemo(() => {
    return wizardSteps.filter((item) => !!item) as WizardStepProps[];
  }, [wizardSteps]);

  const queries = useQueries(
    normalizedWizardSteps.map((step) => ({
      queryKey: [step.type, step.value],
      queryFn: getQueryFn(step.type, step.value),
    }))
  );

  const queriesData = useMemo(() => {
    return queries.map((query) => query.data);
  }, [queries]);

  const wizardStepsWithData = useMemo(() => {
    return normalizedWizardSteps.map((step, index) => {
      return {
        ...step,
        data: queriesData[index],
      };
    });
  }, [queriesData, normalizedWizardSteps]);

  const isLoading = useMemo(
    () => queries.some((query) => query.isLoading),
    [queries]
  );

  return { steps: wizardStepsWithData, isLoading };
}

function getQueryFn(type: string, value?: string) {
  switch (type) {
    case 'objectSingle':
    case 'objectMultiple':
      return getQueryFnByObject(value);
    case 'period':
    case 'checkbox':
      return getQueryFnByCheckbox(value);
    case 'number':
      return getQueryFnByNumber(value);
    default:
      throw new Error(`Unknown type: ${type}`);
  }
}

function getQueryFnByObject(value?: string) {
  switch (value) {
    case 'users':
      return fetchUsers;
    case 'userGroups':
      return fetchUserGroups;
    case 'usersAndGroups':
      return fetchUsersAndGroups;
    case 'device':
      return fetchDevices;
    case 'devicesAndGroups':
      return fetchDevicesAndGroups;
    case 'doorsAndDevices':
      return fetchDoorsAndDevices;
    case 'credentials':
    case 'credential':
      return fetchCredentials;
    case 'cardTypes':
      return fetchCardTypes;
    case 'event':
      return fetchEventCategories;
    case 'eventType':
      return fetchEventTypes;
    default:
      throw new Error(`Unknown value: ${value}`);
  }
}

function getQueryFnByCheckbox(value?: string) {
  return () => [{ name: value }] as FilterCheckboxDataItem[];
}

function getQueryFnByNumber(value?: string) {
  return () => [{ name: value }] as FilterInputNumberDataItem[];
}

const fetchUsers = async () => {
  const filterConditionsApi = new FilterConditionsApi(apiConfig());
  const { data } = await filterConditionsApi.getUsers();
  return data;
};

const fetchUserGroups = async () => {
  const filterConditionsApi = new FilterConditionsApi(apiConfig());
  const { data } = await filterConditionsApi.getUserGroups();
  return data;
};

const fetchUsersAndGroups = async () => {
  const filterConditionsApi = new FilterConditionsApi(apiConfig());
  const { data } = await filterConditionsApi.getUsersAndGroups();
  return data;
};

const fetchDevices = async () => {
  const filterConditionsApi = new FilterConditionsApi(apiConfig());
  const { data } = await filterConditionsApi.getDevices();
  return data;
};

const fetchDevicesAndGroups = async () => {
  const filterConditionsApi = new FilterConditionsApi(apiConfig());
  const { data } = await filterConditionsApi.getDevicesAndGroups();
  return data;
};

const fetchDoorsAndDevices = async () => {
  // TODO: implement fetch API from the backend
  return [];
};

const fetchCredentials = async () => {
  const filterConditionsApi = new FilterConditionsApi(apiConfig());
  const { data } = await filterConditionsApi.getCredentials();
  const listShowCredentials = ['pin', 'card', 'fingerprint', 'face'];
  const filteredData = data.filter((d) => listShowCredentials.includes(d.id));

  return filteredData;
};

const fetchCardTypes = async () => {
  const filterConditionsApi = new FilterConditionsApi(apiConfig());
  const { data } = await filterConditionsApi.getCardTypes(true);
  const cloned = JSON.parse(JSON.stringify(data));
  const modified = cloned.map((d: any) => {
    if (d.id === '1') {
      return {
        ...d,
        name: 'Wiegand',
      };
    }
    return d;
  });

  return modified;
};

const fetchEventCategories = async () => {
  const customReportApi = new FilterConditionsApi(apiConfig());
  const { data } = await customReportApi.getEventCategories();
  return data;
};

const fetchEventTypes = async () => {
  const customReportApi = new FilterConditionsApi(apiConfig());
  const { data } = await customReportApi.getEventTypes();
  return data;
};
