import classNames from 'classnames';
import { useTranslation } from 'core/context/i18n.context';
import React, { useEffect, useMemo } from 'react';
import { useTable, Column, useFlexLayout, useRowSelect } from 'react-table';
import { useFirstRender } from 'shared/hooks/use-first-render';
import { ItemNotFound } from '../item-not-found';
import { SelectionCheckbox } from './selection-checkbox';
import styles from './index.module.css';
import { RowDragAndDrop } from './row-drag-and-drop';
import { SorterDtoOrder } from 'api-client';

export interface StandardTableProps {
  columns: (Column & { initialOrder?: SorterDtoOrder })[];
  data: {}[];
  setData: React.Dispatch<React.SetStateAction<any>>;
  tableStyle?: string;
  useRowSelection?: boolean;
  useRowDragAndDrop?: boolean;
  resetData?: number;
  onChange?: (data: any) => void;
}

export const StandardTable: React.FC<StandardTableProps> = ({
  columns,
  data,
  setData,
  tableStyle,
  useRowSelection,
  useRowDragAndDrop,
  resetData,
  onChange,
}) => {
  const { t } = useTranslation();
  const isFirstRender = useFirstRender();

  const initialSelectedRowIds = useMemo(() => {
    if (!useRowSelection) return {};
    return data.reduce((prev, curr: Record<string, any>) => {
      if (curr.showColumn) {
        return { ...prev, [curr.columnId]: true };
      }
      return prev;
    }, {});
  }, [data, useRowSelection]);

  const getRowId = React.useCallback((row) => {
    return row.columnId;
  }, []);

  const selectionColumn = {
    id: 'selection',
    Header: ({ getToggleAllRowsSelectedProps }: any) => (
      <SelectionCheckbox {...getToggleAllRowsSelectedProps()} />
    ),
    Cell: ({ row }: any) => (
      <SelectionCheckbox {...row.getToggleRowSelectedProps()} />
    ),
    width: 24,
  };

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    toggleRowSelected,
    state: { selectedRowIds },
  } = useTable(
    {
      columns,
      data,
      getRowId,
      autoResetSelectedRows: false,
      initialState: { selectedRowIds: initialSelectedRowIds },
    } as any,
    useFlexLayout,
    useRowSelect,
    (hooks) => {
      hooks.visibleColumns.push((columns) => [
        ...(useRowSelection ? [selectionColumn] : []),
        ...columns,
      ]);
    }
  ) as any;

  // Reset data
  useEffect(() => {
    if (!isFirstRender) {
      if (useRowSelection) {
        data.forEach((item: any) => {
          toggleRowSelected(item.columnId, !!item.showColumn);
        });
      }
      setData(data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resetData]);

  useEffect(() => {
    if (!isFirstRender && !!onChange) {
      const result = data.map((item: any, idx) => {
        if (useRowSelection) {
          const selectedItem = selectedRowIds[item.columnId];
          item.selectedItem = !!selectedItem;
          item.showColumn = !!selectedItem;
        }

        if (useRowDragAndDrop) {
          item.newItemOrder = idx;
        }

        return item;
      });
      onChange(result);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, selectedRowIds]);

  const moveRow = (dragIndex: number, hoverIndex: number) => {
    setData((prevItems: any) => {
      const newItems: any[] = JSON.parse(JSON.stringify(prevItems));
      newItems.splice(dragIndex, 0, ...newItems.splice(hoverIndex, 1));
      return newItems;
    });
  };

  const renderEmpty = () => {
    return (
      <div className={styles.infoWrapper}>
        <div className={styles.empty}>
          <ItemNotFound message={t('reportApp.empty')} lite />
        </div>
      </div>
    );
  };

  const renderData = () => {
    return rows.map((row: any, rowIdx: number) => {
      prepareRow(row);

      if (useRowDragAndDrop) {
        return (
          <RowDragAndDrop
            row={row}
            index={rowIdx}
            moveRow={moveRow}
            {...row.getRowProps()}
          ></RowDragAndDrop>
        );
      }

      return (
        <div {...row.getRowProps()} className={styles.tbodyRow}>
          {row.cells.map((cell: any) => {
            return (
              <div
                {...cell.getCellProps()}
                className={classNames('body-cell', styles.tbodyData)}
              >
                {cell.render('Cell')}
              </div>
            );
          })}
        </div>
      );
    });
  };

  return (
    <div {...getTableProps()} className={classNames(styles.table, tableStyle)}>
      <div className="table-head">
        {headerGroups.map((headerGroup: any) => (
          <div
            {...headerGroup.getHeaderGroupProps()}
            className={styles.theadRow}
          >
            {headerGroup.headers.map((column: any) => (
              <div {...column.getHeaderProps()} className={styles.theadData}>
                <div className={classNames(styles.theadDataValue)}>
                  {column.render('Header')}
                </div>
              </div>
            ))}
          </div>
        ))}
      </div>
      <div {...getTableBodyProps()} className="table-body">
        {!rows.length && renderEmpty()}
        {!!rows.length && renderData()}
      </div>
    </div>
  );
};
