import { useAuth } from 'core/context/auth.context';
import { Navigate } from 'react-router-dom';

/**
 * Protect the route from being accessed by anyone.
 * Only those who have been authenticated can access the requested pages.
 *
 * It will redirect to the login page if they're not logged-in yet
 * when accessing the protected routes.
 */
export const AuthGuard: React.FC = ({ children }) => {
  const { accessToken } = useAuth();

  if (!accessToken) {
    return <Navigate to="/auth/login"></Navigate>;
  }

  return <>{children}</>;
};
