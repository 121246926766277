import { PreferencesApi } from 'api-client';
import { useQuery } from 'react-query';
import { apiConfig } from 'shared/utils/api-config';

export function useGetPreference() {
  const api = new PreferencesApi(apiConfig());

  async function fetchPreference() {
    const { data } = await api.getUserPreference();
    return data;
  }

  return useQuery('userPreference', fetchPreference, { enabled: false });
}
