/* tslint:disable */
/* eslint-disable */
/**
 * BioStar 2 Report API
 * API Documentation for BioStar 2 Report
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { ReportObjectFilterDto } from './report-object-filter-dto';

/**
 * 
 * @export
 * @interface HeaderDto
 */
export interface HeaderDto {
    /**
     * 
     * @type {number}
     * @memberof HeaderDto
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof HeaderDto
     */
    keyName: HeaderDtoKeyName;
    /**
     * 
     * @type {string}
     * @memberof HeaderDto
     */
    name: string;
    /**
     * 
     * @type {number}
     * @memberof HeaderDto
     */
    defaultItemOrder: number;
    /**
     * 
     * @type {Array<ReportObjectFilterDto> | string | number}
     * @memberof HeaderDto
     */
    value?: Array<ReportObjectFilterDto> | string | number;
}

/**
    * @export
    * @enum {string}
    */
export enum HeaderDtoKeyName {
    CreatedDatetime = 'createdDatetime',
    CreatedBy = 'createdBy',
    UsersAndGroups = 'usersAndGroups',
    Device = 'device',
    DevicesAndGroups = 'devicesAndGroups',
    TotalUsers = 'totalUsers',
    ShowOnlyAssignedCard = 'showOnlyAssignedCard',
    Days = 'days',
    Months = 'months',
    UserGroups = 'userGroups',
    CardTypes = 'cardTypes'
}



