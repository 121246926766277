import { TranslationApi } from 'api-client';
import { i18nConfig } from 'core/config/i18n.config';
import { useQuery } from 'react-query';
import { apiConfig } from 'shared/utils/api-config';

export function useGetTranslationMessages() {
  const api = new TranslationApi(apiConfig());

  async function fetchTranslationMessages() {
    const lang =
      localStorage.getItem('language') || i18nConfig.fallbackLanguage;
    const { data } = await api.getCurrentTranslation(lang);

    return data;
  }

  return useQuery('translationMessages', fetchTranslationMessages);
}
