import { createContext, useCallback, useContext, useState } from 'react';
import { SuccessDialog } from 'shared/components/dialogs/success-dialog';

interface ShowSuccessDialogParams {
  message: string;
  onClose?: () => void;
}

interface ContextProps {
  showSuccessDialog: (params: ShowSuccessDialogParams) => void;
}

const SuccessDialogContext = createContext<ContextProps>({
  showSuccessDialog: () => {},
});

export const SuccessDialogProvider: React.FC = ({ children }) => {
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const [message, setMessage] = useState<string>('');
  const [onCloseFn, setOnCloseFn] = useState<Function>();

  const showSuccessDialog = (params: ShowSuccessDialogParams) => {
    setMessage(params.message);
    setDialogOpen(true);
    setOnCloseFn(params.onClose);
  };

  const handleCloseDialog = useCallback(() => {
    setMessage('');
    setDialogOpen(false);

    if (onCloseFn) {
      onCloseFn();
    }
  }, [onCloseFn]);

  return (
    <SuccessDialogContext.Provider value={{ showSuccessDialog }}>
      {children}
      <SuccessDialog
        open={dialogOpen}
        onClose={handleCloseDialog}
        message={message}
        disableEscapeKeyDown
        disableBackdropClick
      ></SuccessDialog>
    </SuccessDialogContext.Provider>
  );
};

export function useSuccessDialog() {
  return useContext(SuccessDialogContext);
}
