/* tslint:disable */
/* eslint-disable */
/**
 * BioStar 2 Report API
 * API Documentation for BioStar 2 Report
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { ReportInputFilterDto } from './report-input-filter-dto';
import { ReportObjectFilterDto } from './report-object-filter-dto';

/**
 * 
 * @export
 * @interface PredefinedReportFilterConditionDto
 */
export interface PredefinedReportFilterConditionDto {
    /**
     * 1 = usersAndGroups, 2 = device, 3 = showPhoto, 4 = credential, 5 = cardTypes, 6 = showOnlyAssignedCard, 7 = userGroups, 8 = days, 9 = months, 10 = devicesAndGroups
     * @type {number}
     * @memberof PredefinedReportFilterConditionDto
     */
    filterConditionId: PredefinedReportFilterConditionDtoFilterConditionId;
    /**
     * 
     * @type {Array<ReportObjectFilterDto> | Array<ReportInputFilterDto> | string | number | boolean}
     * @memberof PredefinedReportFilterConditionDto
     */
    value: Array<ReportObjectFilterDto> | Array<ReportInputFilterDto> | string | number | boolean;
}

/**
    * @export
    * @enum {string}
    */
export enum PredefinedReportFilterConditionDtoFilterConditionId {
    NUMBER_1 = 1,
    NUMBER_2 = 2,
    NUMBER_3 = 3,
    NUMBER_4 = 4,
    NUMBER_5 = 5,
    NUMBER_6 = 6,
    NUMBER_7 = 7,
    NUMBER_8 = 8,
    NUMBER_9 = 9,
    NUMBER_10 = 10
}



