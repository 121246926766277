import { TranslationApi } from 'api-client';
import { i18nConfig } from 'core/config/i18n.config';
import { useQuery } from 'react-query';
import { apiConfig } from 'shared/utils/api-config';

export function useGetFallbackTranslationMessages() {
  const api = new TranslationApi(apiConfig());

  async function fetchFallbackTranslationMessages() {
    const lang = i18nConfig.fallbackLanguage;
    const { data } = await api.getCurrentTranslation(lang);

    return data;
  }

  return useQuery(
    'fallbackTranslationMessages',
    fetchFallbackTranslationMessages
  );
}
