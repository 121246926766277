/* tslint:disable */
/* eslint-disable */
/**
 * BioStar 2 Report API
 * API Documentation for BioStar 2 Report
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @interface UpsertCustomReportColumnSettingDto
 */
export interface UpsertCustomReportColumnSettingDto {
    /**
     * 
     * @type {number}
     * @memberof UpsertCustomReportColumnSettingDto
     */
    columnId: UpsertCustomReportColumnSettingDtoColumnId;
    /**
     * 
     * @type {number}
     * @memberof UpsertCustomReportColumnSettingDto
     */
    columnOrder: number;
    /**
     * 
     * @type {boolean}
     * @memberof UpsertCustomReportColumnSettingDto
     */
    showColumn: boolean;
}

/**
    * @export
    * @enum {string}
    */
export enum UpsertCustomReportColumnSettingDtoColumnId {
    NUMBER_1 = 1,
    NUMBER_2 = 2,
    NUMBER_3 = 3,
    NUMBER_4 = 4,
    NUMBER_5 = 5,
    NUMBER_6 = 6,
    NUMBER_7 = 7,
    NUMBER_8 = 8,
    NUMBER_9 = 9,
    NUMBER_10 = 10,
    NUMBER_11 = 11,
    NUMBER_12 = 12,
    NUMBER_13 = 13,
    NUMBER_14 = 14,
    NUMBER_15 = 15,
    NUMBER_16 = 16,
    NUMBER_17 = 17,
    NUMBER_18 = 18,
    NUMBER_19 = 19,
    NUMBER_20 = 20,
    NUMBER_21 = 21,
    NUMBER_22 = 22,
    NUMBER_23 = 23,
    NUMBER_24 = 24,
    NUMBER_25 = 25,
    NUMBER_26 = 26,
    NUMBER_27 = 27,
    NUMBER_28 = 28,
    NUMBER_29 = 29,
    NUMBER_30 = 30,
    NUMBER_31 = 31,
    NUMBER_32 = 32,
    NUMBER_33 = 33,
    NUMBER_34 = 34,
    NUMBER_35 = 35,
    NUMBER_36 = 36,
    NUMBER_37 = 37,
    NUMBER_38 = 38,
    NUMBER_39 = 39,
    NUMBER_40 = 40,
    NUMBER_41 = 41,
    NUMBER_42 = 42,
    NUMBER_43 = 43,
    NUMBER_44 = 44,
    NUMBER_45 = 45,
    NUMBER_46 = 46,
    NUMBER_47 = 47,
    NUMBER_48 = 48,
    NUMBER_49 = 49,
    NUMBER_50 = 50,
    NUMBER_51 = 51
}



