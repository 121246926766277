import { FilterConditionDtoType } from 'api-client';
import { useEffect, useMemo, useState } from 'react';
import { WizardStepProps } from 'shared/components/wizard-event/index.type';

export function useEditFilterHeaderCustomReport(customReportFilters: any) {
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const [headerIndex, setHeaderIndex] = useState<number | undefined>();

  const openDialog = (index: number) => () => {
    setHeaderIndex(index);
    setDialogOpen(true);
  };
  const closeDialog = () => setDialogOpen(false);

  // Delay the set header index while closing the dialog,
  // to avoid UI glitch.
  useEffect(() => {
    if (!dialogOpen && !!headerIndex) {
      let delay = setTimeout(() => setHeaderIndex(undefined), 500);
      return () => clearTimeout(delay);
    }
  }, [dialogOpen, headerIndex]);

  const getType = useMemo(() => {
    switch (headerIndex) {
      case 0:
        return FilterConditionDtoType.ObjectMultiple;
      case 1:
        return FilterConditionDtoType.Period;
      case 2:
        return FilterConditionDtoType.ObjectMultiple;
      default:
        break;
    }
  }, [headerIndex]);

  const getValue = useMemo(() => {
    switch (headerIndex) {
      case 0:
        return 'event';
      case 1:
        return 'period';
      case 2:
        return 'userDoorDevice';
      default:
        break;
    }
  }, [headerIndex]);

  // There will be only one step while editing a filter header.
  const filterStep = useMemo(() => {
    if (typeof headerIndex === 'number') {
      const data: WizardStepProps = {
        stepIndex: 0,
        type: getType as FilterConditionDtoType,
        value: getValue,
        rules: headerIndex === 2 ? [] : ['required'],
      };
      return data;
    }
  }, [getType, getValue, headerIndex]);

  const filterResult = useMemo(() => {
    switch (headerIndex) {
      case 0:
        return customReportFilters.events;
      case 1:
        return customReportFilters.period;
      case 2:
        return [
          customReportFilters.users,
          customReportFilters.doors,
          customReportFilters.devices,
        ];
      default:
        break;
    }
  }, [customReportFilters, headerIndex]);

  return {
    dialogOpen,
    openDialog,
    closeDialog,
    filterStep,
    filterResult,
    headerIndex,
  };
}
