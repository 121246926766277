import { useTranslation } from 'core/context/i18n.context';
import { ButtonPrimary } from 'shared/components/buttons/button-primary';
import { BaseDialog } from '../base-dialog';
import styles from './index.module.css';
import classNames from 'classnames';
import { Checkbox, CircularProgress, FormControlLabel } from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';
import {
  PdfOptionsDto,
  PdfOptionsDtoPageSize,
  PrintConfigDtoName,
} from 'api-client';
import { usePrintReport } from 'shared/hooks/use-print-report';
import { usePrintReportConfig } from 'shared/hooks/use-print-report-config';
import { ButtonDefault } from 'shared/components/buttons/button-default';
import { SelectField } from 'shared/components/inputs/select-field';
import bulletItem from 'core/assets/bl_item.png';

interface PrintDialogProps {
  open: boolean;
  onClose: () => void;
  onSuccess: (uri: string) => void;
  report: {
    id: string;
    title: string;
  };
}
export const PrintDialog: React.FC<PrintDialogProps> = ({
  open,
  onClose,
  report,
  onSuccess,
}) => {
  const { t } = useTranslation();

  const { printReportMutation, supportedPageSizesQuery } = usePrintReport();
  const lastConfig = usePrintReportConfig();

  const [pageSize, setPageSize] = useState<PdfOptionsDtoPageSize>(
    PdfOptionsDtoPageSize.A4
  );
  const [printOptions, setPrintOptions] = useState<PdfOptionsDto>({
    showTitleOnEveryPage: false,
    showHeader: false,
    showHeaderOnEveryPage: false,
    showPageNumber: false,
    pageSize: pageSize,
  });

  const handleChangeShowTitle = (e: React.BaseSyntheticEvent) =>
    setPrintOptions((prevItems: any) => ({
      ...prevItems,
      showTitleOnEveryPage: e.target.checked,
    }));

  const handleChangeShowHeader = (e: React.BaseSyntheticEvent) =>
    setPrintOptions((prevItems: any) => ({
      ...prevItems,
      showHeader: e.target.checked,
      showHeaderOnEveryPage: !e.target.checked
        ? false
        : prevItems.showHeaderOnEveryPage,
    }));

  const handleChangeShowHeaderOnEveryPage = (e: React.BaseSyntheticEvent) =>
    setPrintOptions((prevItems: any) => ({
      ...prevItems,
      showHeaderOnEveryPage: e.target.checked,
    }));

  const showHeaderEveryPageDisable = useMemo(
    () => !printOptions.showHeader,
    [printOptions]
  );

  const handleChangeShowPageNumber = (e: React.BaseSyntheticEvent) =>
    setPrintOptions((prevItems: any) => ({
      ...prevItems,
      showPageNumber: e.target.checked,
    }));

  const handleChangePageSize = (e: any) => setPageSize(e.id);

  const printReport = () => {
    printReportMutation.mutate(
      {
        reportId: parseInt(report.id),
        title: report.title,
        options: printOptions,
      },
      {
        onSuccess: (data) => onSuccess(data.uri),
      }
    );
  };

  useEffect(() => {
    if (lastConfig.data) {
      const config = lastConfig.data
        .filter((v) => v.name !== PrintConfigDtoName.FileFormat)
        .map((c) => ({ [c.name]: c.value }))
        .reduce((prev, curr) => ({ ...prev, ...curr }), {});

      setPrintOptions((prev) => ({ ...prev, ...config }));
    }
  }, [lastConfig.data]);

  const pageSizes = useMemo(() => {
    return supportedPageSizesQuery.data?.map((val) => ({
      id: val,
      name: val,
    }));
  }, [supportedPageSizesQuery.data]);

  const DialogActions = () => {
    return (
      <>
        <ButtonPrimary
          title={t('reportApp.print')}
          size="small"
          loading={printReportMutation.isLoading}
          onClick={printReport}
          className={styles.buttonAction}
        ></ButtonPrimary>
        <ButtonDefault
          title={t('reportApp.cancel')}
          size="small"
          onClick={onClose}
          className={styles.buttonAction}
        ></ButtonDefault>
      </>
    );
  };

  const renderDialogContent = () => {
    return (
      <>
        <div className={styles.container}>
          <img src={bulletItem} className={styles.bulletItem} alt="" />
          <span className={classNames(styles.label)}>
            {t('reportApp.title')}
          </span>
          <FormControlLabel
            control={
              <Checkbox
                sx={{
                  color: '#8c8282',
                  '&.Mui-checked': { color: '#8c8282' },
                }}
                size="small"
              />
            }
            label={
              <span className={styles.formControlLabel}>
                {t('reportApp.showTitle')}
              </span>
            }
            className={styles.checkboxTitle}
            onChange={handleChangeShowTitle}
            checked={printOptions.showTitleOnEveryPage}
          />
        </div>
        <div className={styles.container}>
          <img src={bulletItem} className={styles.bulletItem} alt="" />
          <span className={styles.label}>{t('reportApp.header')}</span>
          <FormControlLabel
            control={
              <Checkbox
                sx={{
                  color: '#8c8282',
                  '&.Mui-checked': { color: '#8c8282' },
                }}
                size="small"
              />
            }
            label={
              <span className={styles.formControlLabel}>
                {t('reportApp.showHeader')}
              </span>
            }
            className={styles.checkboxHeader}
            onChange={handleChangeShowHeader}
            checked={printOptions.showHeader}
          />
          <FormControlLabel
            control={
              <Checkbox
                sx={{
                  color: '#8c8282',
                  '&.Mui-checked': { color: '#8c8282' },
                }}
                size="small"
              />
            }
            label={
              <span className={styles.formControlLabel}>
                {t('reportApp.onEveryPage')}
              </span>
            }
            className={styles.checkboxEveryPage}
            onChange={handleChangeShowHeaderOnEveryPage}
            checked={printOptions.showHeaderOnEveryPage}
            disabled={showHeaderEveryPageDisable}
          />
        </div>
        <div className={styles.container}>
          <img src={bulletItem} className={styles.bulletItem} alt="" />
          <span className={styles.label}>{t('reportApp.footer')}</span>
          <FormControlLabel
            control={
              <Checkbox
                sx={{
                  color: '#8c8282',
                  '&.Mui-checked': { color: '#8c8282' },
                }}
                size="small"
              />
            }
            label={
              <span className={styles.formControlLabel}>
                {t('reportApp.pageNumber')}
              </span>
            }
            onChange={handleChangeShowPageNumber}
            checked={printOptions.showPageNumber}
          />
        </div>
        <div className={styles.container}>
          <img src={bulletItem} className={styles.bulletItem} alt="" />
          <span className={styles.label}>{t('reportApp.pageSize')}</span>
          <SelectField
            name="pageSize"
            dataSource={pageSizes}
            selectProps={{
              value: PdfOptionsDtoPageSize.A4,
              style: { width: 414, textTransform: 'uppercase' },
            }}
            onChange={handleChangePageSize}
            styleType="emboss"
            hidePlaceholder={true}
          ></SelectField>
        </div>
      </>
    );
  };

  return (
    <BaseDialog
      onClose={onClose}
      open={open}
      title={t('reportApp.printReport')}
      disableBackdropClick
      disableEscapeKeyDown
      actions={<DialogActions />}
    >
      <div className={styles.containerWrapper}>
        {lastConfig.isLoading && <CircularProgress />}
        {lastConfig.isFetched && renderDialogContent()}
      </div>
    </BaseDialog>
  );
};
