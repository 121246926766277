import { ColumnResponseDto, UpdateColumnSettingDto } from 'api-client';
import { useTranslation } from 'core/context/i18n.context';
import { useMemo, useState } from 'react';
import { ButtonDefault } from 'shared/components/buttons/button-default';
import { ButtonPrimary } from '../../buttons/button-primary';
import { BaseDialog, BaseDialogProps } from '../base-dialog';
import { DialogContent } from './dialog-content';
import styles from './index.module.css';

export interface ColumnSettingDialogProps
  extends Omit<BaseDialogProps, 'title' | 'actions'> {
  data: ColumnResponseDto[];
  onApply?: (data: any) => Promise<void>;
}

export const ColumnSettingDialog: React.FC<ColumnSettingDialogProps> = ({
  data,
  onApply,
  ...props
}) => {
  const { t } = useTranslation();

  const [dataResult, setDataResult] = useState<
    UpdateColumnSettingDto[] | undefined
  >();
  const handleDataChange = (data: any) => setDataResult(data);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const handleApply = async () => {
    if (!!onApply) {
      setIsLoading(true);
      await onApply(dataResult);
      setIsLoading(false);
    }
  };

  const handleClose = () => {
    if (!!props.onClose) {
      setDataResult(undefined);
      props.onClose();
      setIsLoading(false);
    }
  };

  const noColumnChecked = useMemo(
    () => dataResult?.every((data) => !data.showColumn),
    [dataResult]
  );

  const Actions = () => {
    return (
      <>
        <ButtonPrimary
          title={t('reportApp.apply')}
          size="small"
          className={styles.actionButton}
          loading={isLoading}
          onClick={handleApply}
          disabled={noColumnChecked}
        ></ButtonPrimary>
        <ButtonDefault
          title={t('reportApp.cancel')}
          size="small"
          className={styles.actionButton}
          onClick={handleClose}
        ></ButtonDefault>
      </>
    );
  };

  return (
    <BaseDialog
      {...props}
      title={t('reportApp.columnSetting')}
      actions={<Actions />}
    >
      <DialogContent data={data} onChange={handleDataChange}></DialogContent>
    </BaseDialog>
  );
};
