import { FilterConditionsApi } from 'api-client';
import { useQuery } from 'react-query';
import { apiConfig } from 'shared/utils/api-config';

export function useEventType() {
  const customReportApi = new FilterConditionsApi(apiConfig());

  const fetchEventType = async () => {
    const { data } = await customReportApi.getEventTypes();
    return data;
  };

  return useQuery('eventType', fetchEventType);
}
