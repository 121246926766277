import { EventCategoryFilterContextDto, EventTypeFilterDto } from 'api-client';
import { useCallback, useMemo, useState } from 'react';
import { FilterObjectDataItem } from 'shared/components/filter-conditions/filter-event';
import { compareStringArrays } from 'shared/utils/compare-string-arrays';

export interface NestedCheckboxItem {
  id: string;
  parentId?: any;
  children?: NestedCheckboxItem[];
  context?: EventCategoryFilterContextDto;
}

export function useEventNestedCheckbox<Item extends NestedCheckboxItem>(
  eventCategoriesDataSource: Item[],
  eventTypesDataSource: any[]
) {
  //
  // Event category stuffs
  //

  const [checkedEventCategories, setCheckedEventCategories] = useState<Item[]>(
    []
  );

  const [selectedEventCategory, setSelectedEventCategory] = useState<any>();

  const checkedEventCategoryIds = useMemo(
    () => checkedEventCategories.map((i) => i.id),
    [checkedEventCategories]
  );

  const eventCategoryParentItems = useMemo(
    () => flattenParentItems(eventCategoriesDataSource),
    [eventCategoriesDataSource]
  );

  const eventCategoryParentItemIds = useMemo(
    () => eventCategoryParentItems.map((item) => item.id),
    [eventCategoryParentItems]
  );

  //
  // Event type stuffs
  //

  const [checkedEventTypes, setCheckedEventTypes] = useState<Item[]>([]);

  const checkedEventTypeIds = useMemo(
    () => checkedEventTypes.map((type) => type.id),
    [checkedEventTypes]
  );

  const selectedEventTypes = useMemo(() => {
    if (!selectedEventCategory && !!checkedEventTypes.length) {
      return [];
    }

    if (!selectedEventCategory) return [];

    return eventTypesDataSource
      .filter((eventType: EventTypeFilterDto) => {
        const eventTypeCategories = eventType.categories.flat();
        const selectedEventCategoryId = selectedEventCategory.id.split('-');

        return selectedEventCategoryId.every((category: any) =>
          eventTypeCategories.includes(category)
        );
      })
      .map((item) => {
        const data: FilterObjectDataItem = {
          name: item.name,
          id: item.code,
          categories: item.categories,
        };
        return data;
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedEventCategory, eventTypesDataSource, checkedEventTypes]);

  const indeterminateLastEventCategories = useMemo(() => {
    return flattenLastEventCategories(eventCategoriesDataSource).filter(
      (category: any) => {
        // Available event types of the current categories.
        const availableEventTypes = eventTypesDataSource.filter((eventType) => {
          return eventType.categories.some(
            (c: any) =>
              JSON.stringify(c) === JSON.stringify(category.context.categories)
          );
        });

        const isAllEventTypesSelected = availableEventTypes?.every((c) => {
          return checkedEventTypeIds.includes(c.code);
        });

        const isSomeEventTypesSelected = availableEventTypes?.some((c) => {
          return checkedEventTypeIds.includes(c.code);
        });

        if (isSomeEventTypesSelected && !isAllEventTypesSelected) return true;
        return false;
      }
    );
  }, [eventCategoriesDataSource, eventTypesDataSource, checkedEventTypeIds]);

  const indeterminateEventCategories = useMemo(() => {
    const categoryIds = [
      ...checkedEventCategoryIds,
      ...indeterminateLastEventCategories.map((i: any) => i.id),
    ].filter((v) => v);

    const eventCategoryParents = eventCategoryParentItems.filter((item) => {
      const childrenIds = item.children?.map((i) => i.id);
      const childrenOfChildrenIds = item.children?.map((child) => {
        if (!!child.children) {
          return child.children.map((c) => c.id);
        }

        return child.id;
      });

      const allSelected = childrenIds?.every((i) =>
        checkedEventCategoryIds.includes(i)
      );
      if (allSelected) return false;

      const someSelected = childrenIds?.some((i) => categoryIds.includes(i));

      const someChildrenSelected = childrenOfChildrenIds?.some((child) => {
        if (Array.isArray(child)) {
          return !!child.filter((c) => categoryIds.includes(c)).length;
        }

        return categoryIds.includes(child);
      });

      if (someSelected) return true;
      if (someChildrenSelected) return true;

      return false;
    });

    return [...eventCategoryParents, ...indeterminateLastEventCategories];
  }, [
    eventCategoryParentItems,
    checkedEventCategoryIds,
    indeterminateLastEventCategories,
  ]);

  const indeterminateEventCategoryIds = useMemo(
    () => indeterminateEventCategories.map((category) => category.id),
    [indeterminateEventCategories]
  );

  const handleChangeEventTypes = useCallback(
    (type: 'add' | 'remove', data: any[]) => {
      if (type === 'remove') {
        const dataIds = data.map((item) => item.id);
        const result = checkedEventTypes.filter(
          (item) => !dataIds.includes(item.id)
        );
        setCheckedEventTypes(result);
      }

      if (type === 'add') {
        // Filter data that does not exists on checked data.
        const checkedEventTypeIds = checkedEventTypes.map((item) => item.id);
        const newItems = data.filter(
          (item) => !checkedEventTypeIds.includes(item.id)
        );
        setCheckedEventTypes((prev) => [...prev, ...newItems]);
      }
    },
    [checkedEventTypes]
  );

  const onChangeEventCategory = useCallback(
    (item: Item, checked?: boolean, skipEventTypes?: boolean) => {
      // Set selected event category
      setSelectedEventCategory(item);

      const itemExists = checkedEventCategories.some((i) => i.id === item.id);

      const typeAdd = checked && !itemExists;
      const typeRemove = !checked && itemExists;

      let type: 'add' | 'remove' | 'unknown' = 'unknown';
      if (typeAdd) type = 'add';
      if (typeRemove) type = 'remove';

      if (type === 'unknown') return;
      let result = handleChangeItem(
        checkedEventCategories,
        item,
        type,
        eventCategoryParentItems
      );
      if (!result) return;

      result = filterUniqueItems(result);
      const resultIds = result.map((i) => i.id);

      const filteredDuplicatedIds = checkedEventCategoryIds.filter(
        (id) => !indeterminateEventCategoryIds.includes(id)
      );

      const resultDiffers = compareStringArrays(
        resultIds,
        filteredDuplicatedIds
      );
      if (!resultDiffers) return;

      const prevCheckedCategories = checkedEventCategories;
      const changedCategories = prevCheckedCategories.filter((item) => {
        const ids = result?.map((i) => i.id);
        return !ids?.includes(item.id);
      });
      setCheckedEventCategories(result);

      if (skipEventTypes) return;

      const categoryTargets = type === 'add' ? result : changedCategories;
      const categoryIds = categoryTargets.map((item) => item.id);
      const relatedEventTypes = eventTypesDataSource
        .filter((item) => {
          return item.categories.some((i: any) => {
            const category = i.join('-');
            return categoryIds.includes(category);
          });
        })
        .map((item) => ({
          id: item.code,
          uid: item.code,
          name: item.name,
          categories: item.categories,
        }));

      handleChangeEventTypes(type, relatedEventTypes);
    },
    [
      checkedEventCategories,
      checkedEventCategoryIds,
      indeterminateEventCategoryIds,
      eventCategoryParentItems,
      handleChangeEventTypes,
      eventTypesDataSource,
    ]
  );

  const onChangeEventTypeCheckedParent = useCallback(
    (item: any, type: string, eventTypes?: any[]) => {
      const lastChildCategories = flattenLastEventCategories(
        eventCategoriesDataSource
      );
      const parentId = item.categories.map((value: string[]) =>
        value.join('-')
      );
      const selectedEventCategory = lastChildCategories.find((item: any) =>
        parentId.some((value: string) => value === item.id)
      );

      const availableEventTypes = eventTypesDataSource.filter((item) => {
        const categories = item.categories.map((i: any) => i.join('-'));
        return categories.includes(selectedEventCategory.id);
      });
      const eventTypeCodes = eventTypes?.map((et) => et.id);
      const doesAllEventTypesChecked = availableEventTypes.every((et) =>
        eventTypeCodes?.includes(et.code)
      );

      if (type === 'add' && doesAllEventTypesChecked) {
        onChangeEventCategory(selectedEventCategory, true, true);
      }

      if (type === 'remove') {
        onChangeEventCategory(selectedEventCategory, false, true);
      }
    },
    [eventCategoriesDataSource, eventTypesDataSource, onChangeEventCategory]
  );

  const onChangeEventType = useCallback(
    (item: any, checked: boolean) => {
      const itemExists = checkedEventTypes.some((i) => i.id === item.id);

      const typeAdd = checked && !itemExists;
      const typeRemove = !checked && itemExists;

      let type: 'add' | 'remove' | 'unknown' = 'unknown';
      if (typeAdd) type = 'add';
      if (typeRemove) type = 'remove';

      if (type === 'unknown') return;
      let result = handleChangeItem(checkedEventTypes, item, type, []);
      if (!result) return;

      result = filterUniqueItems(result);
      const resultIds = result.map((i) => i.id);

      const resultDiffers = compareStringArrays(resultIds, checkedEventTypeIds);
      if (!resultDiffers) return;

      onChangeEventTypeCheckedParent(item, type, result);

      return setCheckedEventTypes(result);
    },
    [checkedEventTypes, checkedEventTypeIds, onChangeEventTypeCheckedParent]
  );

  const removeEventCategory = (item: Item) =>
    onChangeEventCategory(item, false);
  const removeEventType = (item: Item) => onChangeEventType(item, false);

  const checkEventCategoryIsChecked = (item: Item) =>
    checkedEventCategories.some((i) => i.id === item.id);

  const checkEventTypeIsChecked = (item: Item) =>
    checkedEventTypes.some((type: any) => {
      return type.id === item.id;
    });

  const checkEventCategoryIsIndeterminate = useCallback(
    (item: Item) => {
      return indeterminateEventCategories.some((i) => i.id === item.id);
    },
    [indeterminateEventCategories]
  );

  const showEventTypes = (item: FilterObjectDataItem) => {
    setSelectedEventCategory(item);
  };

  const getEventCategoriesFromCurrentEventTypes = useCallback(
    (eventTypes: Item[]) => {
      if (!eventTypes.length) return [];

      const currentEventCategory = flattenLastEventCategories(
        eventCategoriesDataSource
      ).filter((category: any) => {
        const eventTypeCategories = eventTypes.map((et: any) =>
          et.categories.flat()
        );

        const categoryId = category.id.split('-');

        return eventTypeCategories.some((cat) => {
          return JSON.stringify(cat) === JSON.stringify(categoryId);
        });
      });

      const parentCurrentEventCategories = currentEventCategory.map(
        (cat: any) =>
          eventCategoryParentItems.find((item) => item.id === cat.parentId)
      );
      const uniqueParents = [...new Set(parentCurrentEventCategories)];

      return [...uniqueParents, ...currentEventCategory];
    },
    [eventCategoriesDataSource, eventCategoryParentItems]
  );

  const setInitialEventTypesValue = useCallback(
    (eventTypes: Item[]) => {
      setCheckedEventTypes(eventTypes);
      setCheckedEventCategories(
        getEventCategoriesFromCurrentEventTypes(eventTypes)
      );
    },
    [getEventCategoriesFromCurrentEventTypes]
  );

  return {
    checkedEventCategories,
    checkedEventCategoryIds,
    eventCategoryParentItems,
    eventCategoryParentItemIds,
    setCheckedEventCategories,
    onChangeEventCategory,
    onChangeEventType,
    removeEventCategory,
    removeEventType,
    checkEventCategoryIsChecked,
    checkEventCategoryIsIndeterminate,
    setCheckedEventTypes,
    setInitialEventTypesValue,
    checkedEventTypes,
    checkedEventTypeIds,
    selectedEventTypes,
    showEventTypes,
    checkEventTypeIsChecked,
    selectedEventCategory,
    indeterminateEventCategoryIds,
  };
}

function flattenParentItems<Item extends NestedCheckboxItem>(
  items: Item[]
): Item[] {
  return items.reduce((prev: Item[], curr) => {
    if (!curr.children?.length) return prev;
    const children = flattenParentItems(curr.children as Item[]);
    return [...prev, curr, ...children];
  }, []);
}

function flattenLastEventCategories(items: any[]): any {
  return items.reduce((prev, current) => {
    if (current.children?.length) {
      const categories = flattenLastEventCategories(current.children);
      return [...prev, ...categories];
    }
    return [...prev, current];
  }, []);
}

function handleParentChange<Item extends NestedCheckboxItem>(
  prevItems: Item[],
  item: Item,
  type: 'add' | 'remove',
  parentItems: Item[],
  hasParent: boolean
) {
  let result = [...prevItems];

  if (type === 'add') {
    // Add current item to the selected items.
    result = [...result, item];

    const nestedResult = handleNestedChildrenOnParentChange(
      result,
      parentItems,
      item,
      'add'
    );

    result = [...result, ...nestedResult];
  }

  if (type === 'remove') {
    // Remove current item from selected items.
    result = result.filter((i) => i.id !== item.id);

    const nestedResult = handleNestedChildrenOnParentChange(
      result,
      parentItems,
      item,
      'remove'
    );

    // Get all the children and remove it from the selected items.
    if (hasParent) {
      result = nestedResult;
    } else {
      const childrenIds = nestedResult.map((i) => i.id);
      result = result.filter((i) => !childrenIds.includes(i.id));
    }
  }

  return result;
}

function handleChildChange<Item extends NestedCheckboxItem>(
  prevItems: Item[],
  item: Item,
  type: 'add' | 'remove',
  parentItems: Item[]
) {
  let result = [...prevItems];

  if (type === 'add') {
    result = [...result, item];
    const nestedParentResult = handleNestedParentOnChildChange(
      result,
      parentItems,
      item
    );
    result = nestedParentResult;
  }

  if (type === 'remove') {
    result = result.filter((i) => {
      return i.id !== item.id;
    });
    const nestedParentResult = handleNestedParentOnChildChange(
      result,
      parentItems,
      item
    );
    result = nestedParentResult;
  }
  return result;
}

function doesAllChildrenChecked<Item extends NestedCheckboxItem>(
  prevItems: Item[],
  item: Item
) {
  const prevItemIds = prevItems.map((i) => i.id);
  return (item.children || []).every((i) => prevItemIds.includes(i.id));
}

function handleNestedParentOnChildChange<Item extends NestedCheckboxItem>(
  prevItems: Item[],
  parentItems: Item[],
  item: Item
) {
  let result = [...prevItems];

  if (!item.parentId) return result;
  const parent = parentItems.find((i) => i.id === item.parentId);
  if (!parent) return result;

  // Check if last child of the parent is checked.
  const childrenChecked = doesAllChildrenChecked(result, parent);
  if (childrenChecked) result = [...result, parent];
  else result = result.filter((i) => i.id !== parent.id);

  if (!parent.parentId) return result;

  // Handle nested parents
  const nestedParentResult = handleNestedParentOnChildChange(
    result,
    parentItems,
    parent
  );
  result = nestedParentResult;

  return result;
}

function handleNestedChildrenOnParentChange<Item extends NestedCheckboxItem>(
  prevItems: Item[],
  parentItems: Item[],
  item: Item,
  type: 'add' | 'remove'
) {
  if (type === 'add') {
    let result = [...prevItems];
    const children: Item[] = ((item.children as Item[]) || []).reduce(
      (prev: Item[], curr: Item) => {
        const nestedChildren = curr.children?.length
          ? handleNestedChildrenOnParentChange(result, parentItems, curr, 'add')
          : [];
        return [...prev, curr, ...nestedChildren];
      },
      []
    );
    result = [...result, ...children];
    return result;
  }

  if (type === 'remove') {
    let result: Item[] = [];
    const children: Item[] = ((item.children as Item[]) || []).reduce(
      (prev: Item[], curr: Item) => {
        const nestedChildren = curr.children?.length
          ? handleNestedChildrenOnParentChange([], parentItems, curr, 'remove')
          : [];
        return [...prev, curr, ...nestedChildren];
      },
      []
    );

    result = [...result, ...children];
    return result;
  }

  return [];
}

function handleChangeItem<Item extends NestedCheckboxItem>(
  prevItems: Item[],
  item: Item,
  type: 'add' | 'remove',
  parentItems: Item[]
) {
  const isParent = !!item.children;
  const hasParent = !!item.parentId;

  if (type === 'add') {
    if (isParent && hasParent) {
      const parentResult = handleParentChange(
        prevItems,
        item,
        'add',
        parentItems,
        hasParent
      );
      const itemResult = handleChildChange(prevItems, item, 'add', parentItems);
      return [...parentResult, ...itemResult];
    }

    if (isParent) {
      return handleParentChange(prevItems, item, 'add', parentItems, hasParent);
    }

    return handleChildChange(prevItems, item, 'add', parentItems);
  }

  if (type === 'remove') {
    if (isParent && hasParent) {
      const parentResult = handleParentChange(
        prevItems,
        item,
        'remove',
        parentItems,
        hasParent
      );

      const itemResult = handleChildChange(
        prevItems,
        item,
        'remove',
        parentItems
      );

      const parentResultIds = parentResult.map((i) => i.id);
      const result = itemResult.filter((i) => !parentResultIds.includes(i.id));

      return result;
    }

    if (isParent) {
      return handleParentChange(
        prevItems,
        item,
        'remove',
        parentItems,
        hasParent
      );
    }

    return handleChildChange(prevItems, item, 'remove', parentItems);
  }
}

function filterUniqueItems<Item extends NestedCheckboxItem>(items: Item[]) {
  return items.reduce((prev: Item[], curr) => {
    const exists = prev.some((i) => i.id === curr.id);
    if (exists) return prev;
    return [...prev, curr];
  }, []);
}
