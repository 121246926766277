import { BiostarInformationApi } from 'api-client';
import { useQuery } from 'react-query';
import { apiConfig } from 'shared/utils/api-config';

export function useGetLanguage() {
  const biostarInformationApi = new BiostarInformationApi(apiConfig());

  async function fetchLanguage() {
    const { data } = await biostarInformationApi.getSystemInfo();
    return data.language;
  }

  return useQuery('activeLanguage', fetchLanguage);
}
