import { CircularProgress } from '@mui/material';
import { useTranslation } from 'core/context/i18n.context';
import { useRegenerateReport } from 'generate-report/hooks/use-regenerate-report';
import { useCallback, useEffect, useMemo } from 'react';
import { ButtonPrimary } from 'shared/components/buttons/button-primary';
import { BaseDialog } from 'shared/components/dialogs/base-dialog';
import { useErrorDialog } from 'shared/contexts/error-dialog.context';
import { useFilterCondition } from 'shared/components/wizard/hooks/use-filter-condition';
import { useFilterReducer } from 'shared/components/wizard/hooks/use-filter-reducer';
import { WizardStepProps } from 'shared/components/wizard/index.type';
import styles from './index.module.css';
import { RegenerateReportDto } from 'api-client';
import { ButtonDefault } from 'shared/components/buttons/button-default';
import {
  FilterInputNumber,
  FilterInputNumberDataItem,
} from 'shared/components/filter-conditions/filter-input-number';

interface GenerateReportProps {
  open: boolean;
  onClose: () => void;
  onSuccess: (id: number) => void;
  step?: WizardStepProps;
  value: FilterInputNumberDataItem[];
  reportId?: number;
  reportKeyName?: string;
}

export const UpdateInputNumber: React.FC<GenerateReportProps> = ({
  open,
  onClose,
  step,
  value,
  reportId,
  onSuccess,
  reportKeyName,
}) => {
  const { t } = useTranslation();
  const filterCondition = useFilterCondition([...(step ? [step] : [])]);
  const regenerateReportMutation = useRegenerateReport();
  const { showErrorDialog } = useErrorDialog();

  const [dataFilter, dispatchFilter] = useFilterReducer();

  const onChangeFilterInputNumber = useCallback(
    (stepIndex: number) => (data: FilterInputNumberDataItem[]) => {
      const val = data;
      dispatchFilter({
        type: 'onChange',
        payload: { stepIndex, value: val },
      });
    },
    [dispatchFilter]
  );

  useEffect(() => {
    onChangeFilterInputNumber(0)(value);
  }, [value, onChangeFilterInputNumber]);

  const validations = useMemo(() => {
    const errors = [];

    if (
      step?.rules?.includes('required') &&
      !!dataFilter.length &&
      !dataFilter[0][0].value
    ) {
      errors.push('required');
    }

    if (step?.rules.includes('limit') && !!dataFilter?.length) {
      const daysValidation =
        dataFilter[0][0]?.name === 'days' &&
        (parseInt(dataFilter[0][0]?.value) < 0 ||
          parseInt(dataFilter[0][0]?.value) > 365);
      const monthsValidation =
        dataFilter[0][0]?.name === 'months' &&
        (parseInt(dataFilter[0][0]?.value) < 1 ||
          parseInt(dataFilter[0][0]?.value) > 60);

      if (daysValidation || monthsValidation) {
        errors.push('limit');
      }
    }

    return errors;
  }, [dataFilter, step]);

  const errorRequired = useMemo(() => {
    return validations.some((v) => v.includes('required'));
  }, [validations]);

  const errorLimit = useMemo(() => {
    return validations.some((v) => v.includes('limit'));
  }, [validations]);

  const handleRegenerateReport = () => {
    if (!reportId) return;

    if (errorRequired) {
      return showErrorDialog({
        alertType: 'warning',
        message: t('reportApp.filterConditionRequired'),
      });
    }

    if (errorLimit) {
      const { name } = dataFilter[0][0];
      const minLimit = name === 'days' ? 0 : 1;
      const maxLimit = name === 'days' ? 365 : 60;

      return showErrorDialog({
        alertType: 'warning',
        message: t('reportApp.limitNumber', [name, minLimit, maxLimit]),
      });
    }

    const payload: RegenerateReportDto = {
      data: {
        filterConditions: [
          {
            filterConditionId: step!.id,
            value: dataFilter[0],
          },
        ],
      },
    };

    regenerateReportMutation.mutate(
      {
        id: reportId,
        regenerateReportDto: payload,
      },
      {
        onError: (error) =>
          showErrorDialog({
            alertType: 'error',
            message: error.message,
          }),
        onSuccess: (data) => onSuccess(data.id),
      }
    );
  };

  const DialogActions = () => {
    return (
      <>
        <ButtonPrimary
          title={t('reportApp.regenerate')}
          size="small"
          className={styles.dialogActionButton}
          onClick={handleRegenerateReport}
          loading={regenerateReportMutation.isLoading}
          disabled={filterCondition.isLoading}
        ></ButtonPrimary>
        <ButtonDefault
          title={t('reportApp.cancel')}
          onClick={onClose}
          size="small"
          className={styles.dialogActionButton}
        ></ButtonDefault>
      </>
    );
  };

  const renderLoading = () => {
    return (
      <div className={styles.loadingWrapper}>
        <CircularProgress />
      </div>
    );
  };

  const renderStepError = () => {
    const errors = validations;
    const hasError = !!errors?.length;
    const firstError = hasError ? errors[0] : null;

    if (!hasError) return <></>;

    if (firstError === 'required') {
      return (
        <p className={styles.filterConditionRequired}>
          *{t('reportApp.filterConditionRequired')}
        </p>
      );
    }
  };

  return (
    <BaseDialog
      onClose={onClose}
      open={open}
      title={t('reportApp.filterCondition')}
      disableBackdropClick
      disableEscapeKeyDown
      actions={<DialogActions />}
    >
      {filterCondition.isLoading && renderLoading()}
      {!!filterCondition.steps.length && !filterCondition.isLoading && (
        <>
          <FilterInputNumber
            dataSource={filterCondition.steps[0].data as any}
            value={dataFilter[0][0].value}
            onChange={onChangeFilterInputNumber(0)}
            predefinedReportKeyName={reportKeyName}
          />
          {renderStepError()}
        </>
      )}
    </BaseDialog>
  );
};
