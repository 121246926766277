import { useCallback } from 'react';

export function useSqueezeText(titleId: string) {
  const titleEl = document.getElementById(titleId);
  const titleWidth = document.getElementById(titleId)?.clientWidth!;
  const titleScrollWidth = document.getElementById(titleId)?.scrollWidth!;

  const squeezeText = useCallback(() => {
    let xScale: number = titleWidth / titleScrollWidth;
    if (!!titleEl) {
      if (xScale < 1) {
        titleEl.style.transform = 'scaleX(' + xScale + ')';
      } else {
        titleEl.style.transform = 'scaleX(1)';
      }
    }
  }, [titleEl, titleScrollWidth, titleWidth]);

  return squeezeText;
}
