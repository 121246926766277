import { CircularProgress } from '@mui/material';
import { Overlay } from '../overlay';
import { Portal } from '../portal';
import styles from './index.module.css';

export const OverlayLoading: React.FC = () => {
  return (
    <Portal>
      <Overlay>
        <div className={styles.spinnerWrapper}>
          <CircularProgress />
        </div>
      </Overlay>
    </Portal>
  );
};
