import { Box, Stack, useMediaQuery } from '@mui/material';
import classNames from 'classnames';
import { useTranslation } from 'core/context/i18n.context';
import {
  GridLayout,
  renderGridLayoutItem,
} from 'dashboard/components/grid-layout';
import { WidgetDialog } from 'dashboard/components/widget-dialog';
import { WidgetElement } from 'dashboard/components/widget-element';
import { useDashboardSetting } from 'dashboard/hooks/use-dashboard-setting';
import { useDialog } from 'dashboard/hooks/use-dialog';
import { useWidget } from 'dashboard/hooks/use-widget';
import { WidgetAreaItem } from 'dashboard/types/widget-item.type';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Layout } from 'react-grid-layout';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { AppBar } from 'shared/components/app-bar';
import { BackButton } from 'shared/components/back-button';
import { ButtonDefault } from 'shared/components/buttons/button-default';
import { ButtonPrimary } from 'shared/components/buttons/button-primary';
import { ConfirmDialog } from 'shared/components/dialogs/confirm-dialog';
import { Title } from 'shared/components/title';
import { useErrorDialog } from 'shared/contexts/error-dialog.context';
import { GeneralSetting } from './general-setting';
import styles from './index.module.css';

export const DashboardSettingsPage: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const newWidgetDialog = useDialog();
  const widget = useWidget();
  const form = useForm();
  const { showErrorDialog } = useErrorDialog();
  const dashboardSetting = useDashboardSetting();
  const [searchParams] = useSearchParams();
  const [widgetsWithDeletedDoor, setWidgetsWithDeletedDoor] = useState<
    number[]
  >([]);
  const [widgetsWithDeletedDevice, setWidgetsWithDeletedDevice] = useState<
    number[]
  >([]);
  const matchesXLScreen = useMediaQuery('(max-width:1875px)');

  const openNewWidgetDialog = useMemo(
    () => searchParams.get('openNewWidgetDialog') || undefined,
    [searchParams]
  );

  const navigateTo = (path: string) => () => navigate(path);

  const applyWidget = async (data: WidgetAreaItem) => {
    widget.addNewWidget(data);
  };

  const updateWidget = async (data: WidgetAreaItem) => {
    widget.updateWidget(data);
  };

  const [loadingApplySetting, setLoadingApplySetting] = useState(false);
  const onValidApplySettings = async () => {
    setLoadingApplySetting(true);
    try {
      await Promise.all([
        widget.saveWidgets(),
        dashboardSetting.saveSetting(form.getValues('general')),
      ]);
      navigateTo('..')();
    } catch (error: any) {
      if (error?.code === 'FAILED_SAVE_WIDGETS') {
        showErrorDialog({
          alertType: 'error',
          message: t('reportApp.maxNumberWidgetsReached'),
        });
      } else {
        showErrorDialog({
          alertType: 'error',
          message: t('reportApp.applySettingFailed'),
        });
      }
    } finally {
      setLoadingApplySetting(false);
    }
  };

  const onInvalidApplySettings = () => {
    showErrorDialog({
      alertType: 'error',
      message: t('reportApp.applySettingFailed'),
    });
  };

  const applySettings = () => {
    form.handleSubmit(onValidApplySettings, onInvalidApplySettings)();
  };

  const onGridLayoutChange = (layouts: Layout[]) => {
    widget.updateLayouts(layouts);
  };

  const dialogDeleteWidget = useDialog();
  const [deleteWidgetAreaId, setDeleteWidgetAreaId] = useState<
    string | undefined
  >();
  const confirmDeleteWidget = (id: string) => {
    setDeleteWidgetAreaId(id);
    dialogDeleteWidget.openDialog();
  };
  const handleDeleteWidget = async () => {
    if (!deleteWidgetAreaId) return;
    widget.removeWidget(deleteWidgetAreaId);
    setDeleteWidgetAreaId(undefined);
    dialogDeleteWidget.closeDialog();
  };

  const dialogEditWidget = useDialog();
  const [editWidgetAreaId, setEditWidgetAreaId] = useState<
    string | undefined
  >();
  const handleEditWidget = (id: string) => {
    setEditWidgetAreaId(id);
    dialogEditWidget.openDialog();
  };

  const widgetById = useMemo(() => {
    if (!editWidgetAreaId || !dialogEditWidget.isOpen) return;
    return widget.getWidgetByAreaId(editWidgetAreaId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editWidgetAreaId]);

  const lastWidget = useMemo(
    () => widget.widgets[widget.widgets.length - 1],
    [widget.widgets]
  );

  const handleDeletedDoor = useCallback((widgetId: number) => {
    setWidgetsWithDeletedDoor((prev) => {
      if (prev.includes(widgetId)) return prev;
      const temp = [...prev, widgetId];
      return temp;
    });
  }, []);

  const handleDeletedDevice = useCallback((widgetId: number) => {
    setWidgetsWithDeletedDevice((prev) => {
      if (prev.includes(widgetId)) return prev;
      const temp = [...prev, widgetId];
      return temp;
    });
  }, []);

  // const reactGridLayoutParentWidth = document
  //   .querySelector('.react-grid-layout')
  //   ?.closest('div.MuiBox-root')?.clientWidth;

  // const reactGridLayoutParentHeight = document
  //   .querySelector('.react-grid-layout')
  //   ?.closest('div.MuiBox-root')?.clientHeight;

  // const reactGridItems =
  //   document.querySelectorAll<HTMLElement>('.react-grid-item');

  // const biggestNumberInArray = (arr: any) => {
  //   const max = Math.max(...arr);
  //   return max;
  // };

  // const widthDifference = useMemo(() => {
  //   let getDifference = 0;
  //   reactGridItems.forEach(function (widget) {
  //     const transformValue = new WebKitCSSMatrix(
  //       window.getComputedStyle(widget).transform
  //     );
  //     if (reactGridLayoutParentWidth !== 0) {
  //       getDifference = reactGridLayoutParentWidth
  //         ? reactGridLayoutParentWidth - transformValue.m41
  //         : 0;
  //     }
  //   });
  //   return getDifference;
  // }, [reactGridLayoutParentWidth, reactGridItems]);

  // const heightDifference = useMemo(() => {
  //   let getDifference = 0;
  //   let getReactGridItemsHeight: any = [];
  //   reactGridItems.forEach(function (widget) {
  //     getReactGridItemsHeight.push(widget.clientHeight);
  //   });
  //   if (
  //     reactGridLayoutParentHeight !== 0 &&
  //     (biggestNumberInArray(getReactGridItemsHeight) !==
  //       Number.NEGATIVE_INFINITY ||
  //       biggestNumberInArray(getReactGridItemsHeight) !==
  //         Number.POSITIVE_INFINITY ||
  //       biggestNumberInArray(getReactGridItemsHeight) !== undefined)
  //   ) {
  //     getDifference = reactGridLayoutParentHeight
  //       ? reactGridLayoutParentHeight -
  //         biggestNumberInArray(getReactGridItemsHeight)
  //       : 0;
  //   }
  //   return getDifference;
  // }, [reactGridLayoutParentHeight, reactGridItems]);

  useEffect(() => {
    // set document title for dashboard
    document.title = t('reportApp.pageTitle', [t('reportApp.dashboard')]);

    if (!!openNewWidgetDialog) {
      newWidgetDialog.openDialog();
    }
    if (dashboardSetting.dashboardSettingFormData) {
      form.setValue('general', dashboardSetting.dashboardSettingFormData);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dashboardSetting.dashboardSettingFormData]);

  return (
    <Box overflow="hidden" display="flex" flexDirection="column">
      <AppBar>
        <Box
          paddingLeft="40px"
          paddingRight={matchesXLScreen ? '54px' : '42px'}
          py="8px"
          display="flex"
          justifyContent="space-between"
        >
          <Stack direction="row" alignItems="center">
            <BackButton onClick={navigateTo('..')}></BackButton>
            <Title>{t('reportApp.dashboardSettings')}</Title>
          </Stack>
          <Stack direction="row">
            <ButtonPrimary
              title={t('reportApp.apply')}
              size="small"
              onClick={applySettings}
              loading={loadingApplySetting}
              className={classNames(styles.button)}
            />
            <Box width="4px" />
            <ButtonDefault
              title={t('reportApp.cancel')}
              size="small"
              onClick={navigateTo('..')}
              className={classNames(styles.button)}
            />
          </Stack>
        </Box>
      </AppBar>

      <Box
        marginLeft="40px"
        paddingBottom="40px"
        paddingRight="42px"
        overflow="auto"
      >
        <Box display="flex" justifyContent="space-between" my="16px">
          <Box display="flex" alignItems="end">
            <b className={styles.guideMsg}>
              {t('reportApp.dashboardSettings.guideMessage')}
            </b>
          </Box>
          <>
            <ButtonPrimary
              title={t('reportApp.addWidget')}
              size="small"
              onClick={newWidgetDialog.openDialog}
              className={styles.btnRadius}
              disabled={widget.widgets.length > 30}
            />
            <WidgetDialog
              open={newWidgetDialog.isOpen}
              onClose={newWidgetDialog.closeDialog}
              onApply={applyWidget}
              value={lastWidget}
            ></WidgetDialog>
          </>
        </Box>

        <Box
          height="800px"
          pb="200px"
          mb="32px"
          boxSizing="border-box"
          border="1px solid var(--neutral-color-4)"
          sx={{
            backgroundColor: 'var(--background-color-3)',
          }}
          style={{
            overflowX: 'hidden',
          }}
          className={classNames(styles.dashboardSettingBox)}
        >
          <GridLayout
            style={{
              minWidth: '1819px',
              overflow: 'hidden',
            }}
            onLayoutChange={onGridLayoutChange}
          >
            {widget.widgets.map((w, index) =>
              renderGridLayoutItem({
                key: w.area.i,
                dataGrid: w.area,
                element: (
                  <WidgetElement
                    data={w}
                    showActions
                    onClickDelete={confirmDeleteWidget}
                    onClickEdit={handleEditWidget}
                    index={index}
                    handleDeletedDoor={handleDeletedDoor}
                    widgetsWithDeletedDoor={widgetsWithDeletedDoor}
                    handleDeletedDevice={handleDeletedDevice}
                    widgetsWithDeletedDevice={widgetsWithDeletedDevice}
                  />
                ),
              })
            )}
          </GridLayout>
        </Box>

        <ConfirmDialog
          message={t('reportApp.confirmDeleteWidget')}
          open={dialogDeleteWidget.isOpen}
          onClose={dialogDeleteWidget.closeDialog}
          onOk={handleDeleteWidget}
          disableBackdropClick
          disableEscapeKeyDown
        />

        <WidgetDialog
          open={dialogEditWidget.isOpen}
          onClose={dialogEditWidget.closeDialog}
          onApply={updateWidget}
          value={widgetById}
          isEdit={true}
        ></WidgetDialog>

        <FormProvider {...form}>
          <GeneralSetting />
        </FormProvider>
      </Box>
    </Box>
  );
};
