import { useTranslation } from 'core/context/i18n.context';
import React, { useState } from 'react';
import { ButtonPrimary } from '../buttons/button-primary';
import styles from './index.module.css';

interface SearchFieldProps {
  value?: string;
  onChangeSearch?: (value: string) => void;
  onSubmit?: (value: string) => void;
}

export const SearchField: React.FC<SearchFieldProps> = ({
  value = '',
  onChangeSearch,
  onSubmit,
}) => {
  const { t } = useTranslation();
  const [search, setSearch] = useState<string>(value);

  const handleSearchInput: React.ChangeEventHandler<HTMLInputElement> = (
    event
  ) => {
    setSearch(event.target.value);
    if (onChangeSearch) {
      onChangeSearch(event.target.value);
    }
  };

  const handleSubmit: React.FormEventHandler<HTMLFormElement> = (event) => {
    event.preventDefault();
    if (onSubmit) {
      onSubmit(search);
    }
  };

  return (
    <form className={styles.searchFieldWrapper} onSubmit={handleSubmit}>
      <input
        className={styles.searchInput}
        type="text"
        value={search}
        onChange={handleSearchInput}
      />
      <ButtonPrimary
        type="submit"
        className={styles.goBtn}
        title={t('reportApp.go')}
      />
    </form>
  );
};
