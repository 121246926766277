import { CircularProgress } from '@mui/material';
import { useTranslation } from 'core/context/i18n.context';
import React from 'react';
import {
  useFlexLayout,
  useResizeColumns,
  useSortBy,
  useTable,
} from 'react-table';
import { ItemNotFound } from '../item-not-found';
import { TableProps } from '../table';
import styles from './index.module.css';
import memberPhoto from 'core/assets/member_photo.png';
import { Card } from './card';

export const TableParticularTwo: React.FC<TableProps> = ({
  columns,
  data,
  loading,
}) => {
  const { t } = useTranslation();

  const defaultColumn = React.useMemo(
    () => ({
      // When using the useFlexLayout:
      minWidth: 80, // minWidth is only used as a limit for resizing
      width: 150, // width is used for both the flex-basis and flex-grow
      maxWidth: 280, // maxWidth is only used as a limit for resizing
    }),
    []
  );

  const { getTableProps, rows, prepareRow } = useTable(
    { columns, data, defaultColumn },
    useResizeColumns,
    useFlexLayout,
    useSortBy
  );

  const renderLoading = () => {
    return (
      <div className={styles.infoWrapper}>
        <div className={styles.loading}>
          <CircularProgress />
        </div>
      </div>
    );
  };

  const renderNoResult = () => {
    return (
      <div className={styles.infoWrapper}>
        <div className={styles.noResult}>
          <ItemNotFound message={t('reportApp.noResult')} lite />
        </div>
      </div>
    );
  };

  const renderImage = (value: any) => {
    if (!value)
      return (
        <img src={memberPhoto} alt="user-profile" className={styles.image} />
      );

    return <img src={value} alt="user-profile" className={styles.image} />;
  };

  const renderTable = () => {
    return rows.map((row) => {
      prepareRow(row);
      return (
        <Card key={row.id}>
          {renderImage(row.values.photo)}
          <span className={styles.userId}>{row.values.userId}</span>
          <span className={styles.userName}>{row.values.userName}</span>
        </Card>
      );
    });
  };

  return (
    <div
      {...getTableProps()}
      className={styles.tableWrapper}
      style={{ minWidth: '960px' }}
    >
      {loading && renderLoading()}
      {!rows.length && !loading && renderNoResult()}
      {!!rows.length && !loading && renderTable()}
    </div>
  );
};
