import { Outlet } from 'react-router';
import { NotFountPage } from '../pages/not-found';
import { RouteObjectExtended } from '../types';

/**
 * Extend the route objects to have a guard on the route
 * and provide the `not found` component.
 * @param routes Route objects.
 * @returns Extended route objects.
 */
export function withRoutesExtended(routes: RouteObjectExtended[]) {
  const extendedRoutes = routes.map((route) => {
    const modifiedRoute = { ...route };

    // If route guards exists, the route element will be wrapped with the guards.
    if (modifiedRoute.guards?.length) {
      modifiedRoute.element = modifiedRoute.element || <Outlet />;
      for (const Guard of modifiedRoute.guards) {
        modifiedRoute.element = <Guard>{modifiedRoute.element}</Guard>;
      }
    }

    // Extend route children recursively.
    if (modifiedRoute.children?.length) {
      modifiedRoute.children = withRoutesExtended(modifiedRoute.children);
    }

    // Add not found route to the children
    // if the children does not have an index route.
    const hasChildIndexRoute = modifiedRoute.children?.some(
      (c) => c.path === ''
    );
    if (modifiedRoute.children?.length && !hasChildIndexRoute) {
      const notFoundRoute: RouteObjectExtended = {
        path: '',
        element: <NotFountPage />,
      };
      modifiedRoute.children.push(notFoundRoute);
    }

    return modifiedRoute;
  });

  return extendedRoutes;
}
