/* tslint:disable */
/* eslint-disable */
/**
 * BioStar 2 Report API
 * API Documentation for BioStar 2 Report
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @interface SorterDto
 */
export interface SorterDto {
    /**
     * 
     * @type {string}
     * @memberof SorterDto
     */
    fieldName: string;
    /**
     * 
     * @type {string}
     * @memberof SorterDto
     */
    order: SorterDtoOrder;
}

/**
    * @export
    * @enum {string}
    */
export enum SorterDtoOrder {
    Asc = 'asc',
    Desc = 'desc'
}



