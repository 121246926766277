/* tslint:disable */
/* eslint-disable */
/**
 * BioStar 2 Report API
 * API Documentation for BioStar 2 Report
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @interface ReportMenuItemDto
 */
export interface ReportMenuItemDto {
    /**
     * With prefix to identify if it is a parent
     * @type {string}
     * @memberof ReportMenuItemDto
     */
    id: string;
    /**
     * The original id without prefix
     * @type {number}
     * @memberof ReportMenuItemDto
     */
    originalId: number;
    /**
     * 
     * @type {string}
     * @memberof ReportMenuItemDto
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof ReportMenuItemDto
     */
    type: ReportMenuItemDtoType;
    /**
     * 
     * @type {string}
     * @memberof ReportMenuItemDto
     */
    subtype?: string;
    /**
     * 
     * @type {number}
     * @memberof ReportMenuItemDto
     */
    order?: number;
    /**
     * The children is a type of ReportMenuItemDto
     * @type {Array<object>}
     * @memberof ReportMenuItemDto
     */
    children?: Array<object>;
}

/**
    * @export
    * @enum {string}
    */
export enum ReportMenuItemDtoType {
    PredefinedReport = 'predefinedReport',
    ReportTemplate = 'reportTemplate',
    Event = 'event'
}



