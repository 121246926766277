import { Box } from '@mui/material';
import { useGetCredential } from 'settings/components/bs-admin/hooks/use-get-credential';
import { useSaveCredential } from 'settings/components/bs-admin/hooks/use-save-credential';
import { useTranslation } from 'core/context/i18n.context';
import React, { Suspense, useMemo } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
// import { EmailSection } from 'settings/components/email-section';
import { ReportSetting } from 'settings/components/report-setting';
import { useSaveReportSetting } from 'settings/hooks/use-save-report-setting';
import { ButtonDefault } from 'shared/components/buttons/button-default';
import { ButtonPrimary } from 'shared/components/buttons/button-primary';
import { LayoutContent } from 'shared/components/layouts/layout-content';
import { StandardLayout } from 'shared/components/layouts/standard-layout';
import { OverlayLoading } from 'shared/components/overlay-loading';
import { useErrorDialog } from 'shared/contexts/error-dialog.context';
import { useSuccessDialog } from 'shared/contexts/success-dialog.context';
import { BSAdmin } from 'settings/components/bs-admin';
import { useGetSchedules } from 'schedule/hooks/use-get-schedules';

type FormData = {
  exportReportPath?: string;
  user: string;
  password: string;
};

const Settings: React.FC = () => {
  const { t } = useTranslation();
  const form = useForm();
  const navigate = useNavigate();
  const saveReportSetting = useSaveReportSetting();
  const adminCredential = useGetCredential();
  const scheduleReport = useGetSchedules();
  const saveAdminCredential = useSaveCredential();
  const { showSuccessDialog } = useSuccessDialog();
  const { showErrorDialog } = useErrorDialog();

  const onSubmitValid: SubmitHandler<FormData> = async (data) => {
    try {
      await Promise.all([
        saveReportSetting.mutateAsync({
          exportReportPath: data.exportReportPath,
        }),
        (!!data.user && !!data.password) ||
        (!adminCredential.data && !!scheduleReport.data?.length)
          ? saveAdminCredential.mutateAsync({
              user: data.user,
              password: data.password,
            })
          : null,
      ]);
      adminCredential.refetch();
      showSuccessDialog({ message: t('reportApp.successSaveSettings') });
    } catch (error: any) {
      if (saveAdminCredential.isError) {
        return showErrorDialog({
          alertType: 'error',
          message: error.message,
        });
      }

      return showErrorDialog({
        alertType: 'error',
        message: t('reportApp.failedSaveSettings'),
      });
    }
  };

  const onApply = () => {
    form.handleSubmit(onSubmitValid)();
  };

  const onCancel = () => navigate('/');

  const isSubmitLoading = useMemo(
    () => saveReportSetting.isLoading,
    [saveReportSetting.isLoading]
  );

  return (
    <StandardLayout>
      <LayoutContent>
        <FormProvider {...form}>
          <ReportSetting />
          {/* <EmailSection></EmailSection> */}
          <Box mb="16px" />
          <BSAdmin />
        </FormProvider>

        <Box display="flex" justifyContent="right" mt="16px">
          <Box width="170px">
            <ButtonPrimary
              title={t('reportApp.apply')}
              fullWidth
              onClick={onApply}
              loading={isSubmitLoading}
            />
          </Box>
          <Box width="4px" />
          <Box width="170px">
            <ButtonDefault
              title={t('reportApp.cancel')}
              fullWidth
              onClick={onCancel}
            />
          </Box>
        </Box>
      </LayoutContent>
    </StandardLayout>
  );
};

export const SettingsPage = () => {
  return (
    <Suspense fallback={<OverlayLoading />}>
      <Settings />
    </Suspense>
  );
};
