import React, { useEffect, useMemo, useState } from 'react';
import { FilterPeriod } from 'shared/components/filter-conditions/filter-period';
import { useTranslation } from 'core/context/i18n.context';
import { WizardStepProps } from './index.type';
import { useSteps } from 'shared/hooks/use-steps';
import { ButtonPrimary } from 'shared/components/buttons/button-primary';
import { useFilterCondition } from './hooks/use-filter-condition';
import { useFilterReducer } from './hooks/use-filter-reducer';
import { useErrorDialog } from 'shared/contexts/error-dialog.context';
import { BaseDialog } from 'shared/components/dialogs/base-dialog';
import {
  ColumnResponseDto,
  ConditionPeriodDto,
  CustomReportSupportedColumnDto,
  DeviceAndGroupFilterDto,
  DoorAndGroupFilterDto,
  EventTypeFilterDto,
  UserAndGroupFilterDto,
} from 'api-client';
import styles from './index.module.css';
import { CircularProgress, Stepper } from '@mui/material';
import { FilterEvent } from '../filter-conditions/filter-event';
import { useGenerateCustomReport } from 'generate-report/hooks/use-generate-custom-report';
import { FilterTab } from '../filter-conditions/filter-tab';
import {
  FilterObjectCustomReport,
  FilterObjectDataItem,
} from '../filter-conditions/filter-object-custom-report';
import { ButtonDefault } from '../buttons/button-default';

interface WizardProps {
  open: boolean;
  onClose: () => void;
  onSuccess: (id: number) => void;
  steps: WizardStepProps[];
  eventTypesData?: EventTypeFilterDto[];
}

export const WizardEvent: React.FC<WizardProps> = ({
  steps,
  open,
  onClose,
  onSuccess,
  eventTypesData,
}) => {
  const { t } = useTranslation();
  const wizardSteps = useSteps(steps);
  const filterCondition = useFilterCondition(steps);
  const generateCustomReportMutation = useGenerateCustomReport();
  const { showErrorDialog } = useErrorDialog();

  const [dataFilter, dispatchFilter] = useFilterReducer();

  const onChangeFilterObject =
    (stepIndex: number) =>
    (data: FilterObjectDataItem[] | FilterObjectDataItem[][]) => {
      return dispatchFilter({
        type: 'onChange',
        payload: { stepIndex, value: data },
      });
    };
  const onChangeFilterPeriod = (stepIndex: number) => (data: any) => {
    return dispatchFilter({
      type: 'onChange',
      payload: { stepIndex, value: data },
    });
  };

  const onChangeFilterEvent = (stepIndex: number) => (data: string[]) =>
    dispatchFilter({
      type: 'onChange',
      payload: { stepIndex, value: data },
    });

  const validations = useMemo(() => {
    return wizardSteps.orderedSteps.map((step, idx) => {
      const errors = [];
      if (step.rules.includes('required') && !dataFilter[idx]?.length) {
        errors.push('required');
      }
      return errors;
    });
  }, [dataFilter, wizardSteps.orderedSteps]);
  const haveErrors = useMemo(() => {
    return validations.some((v) => v.length);
  }, [validations]);

  const errorRequired = useMemo(() => {
    if (!!validations.length) {
      return validations[wizardSteps.activeStepIndex].includes('required');
    }
    return false;
  }, [validations, wizardSteps.activeStepIndex]);

  const generateCustomReport = () => {
    if (haveErrors) {
      return showErrorDialog({
        alertType: 'warning',
        message: t('reportApp.filterConditionRequired'),
      });
    }
    const [events, period, userDoorDevice, columns] = dataFilter;

    const columnsSetting = (columns as CustomReportSupportedColumnDto[]).map(
      (column) => ({
        columnId: column.id,
        columnOrder: column.defaultColumnOrder,
        showColumn: true,
      })
    );

    const payload = {
      condition: {
        events,
        period: period as unknown as ConditionPeriodDto,
        users: (userDoorDevice[0] || []).map(
          (user: UserAndGroupFilterDto) => user.originalId
        ),
        doors: (userDoorDevice[1] || []).map(
          (door: DoorAndGroupFilterDto) => door.originalId
        ),
        devices: (userDoorDevice[2] || []).map(
          (device: DeviceAndGroupFilterDto) => device.originalId
        ),
      },
      columns: columnsSetting,
    };

    generateCustomReportMutation.mutate(payload, {
      onSuccess: (data: any) => onSuccess(data),
      onError: (error) =>
        showErrorDialog({
          alertType: 'error',
          message: error.message,
        }),
    });
  };

  const activeTitle = useMemo(
    () => wizardSteps.activeStep?.title || t('reportApp.filterCondition'),
    [t, wizardSteps.activeStep]
  );

  const columnData = useMemo(
    () => filterCondition.steps?.[3]?.data,
    [filterCondition.steps]
  );
  const [isInitColumnData, setIsInitColumnData] = useState<boolean>(false);
  useEffect(() => {
    if (!isInitColumnData && !!columnData?.length) {
      const defaultSelectedColumns = (
        (columnData || []) as ColumnResponseDto[]
      ).filter((item) => item.showColumn);

      onChangeFilterEvent(0)([]);
      onChangeFilterPeriod(1)([]);
      onChangeFilterObject(2)([]);
      onChangeFilterObject(3)(
        defaultSelectedColumns as unknown as FilterObjectDataItem[]
      );
      setIsInitColumnData(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [columnData, isInitColumnData]);

  const renderStep = (step: WizardStepProps) => {
    let component: React.ReactNode = <></>;

    if (step.value === 'event') {
      component = (
        <FilterEvent
          dataSource={step.data}
          value={dataFilter[step.stepIndex]}
          onChange={onChangeFilterEvent(step.stepIndex)}
          multiple={step.type === 'objectMultiple'}
          eventTypeDataSource={eventTypesData || []}
        />
      );
    }

    if (step.type === 'period') {
      component = (
        <FilterPeriod
          value={dataFilter[step.stepIndex]}
          onChange={onChangeFilterPeriod(step.stepIndex)}
        />
      );
    }

    if (step.value === 'userDoorDevice') {
      component = (
        <FilterTab
          dataSource={step.data}
          value={dataFilter[step.stepIndex]}
          onChange={onChangeFilterObject(step.stepIndex)}
          multiple={step.type === 'objectMultiple'}
        ></FilterTab>
      );
    }

    if (step.value === 'column') {
      component = (
        <FilterObjectCustomReport
          dataSource={step.data}
          value={dataFilter[step.stepIndex]}
          onChange={onChangeFilterObject(step.stepIndex)}
          multiple={step.type === 'objectMultiple'}
        />
      );
    }

    return component;
  };

  const renderStepError = (step: WizardStepProps) => {
    const errors = validations[step.stepIndex];
    const hasError = !!errors.length;
    const firstError = hasError ? errors[0] : null;

    if (!hasError) return <></>;

    if (firstError === 'required') {
      return (
        <p className={styles.filterConditionRequired}>
          *{t('reportApp.filterConditionRequired')}
        </p>
      );
    }
  };

  const DialogActions = () => {
    return (
      <>
        {!wizardSteps.isFirstStep && (
          <ButtonPrimary
            title={t('reportApp.previous')}
            size="small"
            className={styles.dialogActionButton}
            onClick={wizardSteps.prevStep}
            disabled={filterCondition.isLoading}
          ></ButtonPrimary>
        )}
        {!wizardSteps.isLastStep && (
          <ButtonPrimary
            title={t('reportApp.next')}
            size="small"
            className={styles.dialogActionButton}
            onClick={wizardSteps.nextStep}
            disabled={errorRequired || filterCondition.isLoading}
          ></ButtonPrimary>
        )}
        {wizardSteps.isLastStep && (
          <ButtonPrimary
            title={t('reportApp.generate')}
            onClick={generateCustomReport}
            loading={generateCustomReportMutation.isLoading}
            disabled={filterCondition.isLoading}
            size="small"
            className={styles.dialogActionButton}
          ></ButtonPrimary>
        )}
        <ButtonDefault
          title={t('reportApp.cancel')}
          onClick={onClose}
          size="small"
          className={styles.dialogActionButton}
        ></ButtonDefault>
      </>
    );
  };

  const renderLoading = () => {
    return (
      <div className={styles.loadingWrapper}>
        <CircularProgress />
      </div>
    );
  };

  const dialogMinWidth = useMemo(() => {
    switch (wizardSteps.activeStep?.value) {
      case 'period':
        return 500;
      case 'event':
        return 900;
      default:
        return 700;
    }
  }, [wizardSteps.activeStep?.value]);

  const dialogMinHeight = useMemo(() => {
    switch (wizardSteps.activeStep?.value) {
      case 'event':
        return 550;
      case 'period':
        return 200;
      default:
        return 481;
    }
  }, [wizardSteps.activeStep?.value]);

  const contentCustomStyles = useMemo(() => {
    switch (wizardSteps.activeStep?.value) {
      case 'period':
        return styles.contentCustomStyles;
      default:
        return;
    }
  }, [wizardSteps.activeStep?.value]);

  return (
    <BaseDialog
      onClose={onClose}
      open={open}
      title={activeTitle}
      disableBackdropClick
      disableEscapeKeyDown
      actions={<DialogActions />}
      minWidth={dialogMinWidth}
      minHeight={dialogMinHeight}
      contentCustomStyles={contentCustomStyles}
    >
      {filterCondition.isLoading && renderLoading()}
      <Stepper activeStep={wizardSteps.activeStepIndex}></Stepper>

      {!!filterCondition.steps.length &&
        !filterCondition.isLoading &&
        filterCondition.steps.map((step) => (
          <React.Fragment key={step.stepIndex}>
            {wizardSteps.activeStepIndex === step.stepIndex && (
              <>
                {renderStep(step)}
                {renderStepError(step)}
              </>
            )}
          </React.Fragment>
        ))}
    </BaseDialog>
  );
};
