import { FilterConditionsApi } from 'api-client';
import { useQuery } from 'react-query';
import { apiConfig } from 'shared/utils/api-config';

export function useGetDoorsAndGroups() {
  const api = new FilterConditionsApi(apiConfig());

  return useQuery(['doorsAndGroups'], async () => {
    const { data } = await api.getDoorsAndGroups();
    return data;
  });
}
