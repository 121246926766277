import { useState } from 'react';

export function useDialog() {
  const [open, setOpen] = useState<boolean>(false);

  const openDialog = () => setOpen(true);
  const closeDialog = () => setOpen(false);

  return { isOpen: open, openDialog, closeDialog };
}
