import { Divider } from '@mui/material';
import { useTranslation } from 'core/context/i18n.context';
import { format } from 'date-fns';
import React, { useEffect, useMemo, useState } from 'react';
import { DateTimePicker } from '../date-time-picker';
import styles from './index.module.css';

export interface FilterPeriodChildProps {
  values: any;
  onChange: (data: any) => void;
  type: string;
}

export const DateTimeRangePicker: React.FC<FilterPeriodChildProps> = ({
  values,
  onChange,
  type,
}) => {
  const { t } = useTranslation();
  const [startDate, setStartDate] = useState<Date>(values.startDatetime);
  const [endDate, setEndDate] = useState<Date>(values.endDatetime);
  const [startTime, setStartTime] = useState(values.startDatetime);
  const [endTime, setEndTime] = useState(values.endDatetime);

  const onChangeStartDate = (data: Date) => setStartDate(data);

  const onChangeEndDate = (data: Date) => setEndDate(data);

  const onChangeStartTime = (data: Date) => setStartTime(data);

  const onChangeEndTime = (data: Date) => setEndTime(data);

  const combineDateTime = (date: Date, time: Date) => {
    if (date && time) {
      const combined = new Date(
        date.getFullYear(),
        date.getMonth(),
        date.getDate(),
        time.getHours(),
        time.getMinutes(),
        time.getSeconds()
      );
      return combined;
    }
    return new Date();
  };

  const isEndDateToday = useMemo(() => {
    if (!endDate || !!new Date(endDate)) return true;
    const currentEndDate = format(endDate, 'dd/mm/yyyy');
    const todaysDate = format(new Date(), 'dd/mm/yyyy');

    return currentEndDate === todaysDate;
  }, [endDate]);

  useEffect(() => {
    onChange({
      startDatetime: combineDateTime(startDate, startTime),
      endDatetime: combineDateTime(endDate, endTime),
    });
  }, [type, endDate, endTime, startDate, startTime, onChange]);

  return (
    <div className={styles.datePickerContainerWrapper}>
      <DateTimePicker
        date={startDate}
        time={startTime}
        maxDate={new Date()}
        onChangeDate={onChangeStartDate}
        onChangeTime={onChangeStartTime}
        label={t('reportApp.startDatetime')}
      />
      <Divider orientation="vertical" flexItem />
      <DateTimePicker
        date={endDate}
        time={endTime}
        minDate={startDate}
        maxDate={new Date()}
        maxTime={
          isEndDateToday ? new Date(new Date().setHours(23, 59, 59)) : undefined
        }
        onChangeDate={onChangeEndDate}
        onChangeTime={onChangeEndTime}
        label={t('reportApp.endDatetime')}
      />
    </div>
  );
};
