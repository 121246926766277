import { Box } from '@mui/system';
import {
  AddScheduleDto,
  AddScheduleDtoFileFormat,
  AddScheduleDtoFrequency,
  AddScheduleDtoOutputType,
  EditScheduleDto,
  EditScheduleDtoFileFormat,
  EditScheduleDtoFrequency,
  EditScheduleDtoOutputType,
  ScheduleDto,
} from 'api-client';
import { useTranslation } from 'core/context/i18n.context';
import { Suspense, useEffect } from 'react';
import {
  FieldValues,
  FormProvider,
  SubmitErrorHandler,
  SubmitHandler,
  useForm,
} from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { useAddSchedule } from 'schedule/hooks/use-add-schedule';
import { useEditSchedule } from 'schedule/hooks/use-edit-schedule';
import { useGetSchedule } from 'schedule/hooks/use-get-schedule';
import { ButtonDefault } from 'shared/components/buttons/button-default';
import { ButtonPrimary } from 'shared/components/buttons/button-primary';
import { LayoutContent } from 'shared/components/layouts/layout-content';
import { StandardLayout } from 'shared/components/layouts/standard-layout';
import { OverlayLoading } from 'shared/components/overlay-loading';
import { PageHeader } from 'shared/components/page-header';
import { useErrorDialog } from 'shared/contexts/error-dialog.context';
import { FormatSection } from '../components/format-section';
import { InformationSection } from '../components/information-section';
import { ScheduleSection } from '../components/schedule-section';

type FormData = {
  scheduleName: string;
  report: string;
  frequency: AddScheduleDtoFrequency | EditScheduleDtoFrequency;
  generateTime: string;
  outputType: AddScheduleDtoOutputType | EditScheduleDtoOutputType;
  reportTitle: string;
  showTitleEveryPage?: boolean;
  showHeader?: boolean;
  showHeaderEveryPage?: boolean;
  pageNumber?: boolean;
  fileFormat: AddScheduleDtoFileFormat | EditScheduleDtoFileFormat;
  hours: string;
  minutes: string;
};

export const FormSchedulePage: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { scheduleId } = useParams();
  const scheduleReport = useGetSchedule(scheduleId!);
  const inputTimeValue = 0;
  const form = useForm<FormData>({
    defaultValues: {
      hours: inputTimeValue.toString().padStart(2, '0'),
      minutes: inputTimeValue.toString().padStart(2, '0'),
    },
  });
  const addScheduleHook = useAddSchedule();
  const editScheduleHook = useEditSchedule();
  const { showErrorDialog } = useErrorDialog();

  const formattedDate = (hours: string, minutes: string) => {
    const date = new Date();
    const dateTime = new Date(
      date.getFullYear(),
      date.getMonth(),
      date.getDate(),
      parseInt(hours),
      parseInt(minutes)
    );
    const tzoffset = new Date().getTimezoneOffset() * 60000;

    return new Date(dateTime.getTime() - tzoffset).toISOString().slice(0, -1);
  };

  useEffect(() => {
    if (
      !!scheduleId &&
      !scheduleReport.isLoading &&
      !!scheduleReport.isSuccess
    ) {
      const scheduleReportData = scheduleReport.data as ScheduleDto;
      form.setValue('scheduleName', scheduleReportData.name);
      form.setValue('report', scheduleReportData.reportTemplate.id.toString());
      form.setValue(
        'frequency',
        scheduleReportData.frequency as unknown as EditScheduleDtoFrequency
      );
      form.setValue(
        'hours',
        new Date(scheduleReportData.generateTime).getHours().toString()
      );
      form.setValue(
        'minutes',
        new Date(scheduleReportData.generateTime).getMinutes().toString()
      );
      form.setValue(
        'outputType',
        scheduleReportData.outputType as unknown as EditScheduleDtoOutputType
      );
      form.setValue(
        'showTitleEveryPage',
        scheduleReportData.showTitleOnEveryPage
      );
      form.setValue('showHeader', scheduleReportData.showHeader);
      form.setValue(
        'showHeaderEveryPage',
        scheduleReportData.showHeaderOnEveryPage
      );
      form.setValue('pageNumber', scheduleReportData.showPageNumber);
      form.setValue(
        'fileFormat',
        scheduleReportData.fileFormat as unknown as EditScheduleDtoFileFormat
      );
      form.setValue('reportTitle', scheduleReportData.reportTemplate.name);
    }
  }, [
    form,
    scheduleId,
    scheduleReport.data,
    scheduleReport.isLoading,
    scheduleReport.isSuccess,
  ]);

  const onChangeTimeValue: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    const fieldName = e.target.name as any;
    const maxValue = parseInt(e.target.max);
    let value = parseInt(e.target.value || '0');

    if (!e.target.value.length) {
      e.target.value = '00';
    }

    if (e.target.value.length === 3) {
      e.target.value = value.toString();
    }

    if (value < 10 && 0 <= value) {
      e.target.value = value.toString().padStart(2, '0');
    }

    if (value > maxValue) {
      e.target.value = e.target.max;
    }

    form.setValue(fieldName, e.target.value, { shouldValidate: true });
  };

  const onSubmitValid: SubmitHandler<FormData> = async (data) => {
    const payload: any = {
      name: data.scheduleName,
      reportTemplateId: parseInt(data.report),
      frequency: data.frequency,
      generateTime: formattedDate(data.hours, data.minutes),
      outputType: data.outputType,
      showTitleOnEveryPage: data.showTitleEveryPage,
      showHeader: data.showHeader,
      showHeaderOnEveryPage: data.showHeaderEveryPage,
      showPageNumber: data.pageNumber,
      fileFormat: data.fileFormat,
    };
    try {
      const mutationHook = !!scheduleId
        ? editScheduleHook.mutateAsync({
            id: parseInt(scheduleId),
            dto: payload as EditScheduleDto,
          })
        : addScheduleHook.mutateAsync(payload as AddScheduleDto);
      await mutationHook;
      navigate('..');
    } catch (error) {
      showErrorDialog({
        alertType: 'error',
        message: t('reportApp.failedAddSchedule'),
      });
    }
  };

  const onSubmitInvalid: SubmitErrorHandler<FieldValues> = (err) => {};

  const onApply = () => {
    form.handleSubmit(onSubmitValid, onSubmitInvalid)();
  };

  const onCancel = () => navigate('..');

  return (
    <Suspense fallback={<OverlayLoading />}>
      <StandardLayout>
        <LayoutContent>
          <PageHeader
            title={
              !!scheduleId
                ? t('reportApp.editSchedulePageTitle')
                : t('reportApp.newSchedulePageTitle')
            }
          />
          <Box mb="24px"></Box>

          <FormProvider {...form}>
            <InformationSection />
            <Box mb="16px"></Box>

            <ScheduleSection onChangeTime={onChangeTimeValue} />
            <Box mb="16px"></Box>

            <FormatSection />

            <Box display="flex" justifyContent="right" mt="16px">
              <Box width="170px">
                <ButtonPrimary
                  title={t('reportApp.apply')}
                  fullWidth
                  onClick={onApply}
                  loading={
                    scheduleId
                      ? editScheduleHook.isLoading
                      : addScheduleHook.isLoading
                  }
                />
              </Box>
              <Box width="4px" />
              <Box width="170px">
                <ButtonDefault
                  title={t('reportApp.cancel')}
                  fullWidth
                  onClick={onCancel}
                />
              </Box>
            </Box>
          </FormProvider>
        </LayoutContent>
      </StandardLayout>
    </Suspense>
  );
};
