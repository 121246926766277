import React from 'react';
import { PageHeader } from 'shared/components/page-header';
import { SearchField } from 'shared/components/search-field';
import styles from './index.module.css';

interface HistoryHeaderProps {
  title: string;
}

export const HistoryHeader: React.FC<HistoryHeaderProps> = ({ title }) => {
  return (
    <div className={styles.headerWrapper}>
      <PageHeader title={title} hideBackButton={true}></PageHeader>
      <SearchField onChangeSearch={() => {}}></SearchField>
    </div>
  );
};
