import { Box } from '@mui/system';
import { WidgetConditionDto, WidgetDtoWidgetType } from 'api-client';
import {
  ArcElement,
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LineElement,
  LinearScale,
  PointElement,
  Title,
  Tooltip,
  registerables,
} from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { useTranslation } from 'core/context/i18n.context';
import { useChartEvents } from 'dashboard/hooks/use-chart-events';
import { useGenerateEventsData } from 'dashboard/hooks/use-generate-events-data';
import { useRefreshIntervalValue } from 'dashboard/hooks/use-refresh-interval-value';
import { useCallback, useEffect, useMemo, useState } from 'react';
import {
  Chart as ReactChart,
  ChartProps as ReactChartProps,
} from 'react-chartjs-2';
import { useInterval } from 'shared/hooks/use-interval';
import { mergeDeep } from 'shared/utils/merge-deep';
import styles from './index.module.css';
import { useSqueezeText } from 'shared/hooks/use-squeeze-text';
import { WidgetAreaItem } from 'dashboard/types/widget-item.type';

ChartJS.register(...registerables);

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  LineElement,
  PointElement,
  ArcElement,
  Title,
  Tooltip,
  Legend,
  ChartDataLabels
);

export interface ChartProps {
  type: ReactChartProps['type'];
  data: ReactChartProps['data'];
  horizontal?: boolean;
  title?: string;
  redraw?: boolean;
  chartColor?: string;
  widgetValue?: any;
  widgetType: string;
  isSettings: boolean;
}

export const Chart: React.FC<ChartProps> = ({
  type,
  data,
  horizontal,
  title,
  redraw,
  chartColor,
  widgetValue,
  widgetType,
  isSettings,
}) => {
  const { t } = useTranslation();
  const chartEvents = useChartEvents();
  const generateEvents = useGenerateEventsData();
  const [eventsData, setEventsData] = useState<any[]>([]);
  const isRefresh = localStorage.getItem('refresh');
  const refreshIntervalValue = useRefreshIntervalValue();
  const [legendHeight, setLegendHeight] = useState<number>(0);
  const systemUsageWithMaxNumber = ['zones', 'doors', 'devices'];

  const getEventsData = () => {
    const chartData = chartEvents.find((data) => data.id === widgetValue);
    const chartConditions: WidgetConditionDto[] = chartData?.data!;
    generateEvents.mutate(chartConditions || ([] as WidgetConditionDto[]), {
      onSuccess: (data: any) => {
        setEventsData(data);
      },
    });
  };

  const reconstructData: ReactChartProps['data'] = useMemo(
    () => ({
      ...data,
      datasets: [{ data: eventsData }],
    }),
    [data, eventsData]
  );

  const normalizedChartColor = useMemo(
    () => chartColor?.split(','),
    [chartColor]
  );

  const normalizedNullLabel = useMemo(() => {
    if (type === 'doughnut') {
      return data.datasets[0].label;
    }
  }, [type, data]);

  const customLabel = useMemo(() => {
    if (type === 'doughnut') {
      const currValue = data.datasets[0].data[0] as number;
      const currLabel = data.datasets[0].label;
      if (!!data.datasets.length && (data.datasets[0].data[1] as number) >= 0) {
        const secondValue = data.datasets[0].data[1] as number;
        const maxValue =
          secondValue + currValue > 1000 ? 1000 : secondValue + currValue;
        return `${currLabel}/${maxValue}`;
      }
      return currValue;
    }
    return '';
  }, [type, data]);

  const commonOptions: ReactChartProps['options'] = useMemo(
    () => ({
      indexAxis: horizontal ? 'y' : 'x',
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        title: {
          display: !!title,
          text: title,
          font: {
            size: 20,
            weight: '500',
          },
          padding: {
            bottom: 32,
          },
          // below are custom labels, not exist inside Chart Props so need to be added in commonOptions
          label: customLabel,
          isNull: normalizedNullLabel,
        },
        legend: { display: false },
        datalabels: {
          color: type === 'line' ? '#000' : '#fff',
          anchor: type === 'pie' ? 'center' : 'end',
          align: type === 'line' ? 'end' : 'start',
          offset: 2,
          display: type !== 'doughnut',
        },
        emptyDoughnut: {
          color: 'rgba(255, 128, 0, 0.5)',
          width: 2,
          radiusDecrease: 20,
        },
      },
      layout: {
        padding: 16,
      },
      datasets: {
        bar: {
          barThickness: 'flex',
          barPercentage: 0.8,
          categoryPercentage: 1,
          backgroundColor: normalizedChartColor as any,
        },
        line: {
          pointBorderWidth: 3,
          pointRadius: 5,
          pointBackgroundColor: '#fff',
          borderColor: normalizedChartColor as any,
        },
        pie: { backgroundColor: normalizedChartColor as any },
        doughnut: {
          backgroundColor: normalizedChartColor as any,
          cutout: '80%',
        },
      },
    }),
    [
      horizontal,
      normalizedChartColor,
      title,
      type,
      customLabel,
      normalizedNullLabel,
    ]
  );

  const commonPlugins: any = useMemo(() => {
    if (type === 'doughnut') {
      return [
        {
          id: 'doughnutCenteredText',
          beforeDraw: (chart: any) => {
            const { ctx } = chart;
            var commonConfig = chart.config.options.plugins.title;
            const getDataLabels = data.datasets[0]?.datalabels?.toString();
            const fontSize =
              chart.canvas.offsetWidth > chart.canvas.offsetHeight
                ? Math.floor(chart.canvas.offsetHeight / 85)
                : Math.floor(chart.canvas.offsetWidth / 85);
            const particularFontSize =
              systemUsageWithMaxNumber.includes(getDataLabels!) &&
              commonConfig.label.length > 8
                ? `${Math.floor(chart.canvas.offsetHeight / 12)}px`
                : `${fontSize}em`;

            ctx.font = `bolder ${
              systemUsageWithMaxNumber.includes(getDataLabels!)
                ? particularFontSize
                : `${fontSize}em`
            } sans-serif`;
            ctx.textAlign = 'center';
            ctx.textBaseline = 'middle';

            const text =
              systemUsageWithMaxNumber.includes(getDataLabels!) ||
              commonConfig.isNull
                ? commonConfig.label
                : 0;
            var centerX = (chart.chartArea.left + chart.chartArea.right) / 2;
            var centerY = (chart.chartArea.top + chart.chartArea.bottom) / 2;
            ctx.fillText(text, centerX, centerY);
            ctx.save();
          },
        },
      ];
    }

    if (type === 'pie') {
      return [
        {
          id: 'pieLegend',
          afterUpdate: (chart: any) => {
            chart.legend.top = chart.legend.top - 20;
            setLegendHeight(chart.legend.height);
          },
        },
      ];
    }

    return [
      {
        id: 'legendHeight',
        beforeInit: (chart: any) => {
          const oriFit = chart.legend.fit;

          chart.legend.fit = function fit() {
            oriFit.bind(chart.legend)();
            return (this.height += 30);
          };
        },
      },
    ];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type, data.datasets]);

  const doughnutOptions: ReactChartProps['options'] = useMemo(
    () => ({
      plugins: {
        tooltip: {
          enabled: !!normalizedNullLabel,
        },
      },
    }),
    [normalizedNullLabel]
  );

  const gridAndTicksOptions: ReactChartProps['options'] = useMemo(
    () => ({
      scales: {
        y: {
          grid: {
            drawBorder: horizontal ? false : true,
            display: false,
          },
          ticks: {
            precision: 0,
          },
        },
        x: {
          grid: {
            drawBorder: horizontal ? true : false,
            display: false,
          },
          ticks: {
            precision: 0,
          },
        },
      },
    }),
    [horizontal]
  );

  const ticksCallback = useCallback(
    (value: string | number, index: number, ticks: any[]) => {
      if (
        index === 0 ||
        index === ticks.length - 1 ||
        index === Math.ceil((ticks.length - 1) / 2)
      ) {
        return value;
      }
      return '';
    },
    []
  );

  const verticalBarChartOptions: ReactChartProps['options'] = useMemo(
    () => ({
      scales: {
        y: {
          ticks: {
            callback: ticksCallback,
          },
        },
      },
    }),
    [ticksCallback]
  );

  const horizontalBarChartOptions: ReactChartProps['options'] = useMemo(
    () => ({
      scales: {
        x: {
          ticks: {
            callback: ticksCallback,
          },
        },
      },
    }),
    [ticksCallback]
  );

  const lineChartOptions: ReactChartProps['options'] = useMemo(
    () => ({
      scales: {
        y: {
          ticks: {
            callback: ticksCallback,
          },
          beginAtZero: true,
        },
        x: {
          offset: true,
        },
      },
    }),
    [ticksCallback]
  );

  const pieChartOptions: ReactChartProps['options'] = useMemo(
    () => ({
      plugins: {
        title: {
          padding: {
            bottom: 12,
          },
        },
        legend: {
          display: true,
          align: 'start',
          labels: {
            usePointStyle: true,
            font: {
              size: 12,
            },
            padding: 15,
          },
          maxWidth: 50,
        },
      },
    }),
    []
  );

  const options: ReactChartProps['options'] = useMemo(() => {
    if (type === 'doughnut') return mergeDeep(commonOptions, doughnutOptions);
    if (type === 'pie') return mergeDeep(commonOptions, pieChartOptions);
    if (type === 'line') {
      const combinedOptions = mergeDeep(gridAndTicksOptions, lineChartOptions);
      return mergeDeep(commonOptions, combinedOptions);
    }
    if (horizontal) {
      const combinedOptions = mergeDeep(
        gridAndTicksOptions,
        horizontalBarChartOptions
      );
      return mergeDeep(commonOptions, combinedOptions);
    }
    if (!horizontal) {
      const combinedOptions = mergeDeep(
        gridAndTicksOptions,
        verticalBarChartOptions
      );
      return mergeDeep(commonOptions, combinedOptions);
    }
    return mergeDeep(commonOptions, gridAndTicksOptions);
  }, [
    commonOptions,
    gridAndTicksOptions,
    horizontal,
    horizontalBarChartOptions,
    lineChartOptions,
    pieChartOptions,
    doughnutOptions,
    type,
    verticalBarChartOptions,
  ]);

  const titleId = `${widgetType}-${type}-${(data as WidgetAreaItem).id}`;
  const squeezeText = useSqueezeText(titleId);

  useInterval(() => {
    if (widgetType === WidgetDtoWidgetType.Chart && !isSettings) {
      getEventsData();
    }
  }, refreshIntervalValue * 1000);

  useEffect(() => {
    if (widgetType === WidgetDtoWidgetType.Chart) {
      if (!eventsData.length || isRefresh === 'true') {
        getEventsData();
      }
    }
    squeezeText();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [widgetType, isRefresh, squeezeText]);

  const noData = useMemo(
    () =>
      widgetType === WidgetDtoWidgetType.Chart &&
      type === 'pie' &&
      eventsData.every((d) => !d),
    [eventsData, widgetType, type]
  );

  const renderContent = useMemo(() => {
    if (!isSettings) {
      return (
        <>
          <ReactChart
            type={type}
            redraw={redraw}
            options={options}
            data={
              widgetType === WidgetDtoWidgetType.Chart ? reconstructData : data
            }
            plugins={commonPlugins}
            width={type === 'doughnut' ? '100%' : 0}
            height={type === 'doughnut' ? '100%' : 0}
          />
          {noData && (
            <Box
              position="absolute"
              top={legendHeight > 100 ? '75%' : '50%'}
              left="50%"
              sx={{ transform: 'translate(-50%, -50%)' }}
              fontSize="16px"
              color="var(--neutral-color-4)"
            >
              {t('reportApp.noData')}
            </Box>
          )}
        </>
      );
    }

    return (
      <>
        <div className={styles.widgetTitleWrapper}>
          <h2 className={styles.widgetTitle} id={titleId}>
            {title}
          </h2>
        </div>
        <Box
          position="absolute"
          top="50%"
          left="50%"
          sx={{ transform: 'translate(-50%, -50%)' }}
          fontSize="16px"
          color="var(--neutral-color-4)"
        >
          {t('reportApp.noData')}
        </Box>
      </>
    );
  }, [
    commonPlugins,
    data,
    isSettings,
    legendHeight,
    noData,
    options,
    reconstructData,
    redraw,
    t,
    title,
    type,
    widgetType,
    titleId,
  ]);

  return (
    <Box position="relative" height="100%">
      {renderContent}
    </Box>
  );
};
