import { FilterConditionDtoType } from 'api-client';
import { useTranslation } from 'core/context/i18n.context';
import { useEventCategory } from 'generate-report/hooks/use-event-category';
import { useEventType } from 'generate-report/hooks/use-event-type';
import { useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { WizardEvent } from 'shared/components/wizard-event';
import { WizardStepProps } from 'shared/components/wizard/index.type';

export const GenerateCustomReportNewPage: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const eventCategories = useEventCategory();
  const eventTypes = useEventType();

  const backToGenerateReportPage = useCallback(() => {
    return navigate('/report/generate-report');
  }, [navigate]);

  const wizardSteps = useMemo(() => {
    if (!eventCategories.data) return [];
    const props: WizardStepProps[] = [
      {
        stepIndex: 0,
        type: FilterConditionDtoType.ObjectMultiple,
        value: 'event',
        id: 0,
        rules: ['required'],
        title: t('customReport.dialog.events'),
      },
      {
        stepIndex: 1,
        type: FilterConditionDtoType.Period,
        value: 'period',
        id: 0,
        rules: [],
        title: t('customReport.dialog.period'),
      },
      {
        stepIndex: 2,
        type: FilterConditionDtoType.ObjectMultiple,
        value: 'userDoorDevice',
        id: 0,
        rules: [],
        title: t('customReport.dialog.filters'),
      },
      {
        stepIndex: 3,
        type: FilterConditionDtoType.ObjectMultiple,
        value: 'column',
        id: 0,
        rules: [],
        title: t('customReport.dialog.columns'),
      },
    ];

    return props;
  }, [eventCategories.data, t]);

  const eventTypesData = useMemo(() => eventTypes.data, [eventTypes]);

  const handleSuccessWizard = (id: number) => navigate(`../reports/${id}`);

  return (
    <WizardEvent
      open={!!eventCategories.data}
      onClose={backToGenerateReportPage}
      onSuccess={handleSuccessWizard}
      steps={wizardSteps}
      eventTypesData={eventTypesData}
    ></WizardEvent>
  );
};
