import { useTranslation } from 'core/context/i18n.context';
import { Box } from '@mui/system';
import styles from './index.module.css';
import { useEffect, useMemo, useState } from 'react';
import classNames from 'classnames';
import useFitText from 'use-fit-text';
import { WidgetConditionDto, WidgetDtoWidgetType } from 'api-client';
import { useSqueezeText } from 'shared/hooks/use-squeeze-text';
import { useCounterEvents } from 'dashboard/hooks/use-counter-events';
import { useGenerateEventsData } from 'dashboard/hooks/use-generate-events-data';
import { useInterval } from 'shared/hooks/use-interval';
import { useRefreshIntervalValue } from 'dashboard/hooks/use-refresh-interval-value';

export const WidgetText: React.FC<any> = ({
  data,
  index,
  widgetType,
  isSettings,
}) => {
  const { t } = useTranslation();
  const { ref, fontSize } = useFitText({
    minFontSize: 6.25,
    maxFontSize: 450,
  });
  const counterEvents = useCounterEvents();
  const generateEvents = useGenerateEventsData();
  const isRefresh = localStorage.getItem('refresh');
  const [eventsData, setEventsData] = useState<any[]>([]);
  const refreshIntervalValue = useRefreshIntervalValue();

  const getEventsData = () => {
    const counterData = counterEvents.find((d) => d.id === data.widgetValue);
    const counterConditions: WidgetConditionDto[] = counterData?.data!;
    generateEvents.mutate(counterConditions || ([] as WidgetConditionDto[]), {
      onSuccess: (data: any) => {
        setEventsData(data);
      },
    });
  };

  const reconstructData = useMemo(
    () => ({
      ...data,
      datasets: {
        data: eventsData,
      },
    }),
    [data, eventsData]
  );

  const displayValue = useMemo(() => {
    if (widgetType === WidgetDtoWidgetType.Text) {
      const parseValue = JSON.parse(data.widgetValue);
      return parseValue.value;
    }

    return !!reconstructData.datasets?.data
      ? reconstructData.datasets?.data
      : 0;
  }, [reconstructData.datasets?.data, data.widgetValue, widgetType]);

  const textWidgetValue = useMemo(() => {
    const matched = /\n/.exec(displayValue);
    const isUsePreLine = !!matched?.length;
    if (isUsePreLine) {
      return displayValue?.split('\n');
    }

    return displayValue;
  }, [displayValue]);

  const width = document.getElementById(`wrapper-${index}`)?.offsetWidth!;
  const height = document.getElementById(`wrapper-${index}`)?.offsetHeight!;
  const titleId = `title-${index}`;
  const titleHeight = document.getElementById(titleId)?.offsetHeight!;

  const widgetName =
    width === 60 && data.widgetName.length > 7
      ? data.widgetName.slice(0, 7)
      : data.widgetName;

  const getHeight: number = useMemo(() => {
    if (widgetType === WidgetDtoWidgetType.Counter && !!titleHeight) {
      return height - (20 + titleHeight);
    }

    return 0;
  }, [height, titleHeight, widgetType]);

  const squeezeText = useSqueezeText(titleId);

  useInterval(() => {
    if (widgetType === WidgetDtoWidgetType.Counter && !isSettings) {
      getEventsData();
    }
  }, refreshIntervalValue * 1000);

  useEffect(() => {
    if ((!eventsData.length || isRefresh === 'true') && !isSettings) {
      getEventsData();
    }
    squeezeText();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [squeezeText, isRefresh, isSettings]);

  const renderWidgetContent = () => {
    if (widgetType === WidgetDtoWidgetType.Counter && !data.datasets) {
      return (
        <Box className={styles.noDataWrapper}>{t('reportApp.noData')}</Box>
      );
    }

    if (widgetType === WidgetDtoWidgetType.Text) {
      const matched = /\n/.exec(displayValue);
      const isUsePreLine = !!matched?.length;

      if (isUsePreLine) {
        return (
          <div className={styles.preLineWrapper}>
            {textWidgetValue.map((text: string, i: number) => (
              <div
                key={i}
                ref={ref}
                className={styles.preLineBox}
                style={{ fontSize, justifyContent: 'unset' }}
              >
                {text}
              </div>
            ))}
          </div>
        );
      }

      return (
        <div
          ref={ref}
          className={classNames(styles.textBox)}
          style={{ fontSize }}
        >
          {displayValue}
        </div>
      );
    }

    return (
      <div
        ref={ref}
        className={styles.textBox}
        style={{ fontSize, height: getHeight }}
      >
        {displayValue}
      </div>
    );
  };

  return (
    <Box position="relative" height="100%" id={`wrapper-${index}`}>
      {widgetType !== 'text' && (
        <div className={styles.widgetTitleWrapper}>
          <h2 className={styles.widgetTitle} id={`title-${index}`}>
            {widgetName}
          </h2>
        </div>
      )}
      {!isSettings && renderWidgetContent()}
      {widgetType === WidgetDtoWidgetType.Counter && isSettings && (
        <Box
          position="absolute"
          top="50%"
          left="50%"
          sx={{ transform: 'translate(-50%, -50%)' }}
          fontSize="16px"
          color="var(--neutral-color-4)"
        >
          {t('reportApp.noData')}
        </Box>
      )}
    </Box>
  );
};
