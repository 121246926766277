import { DoorStatusDto } from 'api-client';
import { ErrorResponseDto, WidgetsApi } from 'api-client';
import axios from 'axios';
import { useMutation } from 'react-query';
import { apiConfig } from 'shared/utils/api-config';

export function useDoorStatus() {
  const api = new WidgetsApi(apiConfig());

  return useMutation<DoorStatusDto[], ErrorResponseDto>(() =>
    api
      .doorStatus()
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        if (axios.isAxiosError(error)) {
          throw error.response!.data;
        }
        throw error;
      })
  );
}
