import { Box } from '@mui/system';
import { useTranslation } from 'core/context/i18n.context';
import { memo, useMemo, useState, useRef } from 'react';
import { useFormContext } from 'react-hook-form';
import { Card } from 'shared/components/card';
import { CardTitle } from 'shared/components/card-title';
import { InputError } from 'shared/components/input-error';
import { InputLabel } from 'shared/components/input-label';
import { InputSuffix } from 'shared/components/input-suffix';
import { InputWrapper } from 'shared/components/input-wrapper';
import { TextField } from 'shared/components/inputs/text-field';

/**
 * Unit in seconds.
 */
const REFRESH_INTERVAL_MIN = 10;
/**
 * Unit in seconds.
 */
const REFRESH_INTERVAL_MAX = 600;
const REFRESH_INTERVAL_FIELD = 'general.autoRefreshInterval';

const GeneralSettingStateless = memo<ReturnType<typeof useFormContext>>(
  ({ register, setValue, getValues, watch, formState: { errors } }) => {
    const ref = useRef<any>(null);
    const { t } = useTranslation();
    const [showMessage, setShowMessage] = useState<boolean>(false);
    const [tooltipMessage, setTooltipMessage] = useState<string>('');

    const autoRefreshDefaultValue = useMemo(
      () => getValues(REFRESH_INTERVAL_FIELD) || REFRESH_INTERVAL_MIN,
      [getValues]
    );

    // const normalizeAutoRefreshValue: React.FocusEventHandler<HTMLInputElement> =
    //   (e) => {
    //     const value = parseInt(e.target.value);
    //     if (!value || value < REFRESH_INTERVAL_MIN) {
    //       setValue(REFRESH_INTERVAL_FIELD, REFRESH_INTERVAL_MIN);
    //     }
    //     if (value > REFRESH_INTERVAL_MAX) {
    //       setValue(REFRESH_INTERVAL_FIELD, REFRESH_INTERVAL_MAX);
    //     }
    //   };

    const refreshIntervalValidation: React.FocusEventHandler<HTMLInputElement> =
      (e) => {
        const value = parseInt(e.target.value);

        if (!value) {
          setShowMessage(true);
          setTooltipMessage(
            t('reportApp.generalFieldRequired', [
              t('reportApp.autoRefreshInterval'),
            ])
          );
        } else if (
          !!value &&
          (value < REFRESH_INTERVAL_MIN || value > REFRESH_INTERVAL_MAX)
        ) {
          setShowMessage(true);
          setTooltipMessage(t('reportApp.autoRefreshInterval.outOfRange'));
        } else {
          setShowMessage(false);
          setTooltipMessage('');
        }
      };

    return (
      <Card>
        <CardTitle>{t('reportApp.general')}</CardTitle>
        <Box p="24px" display="flex" flexDirection="column">
          <InputWrapper>
            <InputLabel sx={{ display: 'flex' }} showBullet={true}>
              {t('reportApp.autoRefreshInterval')}
            </InputLabel>
            <Box width="150px" position="relative">
              <Box
                ref={ref}
                sx={{
                  padding: '7px 13px 7px 18px',
                  color: '#9d870e',
                  border: '1px solid #ede381',
                  background: '#f7f2c0',
                  whiteSpace: 'nowrap',
                  top: '-36px',
                  right: tooltipMessage.length > 35 ? '-65%' : '0',
                  position: 'absolute',
                  visibility: !showMessage ? 'hidden' : 'visible',
                }}
              >
                {tooltipMessage}
              </Box>
              <TextField
                name={REFRESH_INTERVAL_FIELD}
                inputProps={{
                  type: 'number',
                  autoComplete: 'off',
                  defaultValue: autoRefreshDefaultValue,
                  ...register(REFRESH_INTERVAL_FIELD, {
                    required: {
                      value: true,
                      message: t('reportApp.fieldRequired'),
                    },
                    min: REFRESH_INTERVAL_MIN,
                    max: REFRESH_INTERVAL_MAX,
                  }),
                  onWheel: (e) => e.currentTarget.blur(),
                  onBlur: refreshIntervalValidation,
                  onFocus: refreshIntervalValidation,
                }}
              />
            </Box>
            <InputSuffix>{t('reportApp.sec')}</InputSuffix>
          </InputWrapper>
          <InputError>
            {errors.general?.autoRefreshInterval?.message}
          </InputError>
        </Box>
      </Card>
    );
  },
  (prevProps, nextProps) =>
    prevProps.formState.isDirty === nextProps.formState.isDirty
);

export const GeneralSetting = () => {
  const form = useFormContext();

  return <GeneralSettingStateless {...form} />;
};
