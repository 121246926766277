import { WidgetsApi } from 'api-client';
import axios from 'axios';
import { useMutation } from 'react-query';
import { apiConfig } from 'shared/utils/api-config';

export function useAutoRefreshWidgets() {
  const api = new WidgetsApi(apiConfig());

  return useMutation(() =>
    api
      .refreshWidgets({ async: true })
      .then((res) => res.data)
      .catch((error) => {
        if (axios.isAxiosError(error)) {
          throw error.response!.data;
        }
        throw error;
      })
  );
}
