import { FilterConditionsApi } from 'api-client';
import { useQuery } from 'react-query';
import { apiConfig } from 'shared/utils/api-config';

export function useGetDevices() {
  const api = new FilterConditionsApi(apiConfig());

  const fetchDevices = async () => {
    const { data } = await api.getDevicesAndGroups();
    return data;
  };

  return useQuery(['widgetValue'], fetchDevices);
}
