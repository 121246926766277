import { Box } from '@mui/system';
import classNames from 'classnames';
import iconDeleted from 'core/assets/delete-icon.png';
import clearBtn from 'core/assets/func_clear.png';
import pauseBtn from 'core/assets/funcdark_pause.png';
import playBtn from 'core/assets/funcdark_play.png';
import { useTranslation } from 'core/context/i18n.context';
import { useCheckpointEvents } from 'dashboard/hooks/use-checkpoint-events';
import { useGetDevices } from 'dashboard/hooks/use-get-devices';
import { useEffect, useMemo, useState } from 'react';
import { useSqueezeText } from 'shared/hooks/use-squeeze-text';
import { ButtonSecondary } from '../buttons/button-secondary';
import { WidgetDisplayImage } from '../widget-display-image';
import styles from './index.module.css';
import { useNormalizedDatetime } from 'shared/hooks/use-normalized-datetime';

export const WidgetCheckpoint: React.FC<any> = ({
  data,
  index,
  handleDeletedDevice,
  isSettings,
}) => {
  const { t } = useTranslation();
  const fetchDevices = useGetDevices();
  const normalizedDatetime = useNormalizedDatetime();
  const width = document.getElementById(`checkpoint-${index}`)?.offsetWidth!;
  const height = document.getElementById(`checkpoint-${index}`)?.offsetHeight!;

  const {
    startCheckpointEvents,
    wsMessage,
    onClickPauseButton,
    onClickPlayButton,
    handleClearCheckpoint,
    isPause,
  } = useCheckpointEvents(data.widgetValue);
  const [replicatedMessage, setReplicatedMessage] = useState<any>([]);

  const normalizedData: any = useMemo(() => {
    const wsMessageLength = wsMessage.length;
    if (wsMessage.length > 5 && !isPause) {
      const lengthDifference = wsMessage.length - 5;
      const result = wsMessage.slice(0, wsMessageLength - lengthDifference);

      return result;
    }

    if (isPause) {
      const result = replicatedMessage.slice(0, 5);
      return result;
    }

    return wsMessage;
  }, [wsMessage, isPause, replicatedMessage]);

  const checkDeviceIsExisted = useMemo(() => {
    if (!fetchDevices.isLoading && !!fetchDevices.isSuccess) {
      const devices = fetchDevices.data;
      const deviceIsExisted = devices.some(
        (device) => device.lastChild && device.originalId === data.widgetValue
      );

      return deviceIsExisted;
    }

    return true;
  }, [data.widgetValue, fetchDevices]);

  const getCheckpointHeight = () => {
    const calcCheckpointHeight = height - 50;

    return calcCheckpointHeight;
  };

  const getCheckpointWidth = () => {
    const calcCheckpointWidth = width - 140;

    return calcCheckpointWidth;
  };

  const renderPlayPauseBtn = useMemo(() => {
    if (!isPause) {
      return {
        title: t('reportApp.pause'),
        imgSrc: pauseBtn,
        onClick: onClickPauseButton,
      };
    }

    return {
      title: t('reportApp.play'),
      imgSrc: playBtn,
      onClick: onClickPlayButton,
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [t, isPause]);

  const renderCheckpoint = () => {
    if (isSettings) {
      return (
        <div className={styles.checkpointNoData}>{t('reportApp.noData')}</div>
      );
    }

    if (!fetchDevices.isLoading) {
      if (!checkDeviceIsExisted) {
        return (
          <div
            className={styles.deletedInfoContentWrapper}
            style={{
              height: `${height ? getCheckpointHeight() : '100%'}`,
              width: `${width ? getCheckpointWidth() : 'auto'}`,
            }}
          >
            <div className={styles.deletedInfoWrapper}>
              <img
                src={iconDeleted}
                alt="icon-deleted"
                className={styles.iconDeleted}
              />
              <span className={styles.deletedInfo}>
                <b>{t('reportApp.checkpoint.deletedInfoMessage')}​</b>
              </span>
            </div>
          </div>
        );
      }

      if (!normalizedData.length) {
        return (
          <div className={styles.checkpointNoData}>{t('reportApp.noData')}</div>
        );
      }

      return (
        <div
          className={styles.checkpointItemsWrapper}
          style={{
            height: `${height ? getCheckpointHeight() : '100%'}`,
            width: `${width ? getCheckpointWidth() : 'auto'}`,
          }}
        >
          {!!normalizedData.length &&
            normalizedData.map((response: any, idx: number) => {
              return (
                <div
                  className={styles.checkpointMessageWrapper}
                  style={{
                    width: getCheckpointWidth() / 5,
                  }}
                  key={`CheckpointItem-${response.index}-${idx}`}
                >
                  <div className={styles.checkpointImageWrapper}>
                    <WidgetDisplayImage
                      widgetData={response.image}
                    ></WidgetDisplayImage>
                  </div>
                  <div className={styles.checkpointTextWrapper}>
                    <span>
                      <b>{t('reportApp.user')} :</b> {response.user}
                    </span>
                    <br />
                    <span>
                      <b>{t('reportApp.date')} :</b>{' '}
                      {normalizedDatetime(response.datetime)}
                    </span>
                    <br />
                    <span>
                      <b>{t('reportApp.device')} :</b> {response.device}
                    </span>
                  </div>
                </div>
              );
            })}
        </div>
      );
    }
  };

  const titleId = `title-wrapper-${index}`;
  const squeezeText = useSqueezeText(titleId);

  useEffect(() => {
    if (checkDeviceIsExisted && !isSettings) {
      startCheckpointEvents();
    }
  }, [checkDeviceIsExisted, isSettings, startCheckpointEvents]);

  useEffect(() => {
    if (!checkDeviceIsExisted) {
      if (!!handleDeletedDevice) {
        handleDeletedDevice(data.id);
      }
    }

    if (!isPause || (!!isPause && !wsMessage.length)) {
      setReplicatedMessage([...wsMessage]);
    }

    squeezeText();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [squeezeText, checkDeviceIsExisted, wsMessage, isPause]);

  return (
    <Box position="relative" id={`checkpoint-${index}`}>
      <div className={styles.widgetTitleWrapper}>
        <h2 className={styles.widgetTitle} id={titleId}>
          {data.widgetName}
        </h2>
      </div>
      <div className={styles.actionButtonsWrapper}>
        <ButtonSecondary
          title={renderPlayPauseBtn.title}
          imgSrc={renderPlayPauseBtn.imgSrc}
          size="small"
          className={classNames(styles.actionButton, styles.playPauseBtn)}
          onClick={renderPlayPauseBtn.onClick}
        />
        <ButtonSecondary
          title={t('reportApp.clear')}
          imgSrc={clearBtn}
          size="small"
          className={styles.actionButton}
          onClick={handleClearCheckpoint}
        />
      </div>
      <br />
      {renderCheckpoint()}
    </Box>
  );
};
