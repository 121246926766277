import { AddScheduleDto, SchedulesApi } from 'api-client';
import axios from 'axios';
import { useMutation } from 'react-query';
import { apiConfig } from 'shared/utils/api-config';

export function useAddSchedule() {
  const api = new SchedulesApi(apiConfig());

  return useMutation((dto: AddScheduleDto) =>
    api
      .addSchedule(dto)
      .then((res) => res.data)
      .catch((error) => {
        if (axios.isAxiosError(error)) {
          throw error.response!.data;
        }
        throw error;
      })
  );
}
