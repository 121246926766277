/* tslint:disable */
/* eslint-disable */
/**
 * BioStar 2 Report API
 * API Documentation for BioStar 2 Report
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { BiostarDSTDto } from '../models';
// @ts-ignore
import { BiostarPreferenceDto } from '../models';
// @ts-ignore
import { DatetimePreferenceDto } from '../models';
// @ts-ignore
import { ErrorResponseDto } from '../models';
/**
 * PreferencesApi - axios parameter creator
 * @export
 */
export const PreferencesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Every user has their own preferences
         * @summary Get datetime preference such as format and timezone
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDatetimePreference: async (userId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('getDatetimePreference', 'userId', userId)
            const localVarPath = `/api/report/preferences/datetime/{userId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get user dst list based on the logged-in user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserDST: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/report/preferences/dst`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get user preference based on the logged-in user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserPreference: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/report/preferences/preference`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PreferencesApi - functional programming interface
 * @export
 */
export const PreferencesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PreferencesApiAxiosParamCreator(configuration)
    return {
        /**
         * Every user has their own preferences
         * @summary Get datetime preference such as format and timezone
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDatetimePreference(userId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DatetimePreferenceDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDatetimePreference(userId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get user dst list based on the logged-in user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserDST(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<BiostarDSTDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUserDST(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get user preference based on the logged-in user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserPreference(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BiostarPreferenceDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUserPreference(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PreferencesApi - factory interface
 * @export
 */
export const PreferencesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PreferencesApiFp(configuration)
    return {
        /**
         * Every user has their own preferences
         * @summary Get datetime preference such as format and timezone
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDatetimePreference(userId: string, options?: any): AxiosPromise<DatetimePreferenceDto> {
            return localVarFp.getDatetimePreference(userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get user dst list based on the logged-in user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserDST(options?: any): AxiosPromise<Array<BiostarDSTDto>> {
            return localVarFp.getUserDST(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get user preference based on the logged-in user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserPreference(options?: any): AxiosPromise<BiostarPreferenceDto> {
            return localVarFp.getUserPreference(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PreferencesApi - object-oriented interface
 * @export
 * @class PreferencesApi
 * @extends {BaseAPI}
 */
export class PreferencesApi extends BaseAPI {
    /**
     * Every user has their own preferences
     * @summary Get datetime preference such as format and timezone
     * @param {string} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PreferencesApi
     */
    public getDatetimePreference(userId: string, options?: any) {
        return PreferencesApiFp(this.configuration).getDatetimePreference(userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get user dst list based on the logged-in user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PreferencesApi
     */
    public getUserDST(options?: any) {
        return PreferencesApiFp(this.configuration).getUserDST(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get user preference based on the logged-in user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PreferencesApi
     */
    public getUserPreference(options?: any) {
        return PreferencesApiFp(this.configuration).getUserPreference(options).then((request) => request(this.axios, this.basePath));
    }
}
