/* tslint:disable */
/* eslint-disable */
/**
 * BioStar 2 Report API
 * API Documentation for BioStar 2 Report
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { ErrorResponseDto } from '../models';
// @ts-ignore
import { ReportSettingDto } from '../models';
// @ts-ignore
import { SaveReportSettingDto } from '../models';
/**
 * SettingsApi - axios parameter creator
 * @export
 */
export const SettingsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get setting for the report section
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReportSetting: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/report/settings/report`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Save setting for the report section
         * @param {SaveReportSettingDto} saveReportSettingDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveReportSetting: async (saveReportSettingDto: SaveReportSettingDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'saveReportSettingDto' is not null or undefined
            assertParamExists('saveReportSetting', 'saveReportSettingDto', saveReportSettingDto)
            const localVarPath = `/api/report/settings/report`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(saveReportSettingDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SettingsApi - functional programming interface
 * @export
 */
export const SettingsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SettingsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get setting for the report section
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getReportSetting(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReportSettingDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getReportSetting(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Save setting for the report section
         * @param {SaveReportSettingDto} saveReportSettingDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async saveReportSetting(saveReportSettingDto: SaveReportSettingDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.saveReportSetting(saveReportSettingDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SettingsApi - factory interface
 * @export
 */
export const SettingsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SettingsApiFp(configuration)
    return {
        /**
         * 
         * @summary Get setting for the report section
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReportSetting(options?: any): AxiosPromise<ReportSettingDto> {
            return localVarFp.getReportSetting(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Save setting for the report section
         * @param {SaveReportSettingDto} saveReportSettingDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveReportSetting(saveReportSettingDto: SaveReportSettingDto, options?: any): AxiosPromise<void> {
            return localVarFp.saveReportSetting(saveReportSettingDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SettingsApi - object-oriented interface
 * @export
 * @class SettingsApi
 * @extends {BaseAPI}
 */
export class SettingsApi extends BaseAPI {
    /**
     * 
     * @summary Get setting for the report section
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SettingsApi
     */
    public getReportSetting(options?: any) {
        return SettingsApiFp(this.configuration).getReportSetting(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Save setting for the report section
     * @param {SaveReportSettingDto} saveReportSettingDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SettingsApi
     */
    public saveReportSetting(saveReportSettingDto: SaveReportSettingDto, options?: any) {
        return SettingsApiFp(this.configuration).saveReportSetting(saveReportSettingDto, options).then((request) => request(this.axios, this.basePath));
    }
}
