import { DashboardApi } from 'api-client';
import { useQuery } from 'react-query';
import { apiConfig } from 'shared/utils/api-config';

export function useGetDashboardSetting() {
  const api = new DashboardApi(apiConfig());

  return useQuery(
    ['dashboardSetting'],
    async () => {
      const { data } = await api.getCurrentUserSetting();
      return data;
    },
    { enabled: false }
  );
}
