import { TreeItemProps } from '@mui/lab';
import React from 'react';
import {
  DragSourceMonitor,
  DropTargetMonitor,
  useDrag,
  useDrop,
  XYCoord,
} from 'react-dnd';
import CustomDraggableTreeItem from '../custom-draggable-tree-item';

export interface DragItem<T = any> {
  index: number;
  dataSource: T;
}

interface DraggableTreeItemProps<T = any> extends TreeItemProps {
  index: number;
  dataSource: T;
  moveItem: (from: number, to: number) => void;
  onDropItem?: (item: DragItem<T>) => void;
  onRenameFinish?: (value: string) => Promise<void>;
}

export const DraggableTreeItem: React.FC<DraggableTreeItemProps> = ({
  index,
  dataSource,
  moveItem,
  onDropItem,
  ...props
}) => {
  const ref = React.useRef<any>(null);

  const [, drop] = useDrop({
    accept: 'item',
    hover(item: DragItem, monitor: DropTargetMonitor) {
      if (!ref.current) return;

      const dragIndex = item.index;
      const hoverIndex = index;

      // Don't replace item with themselves
      if (dragIndex === hoverIndex) return;

      // Determine rectangle on screen
      const hoverBoundingRect = ref.current?.getBoundingClientRect();

      // Get vertical middle
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

      // Determine mouse position
      const clientOffset = monitor.getClientOffset();

      // Get pixels to the top
      const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top;

      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%

      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }

      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }

      moveItem(dragIndex, hoverIndex);

      item.index = hoverIndex;
    },
    drop: (item: DragItem) => {
      if (onDropItem) onDropItem(item);
    },
  });

  const [{ isDragging }, drag] = useDrag({
    type: 'item',
    item: {
      index: index,
      dataSource: dataSource,
    },
    collect: (monitor: DragSourceMonitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  drag(drop(ref));

  return (
    <CustomDraggableTreeItem
      ref={ref}
      style={{
        ...props.style,
        opacity: isDragging ? 0 : 1,
      }}
      {...props}
    />
  );
};
