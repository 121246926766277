import {
  ReportsApi,
  ErrorResponseDto,
  GenerateReportDtoType,
  CustomReportDataDto,
} from 'api-client';
import axios from 'axios';
import { useMutation } from 'react-query';
import { apiConfig } from 'shared/utils/api-config';

export function useGenerateCustomReport() {
  const api = new ReportsApi(apiConfig());

  return useMutation<number, ErrorResponseDto, CustomReportDataDto>(
    (data: CustomReportDataDto) =>
      api
        .generateReport({
          type: GenerateReportDtoType.CustomReport,
          data: data,
        })
        .then((res) => res.data)
        .catch((error) => {
          if (axios.isAxiosError(error)) {
            throw error.response!.data;
          }
          throw error;
        })
  );
}
