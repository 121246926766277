/* tslint:disable */
/* eslint-disable */
/**
 * BioStar 2 Report API
 * API Documentation for BioStar 2 Report
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { ErrorResponseDto } from '../models';
// @ts-ignore
import { PrintConfigDto } from '../models';
// @ts-ignore
import { PrintReportDto } from '../models';
// @ts-ignore
import { PrintReportResponseDto } from '../models';
/**
 * PrintApi - axios parameter creator
 * @export
 */
export const PrintApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get last configuration of print report options
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLastConfiguration: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/report/print/last-configuration`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get list of supported page sizes for print report
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSupportedPageSizes: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/report/print/page-sizes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Print a report
         * @param {PrintReportDto} printReportDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        printReport: async (printReportDto: PrintReportDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'printReportDto' is not null or undefined
            assertParamExists('printReport', 'printReportDto', printReportDto)
            const localVarPath = `/api/report/print`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(printReportDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PrintApi - functional programming interface
 * @export
 */
export const PrintApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PrintApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get last configuration of print report options
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLastConfiguration(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PrintConfigDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getLastConfiguration(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get list of supported page sizes for print report
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSupportedPageSizes(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSupportedPageSizes(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Print a report
         * @param {PrintReportDto} printReportDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async printReport(printReportDto: PrintReportDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PrintReportResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.printReport(printReportDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PrintApi - factory interface
 * @export
 */
export const PrintApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PrintApiFp(configuration)
    return {
        /**
         * 
         * @summary Get last configuration of print report options
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLastConfiguration(options?: any): AxiosPromise<Array<PrintConfigDto>> {
            return localVarFp.getLastConfiguration(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get list of supported page sizes for print report
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSupportedPageSizes(options?: any): AxiosPromise<Array<string>> {
            return localVarFp.getSupportedPageSizes(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Print a report
         * @param {PrintReportDto} printReportDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        printReport(printReportDto: PrintReportDto, options?: any): AxiosPromise<PrintReportResponseDto> {
            return localVarFp.printReport(printReportDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PrintApi - object-oriented interface
 * @export
 * @class PrintApi
 * @extends {BaseAPI}
 */
export class PrintApi extends BaseAPI {
    /**
     * 
     * @summary Get last configuration of print report options
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PrintApi
     */
    public getLastConfiguration(options?: any) {
        return PrintApiFp(this.configuration).getLastConfiguration(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get list of supported page sizes for print report
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PrintApi
     */
    public getSupportedPageSizes(options?: any) {
        return PrintApiFp(this.configuration).getSupportedPageSizes(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Print a report
     * @param {PrintReportDto} printReportDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PrintApi
     */
    public printReport(printReportDto: PrintReportDto, options?: any) {
        return PrintApiFp(this.configuration).printReport(printReportDto, options).then((request) => request(this.axios, this.basePath));
    }
}
