/* tslint:disable */
/* eslint-disable */
/**
 * BioStar 2 Report API
 * API Documentation for BioStar 2 Report
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @interface ColumnDto
 */
export interface ColumnDto {
    /**
     * 
     * @type {number}
     * @memberof ColumnDto
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof ColumnDto
     */
    keyName: ColumnDtoKeyName;
    /**
     * 
     * @type {string}
     * @memberof ColumnDto
     */
    name: string;
    /**
     * 
     * @type {boolean}
     * @memberof ColumnDto
     */
    defaultShowColumn: boolean;
    /**
     * 
     * @type {number}
     * @memberof ColumnDto
     */
    defaultItemOrder: number;
}

/**
    * @export
    * @enum {string}
    */
export enum ColumnDtoKeyName {
    Number = 'number',
    UserId = 'userId',
    UserName = 'userName',
    Email = 'email',
    UserGroup = 'userGroup',
    AccessGroup = 'accessGroup',
    Status = 'status',
    Period = 'period',
    OperatorLevel = 'operatorLevel',
    Department = 'department',
    Title = 'title',
    Telephone = 'telephone',
    NumberOfCard = 'numberOfCard',
    NumberOfFace = 'numberOfFace',
    NumberOfFingerprint = 'numberOfFingerprint',
    Photo = 'photo',
    LoginId = 'loginId',
    UserIp = 'userIp',
    Pin = 'pin',
    Card = 'card',
    Fingerprint = 'fingerprint',
    IrFace = 'irFace',
    VisualFace = 'visualFace',
    Face = 'face',
    Mobile = 'mobile',
    Qr = 'qr',
    Exclude = 'exclude',
    AuthMode1 = 'authMode1',
    AuthMode2 = 'authMode2',
    AuthMode3 = 'authMode3',
    NumberOfIrFace = 'numberOfIrFace',
    NumberOfVisualFace = 'numberOfVisualFace',
    NumberOfMobile = 'numberOfMobile',
    NumberOfQr = 'numberOfQr',
    CardType = 'cardType',
    CardDataFormat = 'cardDataFormat',
    CardId = 'cardId',
    BlacklistedDate = 'blacklistedDate',
    PeriodValidity = 'periodValidity',
    LastEntrance = 'lastEntrance',
    Datetime = 'datetime',
    DoorName = 'doorName',
    DeviceId = 'deviceId',
    DeviceName = 'deviceName',
    Event = 'event',
    InputPort = 'inputPort',
    InputName = 'inputName',
    RelayPort = 'relayPort',
    TimeAndAttendanceKey = 'timeAndAttendanceKey',
    ZoneName = 'zoneName',
    Temperature = 'temperature'
}



