import { Box } from '@mui/system';
import { AddScheduleDtoFileFormat, AddScheduleDtoOutputType } from 'api-client';
import { useTranslation } from 'core/context/i18n.context';
import { useEffect, useMemo } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { Card } from 'shared/components/card';
import { CardTitle } from 'shared/components/card-title';
import { Checkbox } from 'shared/components/checkbox';
import { InputError } from 'shared/components/input-error';
import { InputLabel } from 'shared/components/input-label';
import { InputRow } from 'shared/components/input-row';
import { InputWrapper } from 'shared/components/input-wrapper';
import { SelectField } from 'shared/components/inputs/select-field';
import { TextField } from 'shared/components/inputs/text-field';
import styles from './index.module.css';

export const FormatSection: React.FC = () => {
  const { t } = useTranslation();
  const {
    register,
    control,
    watch,
    setValue,
    formState: { errors },
    getValues,
  } = useFormContext();

  const outputTypes = useMemo(() => {
    return Object.values(AddScheduleDtoOutputType).map((val) => ({
      id: val,
      name: t('reportApp.outputType.' + val),
    }));
  }, [t]);

  const fileFormats = useMemo(() => {
    return Object.values(AddScheduleDtoFileFormat).map((val) => ({
      id: val,
      name: t('reportApp.fileFormat.' + val),
    }));
  }, [t]);

  const onChangeSelectField = (value: any, fieldName?: string) => {
    setValue(fieldName!, value.id);
  };

  const showHeader = watch('showHeader');

  // Uncheck "show header on every page" if "show header" is unchecked.
  useEffect(() => {
    if (!showHeader) {
      setValue('showHeaderEveryPage', false);
    }
  }, [showHeader, setValue]);

  return (
    <Card>
      <CardTitle>{t('reportApp.reportFormat')}</CardTitle>

      <Box p="24px" display="flex" flexDirection="column" paddingLeft="120px">
        <InputWrapper flexDirection="column" alignItems="flex-start">
          <InputRow>
            <InputLabel display="flex" width="150px" showBullet>
              {t('reportApp.outputType')}
            </InputLabel>
            <Box maxWidth="300px" width="100%">
              <SelectField
                name="outputType"
                dataSource={outputTypes}
                selectProps={{
                  ...register('outputType', {
                    required: {
                      value: true,
                      message: t('reportApp.fieldRequired'),
                    },
                  }),
                  defaultValue: getValues('outputType'),
                }}
                onChange={onChangeSelectField}
                styleType="emboss"
              />
            </Box>
          </InputRow>
          <InputError pl="166px">{errors.outputType?.message}</InputError>
        </InputWrapper>
        <Box mb="16px" />

        <InputWrapper flexDirection="column" alignItems="flex-start">
          <InputRow>
            <InputLabel display="flex" width="150px" showBullet>
              {t('reportApp.reportTitle')}
            </InputLabel>
            <Box maxWidth="450px" width="100%">
              <TextField
                name="reportTitle"
                inputProps={{
                  ...register('reportTitle', {
                    required: {
                      value: true,
                      message: t('reportApp.fieldRequired'),
                    },
                  }),
                  autoComplete: 'off',
                  readOnly: true,
                  disabled: true,
                }}
              />
            </Box>
          </InputRow>
          <InputError pl="166px">{errors.reportTitle?.message}</InputError>
        </InputWrapper>
        <Box mb="16px" />

        <InputWrapper>
          <InputLabel width="150px"></InputLabel>
          <Box maxWidth="450px" width="100%">
            <Controller
              name="showTitleEveryPage"
              control={control}
              render={({ field: { ref, ...props } }) => (
                <Checkbox
                  {...props}
                  checked={props.value}
                  className={styles.checkbox}
                >
                  {t('reportApp.showTitle')}
                </Checkbox>
              )}
            />
          </Box>
        </InputWrapper>
        <Box mb="16px" />

        <InputWrapper>
          <InputLabel display="flex" width="150px" showBullet>
            {t('reportApp.header')}
          </InputLabel>
          <Box maxWidth="200px" width="100%">
            <Controller
              name="showHeader"
              control={control}
              render={({ field: { ref, ...props } }) => (
                <Checkbox
                  {...props}
                  checked={props.value}
                  className={styles.checkbox}
                >
                  {t('reportApp.showHeader')}
                </Checkbox>
              )}
            />
          </Box>
          <Box maxWidth="200px" width="100%">
            <Controller
              name="showHeaderEveryPage"
              control={control}
              render={({ field: { ref, ...props } }) => (
                <Checkbox
                  {...props}
                  checked={props.value}
                  disabled={!showHeader}
                  className={styles.checkbox}
                >
                  {t('reportApp.onEveryPage')}
                </Checkbox>
              )}
            />
          </Box>
        </InputWrapper>
        <Box mb="16px" />

        <InputWrapper>
          <InputLabel display="flex" width="150px" showBullet>
            {t('reportApp.footer')}
          </InputLabel>
          <Box maxWidth="200px" width="100%">
            <Controller
              name="pageNumber"
              control={control}
              render={({ field: { ref, ...props } }) => (
                <Checkbox
                  {...props}
                  checked={props.value}
                  className={styles.checkbox}
                >
                  {t('reportApp.pageNumber')}
                </Checkbox>
              )}
            />
          </Box>
        </InputWrapper>
        <Box mb="16px" />

        <InputWrapper flexDirection="column" alignItems="flex-start">
          <InputRow>
            <InputLabel display="flex" width="150px" showBullet>
              {t('reportApp.fileFormat')}
            </InputLabel>
            <Box maxWidth="300px" width="100%">
              <SelectField
                name="fileFormat"
                dataSource={fileFormats}
                selectProps={{
                  ...register('fileFormat', {
                    required: {
                      value: true,
                      message: t('reportApp.fieldRequired'),
                    },
                  }),
                  defaultValue: getValues('fileFormat'),
                }}
                onChange={onChangeSelectField}
                styleType="emboss"
              />
            </Box>
          </InputRow>
          <InputError pl="166px">{errors.fileFormat?.message}</InputError>
        </InputWrapper>
      </Box>
    </Card>
  );
};
