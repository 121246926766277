import { BoxProps } from '@mui/system';
import { Box } from '@mui/system';

export const InputRow: React.FC<BoxProps> = ({ children, ...props }) => {
  return (
    <Box display="flex" width="100%" alignItems="center" {...props}>
      {children}
    </Box>
  );
};
