import { useEffect } from 'react';
import { LayoutContent } from 'shared/components/layouts/layout-content';
import { StandardLayout } from 'shared/components/layouts/standard-layout';
import { GenerateReportSidebar } from 'generate-report/components/generate-report-sidebar';
import { Outlet } from 'react-router-dom';
import { GenerateReportProvider } from 'generate-report/context/rename.context';
import { useTranslation } from 'core/context/i18n.context';

export const GenerateReportLayout: React.FC = () => {
  const { t } = useTranslation();

  useEffect(() => {
    document.title = t('reportApp.pageTitle', [t('reportApp.report')]);
  }, [t]);

  return (
    <StandardLayout>
      <GenerateReportProvider>
        <GenerateReportSidebar />
      </GenerateReportProvider>
      <LayoutContent>
        <Outlet />
      </LayoutContent>
    </StandardLayout>
  );
};
