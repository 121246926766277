import React, { useState } from 'react';
import {
  DatePicker,
  LocalizationProvider,
  StaticDatePicker,
  TimePicker,
} from '@mui/lab';
import { TextField } from '@mui/material';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import styles from './index.module.css';
import { styled } from '@mui/system';
import { makeStyles } from '@mui/styles';
import { useTranslation } from 'core/context/i18n.context';

interface DateTimePickerProps {
  label: string;
  date: Date;
  time: Date;
  minDate?: Date;
  maxDate?: Date;
  maxTime?: Date;
  onChangeDate: (data: any) => void;
  onChangeTime: (data: any) => void;
}

const CustomTextField = styled(TextField)({
  '& .MuiOutlinedInput-root': {
    boxShadow: 'inset 0 1px 3px 0 rgb(0 0 0 / 20%)',
    borderRadius: '4px',
    outline: 'none',
    height: '28px',
    fontSize: '12px',
  },
});

const useHelperTextStyles: any = makeStyles(() => ({
  root: {
    fontSize: '10px',
    margin: '0',
  },
}));

export const DateTimePicker: React.FC<DateTimePickerProps> = ({
  label,
  date,
  time,
  minDate,
  maxDate,
  maxTime,
  onChangeDate,
  onChangeTime,
}) => {
  const { t } = useTranslation();
  const [dateError, setDateError] = useState<boolean>(false);
  const [timeError, setTimeError] = useState<boolean>(false);

  const helperTextStyles = useHelperTextStyles();
  return (
    <div className={styles.staticDatePickerWrapper}>
      <span className={styles.label}>&#8226; {label}</span>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <StaticDatePicker
          displayStaticWrapperAs="desktop"
          openTo="day"
          value={date}
          minDate={minDate}
          maxDate={maxDate}
          onChange={(newValue) => {
            onChangeDate(newValue as Date);
          }}
          onError={(e) =>
            e === 'invalidDate' ? setDateError(true) : setDateError(false)
          }
          renderInput={(params) => <TextField {...params} />}
        />
      </LocalizationProvider>
      <div className={styles.inputWrapper}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
            openTo="day"
            views={['year', 'month', 'day']}
            value={date}
            onChange={(value) => onChangeDate(value)}
            mask="____/__/__"
            inputFormat="yyyy/MM/dd"
            renderInput={(params) => (
              <CustomTextField
                {...params}
                className={styles.dateInput}
                InputProps={{ endAdornment: <></> }}
                helperText={dateError ? t('reportApp.invalidDate') : ''}
                FormHelperTextProps={{
                  classes: {
                    root: helperTextStyles.root,
                  },
                }}
              />
            )}
          />
          <TimePicker
            value={time}
            onChange={(newValue) => {
              onChangeTime(newValue);
            }}
            ampm={false}
            maxTime={maxTime}
            onError={(e) =>
              e === 'invalidDate' ? setTimeError(true) : setTimeError(false)
            }
            renderInput={(params) => (
              <div className={styles.timeInputWrapper}>
                <CustomTextField
                  {...params}
                  className={styles.timeInput}
                  InputProps={{ endAdornment: <></> }}
                  helperText={timeError ? t('reportApp.invalidTime') : ''}
                  FormHelperTextProps={{
                    classes: {
                      root: helperTextStyles.root,
                    },
                  }}
                />
                {params.InputProps?.endAdornment}
              </div>
            )}
          />
        </LocalizationProvider>
      </div>
    </div>
  );
};
