import { WidgetsApi } from 'api-client';
import axios from 'axios';
import { useMutation } from 'react-query';
import { apiConfig } from 'shared/utils/api-config';

export function useGetUsers() {
  const api = new WidgetsApi(apiConfig());

  return useMutation(
    async (payload: { limit?: number; offset: number; search?: string }) => {
      try {
        const result = api.usersWithLimitAndOffset({
          limit: payload.limit,
          offset: payload.offset,
          search: payload.search,
        });
        return result;
      } catch (error) {
        if (axios.isAxiosError(error)) {
          throw error.response!.data;
        }
        throw error;
      }
    }
  );
}
