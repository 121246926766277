import { SorterDto } from 'api-client';
import { useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';

export function useSortBy() {
  const [searchParams, setSearchParams] = useSearchParams();

  const valueString = useMemo(
    () => searchParams.get('sortBy') || undefined,
    [searchParams]
  );

  const values = useMemo(() => {
    const valueString = searchParams.get('sortBy');
    if (!valueString) return undefined;

    const sorters = valueString.split(',');
    if (!sorters?.length) return undefined;

    const results = sorters
      .map((sorter) => {
        const [fieldName, order] = sorter.split(':');
        if (!fieldName || !order) return undefined;
        const data: SorterDto = {
          fieldName,
          order: order as SorterDto['order'],
        };
        return data;
      })
      .filter((v) => !!v) as SorterDto[];

    return results;
  }, [searchParams]);

  const onSortByChange = (value?: { id: string; desc: boolean }[]) => {
    if (value?.length) {
      const combinedValue = value.map((v) => {
        const order = v.desc ? 'desc' : 'asc';
        return `${v.id}:${order}`;
      });
      searchParams.set('sortBy', combinedValue.join(','));
    } else {
      searchParams.delete('sortBy');
    }
    setSearchParams(searchParams);
  };

  return { valueString, values, onSortByChange };
}
