import { AuthGuard } from 'core/guards/auth.guard';
import { GuestGuard } from 'core/guards/guest.guard';
import { RouteObjectExtended } from 'core/types';
import { LoginPage } from './pages/login';
import { LogoutPage } from './pages/logout';

export const authRoutes: RouteObjectExtended[] = [
  {
    path: 'login',
    guards: [GuestGuard],
    element: <LoginPage />,
  },
  {
    path: 'logout',
    guards: [AuthGuard],
    element: <LogoutPage />,
  },
];
