import classNames from 'classnames';
import React from 'react';
import styles from './index.module.css';

const getDeviceImgPosition = (typeId: any) => {
  switch (typeId) {
    case 0:
      return '-79px -285px';
    case 1:
      return '-337px -312px';
    case 2:
      return '-285px -312px';
    case 3:
      return '-389px -312px';
    case 4:
      return '-441px -312px';
    case 5:
      return '-545px -312px';
    case 6:
      return '-275px -308px';
    case 7:
      return '-909px -312px';
    case 8:
      return '-857px -312px';
    case 9:
      return '-1013px -312px';
    case 10:
      return '-181px -344px';
    case 11:
      return '-961px -312px';
    case 12:
      return '-1065px -312px';
    case 13:
      return '-1117px -312px';
    case 14:
      return '-493px -344px';
    case 15:
      return '-337px -344px';
    case 17:
      return '-389px -344px';
    case 18:
      return '-597px -344px';
    case 19:
      return '-701px -344px';
    case 21:
      return '-441px -344px';
    case 22:
      return '-545px -344px';
    case 24:
      return '-649px -344px';
    case 25:
      return '-753px -344px';
    case 26:
      return ''; // no CSS data in Biostar 2
    case 27:
      return ''; // no CSS data in Biostar 2
    case 28:
      return ''; // no CSS data in Biostar 2
    case 29:
      return '-805px -344px';
    case 30:
      return '-857px -344px';
    case 31:
      return '-1117px -344px';
    case 32:
      return '-1117px -344px';
    case 33:
      return '-129px -376px';
    case 34:
      return '-1117px -344px';
    case 35:
      return '-181px -376px';
    case 36:
      return '-233px -376px';
    case 38:
      return '-753px -375px';
    case 254:
      return '-607px -285px';
    default:
      return '0px 0px';
  }
};

export const DeviceIcons: React.FC<any> = ({ typeId }) => {
  const deviceImagePosition = getDeviceImgPosition(typeId);

  return (
    <div
      className={classNames(styles.dataImg)}
      style={{
        backgroundPosition: deviceImagePosition,
        // width: typeId === 10 ? '28px' : '18px',
        height: typeId !== 0 ? '28px' : '25px',
      }}
    />
  );
};
