import { authRoutes } from 'auth/auth.routes';
import { AuthGuard } from 'core/guards/auth.guard';
import { NotFountPage } from 'core/pages/not-found';
import { RouteObjectExtended } from 'core/types';
import { dashboardRoutes } from 'dashboard/dashboard.routes';
import { historyRoutes } from 'history/history.routes';
import { scheduleReportRoutes } from 'schedule/schedule.routes';
import { settingRoutes } from 'settings/setting.routes';
import { generateReportRoutes } from './generate-report/generate-report.routes';

export const appRoutes: RouteObjectExtended[] = [
  {
    path: 'auth',
    children: authRoutes,
  },
  {
    path: '',
    guards: [AuthGuard],
    children: [
      {
        path: 'report',
        children: [
          {
            path: 'generate-report',
            children: generateReportRoutes,
          },
          {
            path: 'schedule',
            children: scheduleReportRoutes,
          },
          {
            path: 'history',
            children: historyRoutes,
          },
          {
            path: 'settings',
            children: settingRoutes,
          },
        ],
      },
      {
        path: 'dashboard',
        children: dashboardRoutes,
      },
    ],
  },
  {
    path: '*',
    element: <NotFountPage />,
  },
];
