import { useAuth } from 'core/context/auth.context';
import { Navigate } from 'react-router-dom';

/**
 * The routes can only be accessed when not logged-in.
 * If they access it, they will redirected to the homepage.
 *
 * Example:
 * The user is already authenticated.
 * They access the login page manually via address bar.
 * Because of they already authenticated,
 * they will redirected to the homepage.
 */
export const GuestGuard: React.FC = ({ children }) => {
  const { accessToken } = useAuth();

  if (accessToken) {
    return <Navigate to="/report"></Navigate>;
  }

  return <>{children}</>;
};
