/* tslint:disable */
/* eslint-disable */
/**
 * BioStar 2 Report API
 * API Documentation for BioStar 2 Report
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { WidgetAreaDto } from './widget-area-dto';
import { WidgetDataItemDto } from './widget-data-item-dto';

/**
 * 
 * @export
 * @interface WidgetDto
 */
export interface WidgetDto {
    /**
     * 
     * @type {number}
     * @memberof WidgetDto
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof WidgetDto
     */
    userId: string;
    /**
     * 
     * @type {string}
     * @memberof WidgetDto
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof WidgetDto
     */
    widgetBase: WidgetDtoWidgetBase;
    /**
     * 
     * @type {string}
     * @memberof WidgetDto
     */
    widgetType: WidgetDtoWidgetType;
    /**
     * 
     * @type {string}
     * @memberof WidgetDto
     */
    chartType?: WidgetDtoChartType;
    /**
     * 
     * @type {string}
     * @memberof WidgetDto
     */
    chartColor?: string;
    /**
     * 
     * @type {string}
     * @memberof WidgetDto
     */
    createdDate: string;
    /**
     * 
     * @type {string}
     * @memberof WidgetDto
     */
    updatedDate: string;
    /**
     * 
     * @type {WidgetAreaDto}
     * @memberof WidgetDto
     */
    widgetArea: WidgetAreaDto;
    /**
     * 
     * @type {Array<WidgetDataItemDto>}
     * @memberof WidgetDto
     */
    dataItems: Array<WidgetDataItemDto>;
    /**
     * 
     * @type {object}
     * @memberof WidgetDto
     */
    widgetValue?: object;
}

/**
    * @export
    * @enum {string}
    */
export enum WidgetDtoWidgetBase {
    Event = 'event',
    NonEvent = 'nonEvent'
}
/**
    * @export
    * @enum {string}
    */
export enum WidgetDtoWidgetType {
    Chart = 'chart',
    Counter = 'counter',
    Text = 'text',
    Webpage = 'webpage',
    SystemUsage = 'systemUsage',
    RealTimeEvents = 'realTimeEvents',
    Checkpoint = 'checkpoint',
    DoorControl = 'doorControl'
}
/**
    * @export
    * @enum {string}
    */
export enum WidgetDtoChartType {
    VerticalBar = 'verticalBar',
    HorizontalBar = 'horizontalBar',
    Line = 'line',
    Pie = 'pie',
    Doughnut = 'doughnut'
}



