import { useMemo, useState } from 'react';

export interface Step {
  stepIndex: number;
}

export function useSteps<S extends Step>(steps: S[] = []) {
  const orderedSteps = useMemo(
    () => steps.sort((a, b) => a.stepIndex - b.stepIndex),
    [steps]
  );

  const stepIndexes = useMemo(
    () => orderedSteps.map((step) => step.stepIndex),
    [orderedSteps]
  );

  const totalSteps = useMemo(() => steps.length, [steps]);

  const [activeStepIndex, setActiveStepIndex] = useState<number>(
    stepIndexes[0] || 0
  );

  const activeStep = useMemo(
    () => orderedSteps.find((step) => step.stepIndex === activeStepIndex),
    [activeStepIndex, orderedSteps]
  );

  /**
   * Index order of the active step in array.
   */
  const currentStepIndexInArray = useMemo(
    () => stepIndexes.indexOf(activeStepIndex),
    [stepIndexes, activeStepIndex]
  );

  const isFirstStep = useMemo(
    () => currentStepIndexInArray === 0,
    [currentStepIndexInArray]
  );

  const isLastStep = useMemo(
    () => currentStepIndexInArray + 1 === totalSteps,
    [currentStepIndexInArray, totalSteps]
  );

  const nextStep = () => {
    if (currentStepIndexInArray < 0) return;
    if (currentStepIndexInArray === totalSteps) return;

    const nextIndexInArray = currentStepIndexInArray + 1;
    const nextStepIndex = stepIndexes[nextIndexInArray];

    setActiveStepIndex(nextStepIndex);
  };

  const prevStep = () => {
    if (currentStepIndexInArray <= 0) return;

    const prevIndexInArray = currentStepIndexInArray - 1;
    const prevStepIndex = stepIndexes[prevIndexInArray];

    setActiveStepIndex(prevStepIndex);
  };

  return {
    orderedSteps,
    stepIndexes,
    totalSteps,
    activeStepIndex,
    setActiveStepIndex,
    activeStep,
    nextStep,
    prevStep,
    isFirstStep,
    isLastStep,
  };
}
