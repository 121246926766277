import { useAuth } from 'core/context/auth.context';
import { useEffect } from 'react';

export const LogoutPage: React.FC = () => {
  const auth = useAuth();

  useEffect(() => {
    auth.removeToken();
    window.location.reload();
  }, [auth]);

  return <></>;
};
