import { ReportsApi } from 'api-client';
import { useMemo } from 'react';
import { useQuery } from 'react-query';
import { apiConfig } from 'shared/utils/api-config';

export function useReport(reportId?: string) {
  const reportsApi = new ReportsApi(apiConfig());

  const id = useMemo(() => {
    if (!reportId) return undefined;

    const value = parseInt(reportId);
    if (isNaN(value)) return undefined;

    return value;
  }, [reportId]);

  const fetchReport = async () => {
    const { data } = await reportsApi.getReportDetail(id!);
    return data;
  };

  return useQuery(['report', id], fetchReport, { enabled: !!id });
}
