import React, { useEffect, useMemo } from 'react';
import { PlainSearchField } from '../../inputs/plain-search-field';
import { useFirstRender } from 'shared/hooks/use-first-render';
import { useSearchItems } from 'shared/hooks/use-search-items';
import {
  NestedCheckboxItem,
  useEventNestedCheckbox,
} from 'shared/hooks/use-event-nested-checkbox';
import { EventTypeFilterDto } from 'api-client';
import { Tree, TreeProps } from 'shared/components/tree';
import styles from './index.module.css';

export interface FilterObjectDataItem extends NestedCheckboxItem {
  name: string;
  children?: FilterObjectDataItem[];
  id: string;
  parentId?: any;
  categories?: any;
}

export interface FilterObjectProps {
  dataSource: FilterObjectDataItem[];
  value?: FilterObjectDataItem[];
  onChange?: (data: string[]) => void;
  multiple?: boolean;
  eventTypeDataSource: EventTypeFilterDto[];
}

export const FilterEvent: React.FC<FilterObjectProps> = ({
  dataSource = [],
  value = [],
  onChange,
  multiple,
  eventTypeDataSource = [],
}) => {
  const isFirstRender = useFirstRender();
  const nestedCheckbox = useEventNestedCheckbox<FilterObjectDataItem>(
    dataSource,
    eventTypeDataSource
  );

  const { setItems, onSearch, filteredItems } =
    useSearchItems<FilterObjectDataItem>();

  useEffect(() => {
    setItems(dataSource);
  }, [dataSource, setItems]);

  const normalizedValue = useMemo(() => {
    if (value.every((val) => typeof val === 'string')) return value;

    return value.map((val) => (val as any).code);
  }, [value]);

  const itemization = useMemo(() => {
    const filteredEventTypes = eventTypeDataSource.filter((eventType) => {
      return normalizedValue.includes(eventType.code);
    });

    return (filteredEventTypes as unknown as EventTypeFilterDto[]).map((v) => {
      const data: FilterObjectDataItem = {
        name: v.name,
        id: v.code,
        categories: v.categories,
      };
      return data;
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  useEffect(() => {
    nestedCheckbox.setInitialEventTypesValue(itemization);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSearch: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    onSearch(event.target.value);
  };

  const eventTypeSearchItems = useSearchItems<FilterObjectDataItem>();

  const handleSubItemsSearch: React.ChangeEventHandler<HTMLInputElement> = (
    event
  ) => {
    eventTypeSearchItems.onSearch(event.target.value);
  };

  const onSelectEventCategory: TreeProps['onSelect'] = (_, info) => {
    info.nativeEvent.stopPropagation();
    nestedCheckbox.onChangeEventCategory(info.node as any, info.selected);
  };

  const onChangeEventCategory: TreeProps['onCheck'] = (_, info) => {
    info.nativeEvent.stopPropagation();
    nestedCheckbox.onChangeEventCategory(info.node as any, info.checked);
  };

  const onChangeEventType: TreeProps['onCheck'] = (_, info) => {
    info.nativeEvent.stopPropagation();
    nestedCheckbox.onChangeEventType(info.node, info.checked);
  };

  const removeSelectedSubItem = (item: FilterObjectDataItem) => () => {
    nestedCheckbox.removeEventType(item);
  };

  useEffect(() => {
    eventTypeSearchItems.setItems(nestedCheckbox.selectedEventTypes);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nestedCheckbox.selectedEventTypes]);

  useEffect(() => {
    if (onChange && !isFirstRender) {
      const eventTypeIds = nestedCheckbox.checkedEventTypes.map(
        (eventType) => eventType.id
      );
      onChange(eventTypeIds);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nestedCheckbox.checkedEventTypes]);

  const eventCategoryCheckedKeys = useMemo<TreeProps['checkedKeys']>(() => {
    const filteredDuplicatedIds = nestedCheckbox.checkedEventCategoryIds.filter(
      (id) => !nestedCheckbox.indeterminateEventCategoryIds.includes(id)
    );

    return {
      checked: filteredDuplicatedIds,
      halfChecked: nestedCheckbox.indeterminateEventCategoryIds,
    };
  }, [
    nestedCheckbox.checkedEventCategoryIds,
    nestedCheckbox.indeterminateEventCategoryIds,
  ]);

  // Only return checked keys based on the event types displayed in the UI.
  const currentEventTypeCheckedKeys = useMemo(() => {
    return eventTypeSearchItems.filteredItems
      .filter((item) => nestedCheckbox.checkedEventTypeIds.includes(item.id))
      .map((item) => item.id);
  }, [nestedCheckbox.checkedEventTypeIds, eventTypeSearchItems.filteredItems]);

  return (
    <div className={styles.mainWrapper}>
      <div className={styles.boxWrapper}>
        <div>
          <PlainSearchField handleSearch={handleSearch} />
          <div className={styles.horizontalDivider}></div>
        </div>
        <div className={styles.treeWrapper}>
          {!!filteredItems?.length && (
            <Tree
              checkable
              treeData={filteredItems}
              defaultExpandedKeys={nestedCheckbox.eventCategoryParentItemIds}
              onSelect={onSelectEventCategory}
              onCheck={onChangeEventCategory}
              checkStrictly
              checkedKeys={eventCategoryCheckedKeys}
            ></Tree>
          )}
        </div>
      </div>
      <>
        <div className={styles.spacer}></div>
        <div className={styles.boxWrapper}>
          <div>
            <PlainSearchField handleSearch={handleSubItemsSearch} />
            <div className={styles.horizontalDivider}></div>
          </div>
          <div className={styles.treeWrapper}>
            {!!eventTypeSearchItems.filteredItems?.length && (
              <Tree
                checkable
                selectable={false}
                treeData={eventTypeSearchItems.filteredItems}
                checkedKeys={currentEventTypeCheckedKeys}
                onCheck={onChangeEventType}
              ></Tree>
            )}
          </div>
        </div>
      </>
      <div className={styles.spacer}></div>
      <div className={styles.boxWrapper}>
        <ul className={styles.boxResult}>
          {nestedCheckbox.checkedEventTypes.map((item) => (
            <li key={item.id} className={styles.selectedItem}>
              <span className={styles.selectedItemText} title={item.name}>
                {item.name}
              </span>
              <button
                className={styles.btnDelete}
                onClick={removeSelectedSubItem(item)}
              ></button>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};
