import {
  SaveWidgetDto,
  SaveWidgetDtoChartType,
  SaveWidgetDtoWidgetBase,
  SaveWidgetDtoWidgetType,
  WidgetDto,
  WidgetDtoWidgetType,
} from 'api-client';
import { WidgetAreaItem } from 'dashboard/types/widget-item.type';
import { useEffect, useMemo, useState } from 'react';
import { Layout } from 'react-grid-layout';
import { useChartEvents } from './use-chart-events';
import { useCounterEvents } from './use-counter-events';
import { useGetWidgets } from './use-get-widgets';
import { useSaveWidgets } from './use-save-widgets';
import { useSystemUsage } from './use-system-usage';

export function useWidget() {
  const [widgets, setWidgets] = useState<WidgetAreaItem[]>([]);

  const getWidgetsQuery = useGetWidgets();
  const saveWidgetsMutation = useSaveWidgets();
  const systemUsage = useSystemUsage();
  const chartEvents = useChartEvents();
  const counterEvents = useCounterEvents();

  const isLoading = useMemo(
    () =>
      (getWidgetsQuery.isFetching || getWidgetsQuery.isLoading) &&
      !getWidgetsQuery.isSuccess,
    [
      getWidgetsQuery.isFetching,
      getWidgetsQuery.isLoading,
      getWidgetsQuery.isSuccess,
    ]
  );

  const systemUsageResponseData = useMemo(() => {
    if (!systemUsage.isLoading && !!systemUsage.isSuccess) {
      return systemUsage.data;
    }

    return {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [systemUsage.data]);

  const getChartData = (key: string) =>
    chartEvents.find((data) => data.id === key);

  const getCounterData = (key: string) =>
    counterEvents.find((data) => data.id === key);

  const widgetsResponseData: WidgetAreaItem[] = useMemo(() => {
    return (getWidgetsQuery.data || []).map((item) => {
      let chartData;
      let counterData;
      if (item.widgetType === WidgetDtoWidgetType.Chart) {
        chartData = getChartData(item.widgetValue as any);
      }

      if (item.widgetType === WidgetDtoWidgetType.Counter) {
        counterData = getCounterData(item.widgetValue as any);
      }

      switch (item.widgetType) {
        case WidgetDtoWidgetType.Counter:
          return transformCounterWidgetData(item, counterData);
        case WidgetDtoWidgetType.Text:
        case WidgetDtoWidgetType.Webpage:
        case WidgetDtoWidgetType.RealTimeEvents:
        case WidgetDtoWidgetType.Checkpoint:
        case WidgetDtoWidgetType.DoorControl:
          return transformData(item);
        case WidgetDtoWidgetType.SystemUsage:
          return transformSystemUsageWidgetData(item, systemUsage.data);
        case WidgetDtoWidgetType.Chart:
        default:
          return transformChartWidgetData(item, chartData);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getWidgetsQuery.data, systemUsage.data]);

  const getSystemUsageDataBasedOnType = (type: string) =>
    systemUsageResponseData[`${type}`];

  const saveWidgets = () => {
    const data = widgets.map((widget) => {
      if (widget.widgetType === WidgetDtoWidgetType.SystemUsage) {
        widget = {
          ...widget,
          widgetValue: (widget as any).systemUsageId,
        };
      }

      return transformSaveWidgetData(widget, widgetsResponseData);
    });
    return saveWidgetsMutation.mutateAsync(data);
  };

  const updateWidget = (data: WidgetAreaItem) => {
    const widgetsWithoutUpdatedItem = widgets.filter(
      (item) => item.area.i !== data.area.i
    );
    let widget: WidgetAreaItem;
    if (data.widgetType === WidgetDtoWidgetType.SystemUsage) {
      widget = {
        ...data,
        widgetValue: systemUsageResponseData[data.widgetValue],
        systemUsageId: data.widgetValue,
      } as any;
      setWidgets([
        ...widgetsWithoutUpdatedItem,
        {
          ...widget,
          datasets: undefined,
        },
      ]);
    } else {
      setWidgets([
        ...widgetsWithoutUpdatedItem,
        { ...data, datasets: undefined },
      ]);
    }
  };

  const getWidgetByAreaId = (areaId: string) => {
    const widget = widgets.find((widget) => widget.area.i === areaId);
    if (widget?.widgetType === WidgetDtoWidgetType.SystemUsage) {
      return {
        ...widget,
        widgetValue: (widget as any).systemUsageId,
      };
    }

    return widget;
  };

  const newWidgetArea = (widgetType?: string): WidgetAreaItem['area'] => {
    switch (widgetType) {
      case WidgetDtoWidgetType.Chart:
        return { i: Date.now().toString(), x: 0, y: 0, w: 10, h: 5 };
      case WidgetDtoWidgetType.Text:
        return { i: Date.now().toString(), x: 0, y: 0, w: 6, h: 1 };
      case WidgetDtoWidgetType.RealTimeEvents:
        return { i: Date.now().toString(), x: 0, y: 0, w: 19, h: 10 };
      case WidgetDtoWidgetType.Checkpoint:
        return { i: Date.now().toString(), x: 0, y: 0, w: 27, h: 9 };
      case WidgetDtoWidgetType.Counter:
        return { i: Date.now().toString(), x: 0, y: 0, w: 5, h: 3 };
      case WidgetDtoWidgetType.DoorControl:
      case WidgetDtoWidgetType.SystemUsage:
        return { i: Date.now().toString(), x: 0, y: 0, w: 5, h: 4 };
      default:
        return { i: Date.now().toString(), x: 0, y: 0, w: 2, h: 2 };
    }
  };

  const addNewWidget = (data: WidgetAreaItem) => {
    const widgetArea = newWidgetArea(data?.widgetType);
    let widget: WidgetAreaItem;
    if (data.widgetType === WidgetDtoWidgetType.SystemUsage) {
      widget = {
        ...data,
        widgetValue: systemUsageResponseData[data.widgetValue],
        systemUsageId: data.widgetValue,
      } as any;
      setWidgets((prev) => [
        ...prev,
        {
          ...widget,
          area: widgetArea,
          datasets: undefined,
        },
      ]);
    } else {
      setWidgets((prev) => [
        ...prev,
        { ...data, area: widgetArea, datasets: undefined },
      ]);
    }
  };

  const removeWidget = (areaId: string) => {
    setWidgets((prev) => {
      return prev.filter((item) => item.area.i !== areaId);
    });
  };

  const updateLayouts = (layouts: Layout[]) => {
    const updatedWidgetLayouts = widgets.map((item: any) => {
      // Find layout of the widget
      const layout = layouts.find((l) => l.i === item.area.i);
      return { ...item, area: layout } as WidgetAreaItem;
    });
    setWidgets(updatedWidgetLayouts);
  };

  const refetchWidgets = () => getWidgetsQuery.refetch();
  const noWidget = useMemo(
    () =>
      !getWidgetsQuery.isLoading &&
      getWidgetsQuery.isSuccess &&
      !getWidgetsQuery.data.length,
    [
      getWidgetsQuery.data?.length,
      getWidgetsQuery.isLoading,
      getWidgetsQuery.isSuccess,
    ]
  );

  useEffect(() => {
    refetchWidgets();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setWidgets(widgetsResponseData);
  }, [widgetsResponseData]);

  return {
    widgets,
    isLoading,
    addNewWidget,
    saveWidgets,
    updateLayouts,
    removeWidget,
    getWidgetByAreaId,
    updateWidget,
    refetchWidgets,
    getSystemUsageDataBasedOnType,
    noWidget,
  };
}

function systemUsageDatasets(key: string, data: any) {
  const value = !data ? 0 : data[key];
  const maxNumber = getSystemUsageMaxNumber(key);
  const cleanedValue = !value ? value + 1 : value;
  const secondValue =
    maxNumber - cleanedValue < 0 ? 0 : maxNumber - cleanedValue;
  const datasets = [
    {
      data: !!maxNumber ? [cleanedValue, secondValue] : [cleanedValue],
      backgroundColor: !!maxNumber
        ? ['rgba(28, 182, 116)', 'rgba(28, 113, 212)']
        : ['rgba(28, 182, 116)'],
      borderColor: !!maxNumber
        ? ['rgba(28, 182, 116)', 'rgba(28, 113, 212)']
        : ['rgba(28, 182, 116)'],
      borderWidth: 1,
      label: value,
      datalabels: key,
    },
  ];
  return datasets;
}

function getSystemUsageMaxNumber(key: string) {
  if (key === 'doors' || key === 'devices' || key === 'zones') {
    return 1000;
  }
  return 0;
}

function transformChartWidgetData(
  data: WidgetDto,
  chartData: any
): WidgetAreaItem {
  // const isEmptyData = data.dataItems.every((item) => !item.value);
  return {
    id: data.id,
    area: {
      id: data.widgetArea.id,
      h: data.widgetArea.height,
      w: data.widgetArea.width,
      x: data.widgetArea.xPosition,
      y: data.widgetArea.yPosition,
      i: data.widgetArea.index,
    },
    widgetName: data.name,
    widgetBase: data.widgetBase,
    widgetType: data.widgetType,
    chartType: data.chartType,
    chartColor: data.chartColor,
    categories: chartData.data.map((d: any) => ({
      categoryName: ' ',
      period: d.period,
    })),
    datasets: [{ data: [] }],
    widgetValue: data.widgetValue,
  };
}

function transformCounterWidgetData(data: WidgetDto, counterData: any): any {
  return {
    id: data.id,
    area: {
      id: data.widgetArea.id,
      h: data.widgetArea.height,
      w: data.widgetArea.width,
      x: data.widgetArea.xPosition,
      y: data.widgetArea.yPosition,
      i: data.widgetArea.index,
    },
    widgetName: data.name,
    widgetBase: data.widgetBase,
    widgetType: data.widgetType,
    categories: counterData.data.map((d: any) => ({
      categoryName: '',
      period: d.period,
    })),
    datasets: { data: [] },
    widgetValue: data.widgetValue,
  };
}

function transformData(data: WidgetDto): any {
  return {
    id: data.id,
    area: {
      id: data.widgetArea.id,
      h: data.widgetArea.height,
      w: data.widgetArea.width,
      x: data.widgetArea.xPosition,
      y: data.widgetArea.yPosition,
      i: data.widgetArea.index,
    },
    widgetName: data.name,
    widgetBase: data.widgetBase,
    widgetType: data.widgetType,
    widgetValue: data.widgetValue,
  };
}

function transformSystemUsageWidgetData(
  data: WidgetDto,
  systemUsageData: any
): any {
  return {
    id: data.id,
    area: {
      id: data.widgetArea.id,
      h: data.widgetArea.height,
      w: data.widgetArea.width,
      x: data.widgetArea.xPosition,
      y: data.widgetArea.yPosition,
      i: data.widgetArea.index,
    },
    widgetName: data.name,
    widgetBase: data.widgetBase,
    widgetType: data.widgetType,
    widgetValue: !!systemUsageData
      ? systemUsageData[data.widgetValue as any]
      : 0,
    chartType: 'doughnut',
    datasets: systemUsageDatasets(data.widgetValue as any, systemUsageData),
    systemUsageId: data.widgetValue,
  };
}

function transformSaveWidgetData(
  widget: WidgetAreaItem,
  widgetsResponseData: WidgetAreaItem[]
): SaveWidgetDto {
  // Get the widget area id from the API response,
  // because the widget area id field will be removed by grid layout library.
  // So that we need to add it back before sending to the API.
  const areaId = widgetsResponseData.find((item) => item.id === widget.id)?.area
    .id;
  return {
    id: widget.id,
    name: widget.widgetName,
    widgetArea: {
      id: areaId,
      xPosition: widget.area.x,
      yPosition: widget.area.y,
      width: widget.area.w,
      height: widget.area.h,
      index: widget.area.i,
    },
    widgetBase: widget.widgetBase as SaveWidgetDtoWidgetBase,
    widgetType: widget.widgetType as SaveWidgetDtoWidgetType,
    chartType: widget.chartType as SaveWidgetDtoChartType,
    chartColor: widget.chartColor,
    dataItems: [],
    // dataItems: !widget.categories?.length
    //   ? []
    //   : widget.categories.map((item) => {
    //       const data = item as WidgetItemCategory1;
    //       return {
    //         id: data.id,
    //         name: data.categoryName,
    //         reportTemplateId: parseInt(data.type),
    //       };
    //     }),
    widgetValue: widget.widgetValue,
  };
}
