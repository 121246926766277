import { Configuration } from 'api-client';
import { isProduction } from './environment';

/**
 * The API configuration to be used in creating an API client instance.
 */
export function apiConfig() {
  return new Configuration({
    basePath: isProduction ? window.location.origin : 'https://localhost:4124',
    accessToken: sessionStorage.getItem('authToken') || '',
  });
}
