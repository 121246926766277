import { ExportApi } from 'api-client';
import { useQuery } from 'react-query';
import { apiConfig } from 'shared/utils/api-config';

export function useExportReportConfig() {
  const exportApi = new ExportApi(apiConfig());

  const fetchLastConfig = async () => {
    const { data } = await exportApi.getLastConfiguration();
    return data;
  };

  const lastConfig = useQuery('exportLastConfig', fetchLastConfig);

  return lastConfig;
}
