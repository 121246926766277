import { PreferencesApi } from 'api-client';
import { useQuery } from 'react-query';
import { apiConfig } from 'shared/utils/api-config';

export function useUserDST() {
  const api = new PreferencesApi(apiConfig());

  const fetchUserDST = async () => {
    const { data } = await api.getUserDST();
    return data;
  };

  return useQuery('userDST', fetchUserDST);
}
