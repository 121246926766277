import { PreferencesApi } from 'api-client';
import { useQuery } from 'react-query';
import { apiConfig } from 'shared/utils/api-config';

export function useDatetimePreference() {
  const api = new PreferencesApi(apiConfig());

  const fetchDatetimePreference = async () => {
    const userId = sessionStorage.getItem('userId') || '1';
    const { data } = await api.getDatetimePreference(userId);
    return data;
  };

  return useQuery('datetimePreference', fetchDatetimePreference);
}
