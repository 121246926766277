import { Box, Stack } from '@mui/material';
import classNames from 'classnames';
import { useTranslation } from 'core/context/i18n.context';
import { useNavigate } from 'react-router-dom';
import { ButtonPrimary } from 'shared/components/buttons/button-primary';
import styles from './index.module.css';

export const NoWidget: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const navigateTo = (path: string) => () => navigate(path);
  return (
    <Box display="flex" justifyContent="center" alignItems="center">
      <Stack alignItems="center">
        <h1
          className={classNames(
            styles.title,
            styles.fontColor,
            styles.textAlignCenter
          )}
        >
          {t('reportApp.noWidgets')}
        </h1>
        <p className={classNames(styles.info, styles.fontColor)}>
          {t('reportApp.addWidgetInfo')}
        </p>
        <div className={styles.divider}></div>
        <ButtonPrimary
          title={t('reportApp.addWidget')}
          size="small"
          className={styles.btnAddWidget}
          onClick={navigateTo('settings?openNewWidgetDialog=true')}
        ></ButtonPrimary>
      </Stack>
    </Box>
  );
};
