import { useGetPreference } from 'core/hooks/use-get-preference';
import { useGetTranslationMessages } from 'core/hooks/use-get-translation-messages';
import { createContext, useContext, useEffect, useMemo } from 'react';
import { useAuth } from './auth.context';
import { useTranslation } from './i18n.context';

const PreferenceContext = createContext<{ datetimeFormat?: string }>({});

export const PreferenceProvider = ({ children }: any) => {
  const preference = useGetPreference();
  const { isAuthenticated } = useAuth();
  const { setLanguageToLocalStorage, removeLanguageOnLocalStorage } =
    useTranslation();
  const translation = useGetTranslationMessages();

  const datetimeFormat = useMemo(
    () => preference.data?.datetimeFormat,
    [preference.data]
  );

  // Get user preference when authenticated.
  useEffect(() => {
    if (isAuthenticated) {
      preference.refetch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated]);

  // Get and set language from preference.
  // Then fetch the translation based on the language.
  useEffect(() => {
    if (isAuthenticated && !!preference.data?.language) {
      setLanguageToLocalStorage(preference.data.language);
    } else {
      removeLanguageOnLocalStorage();
    }
    translation.refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [preference.data]);

  return (
    <PreferenceContext.Provider value={{ datetimeFormat }}>
      {children}
    </PreferenceContext.Provider>
  );
};

export function usePreference() {
  return useContext(PreferenceContext);
}
