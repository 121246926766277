/* tslint:disable */
/* eslint-disable */
/**
 * BioStar 2 Report API
 * API Documentation for BioStar 2 Report
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @interface CategoryDto
 */
export interface CategoryDto {
    /**
     * 
     * @type {number}
     * @memberof CategoryDto
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof CategoryDto
     */
    keyName: CategoryDtoKeyName;
    /**
     * 
     * @type {string}
     * @memberof CategoryDto
     */
    name: string;
    /**
     * 
     * @type {number}
     * @memberof CategoryDto
     */
    defaultItemOrder: number;
}

/**
    * @export
    * @enum {string}
    */
export enum CategoryDtoKeyName {
    User = 'user',
    Event = 'event'
}



