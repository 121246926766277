import { useTranslation } from 'core/context/i18n.context';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { ButtonDefault } from 'shared/components/buttons/button-default';
import { ButtonPrimary } from 'shared/components/buttons/button-primary';
import {
  BaseDialog,
  BaseDialogProps,
} from 'shared/components/dialogs/base-dialog';
import { TextField } from 'shared/components/inputs/text-field';
import styles from './index.module.css';

interface EditTitleDialogProps extends Omit<BaseDialogProps, 'title'> {
  titleValue: string;
  onSave?: (data: EditTitleFormData) => Promise<void>;
}

export type EditTitleFormData = {
  title: string;
};

export const EditTitleDialog: React.FC<EditTitleDialogProps> = (props) => {
  const { t } = useTranslation();
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<EditTitleFormData>();
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    setValue('title', props.titleValue);
  }, [props.titleValue, setValue]);

  const onSubmit = handleSubmit(async (data) => {
    setLoading(true);
    if (props.onSave) {
      await props.onSave(data);
    }
    setLoading(false);
  });

  const closeDialog = () => {
    setLoading(false);
    if (props.onClose) {
      props.onClose();
    }
  };

  const DialogActions = () => {
    return (
      <>
        <ButtonPrimary
          title={t('reportApp.save')}
          size="small"
          loading={loading}
          onClick={onSubmit}
          className={styles.buttonAction}
        ></ButtonPrimary>
        <ButtonDefault
          title={t('reportApp.cancel')}
          size="small"
          onClick={closeDialog}
          className={styles.buttonAction}
        ></ButtonDefault>
      </>
    );
  };

  return (
    <BaseDialog
      {...props}
      title={t('reportApp.editTitle')}
      actions={<DialogActions />}
    >
      <form onSubmit={onSubmit} className={styles.formWrapper}>
        <TextField
          name="title"
          inputProps={{
            ...register('title', {
              required: {
                value: true,
                message: t('reportApp.fieldRequired'),
              },
              maxLength: {
                value: 52,
                message: t('reportApp.titleMaxLength'),
              },
            }),
          }}
          error={errors.title?.message}
        ></TextField>
      </form>
    </BaseDialog>
  );
};
