import { CircularProgress } from '@mui/material';
import { ColumnDtoKeyName } from 'api-client';
import { useTranslation } from 'core/context/i18n.context';
import React, { useMemo } from 'react';
import {
  Cell,
  HeaderGroup,
  Row,
  useFlexLayout,
  useResizeColumns,
  useSortBy,
  useTable,
} from 'react-table';
import { ItemNotFound } from '../item-not-found';
import { TableProps } from '../table';
import styles from './index.module.css';

export const TableParticular: React.FC<TableProps> = ({
  columns,
  data,
  loading,
}) => {
  const { t } = useTranslation();

  const defaultColumn = React.useMemo(
    () => ({
      // When using the useFlexLayout:
      minWidth: 80, // minWidth is only used as a limit for resizing
      width: 150, // width is used for both the flex-basis and flex-grow
      maxWidth: 280, // maxWidth is only used as a limit for resizing
    }),
    []
  );

  const { getTableProps, headerGroups, rows, prepareRow } = useTable(
    { columns, data, defaultColumn },
    useResizeColumns,
    useFlexLayout,
    useSortBy
  );

  const renderLoading = () => {
    return (
      <div className={styles.infoWrapper}>
        <div className={styles.loading}>
          <CircularProgress />
        </div>
      </div>
    );
  };

  const renderNoResult = () => {
    return (
      <div className={styles.infoWrapper}>
        <div className={styles.noResult}>
          <ItemNotFound message={t('reportApp.noResult')} lite />
        </div>
      </div>
    );
  };

  const renderItem = (fieldName: any, value?: any) => {
    if (!value) return '-';

    if (fieldName === 'accessGroup') {
      const parsed = JSON.parse(value);
      const joinedGroups = parsed.nameOfGroups.join(', ');
      return joinedGroups;
    }

    return value;
  };

  const renderImage = (value?: any) => {
    if (!value) return <div className={styles.imagePlaceholder} />;

    return <img src={value} alt="user-profile" className={styles.photo} />;
  };

  const includedUserInfoDataIds = [
    ColumnDtoKeyName.UserGroup,
    ColumnDtoKeyName.Title,
    ColumnDtoKeyName.Department,
    ColumnDtoKeyName.Email,
    ColumnDtoKeyName.Telephone,
  ];

  const userInfoHeaderGroups = useMemo(() => {
    return headerGroups.map((headerGroup: HeaderGroup) => {
      const filteredHeaders = headerGroup.headers.filter(
        (header: HeaderGroup) =>
          includedUserInfoDataIds.includes(header.id as ColumnDtoKeyName)
      );
      const filteredHeaderIds = filteredHeaders.map((header) => header.id);
      const newIndexes = includedUserInfoDataIds.map((id) =>
        filteredHeaderIds.indexOf(id)
      );
      const reOrderedHeaders = newIndexes.map((i) => filteredHeaders[i]);

      return { ...headerGroup, headers: reOrderedHeaders };
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [headerGroups]);

  const renderUserInfoHeaders = () => {
    return userInfoHeaderGroups.map((headerGroup: HeaderGroup) => (
      <div
        {...headerGroup.getHeaderGroupProps()}
        className={styles.userInfoHeadRow}
        style={{ minWidth: '150px' }}
      >
        {headerGroup.headers.map((column: HeaderGroup) => {
          return (
            <div {...column.getHeaderProps()} className={styles.userInfoHead}>
              {column.render('Header')}
            </div>
          );
        })}
      </div>
    ));
  };

  const reOrderedUserInfoCellData = (cells: Cell[]) => {
    const filteredCellData = cells.filter((cell: Cell) =>
      includedUserInfoDataIds.includes(cell.column.id as ColumnDtoKeyName)
    );
    const currentCellDataIds = filteredCellData.map(
      (data: Cell) => data.column.id
    );
    const newIndexes = includedUserInfoDataIds.map((id: string) =>
      currentCellDataIds.indexOf(id)
    );

    const reOrderedData = newIndexes.map((i: number) => filteredCellData[i]);

    return reOrderedData;
  };

  const renderUserInfo = (row: Row) => {
    const userInfoCellData = reOrderedUserInfoCellData(row.cells);
    return (
      <div className={styles.userInfo}>
        <div className={styles.userInfoContainer}>
          <div className={styles.userNameWrapper}>
            <span className={styles.userName}>{row.values.userName}</span>
            <span className={styles.userId}>{` / ${row.values.userId}`}</span>
          </div>
          <div className={styles.userInfoWrapper}>
            {renderUserInfoHeaders()}
            <div
              {...row.getRowProps()}
              className={styles.userInfoDataRow}
              style={{
                flex: 'none',
                minWidth: 'none',
              }}
            >
              {userInfoCellData.map((cell: Cell) => (
                <div {...cell.getCellProps()} className={styles.userInfoData}>
                  {renderItem(cell.column.id, cell.value)}
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className={styles.photoWrapper}>
          {renderImage(row.values.photo)}
        </div>
      </div>
    );
  };

  const renderUserStatusHeader = (userStatusDataIds: string[]) => {
    return headerGroups.map((headerGroup: HeaderGroup) => {
      return (
        <div
          {...headerGroup.getHeaderGroupProps()}
          className={styles.userStatusHeadRow}
        >
          {headerGroup.headers
            .filter((header: HeaderGroup) =>
              userStatusDataIds.includes(header.id)
            )
            .map((column: HeaderGroup) => {
              return (
                <div
                  {...column.getHeaderProps()}
                  className={styles.userStatusHead}
                >
                  {column.render('Header')}
                </div>
              );
            })}
        </div>
      );
    });
  };

  const renderUserStatus = (row: Row) => {
    const includedUserStatusDataIds = [
      ColumnDtoKeyName.Status,
      ColumnDtoKeyName.Period,
      ColumnDtoKeyName.OperatorLevel,
      ColumnDtoKeyName.LoginId,
      ColumnDtoKeyName.UserIp,
    ];
    return (
      <div className={styles.userStatus}>
        {renderUserStatusHeader(includedUserStatusDataIds)}
        <div {...row.getRowProps()} className={styles.userStatusDataRow}>
          {row.cells
            .filter((cell: Cell) =>
              includedUserStatusDataIds.includes(
                cell.column.id as ColumnDtoKeyName
              )
            )
            .map((cell: Cell) => (
              <div {...cell.getCellProps()} className={styles.userStatusData}>
                {renderItem(cell.column.id, cell.value)}
              </div>
            ))}
        </div>
      </div>
    );
  };

  const renderAccessGroup = (row: Row) => {
    return (
      <div className={styles.userAccessGroups}>
        {headerGroups.map((headerGroup: HeaderGroup) => {
          return (
            <div
              {...headerGroup.getHeaderGroupProps()}
              className={styles.accessGroupHead}
            >
              {headerGroup.headers
                .filter((h) => h.id === ColumnDtoKeyName.AccessGroup)
                .map((column) => {
                  return (
                    <div {...column.getHeaderProps()}>
                      {column.render('Header')}
                    </div>
                  );
                })}
            </div>
          );
        })}
        <div {...row.getRowProps()} className={styles.accessGroupData}>
          {row.cells
            .filter(
              (cell: Cell) => cell.column.id === ColumnDtoKeyName.AccessGroup
            )
            .map((cell: Cell) => (
              <div {...cell.getCellProps()}>
                {renderItem(cell.column.id, cell.value)}
              </div>
            ))}
        </div>
      </div>
    );
  };

  const renderTable = () => {
    return rows.map((row: Row) => {
      prepareRow(row);
      return (
        <div key={row.id} className={styles.table}>
          <div>{renderUserInfo(row)}</div>
          <div>{renderUserStatus(row)}</div>
          <div>{renderAccessGroup(row)}</div>
        </div>
      );
    });
  };

  return (
    <div
      {...getTableProps()}
      className={styles.tableWrapper}
      style={{ minWidth: '960px' }}
    >
      {loading && renderLoading()}
      {!rows.length && !loading && renderNoResult()}
      {!!rows.length && !loading && renderTable()}
    </div>
  );
};
