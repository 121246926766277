import { useNavigate } from 'react-router-dom';
import styles from './index.module.css';

interface PageHeaderProps {
  title: string;
  titleActionComponent?: () => JSX.Element;
  hideBackButton?: boolean;
  onBackButtonClick?: () => void;
}

export const PageHeader: React.FC<PageHeaderProps> = ({
  title,
  titleActionComponent,
  hideBackButton,
  onBackButtonClick,
}) => {
  const navigate = useNavigate();

  const backToPreviousHistory = () => navigate(-1);
  const handleClickBackButton = () => {
    if (onBackButtonClick) {
      onBackButtonClick();
    } else {
      backToPreviousHistory();
    }
  };

  return (
    <div className={styles.headerContainer}>
      <div className={styles.pageTitleWrapper}>
        {!hideBackButton && (
          <button
            className={styles.btnBack}
            onClick={handleClickBackButton}
          ></button>
        )}
        <h1 className={styles.titleStyle}>{title}</h1>
        {!!titleActionComponent && titleActionComponent()}
      </div>
    </div>
  );
};
