import { ButtonPrimary } from 'shared/components/buttons/button-primary';
import { Table } from 'shared/components/table';
import styles from './index.module.css';

interface HistoryTableProps {
  tableColumns: any[];
  dataSource: any[];
}

export const HistoryTable: React.FC<HistoryTableProps> = ({
  tableColumns,
  dataSource,
}) => {
  return (
    <>
      <div className={styles.actionButtonsWrapper}>
        <ButtonPrimary
          className={styles.btn}
          title="Button 1"
          size="small"
        ></ButtonPrimary>
        <ButtonPrimary
          className={styles.btn}
          title="Button 2"
          size="small"
        ></ButtonPrimary>
        <ButtonPrimary
          className={styles.btn}
          title="Button 3"
          size="small"
        ></ButtonPrimary>
        <ButtonPrimary
          className={styles.btn}
          title="Button 4"
          size="small"
        ></ButtonPrimary>
      </div>
      <Table columns={tableColumns} data={[]} loading={false}></Table>
    </>
  );
};
