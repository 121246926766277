import { useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';

export interface PaginationOptions {
  rowsPerPageOptions?: number[];
  rowsPerPage?: number;
  page?: number;
  search?: string;
}

export function usePagination(options?: PaginationOptions) {
  const [searchParams, setSearchParams] = useSearchParams();

  const currentRowsPerPageOptions = useMemo(() => {
    return options?.rowsPerPageOptions || [25, 50, 100, 150];
  }, [options?.rowsPerPageOptions]);

  const currentRowsPerPage = useMemo(() => {
    const value = parseInt(
      searchParams.get('rowsPerPage') || options?.rowsPerPage?.toString() || '0'
    );

    if (value < currentRowsPerPageOptions[0])
      return currentRowsPerPageOptions[0];

    return value;
  }, [searchParams, options?.rowsPerPage, currentRowsPerPageOptions]);

  const currentPage = useMemo(() => {
    return parseInt(
      searchParams.get('page') || options?.page?.toString() || '1'
    );
  }, [searchParams, options?.page]);

  const currentSearch = useMemo(() => {
    return searchParams.get('search') || options?.search || '';
  }, [searchParams, options?.search]);

  const offset = useMemo(
    () => (currentPage - 1) * currentRowsPerPage,
    [currentPage, currentRowsPerPage]
  );
  const limit = useMemo(() => currentRowsPerPage, [currentRowsPerPage]);

  const onPageChange = (value: number) => {
    if (isNaN(value)) value = 1;
    searchParams.set('page', value.toString());
    setSearchParams(searchParams);
  };

  const onRowsPerPageChange = (value: number) => {
    searchParams.set('rowsPerPage', value.toString());
    setSearchParams(searchParams);
  };

  const onSearchChange = (value?: string) => {
    if (value) {
      searchParams.set('search', value);
    } else {
      searchParams.delete('search');
    }
    setSearchParams(searchParams);
  };

  return {
    rowsPerPageOptions: currentRowsPerPageOptions,
    rowsPerPage: currentRowsPerPage,
    page: currentPage,
    search: currentSearch,
    offset,
    limit,
    onPageChange: onPageChange,
    onRowsPerPageChange: onRowsPerPageChange,
    onSearchChange: onSearchChange,
  };
}
