import {
  CreatePreferenceDto,
  ErrorResponseDto,
  ReportTemplatesApi,
  SaveMenuItemsTreeStatus,
  SaveReportTemplateMenuOrderDto,
  UserInterfacesApi,
} from 'api-client';
import axios from 'axios';
import { useMutation } from 'react-query';
import { apiConfig } from 'shared/utils/api-config';

export function useReportMenuMutation() {
  const userInterfaceApi = new UserInterfacesApi(apiConfig());
  const templateApi = new ReportTemplatesApi(apiConfig());

  const menu = useMutation<
    CreatePreferenceDto[],
    ErrorResponseDto,
    SaveMenuItemsTreeStatus
  >((saveMenuItemsTreeStatus: SaveMenuItemsTreeStatus) =>
    userInterfaceApi
      .saveReportMenuItemsStatus(saveMenuItemsTreeStatus)
      .then((res) => res.data)
      .catch((error) => {
        if (axios.isAxiosError(error)) {
          throw error.response!.data;
        }
        throw error;
      })
  );

  const order = useMutation(
    (saveReportTemplateMenuOrderDto: SaveReportTemplateMenuOrderDto) =>
      templateApi
        .saveReportTemplateMenuOrder(saveReportTemplateMenuOrderDto)
        .then((res) => res.data)
        .catch((error) => {
          if (axios.isAxiosError(error)) {
            throw error.response!.data;
          }
          throw error;
        })
  );

  return { menu, order };
}
