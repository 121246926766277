import { Box } from '@mui/system';
import { AddScheduleDtoFrequency } from 'api-client';
import { useTranslation } from 'core/context/i18n.context';
import { useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { useGetReportTemplates } from 'schedule/hooks/use-get-report-templates';
import { Card } from 'shared/components/card';
import { CardTitle } from 'shared/components/card-title';
import { Info } from 'shared/components/info';
import { InputError } from 'shared/components/input-error';
import { InputLabel } from 'shared/components/input-label';
import { InputRow } from 'shared/components/input-row';
import { InputWrapper } from 'shared/components/input-wrapper';
import { SelectField } from 'shared/components/inputs/select-field';
import styles from './index.module.css';

interface ScheduleSectionProps {
  onChangeTime: (data: any) => void;
}

export const ScheduleSection: React.FC<ScheduleSectionProps> = ({
  onChangeTime,
}) => {
  const { t } = useTranslation();
  const {
    register,
    getValues,
    setValue,
    formState: { errors },
  } = useFormContext();

  const reportTemplatesHook = useGetReportTemplates();

  const frequencies = useMemo(() => {
    return Object.values(AddScheduleDtoFrequency).map((val) => ({
      id: val,
      name: t('reportApp.frequency.' + val),
    }));
  }, [t]);

  const reports = useMemo(() => {
    return (reportTemplatesHook.data || []).map((item) => ({
      id: item.id.toString(),
      name: item.name,
    }));
  }, [reportTemplatesHook.data]);

  const handleTime = (e: any) => {
    onChangeTime(e);
  };

  const onChangeReport: any = (event: any, name: string) => {
    const reportTemplateId = parseInt(event.id);
    const reportTemplate = reportTemplatesHook.data?.find(
      (report) => report.id === reportTemplateId
    );
    if (!reportTemplate) return;
    // Update report title field on format section via form context
    setValue('reportTitle', reportTemplate.name);
    setValue('report', reportTemplate.id);
  };

  const onChangeSelectField = (value: any, fieldName?: string) => {
    setValue(fieldName!, value.id);
  };

  return (
    <Card>
      <CardTitle>{t('reportApp.reportAndSchedule')}</CardTitle>

      <Box p="24px" display="flex" flexDirection="column" paddingLeft="120px">
        <InputWrapper flexDirection="column" alignItems="flex-start">
          <InputRow>
            <InputLabel display="flex" width="150px" showBullet>
              {t('reportApp.report')}
            </InputLabel>
            <Box maxWidth="300px" width="100%">
              <SelectField
                name="report"
                dataSource={reports}
                selectProps={{
                  ...register('report', {
                    required: {
                      value: true,
                      message: t('reportApp.fieldRequired'),
                    },
                  }),
                  value: getValues('report'),
                }}
                onChange={onChangeReport}
                styleType="emboss"
              />
            </Box>
            <Box ml="4px">
              <Info message={t('reportApp.scheduleReportInfo')} />
            </Box>
          </InputRow>
          <InputError pl="166px">{errors.report?.message}</InputError>
        </InputWrapper>
        <Box mb="16px" />

        <Box display="flex" alignItems="flex-start" width="100%">
          <InputWrapper flex="1" flexDirection="column" alignItems="flex-start">
            <InputRow>
              <InputLabel display="flex" width="150px" showBullet>
                {t('reportApp.frequency')}
              </InputLabel>
              <Box maxWidth="300px" width="100%">
                <SelectField
                  name="frequency"
                  dataSource={frequencies}
                  selectProps={{
                    ...register('frequency', {
                      required: {
                        value: true,
                        message: t('reportApp.fieldRequired'),
                      },
                    }),
                    value: getValues('frequency'),
                  }}
                  styleType="emboss"
                  onChange={onChangeSelectField}
                />
              </Box>
            </InputRow>
            <InputError pl="166px">{errors.frequency?.message}</InputError>
          </InputWrapper>

          <InputWrapper flex="1" flexDirection="column" alignItems="flex-start">
            <InputRow>
              <InputLabel display="flex" width="150px" showBullet>
                {t('reportApp.generateTime')}
              </InputLabel>
              <Box maxWidth="300px" width="100%">
                <input
                  {...register('hours', {
                    max: {
                      value: 23,
                      message: t('reportApp.maxHours', [23]),
                    },
                    valueAsNumber: true,
                  })}
                  type="number"
                  max={23}
                  name="hours"
                  className={styles.timeInput}
                  onChange={handleTime}
                />
                <span className={styles.separated}>:</span>
                <input
                  {...register('minutes', {
                    max: {
                      value: 59,
                      message: t('reportApp.maxMinutes', [59]),
                    },
                    valueAsNumber: true,
                  })}
                  type="number"
                  max={59}
                  name="minutes"
                  className={styles.timeInput}
                  onChange={handleTime}
                />
              </Box>
            </InputRow>
            <InputError pl="166px">{errors.generateTime?.message}</InputError>
          </InputWrapper>
        </Box>
      </Box>
    </Card>
  );
};
