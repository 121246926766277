import { Box } from '@mui/system';
import { useRealTimeEvents } from 'dashboard/hooks/use-real-time-events';
import styles from './index.module.css';
import { useEffect, useMemo, useState } from 'react';
import { Table, TableProps } from 'shared/components/table';
import { ButtonSecondary } from '../buttons/button-secondary';
import { useTranslation } from 'core/context/i18n.context';
import pauseBtn from 'core/assets/funcdark_pause.png';
import playBtn from 'core/assets/funcdark_play.png';
import clearBtn from 'core/assets/func_clear.png';
import classNames from 'classnames';
import { ViewImageDialog } from '../dialogs/view-image-dialog';
import { useSqueezeText } from 'shared/hooks/use-squeeze-text';

export const WidgetRealTimeEvents: React.FC<any> = ({
  data,
  index,
  isSettings,
}) => {
  const { t } = useTranslation();
  const filterCondition = JSON.parse(data.widgetValue);
  const {
    clearEvents,
    events,
    normalizedEventData,
    onClickPlayButton,
    startRealTimeEvents,
    onClickPauseButton,
    isPause,
    openDialog,
    dialogData,
    handleCloseDialog,
  } = useRealTimeEvents();
  const [replicateEvents, setReplicateEvents] = useState<any[]>();

  const tableColumns: TableProps['columns'] = useMemo(() => {
    return (filterCondition.columns || []).map((col: any) => {
      return {
        Header: t('realTimeEventWidget.column.' + col.keyName),
        accessor: col.keyName,
        showColumn: col.showColumn,
      };
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterCondition.columns]);

  const normalizedData: any[] = useMemo(() => {
    const eventsLength = events.length;
    if (events.length > 100 && !isPause) {
      const lengthDifference = events.length - 100;
      const result = events.slice(0, eventsLength - lengthDifference);

      return normalizedEventData(filterCondition, result);
    }

    if (isPause) {
      const sliced = replicateEvents?.slice(0, 100);
      return normalizedEventData(filterCondition, sliced);
    }

    return normalizedEventData(filterCondition, events);
  }, [events, filterCondition, isPause, normalizedEventData, replicateEvents]);

  const renderTable = () => {
    return (
      <Table
        columns={tableColumns}
        data={normalizedData}
        loading={false}
        isRealTimeEvents={true}
      />
    );
  };

  const renderPlayPauseBtn = useMemo(() => {
    if (!isPause) {
      return {
        title: t('reportApp.pause'),
        imgSrc: pauseBtn,
        onClick: onClickPauseButton,
      };
    }

    return {
      title: t('reportApp.play'),
      imgSrc: playBtn,
      onClick: onClickPlayButton,
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [t, isPause]);

  const titleId = `title-${index}`;
  const squeezeText = useSqueezeText(titleId);

  useEffect(() => {
    setReplicateEvents(events);
    startRealTimeEvents();
    squeezeText();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [replicateEvents, startRealTimeEvents, squeezeText]);

  return (
    <Box position="relative" height="100%" id={`${data.widgetType}-${index}`}>
      <div className={styles.widgetTitleWrapper} id={`title-wrapper-${index}`}>
        <h2 className={styles.widgetTitle} id={`title-${index}`}>
          {data.widgetName}
        </h2>
      </div>
      {!isSettings && (
        <div className={styles.actionButtonsWrapper}>
          <ButtonSecondary
            title={renderPlayPauseBtn.title}
            imgSrc={renderPlayPauseBtn.imgSrc}
            size="small"
            className={classNames(styles.actionButton, styles.playPauseBtn)}
            onClick={renderPlayPauseBtn.onClick}
          />
          <ButtonSecondary
            title={t('reportApp.clear')}
            imgSrc={clearBtn}
            size="small"
            className={classNames(styles.actionButton, styles.clearBtn)}
            onClick={clearEvents}
          />
        </div>
      )}
      {!isSettings && renderTable()}
      {isSettings && (
        <Box
          position="absolute"
          top="50%"
          left="50%"
          sx={{ transform: 'translate(-50%, -50%)' }}
          fontSize="16px"
          color="var(--neutral-color-4)"
        >
          {t('reportApp.noData')}
        </Box>
      )}
      <ViewImageDialog
        open={openDialog}
        onClose={handleCloseDialog}
        data={dialogData}
      ></ViewImageDialog>
    </Box>
  );
};
