import { FilterConditionsApi, ReportsApi } from 'api-client';
import { useMemo } from 'react';
import { useQueries } from 'react-query';
import { FilterCheckboxDataItem } from 'shared/components/filter-conditions/filter-checkbox';
import { FilterInputNumberDataItem } from 'shared/components/filter-conditions/filter-input-number';
import { apiConfig } from 'shared/utils/api-config';
import { WizardStepProps } from '../index.type';

export function useFilterCondition(wizardSteps: WizardStepProps[]) {
  const queries = useQueries(
    wizardSteps.map((step) => ({
      queryKey: [step.type, step.value],
      queryFn: getQueryFn(step.type, step.value),
    }))
  );

  const queriesData = useMemo(() => {
    return queries.map((query) => query.data);
  }, [queries]);

  const wizardStepsWithData = useMemo(() => {
    return wizardSteps.map((step, index) => {
      return {
        ...step,
        data: queriesData[index],
      };
    });
  }, [queriesData, wizardSteps]);

  const isLoading = useMemo(
    () => queries.some((query) => query.isLoading),
    [queries]
  );

  return { steps: wizardStepsWithData, isLoading };
}

function getQueryFn(type: string, value?: string) {
  switch (type) {
    case 'objectSingle':
    case 'objectMultiple':
      return getQueryFnByObject(value);
    case 'period':
    case 'checkbox':
      return getQueryFnByCheckbox(value);
    case 'number':
      return getQueryFnByNumber(value);
    case 'detail':
      return;
    default:
      throw new Error(`Unknown type: ${type}`);
  }
}

function getQueryFnByObject(value?: string) {
  switch (value) {
    case 'event':
      return fetchEventCategories;
    case 'eventType':
      return fetchEventTypes;
    case 'userDoorDevice':
      return getUsersDoorsDevices;
    case 'column':
      return fetchAvailableColumns;
    default:
      throw new Error(`Unknown value: ${value}`);
  }
}

function getQueryFnByCheckbox(value?: string) {
  return () => [{ name: value }] as FilterCheckboxDataItem[];
}

function getQueryFnByNumber(value?: string) {
  return () => [{ name: value }] as FilterInputNumberDataItem[];
}

const getUsersDoorsDevices = async () => {
  const filterConditionsApi = new FilterConditionsApi(apiConfig());
  const usersAndGroups = await filterConditionsApi.getUsersAndGroups();
  const doorsAndGroups = await filterConditionsApi.getDoorsAndGroups();
  const devicesAndGroups = await filterConditionsApi.getDevicesAndGroups();
  return [usersAndGroups.data, doorsAndGroups.data, devicesAndGroups.data];
};

const fetchEventCategories = async () => {
  const customReportApi = new FilterConditionsApi(apiConfig());
  const { data } = await customReportApi.getEventCategories();
  return data;
};

const fetchEventTypes = async () => {
  const customReportApi = new FilterConditionsApi(apiConfig());
  const { data } = await customReportApi.getEventTypes();

  return data;
};

const fetchAvailableColumns = async () => {
  const api = new ReportsApi(apiConfig());
  const { data } = await api.getCustomReportSupportedColumns();

  return data;
};
