import { MenuBar } from '../../menu-bar';
import styles from './index.module.css';

export const StandardLayout: React.FC = ({ children }) => {
  return (
    <div className={styles.layoutWrapper}>
      <MenuBar />
      <div className={styles.layout}>{children}</div>
    </div>
  );
};
