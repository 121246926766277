import { Box } from '@mui/system';
import { useTranslation } from 'core/context/i18n.context';
import { useFormContext } from 'react-hook-form';
import { Card } from 'shared/components/card';
import { CardTitle } from 'shared/components/card-title';
import { InputError } from 'shared/components/input-error';
import { InputLabel } from 'shared/components/input-label';
import { InputRow } from 'shared/components/input-row';
import { InputWrapper } from 'shared/components/input-wrapper';
import { TextField } from 'shared/components/inputs/text-field';

export const InformationSection: React.FC = () => {
  const { t } = useTranslation();
  const {
    register,
    formState: { errors },
  } = useFormContext();

  return (
    <Card>
      <CardTitle>{t('reportApp.information')}</CardTitle>
      <Box p="24px" display="flex" flexDirection="column" paddingLeft="120px">
        <InputWrapper flexDirection="column" alignItems="flex-start">
          <InputRow>
            <InputLabel display="flex" width="150px" showBullet>
              {t('reportApp.scheduleName')}
            </InputLabel>
            <Box maxWidth="450px" width="100%">
              <TextField
                name="scheduleName"
                inputProps={{
                  ...register('scheduleName', {
                    required: {
                      value: true,
                      message: t('reportApp.fieldRequired'),
                    },
                  }),
                  autoComplete: 'off',
                }}
              />
            </Box>
          </InputRow>
          <InputError pl="166px">{errors.scheduleName?.message}</InputError>
        </InputWrapper>
      </Box>
    </Card>
  );
};
