import { ErrorResponseDto, ReportTemplatesApi } from 'api-client';
import axios from 'axios';
import { useMutation } from 'react-query';
import { apiConfig } from 'shared/utils/api-config';

export function useRemoveReportTemplate() {
  const api = new ReportTemplatesApi(apiConfig());

  return useMutation<void, ErrorResponseDto, number>((id: number) =>
    api
      .removeReportTemplate(id)
      .then((res) => res.data)
      .catch((error) => {
        if (axios.isAxiosError(error)) {
          throw error.response!.data;
        }
        throw error;
      })
  );
}
