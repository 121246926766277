import { RouteObjectExtended } from 'core/types';
import { GenerateReportDetailPage } from './pages/generate-report-detail';
import { GenerateReportPage } from './pages/generate-report';
import { GenerateReportNewPage } from './pages/generate-report-new';
import { GenerateReportLayout } from './components/generate-report-layout';

export const generateReportRoutes: RouteObjectExtended[] = [
  {
    path: '',
    element: <GenerateReportLayout />,
    children: [
      {
        path: '',
        element: <GenerateReportPage />,
      },
      {
        path: 'new',
        element: <GenerateReportNewPage />,
      },
      {
        path: 'reports/:reportId',
        element: <GenerateReportDetailPage />,
      },
    ],
  },
];
