import React, { useEffect, useState } from 'react';
import { TreeView, TreeItem, TreeItemProps, treeItemClasses } from '@mui/lab';
import styles from './index.module.css';
import { styled } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import { PlainSearchField } from 'shared/components/inputs/plain-search-field';
import { useReportMenu } from 'generate-report/hooks/use-report-menu';
import { useSearchItems } from 'shared/hooks/use-search-items';
import { CircularProgress } from '@mui/material';
import { useTranslation } from 'core/context/i18n.context';
import {
  ErrorResponseDto,
  ReportMenuItemDto,
  ReportMenuItemDtoType,
} from 'api-client';
import { DraggableTreeItem, DragItem } from '../draggable-tree-item';
import { useReportMenuMutation } from 'generate-report/hooks/use-report-menu-mutation';
import { ContextMenu } from 'shared/components/context-menu';
import { ContextMenuItem } from 'shared/components/context-menu-item';
import { useContextMenu } from 'shared/hooks/use-context-menu';
import { useRenameContext } from 'generate-report/context/rename.context';
import { ConfirmDialog } from 'shared/components/dialogs/confirm-dialog';
import { useUpdateReportTemplate } from 'shared/hooks/use-update-report-template';
import { useErrorDialog } from 'shared/contexts/error-dialog.context';
import { useRemoveReportTemplate } from 'generate-report/hooks/use-remove-report-template';

const CustomTreeItem = styled(TreeItem)<TreeItemProps>(() => ({
  [`& .${treeItemClasses.group}`]: {
    marginLeft: 0,
  },
  [`& .${treeItemClasses.content}`]: {
    boxSizing: 'border-box',
    height: '35px',
    [`& .${treeItemClasses.label}`]: {
      fontSize: '12px',
      lineHeight: '1.2',
    },
  },
}));

const CollapseIcon = () => <div className={styles.collapseIcon} />;
const ExpandIcon = () => <div className={styles.expandIcon} />;

export const GenerateReportSidebar: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const reportMenu = useReportMenu();
  const reportMenuMutation = useReportMenuMutation();
  const { setItems, onSearch, filteredItems } =
    useSearchItems<ReportMenuItemDto>();
  const { setIsRename } = useRenameContext();
  const contextMenu = useContextMenu();
  const updateReportTemplate = useUpdateReportTemplate();
  const removeReportTemplate = useRemoveReportTemplate();
  const { showErrorDialog } = useErrorDialog();

  const [removeTemplateDialogOpen, setRemoveTemplateDialogOpen] =
    useState<boolean>(false);
  const openDialogRemoveTemplate = () => setRemoveTemplateDialogOpen(true);
  const closeDialogRemoveTemplate = () => setRemoveTemplateDialogOpen(false);

  useEffect(() => {
    setItems(reportMenu.data?.items || []);
  }, [reportMenu.data, setItems]);

  const handleSearch: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    onSearch(event.target.value);
  };

  const navigateToNewReportByType = (originalId: number) =>
    navigate(`/report/generate-report/new?predefinedReportId=${originalId}`);

  const navigateToNewReportByTemplate = (originalId: number) =>
    navigate(`/report/generate-report/new?reportTemplateId=${originalId}`);

  const navigateToNewCustomReport = () =>
    navigate('/report/generate-report/new?customReport=true');

  const checkIsParent = (subtype?: string) => subtype === 'parent';

  const treeItemNavigate = (node: ReportMenuItemDto) => () => {
    if (checkIsParent(node.subtype)) {
      return undefined;
    }

    if (node.type === ReportMenuItemDtoType.PredefinedReport) {
      return navigateToNewReportByType(node.originalId);
    }

    if (node.type === ReportMenuItemDtoType.ReportTemplate) {
      return navigateToNewReportByTemplate(node.originalId);
    }

    if (node.type === ReportMenuItemDtoType.Event) {
      return navigateToNewCustomReport();
    }
    return undefined;
  };

  const handleSaveTreeStatus = (
    e: React.SyntheticEvent,
    expandedNodeIds: string[]
  ) => {
    reportMenuMutation.menu.mutate({ ids: expandedNodeIds });
  };

  const handleRenameReportTemplate = () => {
    setIsRename(contextMenu.valueRename);
    contextMenu.closeContextMenu();
    setTimeout(() => {
      document.getElementById('input-edit-report-template')?.focus();
    }, 0);
  };

  const handleFinishRenameReportTemplate = async (value: string) => {
    if (!contextMenu.menuId) return;
    updateReportTemplate.mutate(
      {
        id: contextMenu.menuId,
        data: { name: value },
      },
      {
        onSuccess: () => {
          reportMenu.refetch();
        },
        onError: (error) => {
          showErrorDialog({
            alertType: 'error',
            message: t('reportApp.' + error.code, [value]),
          });
        },
      }
    );
  };

  const handleRemoveReportTemplate = () => {
    contextMenu.closeContextMenu();
    openDialogRemoveTemplate();
  };

  const handleFinishRemoveReportTemplate = async () => {
    if (!contextMenu.menuId) return;

    if (contextMenu.isCategoryWidget && contextMenu.isUnusedReport) {
      closeDialogRemoveTemplate();
      return showErrorDialog({
        alertType: 'error',
        message: t('reportApp.errorCategoryWidget'),
      });
    }
    try {
      await removeReportTemplate.mutateAsync(contextMenu.menuId);
      await reportMenu.refetch();
      closeDialogRemoveTemplate();
    } catch (error) {
      showErrorDialog({
        alertType: 'error',
        message: t('reportApp.' + (error as ErrorResponseDto).code),
      });
      closeDialogRemoveTemplate();
    }
  };

  const addNewLines = (str: string) => {
    if (str.length >= 26) {
      let result = '';
      while (str.length > 0) {
        result += str.substring(0, 26) + '\n';
        str = str.substring(26);
      }
      return result;
    }
    return str;
  };

  const renderTree = (node: ReportMenuItemDto, iteration = 1) => {
    return (
      <CustomTreeItem
        key={node.id}
        nodeId={node.id}
        className={styles.treeItem}
        label={node.name}
        onClick={treeItemNavigate(node)}
        sx={{
          [`& .MuiTreeItem-content`]: {
            paddingLeft: `${8 * iteration}px`,
          },
        }}
      >
        {Array.isArray(node.children)
          ? node.children.map((n: any) =>
              renderTree(n as ReportMenuItemDto, iteration + 1)
            )
          : null}
      </CustomTreeItem>
    );
  };

  const renderLoading = () => {
    return (
      <div className={styles.loadingWrapper}>
        <CircularProgress />
      </div>
    );
  };

  const moveItem = (from: number, to: number) => {
    setItems((items: ReportMenuItemDto[]) => {
      const newItems = JSON.parse(JSON.stringify(items));
      const savedReports = newItems[0].children;
      savedReports.splice(to, 0, ...savedReports.splice(from, 1));

      return newItems;
    });
  };

  const onDropItem = (item: DragItem<ReportMenuItemDto>) => {
    reportMenuMutation.order.mutate({
      itemId: item.dataSource.originalId,
      toIndex: item.index,
    });
  };

  const renderDraggableTreeItem = (node: any) => (
    <CustomTreeItem
      key={node.id}
      nodeId={node.id}
      className={styles.treeItem}
      label={node.name}
      onClick={treeItemNavigate(node)}
    >
      {Array.isArray(node.children)
        ? node.children.map((n: ReportMenuItemDto, index: number) => {
            const nodeName = n.name.startsWith('Custom Report')
              ? addNewLines(n.name)
              : n.name;
            return (
              <DraggableTreeItem
                key={n.id}
                nodeId={n.type + n.id}
                label={nodeName}
                index={index}
                dataSource={n}
                onDropItem={onDropItem}
                moveItem={moveItem}
                onClick={treeItemNavigate(n)}
                sx={{
                  [`& .MuiTreeItem-content`]: {
                    paddingLeft: `16px`,
                  },
                }}
                onContextMenu={(event) => {
                  contextMenu.openContextMenu(n.originalId, n.name, event);
                  contextMenu.setValueRename(n.name);
                }}
                onRenameFinish={handleFinishRenameReportTemplate}
              />
            );
          })
        : null}
      {node.subtype === 'parent' && !node.children?.length && (
        <div className={styles.savedReportsEmpty}>
          {t('reportApp.savedReportsEmpty')}
        </div>
      )}
    </CustomTreeItem>
  );

  return (
    <div className={styles.sideBar}>
      <ContextMenu
        open={contextMenu.isOpen}
        onClose={contextMenu.closeContextMenu}
        anchorReference="anchorPosition"
        anchorPosition={contextMenu.anchorPosition}
      >
        <div className={styles.contextMenuItemWrapper}>
          <ContextMenuItem onClick={handleRenameReportTemplate}>
            {t('reportApp.renameSavedReport')}
          </ContextMenuItem>
          <ContextMenuItem onClick={handleRemoveReportTemplate}>
            {t('reportApp.deleteSavedReport')}
          </ContextMenuItem>
        </div>
      </ContextMenu>
      <ConfirmDialog
        message={t('reportApp.confirmContinueDeleting')}
        open={removeTemplateDialogOpen}
        onClose={closeDialogRemoveTemplate}
        onOk={handleFinishRemoveReportTemplate}
        disableBackdropClick
        disableEscapeKeyDown
      />
      {reportMenu.isLoading && renderLoading()}
      {reportMenu.isSuccess && (
        <div className={styles.sideBarContent}>
          <PlainSearchField handleSearch={handleSearch} />
          <div className={styles.horizontalDivider}></div>
          {!!filteredItems.length && (
            <div className={styles.menuWrapper}>
              <TreeView
                defaultCollapseIcon={<CollapseIcon />}
                defaultExpandIcon={<ExpandIcon />}
                className={styles.treeView}
                disableSelection={true}
                defaultExpanded={reportMenu.data?.expandedItems}
                onNodeToggle={handleSaveTreeStatus}
              >
                {filteredItems.map((item: ReportMenuItemDto) => {
                  if (item.type === 'reportTemplate') {
                    return renderDraggableTreeItem(item);
                  }

                  return [renderTree(item)];
                })}
              </TreeView>
            </div>
          )}
          {!filteredItems.length && (
            <span className={styles.info}>
              {t('reportApp.reportTypeNotFound')}
            </span>
          )}
        </div>
      )}
    </div>
  );
};
