import { ReportMenuDto, UserInterfacesApi } from 'api-client';
import { useMemo } from 'react';
import { useQuery } from 'react-query';
import { apiConfig } from 'shared/utils/api-config';

export function useReportMenu() {
  const userInterfacesApi = new UserInterfacesApi(apiConfig());

  const fetchReportMenu = async () => {
    const { data } = await userInterfacesApi.getReportMenu();
    return data;
  };

  const query = useQuery('reportTypeMenu', fetchReportMenu);

  const data = useMemo(() => {
    if (!query.data) return undefined;

    const result: ReportMenuDto = {
      items: query.data.items,
      expandedItems: query.data.expandedItems,
    };
    return result;
  }, [query.data]);

  return { ...query, data };
}
