/* tslint:disable */
/* eslint-disable */
/**
 * BioStar 2 Report API
 * API Documentation for BioStar 2 Report
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @interface PdfOptionsDto
 */
export interface PdfOptionsDto {
    /**
     * 
     * @type {boolean}
     * @memberof PdfOptionsDto
     */
    showTitleOnEveryPage?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PdfOptionsDto
     */
    showHeader?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PdfOptionsDto
     */
    showHeaderOnEveryPage?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PdfOptionsDto
     */
    showPageNumber?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PdfOptionsDto
     */
    pageSize: PdfOptionsDtoPageSize;
}

/**
    * @export
    * @enum {string}
    */
export enum PdfOptionsDtoPageSize {
    A4 = 'a4'
}



