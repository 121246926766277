import { useTranslation } from 'core/context/i18n.context';
import { ButtonPrimary } from 'shared/components/buttons/button-primary';
import { BaseDialog, BaseDialogProps } from '../base-dialog';
import styles from './index.module.css';
import information from 'core/assets/information.png';

export interface SuccessDialogProps
  extends Omit<BaseDialogProps, 'title' | 'actions'> {
  message: string;
}

export const SuccessDialog: React.FC<SuccessDialogProps> = ({
  message,
  ...props
}) => {
  const { t } = useTranslation();

  const Actions = () => {
    return (
      <>
        <ButtonPrimary
          title={t('reportApp.ok')}
          size="small"
          className={styles.actionButton}
          onClick={props.onClose}
        ></ButtonPrimary>
      </>
    );
  };

  return (
    <BaseDialog
      {...props}
      title={t('reportApp.information')}
      actions={<Actions />}
      minHeight={214}
    >
      <div className={styles.wrapper}>
        <img
          src={information}
          alt={t('reportApp.information')}
          className={styles.informationIcon}
        />
        <p className={styles.message}>{message}</p>
      </div>
    </BaseDialog>
  );
};
