import { EditScheduleDto, SchedulesApi } from 'api-client';
import axios from 'axios';
import { useMutation } from 'react-query';
import { apiConfig } from 'shared/utils/api-config';

export function useEditSchedule() {
  const api = new SchedulesApi(apiConfig());

  return useMutation(({ id, dto }: { id: number; dto: EditScheduleDto }) =>
    api
      .editSchedule(id, dto)
      .then((res) => res.data)
      .catch((error) => {
        if (axios.isAxiosError(error)) {
          throw error.response!.data;
        }
        throw error;
      })
  );
}
