import { useTranslation } from 'core/context/i18n.context';
import { ViewImageBtn } from 'dashboard/components/view-image-button';
import { RealTimeEventsFilterCondition } from 'dashboard/types/filter-condition.type';
import { format, utcToZonedTime } from 'date-fns-tz';
import { useCallback, useMemo, useState } from 'react';
import { useDatetimePreference } from 'shared/hooks/use-datetime-preference';
import { useIpAddress } from './use-ip-address';
import { useStartEvents } from './use-start-events';
import { useStopEvents } from './use-stop-events';

export function useRealTimeEvents() {
  const { t } = useTranslation();
  const sessionId = sessionStorage.getItem('session');
  const datetimePreference = useDatetimePreference();
  const startEvents = useStartEvents();
  const stopEvents = useStopEvents();
  const ipAddress = useIpAddress();

  const [realTimeEventsStart, setRealTimeEventsStart] =
    useState<boolean>(false);
  const [isPause, setIsPause] = useState<boolean>(false);
  const [events, setEvents] = useState<any[]>([]);
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [user, setUser] = useState<any>();
  const [dialogTitle, setDialogTitle] = useState<string>('');
  const [eventDatetime, setDatetime] = useState<string>('');
  const [deviceName, setDeviceName] = useState<string>('');
  const [imageLog, setImageLog] = useState();

  // const getIpAddress = useMemo(() => {
  //   if (!ipAddress.isLoading && ipAddress.status === 'success') {
  //     return ipAddress.data.hostname;
  //   }

  //   return 'localhost';
  // }, [ipAddress]);

  const getHostname = useMemo(() => window.location.hostname, []);

  const getPort = useMemo(() => {
    if (!ipAddress.isLoading && ipAddress.status === 'success') {
      return ipAddress.data.port;
    }

    return '443';
  }, [ipAddress]);

  const wsURL = useMemo(() => {
    return `wss://${getHostname}:${getPort}/wsapi`;
  }, [getHostname, getPort]);

  const ws = useMemo(() => new WebSocket(wsURL), [wsURL]);

  const responseType = useCallback((event: any) => {
    if (event.Response) {
      return 'response';
    }

    if (event.Alert) {
      return 'alert';
    }

    return 'event';
  }, []);

  const normalizedEventData = (
    filterCondition: RealTimeEventsFilterCondition,
    data: any
  ) => {
    const { events, users, doors, devices } = filterCondition;
    let filteredEvents: any[];

    const filteredByEvents = data.filter((event: any) =>
      events.includes(event.event_type_id.code)
    );
    filteredEvents = filteredByEvents;

    if (!users.length && !doors.length && !devices.length) {
      return transformEvents(filteredEvents);
    }

    if (!!users.length) {
      const reformatUserIds = users.map((user) => {
        const dashIndex = (user || '').indexOf('-');
        const transformId = (user || '').substring(dashIndex + 1);
        return transformId;
      });
      const filteredByUsers = filteredEvents.filter((event: any) =>
        !!event.user_id
          ? reformatUserIds.includes(event.user_id.user_id)
          : false
      );

      filteredEvents = filteredByUsers;
    }

    if (!!doors.length) {
      const reformatDoorIds = doors.map((door) => {
        const dashIndex = (door || '').indexOf('-');
        const transformId = (door || '').substring(dashIndex + 1);
        return transformId;
      });

      const eventsDoors = filteredEvents.filter(
        (event: any) => !!event.door_id_list && !!event.door_id_list.length
      );

      const filteredByDoors = eventsDoors.filter((event: any) => {
        const [door] = event.door_id_list;
        return !!door ? reformatDoorIds.includes(door.id) : false;
      });

      filteredEvents = filteredByDoors;
    }

    if (!!devices.length) {
      const reformatDeviceIds = devices.map((device) => {
        const dashIndex = (device || '').indexOf('-');
        const transformId = (device || '').substring(dashIndex + 1);
        return transformId;
      });
      const filteredByDevices = filteredEvents.filter((event: any) =>
        !!event.device_id
          ? reformatDeviceIds.includes(event.device_id.id)
          : false
      );

      filteredEvents = filteredByDevices;
    }

    return transformEvents(filteredEvents);
  };

  const handleOpenDialog = (
    user: any,
    title: string,
    datetime: string,
    deviceName: string,
    image: any
  ) => {
    setUser(user);
    setDialogTitle(title);
    setDatetime(datetime);
    setDeviceName(deviceName);
    setImageLog(image);
    setOpenDialog(true);
  };
  const handleCloseDialog = () => setOpenDialog(false);

  const transformEvents = (data: any) => {
    return data.map((d: any) => {
      const datetime = !!d.datetime
        ? formatDatetime(d.datetime)
        : formatDatetime(new Date().toDateString());
      const image = !!d.image_id && d.image_id;

      return {
        event: t(`eventType.${d.event_type_id.code}`),
        device: d.device_id.name,
        deviceId: d.device_id.id,
        door: (d.door_id_list || []).map((door: any) => door.name).join(', '),
        user: !!d.user_id ? d.user_id.name : '',
        datetime,
        temperature: d.temperature,
        taKey: d.tna_key,
        elevator: !!d.elevator_id ? d.elevator_id.name : '',
        view: !!d.user_id && !!image && (
          <ViewImageBtn
            onClick={() =>
              handleOpenDialog(
                d.user_id,
                t(`eventType.${d.event_type_id.code}`),
                datetime,
                d.device_id.name,
                image
              )
            }
          />
        ),
      };
    });
  };

  const startRealTimeEvents = useCallback(() => {
    if (!ipAddress.isLoading && !!ws) {
      ws.onopen = async () => {
        ws.send(`bs-session-id=${sessionId}`);
        if (!realTimeEventsStart) {
          setRealTimeEventsStart(true);
          await startEvents.mutateAsync();
        }
      };

      ws.onmessage = (event) => {
        if (!!event) {
          const eventData = JSON.parse(event.data);
          const type = responseType(eventData);
          if (type === 'event') {
            const { Event } = eventData;

            if (!!Event) {
              setEvents((prev: any[]) => [Event, ...prev]);
            }
          }
        }
      };

      ws.onclose = (event) => {
        setTimeout(() => {
          startRealTimeEvents();
        }, 5000);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ws, ipAddress.isLoading, getPort, realTimeEventsStart, isPause]);

  const onClickPlayButton = async () => {
    setIsPause(false);
    setRealTimeEventsStart(true);
    await startEvents.mutateAsync();
  };

  const stopRealTimeEvents = useCallback(async () => {
    if (realTimeEventsStart) {
      if (!ipAddress.isLoading && !!ws) {
        setRealTimeEventsStart(false);
        await stopEvents.mutateAsync();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [realTimeEventsStart]);

  const clearEvents = () => setEvents([]);

  const formatDatetime = (datetime: string) => {
    const zonedDatetime = utcToZonedTime(
      datetime,
      datetimePreference?.data?.timezone!
    );
    const resultDatetime = format(
      zonedDatetime,
      datetimePreference?.data?.datetimeFormat!
    );

    return resultDatetime;
  };

  const onClickPauseButton = () => {
    setIsPause(true);
  };

  return {
    startRealTimeEvents,
    stopRealTimeEvents,
    events,
    clearEvents,
    realTimeEventsStart,
    normalizedEventData,
    onClickPlayButton,
    onClickPauseButton,
    isPause,
    openDialog,
    dialogData: {
      image: imageLog,
      user,
      dialogTitle,
      datetime: eventDatetime,
      deviceName,
    },
    handleCloseDialog,
  };
}
