import { WidgetsApi } from 'api-client';
import axios from 'axios';
import { useMutation } from 'react-query';
import { apiConfig } from 'shared/utils/api-config';

export function useDoorClearAlarm() {
  const api = new WidgetsApi(apiConfig());

  return useMutation(async (doorId: any) => {
    try {
      await api.doorClearAlarm(doorId);
    } catch (error) {
      if (axios.isAxiosError(error)) {
        throw error.response!.data;
      }
      throw error;
    }
  });
}
