/* tslint:disable */
/* eslint-disable */
/**
 * BioStar 2 Report API
 * API Documentation for BioStar 2 Report
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { AuthTokenDto } from '../models';
// @ts-ignore
import { CreateAuthTokenDto } from '../models';
// @ts-ignore
import { ErrorResponseDto } from '../models';
// @ts-ignore
import { LoginDto } from '../models';
// @ts-ignore
import { UserDto } from '../models';
/**
 * AuthApi - axios parameter creator
 * @export
 */
export const AuthApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * You can get the user id and session by login in BioStar 2
         * @summary Create auth token from logged-in BioStar 2 user and get back the result
         * @param {CreateAuthTokenDto} createAuthTokenDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAuthToken: async (createAuthTokenDto: CreateAuthTokenDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'createAuthTokenDto' is not null or undefined
            assertParamExists('createAuthToken', 'createAuthTokenDto', createAuthTokenDto)
            const localVarPath = `/api/report/auth/auth-token`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createAuthTokenDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get authenticated user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCurrentAuthUser: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/report/auth/me`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Login to get a token that can be used for authentication
         * @param {LoginDto} loginDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        login: async (loginDto: LoginDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'loginDto' is not null or undefined
            assertParamExists('login', 'loginDto', loginDto)
            const localVarPath = `/api/report/auth/login`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(loginDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AuthApi - functional programming interface
 * @export
 */
export const AuthApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AuthApiAxiosParamCreator(configuration)
    return {
        /**
         * You can get the user id and session by login in BioStar 2
         * @summary Create auth token from logged-in BioStar 2 user and get back the result
         * @param {CreateAuthTokenDto} createAuthTokenDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createAuthToken(createAuthTokenDto: CreateAuthTokenDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AuthTokenDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createAuthToken(createAuthTokenDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get authenticated user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCurrentAuthUser(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCurrentAuthUser(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Login to get a token that can be used for authentication
         * @param {LoginDto} loginDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async login(loginDto: LoginDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AuthTokenDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.login(loginDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AuthApi - factory interface
 * @export
 */
export const AuthApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AuthApiFp(configuration)
    return {
        /**
         * You can get the user id and session by login in BioStar 2
         * @summary Create auth token from logged-in BioStar 2 user and get back the result
         * @param {CreateAuthTokenDto} createAuthTokenDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAuthToken(createAuthTokenDto: CreateAuthTokenDto, options?: any): AxiosPromise<AuthTokenDto> {
            return localVarFp.createAuthToken(createAuthTokenDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get authenticated user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCurrentAuthUser(options?: any): AxiosPromise<UserDto> {
            return localVarFp.getCurrentAuthUser(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Login to get a token that can be used for authentication
         * @param {LoginDto} loginDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        login(loginDto: LoginDto, options?: any): AxiosPromise<AuthTokenDto> {
            return localVarFp.login(loginDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AuthApi - object-oriented interface
 * @export
 * @class AuthApi
 * @extends {BaseAPI}
 */
export class AuthApi extends BaseAPI {
    /**
     * You can get the user id and session by login in BioStar 2
     * @summary Create auth token from logged-in BioStar 2 user and get back the result
     * @param {CreateAuthTokenDto} createAuthTokenDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public createAuthToken(createAuthTokenDto: CreateAuthTokenDto, options?: any) {
        return AuthApiFp(this.configuration).createAuthToken(createAuthTokenDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get authenticated user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public getCurrentAuthUser(options?: any) {
        return AuthApiFp(this.configuration).getCurrentAuthUser(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Login to get a token that can be used for authentication
     * @param {LoginDto} loginDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public login(loginDto: LoginDto, options?: any) {
        return AuthApiFp(this.configuration).login(loginDto, options).then((request) => request(this.axios, this.basePath));
    }
}
