import { useRef, useEffect } from 'react';
import { alpha, styled } from '@mui/material/styles';
import { InputBase } from '@mui/material';
import { useClickOutside } from 'generate-report/hooks/use-click-outside';
import { useRenameContext } from 'generate-report/context/rename.context';
import { useFinishOnEnter } from 'generate-report/hooks/use-finish-on-enter';
import { useErrorDialog } from 'shared/contexts/error-dialog.context';
import { useTranslation } from 'core/context/i18n.context';
import { useForm } from 'react-hook-form';

interface InputRenameProps {
  id: string;
  label: string;
  onRename: (value: string) => void;
}

export const InputRename: React.FC<InputRenameProps> = ({
  id,
  label,
  onRename,
}) => {
  const { t } = useTranslation();
  const containerRef = useRef<any>(null);
  const { setIsRename } = useRenameContext();
  const { showErrorDialog } = useErrorDialog();
  const { register, setValue, handleSubmit, getValues } = useForm();

  const handleFinishEdit = handleSubmit(() => {
    const renameTitle = getValues('rename');
    if (renameTitle.length > 52) {
      showErrorDialog({
        alertType: 'warning',
        message: t('reportApp.titleMaxLength'),
      });
      return;
    }

    onRename(renameTitle);
    setIsRename('');
  });

  useEffect(() => {
    setValue('rename', label);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [label]);

  useClickOutside(containerRef, handleFinishEdit);
  useFinishOnEnter(handleFinishEdit);

  const RenameInput = styled(InputBase)(({ theme }) => ({
    'label + &': {
      marginTop: theme.spacing(3),
    },
    '& .MuiInputBase-input': {
      position: 'relative',
      border: '2px solid #ebebeb',
      fontSize: 12,
      width: 'auto',
      padding: '6px 10px',
      marginLeft: '28px',
      transition: theme.transitions.create([
        'border-color',
        'background-color',
        'box-shadow',
      ]),
      fontFamily: ['Roboto', 'Helvetica', 'Arial', 'sans-serif'].join(','),
      '&:focus': {
        boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
        borderColor: theme.palette.primary.main,
      },
    },
  }));

  const ShowInputRename = () => (
    <form onSubmit={handleFinishEdit}>
      <RenameInput
        name="rename"
        id={id}
        inputProps={{
          ...register('rename', {
            required: true,
          }),
        }}
        autoFocus
      />
    </form>
  );

  return (
    <div ref={containerRef}>
      <ShowInputRename />
    </div>
  );
};
