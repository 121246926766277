import { TreeItem, TreeItemProps, TreeView, treeItemClasses } from '@mui/lab';
import { styled } from '@mui/material/styles';
import classNames from 'classnames';
import { useTranslation } from 'core/context/i18n.context';
import { useClickOutside } from 'generate-report/hooks/use-click-outside';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import {
  ObjectFilterDataNestedNode,
  ObjectFilterDataNode,
} from 'shared/components/filter-conditions/filter-object/type';
import { useSearch } from 'shared/hooks/use-search';
import { FlatToNested } from 'shared/utils/flat-to-nested';
import { flattenNestedChildren } from 'shared/utils/flatten-nested-children';
import styles from './index.module.css';
import { SearchFieldWithMask } from '../search-field-with-mask';

export interface SelectFieldDataSourceItem {
  id: string;
  name: string;
}

interface SelectFieldProps {
  placeholder?: string;
  name: string;
  required?: boolean;
  hint?: string;
  error?: string;
  wrapperProps?: React.HTMLAttributes<HTMLDivElement>;
  selectProps?: React.InputHTMLAttributes<HTMLInputElement>;
  dataSource?: ObjectFilterDataNestedNode[];
  styleType?: 'normal' | 'emboss';
  className?: string;
  onChange: (value: any) => void;
  id: string;
}

const CustomTreeItem = styled(TreeItem)<TreeItemProps>(() => ({
  [`& .${treeItemClasses.group}`]: {
    marginLeft: 0,
  },
  [`& .${treeItemClasses.content}`]: {
    boxSizing: 'border-box',
    height: '35px',
    borderBottom: '1px solid #ebebeb',
    [`& .${treeItemClasses.label}`]: {
      fontSize: '12px',
      lineHeight: '1.2',
    },
  },
  [`& .${treeItemClasses.content}:hover`]: {
    backgroundColor: '#f7f2c0',
  },
}));

const CollapseIcon = () => <div className={styles.collapseIcon} />;
const ExpandIcon = () => <div className={styles.expandIcon} />;

export const SelectFieldExpandable: React.FC<SelectFieldProps> = ({
  placeholder,
  name,
  required,
  hint,
  error,
  wrapperProps,
  selectProps,
  dataSource,
  styleType = 'normal',
  className,
  onChange,
  id,
}) => {
  const { t } = useTranslation();
  const [isActive, setIsActive] = useState(false);
  const [selected, setSelected] = useState<string>('');
  const containerRef = useRef<any>(null);
  const flatToNested = new FlatToNested();

  const onClick = () => {
    if (!!isActive) {
      setSearchValue('');
    }

    setIsActive((prev) => !prev);
  };
  const handleCloseDropdown = () => {
    setSearchValue('');
    setIsActive(false);
  };
  const onSelect = (item: ObjectFilterDataNestedNode, e?: React.MouseEvent) => {
    onChange(item);
    setSelected(item.name);
    handleCloseDropdown();
  };

  const dataFlatten: ObjectFilterDataNode[] = useMemo(() => {
    const data = flattenNestedChildren(
      dataSource || []
    ) as ObjectFilterDataNode[];
    return data;
  }, [dataSource]);

  const dataNested: ObjectFilterDataNestedNode[] = useMemo(
    () => flatToNested.convert(dataFlatten),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dataFlatten]
  );

  const expandedItems = useMemo(() => {
    const filtered = dataFlatten.filter((data: any) => !!data.children);
    return filtered.map((data) => data.id);
  }, [dataFlatten]);

  const {
    data: dataSearched,
    setSearchValue,
    searchValue,
  } = useSearch(dataNested, {
    fieldNames: { text: 'name' },
  });

  const onSearchChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    const { value } = e.target;
    setSearchValue(value);
  };

  const isNotFound = useMemo(() => {
    const searchInput = document.getElementById(`search-field-${id}`);
    return (
      searchInput?.focus && searchValue.length > 0 && !dataSearched?.length
    );
  }, [dataSearched, id, searchValue.length]);

  const flattenDataSearched = useMemo(() => {
    if (!!dataSearched && !isNotFound) {
      const data = flattenNestedChildren(dataSearched);
      const filtered = data.filter(
        (d) => !d.id.toLowerCase().includes('group')
      );
      return filtered;
    }
  }, [dataSearched, isNotFound]);

  useEffect(() => {
    if (!name.startsWith('categories.')) {
      const defaultValue = !selectProps?.value
        ? selectProps?.defaultValue
        : selectProps.value;

      if (!!defaultValue) {
        const data = dataFlatten.filter(
          (d) => !!d.lastChild && d.originalId === defaultValue
        );
        onSelect(data[0] as any);
      }
    } else {
      const value = selectProps?.value as any;
      if (!!value) {
        setSelected(value?.categoryName);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected]);

  const treeLabel = (icon: any, name: string) => (
    <div className={styles.labelWrapper}>
      {!!icon && icon}
      <span className={styles.label}>{name}</span>
    </div>
  );

  const renderTree = (node: ObjectFilterDataNestedNode, iteration = 0) => {
    return (
      <CustomTreeItem
        key={node.id}
        nodeId={node.id}
        label={treeLabel(node.icon, node.name)}
        onClick={!!node.lastChild ? () => onSelect(node) : undefined}
        sx={{
          [`& .MuiTreeItem-content`]: {
            paddingLeft: iteration === 0 ? '8px' : `${32 * iteration}px`,
          },
        }}
      >
        {Array.isArray(node.children)
          ? node.children.map((n: any) =>
              renderTree(n as ObjectFilterDataNestedNode, iteration + 1)
            )
          : null}
      </CustomTreeItem>
    );
  };

  useClickOutside(containerRef, handleCloseDropdown);

  return (
    <div
      ref={containerRef}
      {...wrapperProps}
      className={classNames(styles.fieldWrapper, wrapperProps?.className)}
    >
      <input
        {...selectProps}
        className={classNames(
          styles.field,
          selectProps?.className,
          { [styles.emboss]: styleType === 'emboss' },
          className
        )}
        onClick={onClick}
        placeholder={placeholder || t('reportApp.none')}
        onChange={() => {}}
        value={selected}
        readOnly={true}
      />
      {isActive && (
        <div className={styles.dropdownContent}>
          <SearchFieldWithMask
            className={styles.searchField}
            handleSearch={onSearchChange}
            id={`search-field-${id}`}
            showCloseSearch={isActive}
          />
          <div
            className={classNames({
              [styles.treeWrapper]: dataFlatten.length > 6,
            })}
          >
            {!!isNotFound && (
              <div className={styles.notFound}>{t('reportApp.notFound')}</div>
            )}
            {!isNotFound && !dataSearched && (
              <TreeView
                defaultCollapseIcon={<CollapseIcon />}
                defaultExpandIcon={<ExpandIcon />}
                className={styles.treeView}
                defaultExpanded={expandedItems}
              >
                {dataNested.map((data) => {
                  return [renderTree(data)];
                })}
              </TreeView>
            )}
            {!isNotFound && !!dataSearched && !!dataSearched.length && (
              <ul className={styles.searchResultWrapper}>
                {flattenDataSearched?.map((data) => (
                  <li
                    className={styles.searchResultItem}
                    key={data.id}
                    onClick={() => onSelect(data)}
                  >
                    {data.name}
                  </li>
                ))}
              </ul>
            )}
          </div>
        </div>
      )}
      <div
        className={classNames(styles.customSelectField, {
          [styles.arrowActive]: isActive,
        })}
        onClick={onClick}
      />
      {!!hint && !error && <span className={styles.hint}>{hint}</span>}
      {!!error && (
        <span className={classNames(styles.hint, styles.error)}>{error}</span>
      )}
    </div>
  );
};
