import { useTranslation } from 'core/context/i18n.context';
import { useNavigate } from 'react-router-dom';
import { ButtonSecondary } from 'shared/components/buttons/button-secondary';
import { useErrorDialog } from 'shared/contexts/error-dialog.context';
import styles from './index.module.css';

interface ActionsProps {
  disabledDeleteBtn?: boolean;
  onDelete?: () => void;
  bsAdminLoggedIn?: boolean;
  disabledAddBtn?: boolean;
}

export const Actions: React.FC<ActionsProps> = ({
  disabledDeleteBtn,
  onDelete,
  bsAdminLoggedIn,
  disabledAddBtn,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { showErrorDialog } = useErrorDialog();

  const onClickAddSchedule = () => {
    if (!bsAdminLoggedIn) {
      return showErrorDialog({
        alertType: 'error',
        message: t('reportApp.errorAddSchedule'),
      });
    }
    return navigate('new');
  };

  const handleDelete = () => {
    onDelete?.();
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.section}></div>

      <div className={styles.section}>
        <ButtonSecondary
          title={t('reportApp.addSchedule')}
          size="small"
          onClick={onClickAddSchedule}
          className={styles.actionButton}
          disabled={disabledAddBtn}
        />
        <ButtonSecondary
          title={t('reportApp.delete')}
          size="small"
          onClick={handleDelete}
          className={styles.actionButton}
          disabled={disabledDeleteBtn}
        />
      </div>
    </div>
  );
};
