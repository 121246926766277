import {
  FilterConditionDto,
  FilterConditionDtoKeyName,
  HeaderDto,
  HeaderDtoKeyName,
} from 'api-client';
import { WizardStepProps } from 'shared/components/wizard/index.type';
import { useEffect, useMemo, useState } from 'react';

export function useEditFilterHeader(
  reportConditions?: FilterConditionDto[],
  reportHeaders?: HeaderDto[]
) {
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const [headerKeyName, setHeaderKeyName] = useState<
    FilterConditionDtoKeyName | HeaderDtoKeyName | undefined
  >();

  const openDialog = (
    keyName: FilterConditionDtoKeyName | HeaderDtoKeyName
  ) => {
    setHeaderKeyName(keyName);
    setDialogOpen(true);
  };
  const closeDialog = () => setDialogOpen(false);

  // Delay the set header index while closing the dialog,
  // to avoid UI glitch.
  useEffect(() => {
    if (!dialogOpen && !!headerKeyName) {
      let delay = setTimeout(() => setHeaderKeyName(undefined), 500);
      return () => clearTimeout(delay);
    }
  }, [dialogOpen, headerKeyName]);

  const headerCondition = useMemo(() => {
    if (!headerKeyName || !reportConditions) {
      return undefined;
    }
    const [currentCondition] = reportConditions.filter(
      (rc) => rc.keyName === headerKeyName
    );
    return currentCondition;
  }, [headerKeyName, reportConditions]);

  // There will be only one step while editing a filter header.
  const filterStep = useMemo(() => {
    if (!headerCondition) {
      return undefined;
    }

    const data: WizardStepProps = {
      stepIndex: 0,
      type: headerCondition.type,
      value: headerCondition.keyName,
      id: headerCondition.id,
      rules: ['required'],
    };

    if (
      headerCondition.keyName === 'days' ||
      headerCondition.keyName === 'months'
    ) {
      data.rules.push('limit');
    }

    return data;
  }, [headerCondition]);

  // List of all conditions results for all headers.
  const conditionResults = useMemo(() => reportHeaders, [reportHeaders]);

  // Get list of condition result that related with the current header.
  const conditionResult = useMemo(() => {
    if (!headerKeyName || !conditionResults) {
      return [];
    }
    const result = conditionResults.find(
      (item) => item.keyName === (headerKeyName as unknown as HeaderDtoKeyName)
    );

    return result?.value || [];
  }, [conditionResults, headerKeyName]);

  return {
    dialogOpen,
    openDialog,
    closeDialog,
    filterStep,
    conditionResult,
    headerKeyName,
  };
}
