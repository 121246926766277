import { i18nConfig } from 'core/config/i18n.config';
import { useGetFallbackTranslationMessages } from 'core/hooks/use-get-fallback-translation-message';
import { useGetLanguage } from 'core/hooks/use-get-language';
import { useGetTranslationMessages } from 'core/hooks/use-get-translation-messages';
import React, { createContext, useContext, useMemo } from 'react';
import { ScreenLoading } from 'shared/components/screen-loading';
import { Translator } from 'translator/src';

interface ContextProps {
  activeLanguage: string;
  t: (translationKey: string, params?: (string | number)[]) => string;
  getLanguageFromLocalStorage: () => string | undefined;
  setLanguageToLocalStorage: (lang: string) => void;
  removeLanguageOnLocalStorage: () => void;
}

const TranslationContext = createContext<ContextProps>({
  activeLanguage: i18nConfig.fallbackLanguage,
  t: () => '',
  getLanguageFromLocalStorage: () => undefined,
  setLanguageToLocalStorage: () => {},
  removeLanguageOnLocalStorage: () => {},
});

export const I18nProvider: React.FC = ({ children }) => {
  const language = useGetLanguage();
  const translation = useGetTranslationMessages();
  const fallbackTranslation = useGetFallbackTranslationMessages();

  const isLoading = useMemo(
    () => language.isLoading || translation.isLoading,
    [language.isLoading, translation.isLoading]
  );

  function t(translationKey: string, params?: (string | number)[]) {
    if (!translation.data) {
      throw new Error('Translation messages is not available');
    }
    const translator = new Translator(translation.data);
    const translationResult = translator.translate(translationKey, params);

    if (
      !!fallbackTranslation.data &&
      translationResult
        .toLowerCase()
        .startsWith('missing translation message for key ')
    ) {
      const fallbackTranslator = new Translator(fallbackTranslation.data);
      return fallbackTranslator.translate(translationKey, params);
    }

    return translationResult;
  }

  function getLanguageFromLocalStorage() {
    return localStorage.getItem('language') || i18nConfig.fallbackLanguage;
  }

  function setLanguageToLocalStorage(lang: string) {
    localStorage.setItem('language', lang);
  }

  function removeLanguageOnLocalStorage() {
    localStorage.removeItem('language');
  }

  if (isLoading) {
    return <ScreenLoading />;
  }

  return (
    <TranslationContext.Provider
      value={{
        activeLanguage: language.data || i18nConfig.fallbackLanguage,
        t,
        getLanguageFromLocalStorage,
        setLanguageToLocalStorage,
        removeLanguageOnLocalStorage,
      }}
    >
      {children}
    </TranslationContext.Provider>
  );
};

export function useTranslation() {
  return useContext(TranslationContext);
}
