import { Box } from '@mui/system';
import { useTranslation } from 'core/context/i18n.context';
import React, { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { useGetReportSetting } from 'settings/hooks/use-get-report-setting';
import { Card } from 'shared/components/card';
import { CardTitle } from 'shared/components/card-title';
import { Info } from 'shared/components/info';
import { InputLabel } from 'shared/components/input-label';
import { InputWrapper } from 'shared/components/input-wrapper';
import { TextField } from 'shared/components/inputs/text-field';
import styles from './index.module.css';

export const ReportSetting: React.FC = () => {
  const { t } = useTranslation();
  const { register, setValue } = useFormContext();

  const reportSetting = useGetReportSetting();

  // Set initial value for form
  useEffect(() => {
    setValue('exportReportPath', reportSetting.data?.exportReportPath);
  }, [reportSetting.data, setValue]);

  // const [checked, setChecked] = useState(false);
  // const handleChecked = () => {
  //   setChecked(!checked);
  // };

  return (
    <Card>
      <CardTitle>{t('reportApp.report')}</CardTitle>

      <Box p="24px" display="flex" flexDirection="column" paddingLeft="120px">
        {/* <div className={styles.content}>
          <InputLabel showBullet={true}>
            {t('reportApp.companyLogo')}
          </InputLabel>
          <div className={styles.uploadBtnWrapper}>
            <label htmlFor="filePicker" className={styles.uploadLogo}>
              {t('reportApp.uploadLogoImage')}
            </label>
            <input
              id="filePicker"
              style={{ visibility: 'hidden' }}
              type={'file'}
            />
          </div>
        </div> */}
        <InputWrapper>
          <InputLabel className={styles.inputLabelWrapper} showBullet={true}>
            <div className={styles.inputLabel}>
              {t('reportApp.exportReportPath')}
            </div>
          </InputLabel>
          <Box maxWidth="400px" width="100%">
            <TextField
              name="exportReportPath"
              inputProps={{
                ...register('exportReportPath'),
                autoComplete: 'off',
              }}
            />
          </Box>
          <Box ml="4px">
            <Info
              message={t('reportApp.exportPathInfo').replace(/\\\\/g, '\\')}
            />
          </Box>
        </InputWrapper>
        {/* <div className={styles.content}>
          <InputLabel showBullet={true}>
            {t('reportApp.saveEveryReport')}
          </InputLabel>
          <FormControlLabel
            control={
              <SwitchButton onChange={handleChecked} checked={checked} />
            }
            label={t('reportApp.use')}
            className={styles.switchButton}
            disableTypography={true}
            sx={{ marginLeft: '38px' }}
          />
          <div className={styles.contentFormat}>
            <InputLabel showBullet={true}>{t('reportApp.format')}</InputLabel>
            <select className={styles.select} disabled={!checked}>
              <option></option>
            </select>
          </div>
        </div> */}
      </Box>
    </Card>
  );
};
