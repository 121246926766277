import { GenerateReportDtoType, ReportMenuItemDtoType } from 'api-client';
import { GenerateCustomReportNewPage } from './generate-custom-report';
import { useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import { GenerateReportByTemplate } from './generate-report-by-template';
import { GenerateReportByType } from './generate-report-by-type';

export const GenerateReportNewPage: React.FC = () => {
  const [searchParams] = useSearchParams();

  const reportTypeId = useMemo(
    () => searchParams.get('predefinedReportId') || undefined,
    [searchParams]
  );

  const reportTemplateId = useMemo(
    () => searchParams.get('reportTemplateId') || undefined,
    [searchParams]
  );

  const isCustomReport = useMemo(
    () => searchParams.get('customReport') || undefined,
    [searchParams]
  );

  const reportMenuType = useMemo(() => {
    if (reportTemplateId) return ReportMenuItemDtoType.ReportTemplate;
    if (reportTypeId) return ReportMenuItemDtoType.PredefinedReport;
    if (isCustomReport) return GenerateReportDtoType.CustomReport;
    return undefined;
  }, [isCustomReport, reportTemplateId, reportTypeId]);

  if (reportMenuType === ReportMenuItemDtoType.ReportTemplate) {
    return (
      <GenerateReportByTemplate
        reportTemplateId={reportTemplateId!}
      ></GenerateReportByTemplate>
    );
  }

  if (reportMenuType === ReportMenuItemDtoType.PredefinedReport) {
    return (
      <GenerateReportByType reportTypeId={reportTypeId!}></GenerateReportByType>
    );
  }

  if (reportMenuType === GenerateReportDtoType.CustomReport) {
    return <GenerateCustomReportNewPage></GenerateCustomReportNewPage>;
  }

  return <></>;
};
