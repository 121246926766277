import { ExportApi, ExportReportDto } from 'api-client';
import axios from 'axios';
import { useMutation, useQuery } from 'react-query';
import { apiConfig } from 'shared/utils/api-config';

export function useExportReport() {
  const exportApi = new ExportApi(apiConfig());

  const exportReportMutation = useMutation((exportReportDto: ExportReportDto) =>
    exportApi
      .exportReport(exportReportDto, { responseType: 'blob' })
      .then((res) => {
        const filename = res.headers['x-filename'];

        // Create blob link to download
        const url = window.URL.createObjectURL(new Blob([res.data as any]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', decodeURI(filename));

        // Append to html link element page
        document.body.appendChild(link);

        // Start download
        link.click();

        // Clean up and remove the link
        link.parentNode?.removeChild(link);
      })
      .catch((error) => {
        if (axios.isAxiosError(error)) {
          throw error.response!.data;
        }
        throw error;
      })
  );

  const fetchSupportedFileFormats = async () => {
    const { data } = await exportApi.getSupportedFileFormats();

    return data;
  };

  const fetchSupportedPageSizes = async () => {
    const { data } = await exportApi.getSupportedPageSizes();

    return data;
  };

  const supportedFileFormatsQuery = useQuery(
    'fileFormatsExport',
    fetchSupportedFileFormats
  );

  const supportedPageSizesQuery = useQuery(
    'pageSizesExport',
    fetchSupportedPageSizes
  );

  return {
    exportReportMutation,
    supportedFileFormatsQuery,
    supportedPageSizesQuery,
  };
}
