import { BsAdminApi } from 'api-client';
import { useQuery } from 'react-query';
import { apiConfig } from 'shared/utils/api-config';

export function useValidCredential(options: any = true) {
  const api = new BsAdminApi(apiConfig());

  return useQuery(
    ['checkValidAdminCredential'],
    async () => {
      const { data } = await api.checkValidAdminCredential();
      if (!data) {
        return undefined;
      }

      return data;
    },
    {
      suspense: options?.suspense,
    }
  );
}
