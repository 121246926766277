/**
 * Compare two string arrays to check if there is a difference.
 */
export function compareStringArrays(
  arrayOne: string[],
  arrayTwo: string[]
): boolean {
  const arrayOneDiffers = arrayOne.every((val) => arrayTwo.includes(val));
  const arrayTwoDiffers = arrayTwo.every((val) => arrayOne.includes(val));
  return arrayOneDiffers || arrayTwoDiffers;
}
