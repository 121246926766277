import { ColumnResponseDto } from 'api-client';
import { useTranslation } from 'core/context/i18n.context';
import { useMemo, useState } from 'react';
import { ButtonDefault } from 'shared/components/buttons/button-default';
import { StandardTable } from 'shared/components/standard-table';
import { TableProps } from 'shared/components/table';
import styles from './index.module.css';

interface DialogContentProps {
  data: ColumnResponseDto[];
  onChange?: (data: any) => void;
}

export const DialogContent: React.FC<DialogContentProps> = ({
  data,
  onChange,
}) => {
  const { t } = useTranslation();

  const [localData, setLocalData] = useState<any>(data);

  const tableColumns: TableProps['columns'] = useMemo(() => {
    return [
      {
        Header: t('reportApp.column'),
        accessor: 'name',
      },
    ];
  }, [t]);

  const columnSettingsData = useMemo(() => data, [data]);

  const defaultData = columnSettingsData
    .map((item) => ({
      ...item,
      showColumn: item.defaultShowColumn,
      columnOrder: item.defaultColumnOrder,
    }))
    .sort((a, b) => a.defaultColumnOrder - b.defaultColumnOrder);

  const [resetColumns, setResetColumn] = useState<number>(0);
  const handleDefaultColumns = () => {
    setLocalData(defaultData);
    setResetColumn((prev) => prev + 1);
  };

  return (
    <div>
      <ButtonDefault
        size="small"
        title={t('reportApp.defaultColumn')}
        className={styles.defaultColumnButton}
        onClick={handleDefaultColumns}
      ></ButtonDefault>

      <StandardTable
        columns={tableColumns}
        data={localData}
        setData={setLocalData}
        tableStyle={styles.table}
        useRowSelection
        useRowDragAndDrop
        resetData={resetColumns}
        onChange={onChange}
      ></StandardTable>

      <p>{t('reportApp.columnSettingDragDropHint')}</p>
    </div>
  );
};
