import { useTranslation } from 'core/context/i18n.context';
import { ButtonDefault } from 'shared/components/buttons/button-default';
import { ButtonPrimary } from 'shared/components/buttons/button-primary';
import { BaseDialog, BaseDialogProps } from '../base-dialog';
import styles from './index.module.css';
import success from 'core/assets/success.png';
import { useState } from 'react';

export interface ConfirmDialogProps
  extends Omit<BaseDialogProps, 'title' | 'actions'> {
  message: string;
  onOk?: () => Promise<void>;
}

export const ConfirmDialog: React.FC<ConfirmDialogProps> = ({
  message,
  onOk,
  ...props
}) => {
  const { t } = useTranslation();

  const [isLoadingConfirm, setIsLoadingConfirm] = useState<boolean>(false);
  const handleConfirm = async () => {
    setIsLoadingConfirm(true);
    if (onOk) await onOk();
    setIsLoadingConfirm(false);
  };

  return (
    <BaseDialog
      {...props}
      title={t('reportApp.confirm')}
      disableBackdropClick
      disableEscapeKeyDown
      actions={
        <>
          <ButtonPrimary
            title={t('reportApp.yes')}
            size="small"
            className={styles.actionButton}
            onClick={handleConfirm}
            loading={isLoadingConfirm}
          ></ButtonPrimary>
          <ButtonDefault
            title={t('reportApp.no')}
            size="small"
            className={styles.actionButton}
            onClick={props.onClose}
          ></ButtonDefault>
        </>
      }
    >
      <div className={styles.wrapper}>
        <img
          src={success}
          alt={t('reportApp.success')}
          className={styles.successIcon}
        />
        <p className={styles.message}>{message}</p>
      </div>
    </BaseDialog>
  );
};
