import { useEffect, useMemo, useRef, useState } from 'react';
import { Box } from '@mui/material';
import { BaseDialog } from 'shared/components/dialogs/base-dialog';
import { UserSelectionDialogProps } from '.';
import { ButtonPrimary } from 'shared/components/buttons/button-primary';
import { useTranslation } from 'core/context/i18n.context';
import styles from './index.module.css';
import { InputWrapper } from 'shared/components/input-wrapper';
import { InputLabel } from 'shared/components/input-label';
import { FormProvider, useForm } from 'react-hook-form';
import { ButtonSecondary } from 'shared/components/buttons/button-secondary';
import { useGetUsers } from 'dashboard/hooks/use-get-users';
import { SearchFieldWithMask } from 'shared/components/inputs/search-field-with-mask';
import { useClickOutside } from 'generate-report/hooks/use-click-outside';
import classNames from 'classnames';
import { ConfirmDialog } from 'shared/components/dialogs/confirm-dialog';
import { useSearch } from 'shared/hooks/use-search';
import { useErrorDialog } from 'shared/contexts/error-dialog.context';

export const UserSelectionDialogContent: React.FC<UserSelectionDialogProps> = ({
  open,
  onClose,
  onApply,
  loading,
}) => {
  const { t } = useTranslation();
  const fetchUsers = useGetUsers();
  const containerRef = useRef<any>(null);
  const clearAPBForm = useForm<Record<string, any>>();
  const [openUserList, setOpenUserList] = useState<boolean>(false);
  const [userList, setUserList] = useState<any[]>([]);
  const [specificUser, setSpecificUser] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isFetching, setIsFetching] = useState<boolean>(true);
  const [allUsers, setAllUsers] = useState<any[]>([]);
  const usersLimit = 200;

  const [confirmDialogOpen, setConfirmDialogOpen] = useState<boolean>(false);
  const { showErrorDialog: showWarningDialog } = useErrorDialog();
  const [userId, setUserId] = useState<number | undefined>();
  const selectedClearAPBOptions = clearAPBForm.watch('clearAPBOptions');

  const confirmRemoveUser = (user: any, index: number) => () => {
    setConfirmDialogOpen(true);
    setUserId(user.userId);
  };

  const handleConfirmedDialog = async () => {
    setSpecificUser((prev) => {
      const prevArr = prev;
      const filtered = prevArr.filter((data) => data.userId !== userId);
      return filtered;
    });
    closeConfirmDialog();
  };

  const closeConfirmDialog = () => {
    setConfirmDialogOpen(false);
    setUserId(undefined);
  };

  const addUser = (user: any, index: number = 0) => {
    setSpecificUser((prev) => {
      const prevArr = prev;
      return [...prevArr, user];
    });
  };

  const { data: resultData, setSearchValue } = useSearch(allUsers, {
    fieldNames: { text: 'name' },
  });

  const onSearchChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    const { value } = e.target;
    setSearchValue(value);
  };

  const displayData = useMemo(() => {
    const filtered = !specificUser.length
      ? userList
      : userList.filter(
          (user) => !specificUser.some((u) => u.userId === user.userId)
        );
    return !!resultData?.length ? resultData : filtered;
  }, [resultData, specificUser, userList]);
  const usersLength: number = useMemo(
    () => (!!userList.length ? userList.length : 0),
    [userList]
  );

  const specificUserIds = useMemo(
    () => specificUser.map((user) => user.userId),
    [specificUser]
  );

  const specificUserList = useMemo(
    () => allUsers.filter((user) => specificUserIds.includes(user.userId)),
    [specificUserIds, allUsers]
  );

  const onOpenUserList = () => setOpenUserList(true);
  const onCloseUserList = () => setOpenUserList(false);
  const onClickMore = async () => {
    setIsLoading(true);
    const result = await fetchUsers.mutateAsync({
      limit: usersLength + usersLimit,
      offset: 0,
    });

    if (!!result.data.length) {
      setUserList(result.data);
      setIsLoading(false);
    }
  };
  const handleApplyClearAPB = () => {
    if (
      selectedClearAPBOptions === 'specificUser' &&
      !specificUserList.length
    ) {
      showWarningDialog({
        alertType: 'warning',
        message: t('reportApp.userSelection.warningMessage'),
      });
      return;
    }

    if (selectedClearAPBOptions === 'allUsers') {
      onApply([]);
    } else {
      const userIds = specificUserList.map((user) => user.userId);
      onApply(userIds);
      setSpecificUser([]);
    }
  };

  useClickOutside(containerRef, onCloseUserList);

  useEffect(() => {
    (async () => {
      const result = await fetchUsers.mutateAsync({
        limit: usersLimit,
        offset: 0,
      });

      const allUsers = await fetchUsers.mutateAsync({
        offset: 0,
      });

      if (isFetching && !!result.data.length) {
        setUserList(result.data);
        setAllUsers(allUsers.data);
      }
    })();

    return () => setIsFetching(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!selectedClearAPBOptions) {
      clearAPBForm.setValue('clearAPBOptions', 'specificUser');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userList.length]);

  return (
    <BaseDialog
      onClose={onClose}
      open={open}
      title={t('reportApp.doorControl.actions.clearAPB')}
      minWidth={440}
      minHeight={28}
      disableBackdropClick
      disableEscapeKeyDown
      actions={
        <ButtonPrimary
          title={t('reportApp.apply')}
          size="small"
          onClick={handleApplyClearAPB}
          className={styles.btnApply}
          loading={loading}
        />
      }
    >
      <FormProvider {...clearAPBForm}>
        <Box className={styles.contentWrapper}>
          <InputWrapper>
            <InputLabel className={styles.inputLabel} showBullet>
              {t('reportApp.userSelection.subtitle')}
            </InputLabel>
          </InputWrapper>

          <Box height="20px"></Box>

          <InputWrapper>
            <Box className={styles.radioWrapper}>
              <input
                type="radio"
                {...clearAPBForm.register('clearAPBOptions', {
                  required: true,
                })}
                id="allUsers"
                value="allUsers"
                className={styles.checkbox}
              />
              <span className={styles.checkmark} />
            </Box>
            <InputLabel>
              <label htmlFor="allUsers" className={styles.radioLabel}>
                {t('reportApp.userSelection.allUsers')}
              </label>
            </InputLabel>
          </InputWrapper>

          <Box height="20px"></Box>

          <InputWrapper>
            <Box className={styles.radioWrapper}>
              <input
                type="radio"
                {...clearAPBForm.register('clearAPBOptions', {
                  required: true,
                })}
                id="specificUser"
                value="specificUser"
                defaultChecked={true}
                className={styles.checkbox}
              />
              <span className={styles.checkmark} />
            </Box>
            <InputLabel>
              <label htmlFor="specificUser" className={styles.radioLabel}>
                {t('reportApp.userSelection.specificUser')}
              </label>
            </InputLabel>
          </InputWrapper>

          <Box height="20px"></Box>

          {selectedClearAPBOptions === 'specificUser' && (
            <Box className={styles.usersSelectionWrapper}>
              <div className={styles.usersSelection}>
                <div className={styles.titleWrapper}>User</div>
                <div className={styles.selectedUsers}>
                  {!!specificUserList.length && (
                    <div className={styles.userList}>
                      <ul>
                        {specificUserList.map((user, index) => (
                          <li
                            className={classNames(
                              styles.user,
                              styles.selectedUserItem
                            )}
                            key={`${user.name}`}
                          >
                            {user.name}
                            <button
                              className={styles.btnDelete}
                              onClick={confirmRemoveUser(user, index)}
                            ></button>
                          </li>
                        ))}
                      </ul>
                    </div>
                  )}
                </div>
                {openUserList && (
                  <div ref={containerRef} className={styles.selectUsers}>
                    <SearchFieldWithMask
                      handleSearch={onSearchChange}
                      showCloseSearch={!!resultData?.length}
                      title={t('reportApp.userSelection.searchTitle')}
                    ></SearchFieldWithMask>
                    <div className={styles.userList}>
                      <ul {...clearAPBForm.register('users')}>
                        {displayData.map((user) => (
                          <li
                            className={styles.user}
                            key={`${user.userId}`}
                            onClick={() => addUser(user)}
                          >
                            {`${user.userId}(${user.name})`}
                          </li>
                        ))}
                      </ul>
                      {userList.length >= usersLimit && (
                        <li className={styles.buttonWrapper}>
                          <ButtonPrimary
                            title={t('reportApp.more')}
                            size="small"
                            className={styles.moreBtn}
                            onClick={onClickMore}
                            loading={isLoading}
                          />
                        </li>
                      )}
                    </div>
                  </div>
                )}
              </div>
              <Box ml="8px">
                <ButtonSecondary
                  size="smallest"
                  title={`+ ${t('reportApp.add')}`}
                  className={styles.btnAdd}
                  onClick={onOpenUserList}
                ></ButtonSecondary>
              </Box>
            </Box>
          )}
        </Box>

        <ConfirmDialog
          open={confirmDialogOpen || false}
          onClose={closeConfirmDialog}
          onOk={handleConfirmedDialog}
          message={t('reportApp.confirmContinueDeleting')}
        ></ConfirmDialog>
      </FormProvider>
    </BaseDialog>
  );
};
