import { useWidget } from 'dashboard/hooks/use-widget';
import React, { useMemo, useState } from 'react';

export function useContextMenu() {
  const [isRename] = useState<boolean>(false);
  const [valueRename, setValueRename] = useState<string>('');

  const [contextMenu, setContextMenu] = useState<{
    mouseX: number;
    mouseY: number;
  } | null>(null);
  const [menuId, setMenuId] = useState<number | null>(null);
  const [isCategoryWidget, setIsCategoryWidget] = useState(false);
  const [isUnusedReport, setIsUnusedReport] = useState(false);
  const widget = useWidget();
  const widgetsCategories = useMemo(
    () => widget.widgets.map((widget) => widget.categories).flat(),
    [widget.widgets]
  );
  const widgetsCategoryIds = useMemo(
    () => widgetsCategories.map((cat) => (!!cat ? parseInt(cat.type) : 0)),
    [widgetsCategories]
  );

  const openContextMenu = (
    itemId: number,
    name: string,
    event: React.MouseEvent
  ) => {
    event.preventDefault();
    const checkItem = widgetsCategoryIds.includes(itemId);
    setMenuId(itemId);
    setIsCategoryWidget(name.split('_').length === 3);
    setIsUnusedReport(checkItem);
    setContextMenu(
      contextMenu === null
        ? {
            mouseX: event.clientX - 2,
            mouseY: event.clientY - 4,
          }
        : null
    );
  };

  const closeContextMenu = () => {
    setContextMenu(null);
  };

  const anchorPosition = useMemo(() => {
    if (!contextMenu) return undefined;
    return {
      top: contextMenu.mouseY,
      left: contextMenu.mouseX,
    };
  }, [contextMenu]);

  return {
    isRename,
    valueRename,
    setValueRename,
    anchorPosition,
    isOpen: !!contextMenu,
    openContextMenu,
    closeContextMenu,
    menuId,
    isCategoryWidget,
    isUnusedReport,
  };
}
