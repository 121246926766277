import { WidgetsApi } from 'api-client';
import { useQuery } from 'react-query';
import { apiConfig } from 'shared/utils/api-config';

export function useSystemUsage(): any {
  const api = new WidgetsApi(apiConfig());

  return useQuery(['systemUsage'], async () => {
    const { data } = await api.getSystemUsageData();
    return data;
  });
}
