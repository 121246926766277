import { ReportTemplatesApi } from 'api-client';
import { useMemo } from 'react';
import { useQuery } from 'react-query';
import { apiConfig } from 'shared/utils/api-config';

export function useReportTemplate(reportTemplateId?: string) {
  const reportTemplatesApi = new ReportTemplatesApi(apiConfig());

  const id = useMemo(() => {
    if (!reportTemplateId) return undefined;

    const value = parseInt(reportTemplateId);
    if (isNaN(value)) return undefined;

    return value;
  }, [reportTemplateId]);

  const fetchReportTemplate = async () => {
    const { data } = await reportTemplatesApi.getReportTemplateById(
      reportTemplateId
    );
    return data;
  };

  return useQuery(['reportTemplate', id], fetchReportTemplate, {
    enabled: !!reportTemplateId,
  });
}
