import { createCipheriv, createDecipheriv, randomBytes } from 'crypto';
import * as enc from 'shared/utils/enc.json';

const enckey = enc.encryption_key;
const algorithm = 'aes-256-ctr';

export function encrypt(encryptText: string) {
  const parsedRepEncKey = JSON.parse(JSON.stringify(enckey));
  const key = Buffer.from(parsedRepEncKey, 'hex');
  const iv = randomBytes(16);
  const chiper = createCipheriv(algorithm, key.toString(), iv);
  const encrypted = Buffer.concat([chiper.update(encryptText), chiper.final()]);
  return `${iv.toString('hex')}-${encrypted.toString('hex')}`;
}

export function decrypt(decryptedText: string) {
  const parsedRepEncKey = JSON.parse(JSON.stringify(enckey));
  const [iv, text] = decryptedText.split('-');
  const key = Buffer.from(parsedRepEncKey, 'hex');
  const dechiper = createDecipheriv(
    algorithm,
    key.toString(),
    Buffer.from(iv, 'hex')
  );
  const decrypted = Buffer.concat([
    dechiper.update(Buffer.from(text, 'hex')),
    dechiper.final(),
  ]);
  return decrypted.toString();
}
