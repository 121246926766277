import { useDatetimePreference } from './use-datetime-preference';
import { useUserDST } from './use-user-dst';
import { useMemo } from 'react';
import { useGetPreference } from 'core/hooks/use-get-preference';
import { format } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';
import { BiostarDSTDto } from 'api-client';

export function useNormalizedDatetime() {
  const userDST = useUserDST();
  const datetimePreference = useDatetimePreference();
  const userPreference = useGetPreference();

  const getDatetimePreference = useMemo(
    () => datetimePreference.data,
    [datetimePreference.data]
  );

  const getUserPreference = useMemo(
    () => userPreference.data,
    [userPreference.data]
  );

  const getUserDST: BiostarDSTDto[] = useMemo(
    () => userDST.data || [],
    [userDST.data]
  );

  const checkDST = (): boolean => {
    const [userDST] = getUserDST;
    const today = new Date();

    const biostarDSTStartMonth = parseInt(userDST.startPeriod.monthIndex);
    const biostarDSTStartWeek =
      parseInt(userDST.startPeriod.weekIndex) < 0
        ? 4 - parseInt(userDST.startPeriod.weekIndex)
        : parseInt(userDST.startPeriod.weekIndex) + 1;
    const biostarDSTStartDay = parseInt(userDST.startPeriod.dayOfWeek) + 1;
    const biostarDSTStartHours = parseInt(userDST.startPeriod.hour);
    const biostarDSTStartMinutes = parseInt(userDST.startPeriod.min);

    const biostarDSTEndMonth = parseInt(userDST.endPeriod.monthIndex);
    const biostarDSTEndWeek =
      parseInt(userDST.endPeriod.weekIndex) < 0
        ? 4 - parseInt(userDST.startPeriod.weekIndex)
        : parseInt(userDST.endPeriod.weekIndex) + 1;
    const biostarDSTEndDay = parseInt(userDST.endPeriod.dayOfWeek) + 1;
    const biostarDSTEndHours = parseInt(userDST.endPeriod.hour);
    const biostarDSTEndMinutes = parseInt(userDST.startPeriod.min);

    const startDSTDate = getDateByMonthWeekDay(
      biostarDSTStartMonth,
      biostarDSTStartWeek,
      biostarDSTStartDay,
      biostarDSTStartHours,
      biostarDSTStartMinutes
    );
    const endDSTDate = getDateByMonthWeekDay(
      biostarDSTEndMonth,
      biostarDSTEndWeek,
      biostarDSTEndDay,
      biostarDSTEndHours,
      biostarDSTEndMinutes
    );

    return startDSTDate < today && endDSTDate > today;
  };

  const getDateByMonthWeekDay = (
    month: number,
    week: number,
    day: number,
    hours: number,
    minutes: number
  ) => {
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const date = new Date(year, month, 1); // Set the date to the 1st day of the specified month
    const dayOffset = (day - date.getDay() + 7) % 7; // Calculate the offset to the desired day of the week
    const targetDate = new Date(
      year,
      month,
      1 + dayOffset + 7 * (week - 1),
      hours,
      minutes
    );

    return targetDate;
  };

  const normalizedDatetime = (
    datetime: string | number | Date,
    customDatetimeFormat?: string
  ) => {
    const datetimeData = new Date(datetime);
    if (!!getUserPreference?.isDefault) {
      const datetimeFormat = !!customDatetimeFormat
        ? customDatetimeFormat
        : getDatetimePreference
        ? getDatetimePreference.datetimeFormat
        : 'yyyy/MM/dd HH:mm';

      return format(datetimeData, datetimeFormat);
    } else {
      if (!!getUserDST?.length) {
        const isDST = checkDST();

        if (isDST) {
          const datetimeHours = datetimeData.getHours() + 1;
          datetimeData.setHours(datetimeHours);
        }
      }

      const datetime = utcToZonedTime(
        datetimeData,
        getDatetimePreference?.timezone!
      );

      const datetimeFormat = !!customDatetimeFormat
        ? customDatetimeFormat
        : getDatetimePreference
        ? getDatetimePreference.datetimeFormat
        : 'yyyy/MM/dd HH:mm';

      return format(datetime, datetimeFormat);
    }
  };

  return normalizedDatetime;
}
