export class Translator {
  private translationMap: { [key: string]: string } = {};

  /**
   * Constructor.
   *
   * @param {string} translationMessages Contents of a translation file
   */
  constructor(translationMessages: string) {
    this.parse(translationMessages);
  }

  /**
   * Translates a message
   *
   * @param {string} translationKey  The key of the translation message
   * @param params Parameters
   * @returns {string} Translated message
   */
  translate(translationKey: string, params?: (string | number)[]): string {
    let message = this.translationMap[translationKey];
    if (!message) {
      return `Missing translation message for key "${translationKey}"`;
    }

    const containsSpecialChar =
      this.containsASCIICodes(message) ||
      !this.containsOnlyLatin(message) ||
      this.includeSpecialCharLatin(message);

    if (containsSpecialChar) {
      message = message.replace(/\\u([\d\w]{4})/gi, function (match, grp) {
        return String.fromCharCode(parseInt(grp, 16));
      });
    }

    return this.substituteParameters(message, params);
  }

  containsASCIICodes(str: string) {
    const regex = /^[^p\u0080-\uFFFF]*$/u;
    return regex.test(str);
  }

  containsOnlyLatin(str: string) {
    const regex = /^[\u0020-\u0187]*$/;
    return regex.test(str);
  }

  includeSpecialCharLatin(str: string) {
    const regex = /^[^p\u00C0-\u0187]*/;
    return regex.test(str);
  }

  /**
   * Parse the translation file and store the key/value pairs in a map.
   *
   * @param {string} messages Content of the translation file
   * @returns
   */
  private parse(messages: string): void {
    const fileLines = messages
      // Split new lines regardless of line ending types
      .split(/\r\n|\r|\n/)
      // Clean up trailing spaces and remove empty lines
      .map((v) => v?.trim())
      .filter((v) => !!v);

    let currentKey: string | undefined;
    for (const line of fileLines) {
      // Match key/value pair patterns
      const kvPattern = new RegExp("^\\s*([\\w\\.]+)\\s*=\\s*(.*)$", "gm");
      const match = kvPattern.exec(line);
      if (match) {
        // If it's a key/value pair on this line
        currentKey = match[1].trim();
        this.translationMap[currentKey] = match[2]?.trim();
      } else if (!!currentKey) {
        // If it's not a key/value pair on this line, it's a continuation of the
        // previous line. But we must make sure we had a key on previous line.
        this.translationMap[currentKey] += `\n${line}`;
      }
    }
  }

  private substituteParameters(
    message: string,
    params?: (string | number)[]
  ): string {
    if (!params?.length) return message;

    return params.reduce((acc: string, curr, index) => {
      const regex = new RegExp(`\\{${index}\\}`, "g");
      return acc.replace(regex, curr.toString());
    }, message);
  }
}
