/* tslint:disable */
/* eslint-disable */
/**
 * BioStar 2 Report API
 * API Documentation for BioStar 2 Report
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { ConditionPeriodDto } from './condition-period-dto';

/**
 * 
 * @export
 * @interface CustomReportConditionDto
 */
export interface CustomReportConditionDto {
    /**
     * Array of event type code
     * @type {Array<string>}
     * @memberof CustomReportConditionDto
     */
    events: Array<CustomReportConditionDtoEvents>;
    /**
     * 
     * @type {ConditionPeriodDto}
     * @memberof CustomReportConditionDto
     */
    period: ConditionPeriodDto;
    /**
     * Array of user id
     * @type {Array<string>}
     * @memberof CustomReportConditionDto
     */
    users?: Array<string>;
    /**
     * Array of door id
     * @type {Array<string>}
     * @memberof CustomReportConditionDto
     */
    doors?: Array<string>;
    /**
     * Array of device id
     * @type {Array<string>}
     * @memberof CustomReportConditionDto
     */
    devices?: Array<string>;
}

/**
    * @export
    * @enum {string}
    */
export enum CustomReportConditionDtoEvents {
    _4096 = '4096',
    _4097 = '4097',
    _4098 = '4098',
    _4099 = '4099',
    _4100 = '4100',
    _4101 = '4101',
    _4102 = '4102',
    _4103 = '4103',
    _4104 = '4104',
    _4105 = '4105',
    _4106 = '4106',
    _4107 = '4107',
    _4108 = '4108',
    _4109 = '4109',
    _4110 = '4110',
    _4111 = '4111',
    _4112 = '4112',
    _4113 = '4113',
    _4114 = '4114',
    _4115 = '4115',
    _4118 = '4118',
    _4119 = '4119',
    _4120 = '4120',
    _4121 = '4121',
    _4122 = '4122',
    _4123 = '4123',
    _4128 = '4128',
    _4129 = '4129',
    _4352 = '4352',
    _4353 = '4353',
    _4354 = '4354',
    _4355 = '4355',
    _4356 = '4356',
    _4357 = '4357',
    _4358 = '4358',
    _4359 = '4359',
    _4360 = '4360',
    _4361 = '4361',
    _4608 = '4608',
    _4609 = '4609',
    _4610 = '4610',
    _4611 = '4611',
    _4612 = '4612',
    _4613 = '4613',
    _4614 = '4614',
    _4615 = '4615',
    _4616 = '4616',
    _4617 = '4617',
    _4618 = '4618',
    _4619 = '4619',
    _4620 = '4620',
    _4621 = '4621',
    _4622 = '4622',
    _4623 = '4623',
    _4624 = '4624',
    _4625 = '4625',
    _4626 = '4626',
    _4627 = '4627',
    _4632 = '4632',
    _4633 = '4633',
    _4640 = '4640',
    _4641 = '4641',
    _4864 = '4864',
    _4865 = '4865',
    _4866 = '4866',
    _4867 = '4867',
    _4868 = '4868',
    _4869 = '4869',
    _4870 = '4870',
    _4871 = '4871',
    _4872 = '4872',
    _5120 = '5120',
    _5123 = '5123',
    _5124 = '5124',
    _5125 = '5125',
    _5126 = '5126',
    _5127 = '5127',
    _5376 = '5376',
    _5377 = '5377',
    _5378 = '5378',
    _5379 = '5379',
    _5380 = '5380',
    _5381 = '5381',
    _5382 = '5382',
    _5383 = '5383',
    _5384 = '5384',
    _5632 = '5632',
    _5888 = '5888',
    _5889 = '5889',
    _5890 = '5890',
    _6144 = '6144',
    _6145 = '6145',
    _6146 = '6146',
    _6147 = '6147',
    _6148 = '6148',
    _6149 = '6149',
    _6400 = '6400',
    _6401 = '6401',
    _6402 = '6402',
    _6403 = '6403',
    _6404 = '6404',
    _6405 = '6405',
    _6406 = '6406',
    _6407 = '6407',
    _6408 = '6408',
    _6409 = '6409',
    _6410 = '6410',
    _6411 = '6411',
    _6412 = '6412',
    _6414 = '6414',
    _6415 = '6415',
    _6418 = '6418',
    _6419 = '6419',
    _6420 = '6420',
    _6421 = '6421',
    _6422 = '6422',
    _6656 = '6656',
    _6912 = '6912',
    _6913 = '6913',
    _6914 = '6914',
    _6915 = '6915',
    _7168 = '7168',
    _7169 = '7169',
    _7170 = '7170',
    _7188 = '7188',
    _7424 = '7424',
    _7425 = '7425',
    _7680 = '7680',
    _8192 = '8192',
    _8448 = '8448',
    _8449 = '8449',
    _8450 = '8450',
    _8451 = '8451',
    _8457 = '8457',
    _8962 = '8962',
    _8969 = '8969',
    _8704 = '8704',
    _8960 = '8960',
    _8961 = '8961',
    _9216 = '9216',
    _9472 = '9472',
    _9728 = '9728',
    _9984 = '9984',
    _10240 = '10240',
    _10242 = '10242',
    _10244 = '10244',
    _10245 = '10245',
    _10496 = '10496',
    _10752 = '10752',
    _10753 = '10753',
    _10754 = '10754',
    _10755 = '10755',
    _10756 = '10756',
    _10761 = '10761',
    _12288 = '12288',
    _12368 = '12368',
    _12544 = '12544',
    _12800 = '12800',
    _12801 = '12801',
    _12802 = '12802',
    _13056 = '13056',
    _13312 = '13312',
    _13568 = '13568',
    _13824 = '13824',
    _14080 = '14080',
    _14336 = '14336',
    _14592 = '14592',
    _14848 = '14848',
    _15104 = '15104',
    _15120 = '15120',
    _15376 = '15376',
    _15360 = '15360',
    _15616 = '15616',
    _15872 = '15872',
    _16128 = '16128',
    _16129 = '16129',
    _16384 = '16384',
    _16640 = '16640',
    _16896 = '16896',
    _17152 = '17152',
    _17408 = '17408',
    _17664 = '17664',
    _17665 = '17665',
    _17666 = '17666',
    _17667 = '17667',
    _17920 = '17920',
    _18176 = '18176',
    _18432 = '18432',
    _18688 = '18688',
    _18944 = '18944',
    _18945 = '18945',
    _19200 = '19200',
    _19456 = '19456',
    _19712 = '19712',
    _19713 = '19713',
    _19714 = '19714',
    _19715 = '19715',
    _19716 = '19716',
    _20480 = '20480',
    _20736 = '20736',
    _20992 = '20992',
    _21248 = '21248',
    _21504 = '21504',
    _21760 = '21760',
    _22016 = '22016',
    _22272 = '22272',
    _22528 = '22528',
    _22784 = '22784',
    _23040 = '23040',
    _23296 = '23296',
    _23553 = '23553',
    _23554 = '23554',
    _23556 = '23556',
    _23809 = '23809',
    _23810 = '23810',
    _23812 = '23812',
    _24065 = '24065',
    _24066 = '24066',
    _24068 = '24068',
    _24576 = '24576',
    _24577 = '24577',
    _24578 = '24578',
    _24832 = '24832',
    _24833 = '24833',
    _24834 = '24834',
    _24835 = '24835',
    _24836 = '24836',
    _24837 = '24837',
    _24838 = '24838',
    _25088 = '25088',
    _25344 = '25344',
    _25600 = '25600',
    _25856 = '25856',
    _26112 = '26112',
    _26368 = '26368',
    _26624 = '26624',
    _26880 = '26880',
    _27094 = '27094',
    _27136 = '27136',
    _27392 = '27392',
    _27648 = '27648',
    _27904 = '27904',
    _28160 = '28160',
    _28416 = '28416',
    _28417 = '28417',
    _28418 = '28418',
    _28419 = '28419',
    _28420 = '28420',
    _28421 = '28421',
    _28422 = '28422',
    _28423 = '28423',
    _28672 = '28672',
    _28928 = '28928',
    _29185 = '29185',
    _29186 = '29186',
    _29188 = '29188',
    _29192 = '29192',
    _29441 = '29441',
    _29442 = '29442',
    _29444 = '29444',
    _29448 = '29448',
    _29697 = '29697',
    _29698 = '29698',
    _29700 = '29700',
    _29952 = '29952',
    _30208 = '30208',
    _30464 = '30464',
    _30720 = '30720',
    _30976 = '30976',
    _32768 = '32768',
    _36864 = '36864',
    _37120 = '37120',
    _37376 = '37376',
    _37632 = '37632',
    _37888 = '37888',
    _38144 = '38144',
    _38656 = '38656',
    _38912 = '38912',
    _39168 = '39168',
    _39424 = '39424',
    _39680 = '39680',
    _40960 = '40960',
    _40961 = '40961',
    _40962 = '40962',
    _41216 = '41216',
    _41428 = '41428',
    _41472 = '41472',
    _41728 = '41728',
    _41984 = '41984',
    _42753 = '42753',
    _42754 = '42754',
    _45056 = '45056',
    _45312 = '45312',
    _45568 = '45568',
    _47104 = '47104',
    _47360 = '47360',
    _49152 = '49152',
    _3100 = '3100',
    _3110 = '3110',
    _3111 = '3111',
    _3113 = '3113',
    _3131 = '3131',
    _3132 = '3132',
    _3133 = '3133',
    _3141 = '3141',
    _3142 = '3142',
    _3143 = '3143',
    _3211 = '3211',
    _3212 = '3212',
    _3213 = '3213',
    _3231 = '3231',
    _3232 = '3232',
    _3233 = '3233',
    _3241 = '3241',
    _3242 = '3242',
    _3243 = '3243',
    _3351 = '3351',
    _3352 = '3352',
    _3353 = '3353',
    _3354 = '3354',
    _3355 = '3355',
    _3356 = '3356',
    _3357 = '3357',
    _3461 = '3461',
    _3462 = '3462',
    _3463 = '3463',
    _3464 = '3464',
    _3465 = '3465',
    _3466 = '3466',
    _3467 = '3467',
    _4095 = '4095',
    _49920 = '49920',
    _50176 = '50176',
    _4133 = '4133',
    _4134 = '4134',
    _4135 = '4135',
    _4136 = '4136',
    _4137 = '4137',
    _4138 = '4138',
    _4139 = '4139',
    _4140 = '4140',
    _4362 = '4362',
    _4363 = '4363',
    _4364 = '4364',
    _4647 = '4647',
    _4648 = '4648',
    _4651 = '4651',
    _4652 = '4652'
}



