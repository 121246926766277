import { Suspense } from 'react';
import { OverlayLoading } from 'shared/components/overlay-loading';
import { UserSelectionDialogContent } from './user-selection-content';

export interface UserSelectionDialogProps {
  open: boolean;
  onClose: () => void;
  onApply: (data: any) => void;
  value?: any;
  doorId: number;
  loading: boolean;
}

export const UserSelectionDialog: React.FC<UserSelectionDialogProps> = (
  props
) => {
  return (
    <Suspense fallback={<OverlayLoading />}>
      <UserSelectionDialogContent {...props} />
    </Suspense>
  );
};
