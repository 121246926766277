import classNames from 'classnames';
import { useTranslation } from 'core/context/i18n.context';
import { usePreference } from 'core/context/preference.context';
import { format, set, sub } from 'date-fns';
import { useCallback, useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { FilterPeriodChildProps } from '../date-time-range-picker';
import styles from './index.module.css';

type FormField = {
  startDays: number;
  startHours: number;
  startMinutes: number;
  endDays: number;
  endHours: number;
  endMinutes: number;
};

export const DynamicDateTime: React.FC<FilterPeriodChildProps> = ({
  values,
  onChange,
}) => {
  const { t } = useTranslation();
  const preference = usePreference();

  const {
    register,
    watch,
    setValue,
    formState: { errors, isValidating },
  } = useForm<FormField>({
    reValidateMode: 'onChange',
    defaultValues: {
      startDays: parseInt(values.startDaysBack),
      startHours: values.startTimeBack.getHours().toString().padStart(2, '0'),
      startMinutes: values.startTimeBack
        .getMinutes()
        .toString()
        .padStart(2, '0'),
      endDays: parseInt(values.endDaysBack),
      endHours: values.endTimeBack.getHours().toString().padStart(2, '0'),
      endMinutes: values.endTimeBack.getMinutes().toString().padStart(2, '0'),
    },
  });

  const startDays = watch('startDays', 0);
  const startHours = watch('startHours');
  const startMinutes = watch('startMinutes');
  const endDays = watch('endDays', 0);
  const endHours = watch('endHours');
  const endMinutes = watch('endMinutes');

  const onChangeDayValue: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    const fieldName = e.target.name as any;
    const maxValue = parseInt(e.target.max);
    let value = parseInt(e.target.value || '0');

    if (value > maxValue) {
      value = maxValue;
    }

    setValue(fieldName, value, { shouldValidate: true });
    // Normalize input value to exclude 0 on prefix.
    e.target.value = value.toString();
  };

  const onChangeTimeValue: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    const fieldName = e.target.name as any;
    const maxValue = parseInt(e.target.max);
    let value = parseInt(e.target.value || '0');

    if (!e.target.value.length) {
      e.target.value = '00';
    }

    if (e.target.value.length === 3) {
      e.target.value = value.toString();
    }

    if (value < 10 && 0 <= value) {
      e.target.value = value.toString().padStart(2, '0');
    }

    if (value > maxValue) {
      e.target.value = e.target.max;
    }

    setValue(fieldName, e.target.value, { shouldValidate: true });
  };

  const constructDatetime = useCallback(
    (days: number, hours: number, minutes: number) => {
      let datetime = set(new Date(), {
        hours: hours,
        minutes: minutes,
        seconds: 0,
        milliseconds: 0,
      });
      datetime = sub(datetime, { days });
      return datetime;
    },
    []
  );

  const formatDatetime = useCallback(
    (days: number, hours: number, minutes: number) => {
      const datetimeFormat = preference.datetimeFormat || 'dd/MM HH:mm';

      const validDays = !isNaN(days) && days >= 0;
      const validHours = !isNaN(hours) && hours >= 0;
      const validMinutes = !isNaN(minutes) && minutes >= 0;

      if (!validDays || !validHours || !validMinutes) {
        return datetimeFormat;
      }

      const datetime = constructDatetime(days, hours, minutes);
      return format(datetime, datetimeFormat);
    },
    [preference.datetimeFormat, constructDatetime]
  );

  const startDateTimeText = useMemo(
    () => formatDatetime(startDays, startHours, startMinutes),
    [startDays, startHours, startMinutes, formatDatetime]
  );

  const endDateTimeText = useMemo(
    () => formatDatetime(endDays, endHours, endMinutes),
    [endDays, endHours, endMinutes, formatDatetime]
  );

  const finalDatetimeData = useMemo(() => {
    return {
      startDaysBack: startDays,
      endDaysBack: endDays,
      startTimeBack: constructDatetime(startDays, startHours, startMinutes),
      endTimeBack: constructDatetime(endDays, endHours, endMinutes),
    };
  }, [
    constructDatetime,
    endHours,
    endMinutes,
    startHours,
    startMinutes,
    startDays,
    endDays,
  ]);

  useEffect(() => {
    onChange(finalDatetimeData);
  }, [finalDatetimeData, onChange]);

  const generalError = useMemo(() => {
    if (finalDatetimeData.startTimeBack > finalDatetimeData.endTimeBack) {
      return t('reportApp.dynamicPeriodStartDatetimeExceed');
    }
    return undefined;
  }, [finalDatetimeData, t]);

  const errorMessages = useMemo(() => {
    const messages = Object.values(errors)
      .map((err) => err.message)
      .flat();
    return [...new Set(messages)];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isValidating]);

  return (
    <div className={styles.dynamicDateTimeWrapper}>
      <div className={styles.currentDateWrapper}>
        <div className={styles.startWrapper}>
          {t('reportApp.from')}
          <input
            {...register('startDays', {
              valueAsNumber: true,
            })}
            type="number"
            max={365}
            onChange={onChangeDayValue}
            className={classNames(styles.days, {
              [styles.inputError]: !!errors.startDays,
            })}
          />
          {t('reportApp.daysBackAt')}
          <div className={styles.inputWrapper}>
            <input
              {...register('startHours', {
                valueAsNumber: true,
              })}
              max={23}
              onChange={onChangeTimeValue}
              className={classNames(styles.hours, {
                [styles.inputError]: !!errors.startHours,
              })}
            />
          </div>
          :
          <div className={styles.inputWrapper}>
            <input
              {...register('startMinutes', {
                valueAsNumber: true,
              })}
              type="number"
              max={59}
              onChange={onChangeTimeValue}
              className={classNames(styles.minutes, {
                [styles.inputError]: !!errors.startMinutes,
              })}
            />
          </div>
        </div>

        <div className={styles.endWrapper}>
          {t('reportApp.to')}
          <input
            {...register('endDays', {
              valueAsNumber: true,
            })}
            type="number"
            max={365}
            onChange={onChangeDayValue}
            className={classNames(styles.days, {
              [styles.inputError]: !!errors.endDays,
            })}
          />
          {t('reportApp.daysBackAt')}
          <div className={styles.inputWrapper}>
            <input
              {...register('endHours', {
                valueAsNumber: true,
              })}
              type="number"
              max={23}
              onChange={onChangeTimeValue}
              className={classNames(styles.hours, {
                [styles.inputError]: !!errors.endHours,
              })}
            />
          </div>
          :
          <div className={styles.inputWrapper}>
            <input
              {...register('endMinutes', {
                valueAsNumber: true,
              })}
              type="number"
              max={59}
              onChange={onChangeTimeValue}
              className={classNames(styles.minutes, {
                [styles.inputError]: !!errors.endMinutes,
              })}
            />
          </div>
        </div>
      </div>

      <p>
        *{t('reportApp.startsOn')}
        <b>{` ${startDateTimeText}`}</b>, {t('reportApp.endsOn')}
        <b>{` ${endDateTimeText}`}​</b>
      </p>

      <div className={styles.errorWrapper}>
        {!!generalError && (
          <span className={styles.validationText}>*{generalError}</span>
        )}
        {errorMessages.map((msg, idx) => (
          <span key={idx} className={styles.validationText}>
            *{msg}
          </span>
        ))}
      </div>
    </div>
  );
};
