import { useTranslation } from 'core/context/i18n.context';
import { StandardLayout } from 'shared/components/layouts/standard-layout';
import styles from './index.module.css';

export const NotFountPage: React.FC = () => {
  const { t } = useTranslation();

  return (
    <StandardLayout>
      <div className={styles.mainContent}>
        <h1 className={styles.title}>{t('reportApp.notFound')}</h1>
      </div>
    </StandardLayout>
  );
};
