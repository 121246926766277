/**
 * List of Event Code.
 */
export const EVENT_CODE = [
  '4096',
  '4097',
  '4098',
  '4099',
  '4100',
  '4101',
  '4102',
  '4103',
  '4104',
  '4105',
  '4106',
  '4107',
  '4108',
  '4109',
  '4110',
  '4111',
  '4112',
  '4113',
  '4114',
  '4115',
  '4118',
  '4119',
  '4120',
  '4121',
  '4122',
  '4123',
  '4128',
  '4129',
  '4133',
  '4134',
  '4135',
  '4136',
  '4137',
  '4138',
  '4139',
  '4140',
  '4864',
  '4865',
  '4866',
  '4867',
  '4868',
  '4869',
  '4870',
  '4871',
  '4872',
  '5632',
  '6912',
  '6913',
  '6914',
  '6915',
];
