import {
  ConditionPeriodDto,
  ConditionPeriodDtoType,
  RegenerateReportDto,
} from 'api-client';
import { useTranslation } from 'core/context/i18n.context';
import { useRegenerateCustomReport } from 'generate-report/hooks/use-regenerate-custom-report';
import { useEffect, useMemo, useState } from 'react';
import { ButtonDefault } from 'shared/components/buttons/button-default';
import { ButtonPrimary } from 'shared/components/buttons/button-primary';
import { BaseDialog } from 'shared/components/dialogs/base-dialog';
import { FilterPeriod } from 'shared/components/filter-conditions/filter-period';
import { useErrorDialog } from 'shared/contexts/error-dialog.context';
import styles from './index.module.css';

interface GenerateCustomReportProps {
  open: boolean;
  onClose: () => void;
  onSuccess: (id: number) => void;
  value: any;
  reportId?: number;
}

export const UpdatePeriodFilter: React.FC<GenerateCustomReportProps> = ({
  open,
  onClose,
  value,
  reportId,
  onSuccess,
}) => {
  const { t } = useTranslation();
  const regenerateCustomReportMutation = useRegenerateCustomReport(reportId!);
  const { showErrorDialog } = useErrorDialog();

  const [selectedPeriod, setSelectedPeriod] = useState<any>();

  useEffect(() => {
    setSelectedPeriod(value);
  }, [value]);

  const validations = useMemo(() => {
    const errors = [];
    if (!selectedPeriod) {
      errors.push('required');
    }

    if (selectedPeriod?.type === 'static') {
      if (
        selectedPeriod.value.startDatetime.toString() === 'Invalid Date' ||
        selectedPeriod.value.endDatetime.toString() === 'Invalid Date'
      ) {
        errors.push('Invalid Date selected');
      }
    }

    if (selectedPeriod?.type === 'dynamic') {
      if (
        selectedPeriod.value.startDaysBack < selectedPeriod.value.endDaysBack
      ) {
        errors.push('dynamicPeriodStartDatetimeExceed');
      }
    }

    return errors;
  }, [selectedPeriod]);

  const haveErrors = useMemo(() => {
    return !!validations.length;
  }, [validations]);

  const getPeriodData = () => {
    if (selectedPeriod.type === 'static') {
      const data: ConditionPeriodDto = {
        type: ConditionPeriodDtoType.Static,
        value: {
          startDatetime: selectedPeriod.value.startDatetime,
          endDatetime: selectedPeriod.value.endDatetime,
        },
      };
      return data;
    }

    if (selectedPeriod.type === 'dynamic') {
      const data: ConditionPeriodDto = {
        type: ConditionPeriodDtoType.Dynamic,
        value: {
          ...selectedPeriod.value,
          startTimeBack: selectedPeriod.value.startTimeBack,
          endTimeBack: selectedPeriod.value.endTimeBack,
        },
      };
      return data;
    }
  };

  const handleRegenerateReport = () => {
    if (haveErrors) {
      return showErrorDialog({
        alertType: 'warning',
        message: t('reportApp.filterConditionRequired'),
      });
    }

    const period = getPeriodData();
    const payload: RegenerateReportDto = { data: { period } };

    regenerateCustomReportMutation.mutate(payload, {
      onError: (error) => {
        showErrorDialog({
          alertType: 'warning',
          message: error.message,
        });
      },
      onSuccess: (data) => onSuccess(data.id),
    });
  };

  const DialogActions = () => {
    return (
      <>
        <ButtonPrimary
          title={t('reportApp.regenerate')}
          size="small"
          className={styles.dialogActionButton}
          onClick={handleRegenerateReport}
          loading={regenerateCustomReportMutation.isLoading}
          disabled={haveErrors}
        ></ButtonPrimary>
        <ButtonDefault
          title={t('reportApp.cancel')}
          onClick={onClose}
          size="small"
          className={styles.dialogActionButton}
        ></ButtonDefault>
      </>
    );
  };

  const renderStepError = () => {
    const errors = validations;
    const hasError = !!errors?.length;
    const firstError = hasError ? errors[0] : null;

    if (!hasError) return <></>;

    if (firstError === 'required') {
      return (
        <p className={styles.filterConditionRequired}>
          *{t('reportApp.filterConditionRequired')}
        </p>
      );
    }
  };

  return (
    <BaseDialog
      onClose={onClose}
      open={open}
      title={t('reportApp.filterCondition')}
      disableBackdropClick
      disableEscapeKeyDown
      actions={<DialogActions />}
    >
      <FilterPeriod
        value={selectedPeriod}
        oneType={selectedPeriod?.type}
        onChange={setSelectedPeriod}
      />
      {renderStepError()}
    </BaseDialog>
  );
};
