/* tslint:disable */
/* eslint-disable */
/**
 * BioStar 2 Report API
 * API Documentation for BioStar 2 Report
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { ScheduleReportTemplateDto } from './schedule-report-template-dto';

/**
 * 
 * @export
 * @interface ScheduleDto
 */
export interface ScheduleDto {
    /**
     * 
     * @type {number}
     * @memberof ScheduleDto
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof ScheduleDto
     */
    name: string;
    /**
     * 
     * @type {ScheduleReportTemplateDto}
     * @memberof ScheduleDto
     */
    reportTemplate: ScheduleReportTemplateDto;
    /**
     * 
     * @type {string}
     * @memberof ScheduleDto
     */
    frequency: ScheduleDtoFrequency;
    /**
     * 
     * @type {string}
     * @memberof ScheduleDto
     */
    generateTime: string;
    /**
     * 
     * @type {string}
     * @memberof ScheduleDto
     */
    outputType: ScheduleDtoOutputType;
    /**
     * 
     * @type {string}
     * @memberof ScheduleDto
     */
    latestGenerated?: string;
    /**
     * 
     * @type {string}
     * @memberof ScheduleDto
     */
    status: ScheduleDtoStatus;
    /**
     * 
     * @type {string}
     * @memberof ScheduleDto
     */
    fileFormat: ScheduleDtoFileFormat;
    /**
     * 
     * @type {boolean}
     * @memberof ScheduleDto
     */
    showTitleOnEveryPage?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ScheduleDto
     */
    showHeader?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ScheduleDto
     */
    showHeaderOnEveryPage?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ScheduleDto
     */
    showPageNumber?: boolean;
}

/**
    * @export
    * @enum {string}
    */
export enum ScheduleDtoFrequency {
    Daily = 'daily'
}
/**
    * @export
    * @enum {string}
    */
export enum ScheduleDtoOutputType {
    Export = 'export'
}
/**
    * @export
    * @enum {string}
    */
export enum ScheduleDtoStatus {
    Active = 'active',
    Inactive = 'inactive'
}
/**
    * @export
    * @enum {string}
    */
export enum ScheduleDtoFileFormat {
    Csv = 'csv'
}



