import {
  ErrorResponseDto,
  ReportTemplate,
  ReportTemplatesApi,
  SaveReportTemplateDto,
} from 'api-client';
import axios from 'axios';
import { useMutation } from 'react-query';
import { apiConfig } from 'shared/utils/api-config';

export function useSaveReportTemplate() {
  const reportTemplatesApi = new ReportTemplatesApi(apiConfig());

  return useMutation<ReportTemplate, ErrorResponseDto, SaveReportTemplateDto>(
    (saveReportTemplateDto: SaveReportTemplateDto) =>
      reportTemplatesApi
        .saveReportTemplate(saveReportTemplateDto)
        .then((res) => res.data)
        .catch((error) => {
          if (axios.isAxiosError(error)) {
            throw error.response!.data;
          }
          throw error;
        })
  );
}
