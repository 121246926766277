import { CircularProgress } from '@mui/material';
import { useTranslation } from 'core/context/i18n.context';
import { useRegenerateReport } from 'generate-report/hooks/use-regenerate-report';
import { useCallback, useEffect, useMemo } from 'react';
import { ButtonPrimary } from 'shared/components/buttons/button-primary';
import { BaseDialog } from 'shared/components/dialogs/base-dialog';
import { FilterObject } from 'shared/components/filter-conditions/filter-object';
import { useErrorDialog } from 'shared/contexts/error-dialog.context';
import { useFilterCondition } from 'shared/components/wizard/hooks/use-filter-condition';
import { useFilterReducer } from 'shared/components/wizard/hooks/use-filter-reducer';
import { WizardStepProps } from 'shared/components/wizard/index.type';
import styles from './index.module.css';
import { RegenerateReportDto, ReportObjectFilterDto } from 'api-client';
import { ButtonDefault } from 'shared/components/buttons/button-default';
import {
  ObjectFilterDataNestedNode,
  ObjectFilterDataNode,
} from 'shared/components/filter-conditions/filter-object/type';

interface GenerateReportProps {
  open: boolean;
  onClose: () => void;
  onSuccess: (id: number) => void;
  step?: WizardStepProps;
  value: ObjectFilterDataNode[];
  reportId?: number;
}

export const UpdateObjectCondition: React.FC<GenerateReportProps> = ({
  open,
  onClose,
  step,
  value,
  reportId,
  onSuccess,
}) => {
  const { t } = useTranslation();
  const filterCondition = useFilterCondition([step]);
  const regenerateReportMutation = useRegenerateReport();
  const { showErrorDialog } = useErrorDialog();

  const [dataFilter, dispatchFilter] = useFilterReducer();

  const onChangeFilterObject = useCallback(
    (stepIndex: number) => (data: ObjectFilterDataNode[]) =>
      dispatchFilter({
        type: 'onChange',
        payload: { stepIndex, value: data },
      }),
    [dispatchFilter]
  );

  useEffect(() => {
    onChangeFilterObject(0)(value);
  }, [value, onChangeFilterObject]);

  const isMultiple = useMemo(() => step?.type === 'objectMultiple', [step]);

  const validations = useMemo(() => {
    const errors = [];
    if (step?.rules?.includes('required') && !dataFilter[0]?.length) {
      errors.push('required');
    }
    return errors;
  }, [dataFilter, step]);

  const haveErrors = useMemo(() => {
    return !!validations.length;
  }, [validations]);

  const handleRegenerateReport = () => {
    if (!reportId) return;

    if (haveErrors) {
      return showErrorDialog({
        alertType: 'warning',
        message: t('reportApp.filterConditionRequired'),
      });
    }
    const payload: RegenerateReportDto = {
      data: {
        filterConditions: [
          {
            filterConditionId: step!.id,
            value: dataFilter[0].map(
              (d) => d.originalId
            ) as ReportObjectFilterDto[],
          },
        ],
      },
    };

    regenerateReportMutation.mutate(
      {
        id: reportId,
        regenerateReportDto: payload,
      },
      {
        onError: (error) =>
          showErrorDialog({
            alertType: 'error',
            message: error.message,
          }),
        onSuccess: (data) => onSuccess(data.id),
      }
    );
  };

  const DialogActions = () => {
    return (
      <>
        <ButtonPrimary
          title={t('reportApp.regenerate')}
          size="small"
          className={styles.dialogActionButton}
          onClick={handleRegenerateReport}
          loading={regenerateReportMutation.isLoading}
          disabled={filterCondition.isLoading}
        ></ButtonPrimary>
        <ButtonDefault
          title={t('reportApp.cancel')}
          onClick={onClose}
          size="small"
          className={styles.dialogActionButton}
        ></ButtonDefault>
      </>
    );
  };

  const renderLoading = () => {
    return (
      <div className={styles.loadingWrapper}>
        <CircularProgress />
      </div>
    );
  };

  const renderStepError = () => {
    const errors = validations;
    const hasError = !!errors?.length;
    const firstError = hasError ? errors[0] : null;

    if (!hasError) return <></>;

    if (firstError === 'required') {
      return (
        <p className={styles.filterConditionRequired}>
          *{t('reportApp.filterConditionRequired')}
        </p>
      );
    }
  };

  return (
    <BaseDialog
      onClose={onClose}
      open={open}
      title={t('reportApp.filterCondition')}
      disableBackdropClick
      disableEscapeKeyDown
      actions={<DialogActions />}
    >
      {filterCondition.isLoading && renderLoading()}
      {!!filterCondition.steps.length && !filterCondition.isLoading && (
        <>
          <FilterObject
            dataSource={
              filterCondition.steps[0].data as ObjectFilterDataNestedNode[]
            }
            defaultValues={dataFilter[0]}
            onChange={onChangeFilterObject(0)}
            multiple={isMultiple}
            enableAdditionalFilters={
              filterCondition.steps[0].value === 'usersAndGroups'
            }
            dataSourceType={filterCondition.steps[0].value}
            hideDevices={true}
          />
          {renderStepError()}
        </>
      )}
    </BaseDialog>
  );
};
