import { InputAdornment, InputBase, InputBaseProps } from '@mui/material';
import classNames from 'classnames';
import { useState } from 'react';
import styles from './index.module.css';

interface SearchFieldWithMaskProps {
  handleSearch?: InputBaseProps['onChange'];
  className?: string;
  showCloseSearch: boolean;
  title?: string;
  id?: string;
}

export const SearchFieldWithMask: React.FC<SearchFieldWithMaskProps> = ({
  handleSearch,
  className,
  showCloseSearch = false,
  title,
  id,
}) => {
  const [showSearchField, setShowSearchField] = useState(false);
  const handleClick = () => setShowSearchField(!showSearchField);
  const handleClose = () => {
    if (!!handleSearch) {
      handleSearch({ target: { value: '' } } as any);
    }
    setShowSearchField(false);
  };

  return (
    <div>
      {!showSearchField && (
        <div className={styles.searchMask}>
          {title}
          <div className={styles.iconSearch} onClick={handleClick} />
        </div>
      )}
      {showSearchField && (
        <div className={styles.searchFieldWrapper}>
          <InputBase
            fullWidth
            className={classNames(styles.searchField, className)}
            onChange={handleSearch}
            endAdornment={
              <InputAdornment position="end">
                {showCloseSearch && (
                  <div className={styles.iconClose} onClick={handleClose} />
                )}
              </InputAdornment>
            }
            autoFocus
            id={id}
          ></InputBase>
        </div>
      )}
    </div>
  );
};
