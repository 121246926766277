import { useEffect, useCallback } from 'react';

export const useClickOutside = (ref: any, handler: any) => {
  const handleClickOutside = useCallback(
    (event) =>
      !(ref?.current && ref?.current?.contains?.(event.target)) && handler(),
    [handler, ref]
  );

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside, false);
    return () =>
      document.removeEventListener('mousedown', handleClickOutside, false);
  }, [handleClickOutside]);

  if (!handler) return;
};
