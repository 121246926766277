import { useCallback, useMemo, useState } from 'react';

export interface SearchItem {
  text?: string;
  children?: SearchItem[];
}

interface UseSearchOptions {
  fieldNames?: { text?: string };
  searchBy?: string;
}

export function useSearch<Item extends SearchItem>(
  items: Item[] = [],
  options?: UseSearchOptions
) {
  const [searchValue, setSearchValue] = useState<string>('');
  const [userGroupSearchValue, setUserGroupSearchValue] = useState<string>('');
  const [departmentSearchValue, setDepartmentSearchValue] =
    useState<string>('');
  const [titleSearchValue, setTitleSearchValue] = useState<string>('');
  const [emailSearchValue, setEmailSearchValue] = useState<string>('');

  const values = useMemo(
    () => ({
      parentName: userGroupSearchValue,
      department: departmentSearchValue,
      title: titleSearchValue,
      email: emailSearchValue,
    }),
    [
      departmentSearchValue,
      emailSearchValue,
      titleSearchValue,
      userGroupSearchValue,
    ]
  );

  const fieldTextKey = useMemo(
    () => options?.fieldNames?.text || 'text',
    [options?.fieldNames]
  );

  const searchText = useCallback((text: string, keyword: string): boolean => {
    if (!keyword?.trim()) return true;

    const normalizedText = text.toLocaleLowerCase();

    const normalizedTextSearch = keyword
      ?.replaceAll(/\s+/g, ' ')
      .toLocaleLowerCase()
      .trim();

    return normalizedText.includes(normalizedTextSearch);
  }, []);

  const search = useCallback(
    (data: Item[] = [], keyword: string): Item[] => {
      return data.reduce((prev, item: any) => {
        if (item.children && !!(item as any).lastChild) {
          const text = (item as any)[fieldTextKey]?.toString() || '';
          if (!searchText(text, keyword)) {
            const result = search(item.children as Item[], keyword);
            if (!result.length) return prev;

            const modifiedItem = { ...item, children: result };
            return [...prev, modifiedItem];
          }

          return [...prev, item];
        }

        if (item.children && !(item as any).lastChild) {
          const result = search(item.children as Item[], keyword);
          if (!result.length) return prev;

          const modifiedItem = { ...item, children: result };
          return [...prev, modifiedItem];
        }

        const text = (item as any)[fieldTextKey]?.toString() || '';
        if (!searchText(text, keyword)) return prev;

        return [...prev, item];
      }, [] as Item[]);
    },
    [fieldTextKey, searchText]
  );

  const filter = useCallback(
    (data: Item[], query: any): Item[] => {
      return data.reduce((prev, item: Item) => {
        if (item.children) {
          const result = filter(item.children as Item[], query);
          if (!result.length) return prev;

          const modifiedItem = { ...item, children: result };
          return [...prev, modifiedItem];
        }

        const text = Object.keys(query).map((key) => {
          return searchText((item as any)[key] || '', query[key]);
        });
        const checker = text.every(Boolean);
        if (!checker) return prev;

        return [...prev, item];
      }, [] as Item[]);
    },
    [searchText]
  );

  const data = useMemo(
    () => (searchValue ? search(items, searchValue) : undefined),
    [searchValue, items, search]
  );

  const filterData = useMemo(() => {
    const hasSomeValues = Object.values(values).some((v) => !!v);
    if (!hasSomeValues) return undefined;
    return filter(items, values);
  }, [filter, items, values]);

  return {
    searchValue,
    setSearchValue,
    userGroupSearchValue,
    setUserGroupSearchValue,
    departmentSearchValue,
    setDepartmentSearchValue,
    titleSearchValue,
    setTitleSearchValue,
    emailSearchValue,
    setEmailSearchValue,
    data,
    filterData,
  };
}
