import { Tabs } from '@mui/material';
import { styled } from '@mui/styles';
import React from 'react';

const StyledTabs = styled(Tabs)({
  '& .Mui-selected': {
    color: '#6db9be',
  },
  '& .MuiTabs-indicator': {
    backgroundColor: '#6db9be',
  },
});

interface TabsProps {
  children?: React.ReactNode;
  className?: string;
  value: number;
  onChange: (event: React.SyntheticEvent, value: any) => void;
}

export const CustomTabs: React.FC<TabsProps> = ({
  children,
  className,
  value,
  onChange,
  ...restProps
}) => {
  return (
    <StyledTabs
      className={className}
      value={value}
      onChange={onChange}
      {...restProps}
    >
      {children}
    </StyledTabs>
  );
};
