import React from 'react';
import styles from './index.module.css';

export const SelectionCheckbox = React.forwardRef<any, any>(
  ({ indeterminate, ...rest }, ref) => {
    const defaultRef = React.useRef();
    const resolvedRef: any = ref || defaultRef;

    return (
      <input
        className={styles.selectionCheckbox}
        type="checkbox"
        ref={resolvedRef}
        {...rest}
      />
    );
  }
);
