import {
  Checkbox,
  FormControlLabel,
  FormControlLabelProps,
} from '@mui/material';
import { useTranslation } from 'core/context/i18n.context';
import { useEffect } from 'react';
import { useFirstRender } from 'shared/hooks/use-first-render';
import { useFilterCheckbox } from './use-filter-checkbox';
import styles from './index.module.css';
import { useLocation } from 'react-router-dom';

export interface FilterCheckboxDataItem {
  name: string;
}

export interface FilterCheckboxProps {
  dataSource: FilterCheckboxDataItem[];
  value?: FilterCheckboxDataItem[];
  onChange?: (data: FilterCheckboxDataItem[]) => void;
  isActive?: boolean;
}

export const FilterCheckbox: React.FC<FilterCheckboxProps> = ({
  dataSource = [],
  value = [],
  onChange,
  isActive,
}) => {
  const { t } = useTranslation();
  const isFirstRender = useFirstRender();
  const { selectedItems, check, uncheck, setSelectedItems, isChecked } =
    useFilterCheckbox();
  const location = useLocation();
  const isUserDetails =
    location.search.includes('predefinedReport') &&
    location.search.includes('3');

  useEffect(() => {
    if (isUserDetails && isActive) {
      check('showPhoto');
      setSelectedItems(value);
    }
    setSelectedItems(value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUserDetails, isActive]);

  const handleChange: FormControlLabelProps['onChange'] = (event, checked) => {
    const nameAsValue = (event.target as HTMLInputElement).value;
    if (checked) {
      check(nameAsValue);
    } else {
      uncheck(nameAsValue);
    }
  };

  useEffect(() => {
    if (!isFirstRender && onChange) {
      onChange(selectedItems);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedItems]);

  return (
    <div className={styles.checkbox}>
      {dataSource.map((item) => (
        <FormControlLabel
          key={item.name}
          label={
            <span className={styles.formControlLabel}>
              {t('predefinedReport.condition.' + item.name)}
            </span>
          }
          control={
            <Checkbox
              size="small"
              sx={{
                color: '#8c8282',
                '&.Mui-checked': {
                  color: '#8c8282',
                },
              }}
            />
          }
          value={item.name}
          checked={isChecked(item.name)}
          onChange={handleChange}
        />
      ))}
    </div>
  );
};
