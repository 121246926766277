import {
  ErrorResponseDto,
  ReportTemplate,
  ReportTemplatesApi,
  UpdateReportTemplateDto,
} from 'api-client';
import axios from 'axios';
import { useMutation } from 'react-query';
import { apiConfig } from 'shared/utils/api-config';

export function useUpdateReportTemplate() {
  const api = new ReportTemplatesApi(apiConfig());

  return useMutation<
    ReportTemplate,
    ErrorResponseDto,
    { id: number; data: UpdateReportTemplateDto }
  >(({ id, data }: { id: number; data: UpdateReportTemplateDto }) =>
    api
      .updateReportTemplate(id, data)
      .then((res) => res.data)
      .catch((error) => {
        if (axios.isAxiosError(error)) {
          throw error.response!.data;
        }
        throw error;
      })
  );
}
