import { Tab } from '@mui/material';
import { useTranslation } from 'core/context/i18n.context';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { DynamicDateTime } from 'shared/components/dynamic-date-time';
import { CustomTabs } from 'shared/components/tabs';
import { useFirstRender } from 'shared/hooks/use-first-render';
import { DateTimeRangePicker } from '../../date-time-range-picker';
import { TabPanel } from '../../tab-panel';

const PeriodTypes = ['static', 'dynamic'];

interface FilterPeriodProps {
  value?: any;
  onChange: (data: any) => void;
  oneType?: 'static' | 'dynamic';
}

export interface StaticValue {
  startDatetime: Date;
  endDatetime: Date;
}
export interface DynamicValue {
  startDaysBack: number;
  endDaysBack: number;
  startTimeBack: Date;
  endTimeBack: Date;
}
export interface DateTimeForm {
  type: string;
  value?: StaticValue | DynamicValue;
}

export const FilterPeriod: React.FC<FilterPeriodProps> = ({
  value = [],
  onChange,
  oneType,
}) => {
  const { t } = useTranslation();
  const isFirstRender = useFirstRender();

  const isAllTypes = useMemo(() => !oneType, [oneType]);
  const isStatic = useMemo(
    () => isAllTypes || oneType === 'static',
    [isAllTypes, oneType]
  );
  const isDynamic = useMemo(
    () => isAllTypes || oneType === 'dynamic',
    [isAllTypes, oneType]
  );

  const initialCurrentTab = useMemo(() => {
    if (!isAllTypes && isStatic) return 0;
    if (!isAllTypes && isDynamic) return 1;
    return value.type ? PeriodTypes.indexOf(value.type) : 0;
  }, [isAllTypes, isDynamic, isStatic, value.type]);

  const [currentTab, setCurrentTab] = useState(initialCurrentTab);
  const [staticValue, setStaticValue] = useState<StaticValue>({
    startDatetime:
      value.type === 'static' && !!value.value
        ? new Date(value.value.startDatetime)
        : new Date(),
    endDatetime:
      value.type === 'static' && !!value.value
        ? new Date(value.value.endDatetime)
        : new Date(),
  });
  const [dynamicValue, setDynamicValue] = useState<DynamicValue>({
    startDaysBack:
      !!value.value && value.type === 'dynamic' ? value.value.startDaysBack : 0,
    endDaysBack:
      !!value.value && value.type === 'dynamic' ? value.value.endDaysBack : 0,
    startTimeBack:
      !!value.value && value.type === 'dynamic'
        ? new Date(value.value.startTimeBack)
        : new Date(),
    endTimeBack:
      !!value.value && value.type === 'dynamic'
        ? new Date(value.value.endTimeBack)
        : new Date(),
  });
  const [formDateTime, setForm] = useState<DateTimeForm>({
    type: PeriodTypes[currentTab],
    value: undefined,
  });

  useEffect(() => {
    if (value.type === 'static') {
      setForm({ type: PeriodTypes[currentTab], value: staticValue });
    }

    if (value.type === 'dynamic') {
      setForm({ type: PeriodTypes[currentTab], value: dynamicValue });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value.type, currentTab]);

  const handleChangeTab = (event: React.SyntheticEvent, newValue: number) => {
    setCurrentTab(newValue);
  };

  const onChangeStaticValue = useCallback(
    (data: StaticValue) => {
      setStaticValue(data);
      setForm({ type: PeriodTypes[currentTab], value: data });
    },
    [currentTab]
  );

  const onChangeDynamicValue = useCallback(
    (data: DynamicValue) => {
      setDynamicValue(data);
      setForm({ type: PeriodTypes[currentTab], value: data });
    },
    [currentTab]
  );

  useEffect(() => {
    if (onChange && !isFirstRender) {
      onChange(formDateTime);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formDateTime]);

  return (
    <div>
      <CustomTabs value={currentTab} onChange={handleChangeTab}>
        <Tab disabled={!isStatic} label={t('reportApp.static')}></Tab>
        <Tab disabled={!isDynamic} label={t('reportApp.dynamic')}></Tab>
      </CustomTabs>
      <TabPanel value={currentTab} index={0}>
        <DateTimeRangePicker
          onChange={onChangeStaticValue}
          values={staticValue}
          type={PeriodTypes[currentTab]}
        />
      </TabPanel>
      <TabPanel value={currentTab} index={1}>
        <DynamicDateTime
          onChange={onChangeDynamicValue}
          values={dynamicValue}
          type={PeriodTypes[currentTab]}
        />
      </TabPanel>
    </div>
  );
};
