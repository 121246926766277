import { SettingsApi } from 'api-client';
import { useQuery } from 'react-query';
import { apiConfig } from 'shared/utils/api-config';

export function useGetReportSetting() {
  const api = new SettingsApi(apiConfig());

  return useQuery(
    ['reportSetting'],
    async () => {
      const { data } = await api.getReportSetting();
      return data;
    },
    { suspense: true }
  );
}
