/* tslint:disable */
/* eslint-disable */
/**
 * BioStar 2 Report API
 * API Documentation for BioStar 2 Report
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @interface EditScheduleDto
 */
export interface EditScheduleDto {
    /**
     * 
     * @type {string}
     * @memberof EditScheduleDto
     */
    name?: string;
    /**
     * 
     * @type {number}
     * @memberof EditScheduleDto
     */
    reportTemplateId?: number;
    /**
     * 
     * @type {string}
     * @memberof EditScheduleDto
     */
    frequency: EditScheduleDtoFrequency;
    /**
     * 
     * @type {string}
     * @memberof EditScheduleDto
     */
    generateTime?: string;
    /**
     * 
     * @type {string}
     * @memberof EditScheduleDto
     */
    outputType?: EditScheduleDtoOutputType;
    /**
     * 
     * @type {boolean}
     * @memberof EditScheduleDto
     */
    showTitleOnEveryPage?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof EditScheduleDto
     */
    showHeader?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof EditScheduleDto
     */
    showHeaderOnEveryPage?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof EditScheduleDto
     */
    showPageNumber?: boolean;
    /**
     * 
     * @type {string}
     * @memberof EditScheduleDto
     */
    fileFormat?: EditScheduleDtoFileFormat;
}

/**
    * @export
    * @enum {string}
    */
export enum EditScheduleDtoFrequency {
    Daily = 'daily'
}
/**
    * @export
    * @enum {string}
    */
export enum EditScheduleDtoOutputType {
    Export = 'export'
}
/**
    * @export
    * @enum {string}
    */
export enum EditScheduleDtoFileFormat {
    Csv = 'csv'
}



