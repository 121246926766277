import { QueryClient } from 'react-query';

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      cacheTime: 0,
      keepPreviousData: false,
      retry: 1,
      staleTime: 0,
      refetchOnWindowFocus: false,
      onError: (err) => {
        const errObj = JSON.parse(JSON.stringify(err));
        handle401Error(errObj);
      },
    },
  },
});

/**
 * Handle unauthorized requests error.
 *
 * If the error is 401 and have an auth token in session storage,
 * remove the token and redirect to home page.
 *
 * @param err Error object from the query.
 */
function handle401Error(err: Record<string, any>) {
  const isError401 = err.status === 401;
  const hasAuthToken = sessionStorage.getItem('authToken');

  if (isError401 && hasAuthToken) {
    sessionStorage.removeItem('authToken');
    window.location.href = '/';
  }
}
