export function flattenNestedChildren<D extends { children?: D[] }>(
  data: D[]
): D[] {
  return [...data].reduce((prev: D[], curr) => {
    let result = [...prev];

    if (curr.children?.length) {
      const children = flattenNestedChildren(curr.children);
      result = [...result, ...children];
    }

    const { children, ...restCurr } = curr;
    return [...result, restCurr as D];
  }, []);
}
