import { CircularProgress } from '@mui/material';
import classNames from 'classnames';
import { useTranslation } from 'core/context/i18n.context';
import React, { useEffect, useMemo, useState } from 'react';
import {
  useTable,
  Column,
  useResizeColumns,
  useFlexLayout,
  useSortBy,
} from 'react-table';
import { useFirstRender } from 'shared/hooks/use-first-render';
import { ItemNotFound } from '../item-not-found';
import styles from './index.module.css';
import fingerfaceIcon from 'core/assets/authmode_icon_fingerface.png';
import idIcon from 'core/assets/authmode_icon_id.png';
import cardIcon from 'core/assets/authmode_icon_card.png';
import passwordIcon from 'core/assets/authmode_icon_password.png';
import faceIcon from 'core/assets/authmode_icon_face.png';
import fingerIcon from 'core/assets/authmode_icon_finger.png';
import { SorterDtoOrder } from 'api-client';
import { AuthMode } from './auth-mode.enum';

export interface TableProps {
  columns: (Column & { initialOrder?: SorterDtoOrder })[];
  data: {}[];
  loading: boolean;
  onColumnSortChange?: (sortByState: any) => void;
  isRealTimeEvents?: boolean;
}

export const Table: React.FC<TableProps> = ({
  columns,
  data,
  loading,
  onColumnSortChange,
  isRealTimeEvents = false,
}) => {
  const { t } = useTranslation();
  const isFirstRender = useFirstRender();
  const [isTheEndOfScroll, setIsTheEndOfScroll] = useState(false);
  const [isOnTheLeft, setIsOnTheLeft] = useState(false);

  const columnsToBeAlignCenter = [
    'number',
    'userId',
    'status',
    'period',
    'numberOfCard',
    'numberOfFace',
    'numberOfFingerprint',
    'pin',
    'card',
    'fingerprint',
    'face',
    'exclude',
    'cardType',
    'cardDataFormat',
    'cardId',
    'operatorLevel',
    'periodValidity',
  ];

  const isTableScrollable = () => {
    const table = document.getElementById('table');
    if (table?.clientWidth === table?.scrollWidth) {
      return setIsTheEndOfScroll(true);
    }
    return setIsTheEndOfScroll(false);
  };

  const isOnTheLeftTable = () => {
    const table = document.getElementById('table');
    if (table?.scrollLeft === 0) {
      return setIsOnTheLeft(true);
    }
    return setIsOnTheLeft(false);
  };

  useEffect(() => {
    isTableScrollable();
    isOnTheLeftTable();
  }, []);

  const defaultColumn = React.useMemo(
    () => ({
      // When using the useFlexLayout:
      minWidth: 7, // minWidth is only used as a limit for resizing
      width: 150, // width is used for both the flex-basis and flex-grow
      maxWidth: 280, // maxWidth is only used as a limit for resizing
    }),
    []
  );

  const normalizedColumns = useMemo(() => {
    const table = document.getElementById('table');
    return columns.map((column) => {
      let col = { ...column };
      if (column.accessor === 'number') {
        (col as any).disableSortBy = true;
        col.width = 40;
      }

      if (isRealTimeEvents) {
        if (column.accessor === 'datetime') {
          col.width = table?.offsetWidth! * 0.12;
        }

        if (column.accessor === 'device') {
          col.width = table?.offsetWidth! * 0.25;
        }

        if (column.accessor === 'door' || column.accessor === 'event') {
          col.width = table?.offsetWidth! * 0.23;
        }

        if (column.accessor === 'user') {
          col.width = table?.offsetWidth! * 0.1;
        }

        if (column.accessor === 'view') {
          col.width = table?.offsetWidth! * 0.06;
        }
      }

      return col;
    });
  }, [columns, isRealTimeEvents]);

  const sortedColumns = useMemo(() => {
    return normalizedColumns
      .filter((col) => !!col.initialOrder)
      .map((col) => ({
        id: col.accessor,
        desc: col.initialOrder === SorterDtoOrder.Desc,
      }));
  }, [normalizedColumns]);

  const {
    getTableProps,
    headerGroups,
    rows,
    prepareRow,
    disableMultiSort,
    state: { sortBy },
  } = useTable(
    {
      columns: normalizedColumns,
      data,
      defaultColumn,
      initialState: {
        sortBy: sortedColumns,
      },
      manualSortBy: true,
      disableSortBy: isRealTimeEvents,
    } as any,
    useResizeColumns,
    useFlexLayout,
    useSortBy
  ) as any;

  const sortColumn = (column: any) => () => {
    if (!column.canSort) return;
    column.toggleSortBy(undefined, disableMultiSort);
  };

  useEffect(() => {
    if (onColumnSortChange && !isFirstRender) {
      onColumnSortChange(sortBy);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onColumnSortChange, sortBy]);

  const renderLoading = () => {
    return (
      <div className={styles.infoWrapper}>
        <div className={styles.loading}>
          <CircularProgress />
        </div>
      </div>
    );
  };

  const renderNoResult = () => {
    return (
      <div className={styles.infoWrapper}>
        <div className={styles.noResult}>
          <ItemNotFound message={t('reportApp.noResult')} lite />
        </div>
      </div>
    );
  };

  const renderItem = (fieldName: any, value?: any) => {
    if (!value && value !== 0) {
      return '-';
    }

    if (typeof value === 'string' && value.startsWith('data:image')) {
      return (
        <img src={value} alt={t('reportApp.image')} className={styles.image} />
      );
    }

    if (fieldName === 'accessGroup') {
      const parsed = JSON.parse(value);
      const separated = parsed.truncateGroups
        .split(' + ')
        .filter((v: string) => !!v);
      const firstGroupName = separated[0];
      const moreItemsCount =
        Boolean(parsed.nameOfGroups.length) && parsed.nameOfGroups.length > 1
          ? separated[separated.length - 1]
          : 0;

      return (
        <div>
          {firstGroupName}
          {!!moreItemsCount && (
            <span>
              {' '}
              + <span className={styles.moreGroups}>{moreItemsCount}</span>
            </span>
          )}
        </div>
      );
    }

    if (fieldName.startsWith('authMode')) {
      const [authMode, type] = value.split('|');
      const description = getDescription(authMode);

      return (
        <div className={styles.authModeCell}>
          {description} <div className={styles.authModeType}>{type}</div>
        </div>
      );
    }

    if (fieldName === 'period') {
      const datetime = value.split(' ~ ');
      return (
        <div>
          {datetime[0]} <br /> ~ {datetime[1]}
        </div>
      );
    }

    return value;
  };

  const getImgSrc = (name: string, idx: number) => {
    switch (name) {
      case AuthMode.ID:
        return (
          <img src={idIcon} alt={name} className={styles.icon} key={name} />
        );
      case AuthMode.Card:
        return (
          <img src={cardIcon} alt={name} className={styles.icon} key={name} />
        );
      case AuthMode.Fingerprint:
        return (
          <img src={fingerIcon} alt={name} className={styles.icon} key={name} />
        );
      case AuthMode.Biometric:
        return (
          <img
            src={fingerfaceIcon}
            alt={name}
            className={styles.icon}
            key={name}
          />
        );
      case AuthMode.Face:
        return (
          <img src={faceIcon} alt={name} className={styles.icon} key={name} />
        );
      case AuthMode.PIN:
        return (
          <img
            src={passwordIcon}
            alt={name}
            className={styles.icon}
            key={name}
          />
        );
      case '+':
      case '/':
        return (
          <span className={styles.nameSpan} key={`${name}+${idx}`}>
            {name}
          </span>
        );
      default:
        return null;
    }
  };

  const getDescription = (description: string) => {
    const splitted = description.split(/([$-/:-?{-~!"^_`[\]]+)/);

    return (
      <div className={styles.descriptionIcons}>
        {splitted.map((value: string, idx: number) => getImgSrc(value, idx))}
      </div>
    );
  };

  const renderData = () => {
    return rows.map((row: any) => {
      prepareRow(row);
      return (
        <div {...row.getRowProps()} className={styles.tbodyRow}>
          {row.cells.map((cell: any) => {
            return (
              <div
                {...cell.getCellProps()}
                className={classNames(
                  styles.tbodyData,
                  {
                    [styles.textAlignCenter]: columnsToBeAlignCenter.includes(
                      cell.column.id
                    ),
                  },
                  { [styles.noPadding]: cell.column.id.startsWith('authMode') },
                  {
                    [styles.authModeColumn]:
                      cell.column.id.startsWith('authMode'),
                  }
                )}
              >
                {renderItem(cell.column.id, cell.value)}
              </div>
            );
          })}
        </div>
      );
    });
  };

  const scrollMoreTable = () => {
    const table = document.getElementById('table');
    if (table?.scrollLeft! + table?.offsetWidth! >= table?.scrollWidth!) {
      setIsTheEndOfScroll(true);
    } else {
      setIsTheEndOfScroll(false);
    }

    if (table?.scrollLeft === 0) {
      setIsOnTheLeft(true);
    } else {
      setIsOnTheLeft(false);
    }
  };

  return (
    <div
      {...getTableProps()}
      className={classNames(styles.table, styles.scrollbarTrack, {
        [styles.scrollbarBorderRadiusRight]: !isOnTheLeft,
        [styles.scrollbarBorderRadiusLeft]: isOnTheLeft,
      })}
      id="table"
      style={{
        borderRadius: !isRealTimeEvents
          ? isTheEndOfScroll
            ? '8px'
            : '8px 0 0 8px'
          : '8px',
      }}
      onScroll={scrollMoreTable}
    >
      <div className={styles.thead}>
        {headerGroups.map((headerGroup: any) => (
          <div
            {...headerGroup.getHeaderGroupProps()}
            className={styles.theadRow}
          >
            {headerGroup.headers.map((column: any) => {
              return (
                <div
                  {...column.getHeaderProps()}
                  className={classNames(styles.theadData, {
                    [styles.authModeColumn]: column.id.startsWith('authMode'),
                  })}
                >
                  <div
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    className={styles.clickArea}
                    onClick={isRealTimeEvents ? undefined : sortColumn(column)}
                  >
                    <div className={styles.theadDataValue}>
                      {column.render('Header')}
                      <span className={styles.sorting}>
                        {column.isSorted
                          ? column.isSortedDesc
                            ? ' ▼'
                            : ' ▲'
                          : ''}
                      </span>
                    </div>
                  </div>
                  {column.canResize && (
                    <div
                      {...column.getResizerProps()}
                      className={classNames(styles.resizer)}
                    />
                  )}
                </div>
              );
            })}
          </div>
        ))}
      </div>
      <div className={styles.tbody}>
        {loading && renderLoading()}
        {!rows.length && !loading && renderNoResult()}
        {!!rows.length && !loading && renderData()}
      </div>
    </div>
  );
};
