import { Box, BoxProps } from '@mui/system';
import classNames from 'classnames';
import styles from './index.module.css';

export const Card: React.FC<BoxProps> = ({ children, className, ...props }) => {
  return (
    <Box className={classNames(styles.card, className)} {...props}>
      {children}
    </Box>
  );
};
