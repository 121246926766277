import { Box, BoxProps } from '@mui/system';

export const InputError: React.FC<BoxProps> = ({ children, ...props }) => {
  if (!children) return <></>;

  return (
    <Box color="var(--error-color)" fontSize="12px" py="8px" {...props}>
      {children}
    </Box>
  );
};
