const isMergeableObject = (val: any) => {
  var nonNullObject = val && typeof val === 'object';

  return (
    nonNullObject &&
    Object.prototype.toString.call(val) !== '[object RegExp]' &&
    Object.prototype.toString.call(val) !== '[object Date]'
  );
};

const emptyTarget = (val: any) => {
  return Array.isArray(val) ? [] : {};
};

const cloneIfNecessary = (value: any, optionsArgument: any): any => {
  var clone = optionsArgument && optionsArgument.clone === true;
  return clone && isMergeableObject(value)
    ? mergeDeep(emptyTarget(value), value, optionsArgument)
    : value;
};

const defaultArrayMerge = (target: any, source: any, optionsArgument?: any) => {
  var destination = target.slice();
  source.forEach((e: any, i: any) => {
    if (typeof destination[i] === 'undefined') {
      destination[i] = cloneIfNecessary(e, optionsArgument);
    } else if (isMergeableObject(e)) {
      destination[i] = mergeDeep(target[i], e, optionsArgument);
    } else if (target.indexOf(e) === -1) {
      destination.push(cloneIfNecessary(e, optionsArgument));
    }
  });
  return destination;
};

const mergeObject = (target: any, source: any, optionsArgument: any) => {
  var destination = {} as any;
  if (isMergeableObject(target)) {
    Object.keys(target).forEach((key) => {
      destination[key] = cloneIfNecessary(target[key], optionsArgument);
    });
  }
  Object.keys(source).forEach((key) => {
    if (!isMergeableObject(source[key]) || !target[key]) {
      destination[key] = cloneIfNecessary(source[key], optionsArgument);
    } else {
      destination[key] = mergeDeep(target[key], source[key], optionsArgument);
    }
  });
  return destination;
};

export const mergeDeep = (target: any, source: any, optionsArgument?: any) => {
  var array = Array.isArray(source);
  var options = optionsArgument || { arrayMerge: defaultArrayMerge };
  var arrayMerge = options.arrayMerge || defaultArrayMerge;

  if (array) {
    return Array.isArray(target)
      ? arrayMerge(target, source, optionsArgument)
      : cloneIfNecessary(source, optionsArgument);
  } else {
    return mergeObject(target, source, optionsArgument);
  }
};
