import { PrintApi } from 'api-client';
import { useQuery } from 'react-query';
import { apiConfig } from 'shared/utils/api-config';

export function usePrintReportConfig() {
  const printApi = new PrintApi(apiConfig());

  const fetchLastConfig = async () => {
    const { data } = await printApi.getLastConfiguration();
    return data;
  };

  return useQuery('printLastConfig', fetchLastConfig);
}
