import { WidgetsApi } from 'api-client';
import { useQuery } from 'react-query';
import { apiConfig } from 'shared/utils/api-config';

export function useUserImageLog(imageData: string) {
  const api = new WidgetsApi(apiConfig());

  const fetchUserImageLog = async () => {
    const { data } = await api.userImageLog(imageData);
    return data;
  };

  return useQuery(['userImageLog', imageData], fetchUserImageLog, {
    enabled: !!imageData,
  });
}
