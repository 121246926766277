import { CircularProgress } from '@mui/material';
import { useTranslation } from 'core/context/i18n.context';
import { useRegenerateReport } from 'generate-report/hooks/use-regenerate-report';
import { useCallback, useEffect } from 'react';
import { ButtonPrimary } from 'shared/components/buttons/button-primary';
import { BaseDialog } from 'shared/components/dialogs/base-dialog';
import { useErrorDialog } from 'shared/contexts/error-dialog.context';
import { useFilterCondition } from 'shared/components/wizard/hooks/use-filter-condition';
import { useFilterReducer } from 'shared/components/wizard/hooks/use-filter-reducer';
import { WizardStepProps } from 'shared/components/wizard/index.type';
import styles from './index.module.css';
import { RegenerateReportDto, ReportObjectFilterDto } from 'api-client';
import { ButtonDefault } from 'shared/components/buttons/button-default';
import {
  FilterCheckbox,
  FilterCheckboxDataItem,
} from 'shared/components/filter-conditions/filter-checkbox';

interface UpdateCheckboxConditionProps {
  open: boolean;
  onClose: () => void;
  onSuccess: (id: number) => void;
  step?: WizardStepProps;
  value: FilterCheckboxDataItem[];
  reportId?: number;
}

export const UpdateCheckboxCondition: React.FC<UpdateCheckboxConditionProps> =
  ({ open, onClose, step, value, reportId, onSuccess }) => {
    const { t } = useTranslation();
    const filterCondition = useFilterCondition([step]);
    const regenerateReportMutation = useRegenerateReport();
    const { showErrorDialog } = useErrorDialog();

    const [dataFilter, dispatchFilter] = useFilterReducer();

    const onChangeFilterCheckbox = useCallback(
      (stepIndex: number) => (data: FilterCheckboxDataItem[]) =>
        dispatchFilter({
          type: 'onChange',
          payload: { stepIndex, value: data },
        }),
      // eslint-disable-next-line react-hooks/exhaustive-deps
      []
    );

    useEffect(() => {
      onChangeFilterCheckbox(0)(value);
    }, [value, onChangeFilterCheckbox]);

    const handleRegenerateReport = () => {
      if (!reportId) return;

      const payload: RegenerateReportDto = {
        data: {
          filterConditions: [
            {
              filterConditionId: step!.id,
              value: dataFilter[0] as ReportObjectFilterDto[],
            },
          ],
        },
      };

      regenerateReportMutation.mutate(
        {
          id: reportId,
          regenerateReportDto: payload,
        },
        {
          onError: (error) =>
            showErrorDialog({
              alertType: 'error',
              message: error.message,
            }),
          onSuccess: (data) => onSuccess(data.id),
        }
      );
    };

    const DialogActions = () => {
      return (
        <>
          <ButtonPrimary
            title={t('reportApp.regenerate')}
            size="small"
            className={styles.dialogActionButton}
            onClick={handleRegenerateReport}
            loading={regenerateReportMutation.isLoading}
            disabled={filterCondition.isLoading}
          ></ButtonPrimary>
          <ButtonDefault
            title={t('reportApp.cancel')}
            onClick={onClose}
            size="small"
            className={styles.dialogActionButton}
          ></ButtonDefault>
        </>
      );
    };

    const renderLoading = () => {
      return (
        <div className={styles.loadingWrapper}>
          <CircularProgress />
        </div>
      );
    };

    return (
      <BaseDialog
        onClose={onClose}
        open={open}
        title={t('reportApp.filterCondition')}
        disableBackdropClick
        disableEscapeKeyDown
        actions={<DialogActions />}
      >
        {filterCondition.isLoading && renderLoading()}
        {!!filterCondition.steps.length && !filterCondition.isLoading && (
          <FilterCheckbox
            dataSource={filterCondition.steps[0].data || []}
            value={dataFilter[0]}
            onChange={onChangeFilterCheckbox(0)}
          />
        )}
      </BaseDialog>
    );
  };
