import { Box } from '@mui/material';
import styles from './index.module.css';

export const AppBar: React.FC = ({ children }) => {
  return (
    <Box zIndex={10} className={styles.appBar}>
      {children}
    </Box>
  );
};
