import { FC, useContext, createContext, useState } from 'react';

const useValue = () => {
  const [isRename, setIsRename] = useState<string>();

  return {
    isRename,
    setIsRename,
  };
};

const RenameContext = createContext({} as ReturnType<typeof useValue>);
const useRenameContext = () => useContext(RenameContext);
const GenerateReportProvider: FC<any> = (props) => {
  return <RenameContext.Provider value={useValue()} {...props} />;
};

export { GenerateReportProvider, useRenameContext };
