/* tslint:disable */
/* eslint-disable */
/**
 * BioStar 2 Report API
 * API Documentation for BioStar 2 Report
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { ColumnSetting } from '../models';
// @ts-ignore
import { CustomReportSupportedColumnDto } from '../models';
// @ts-ignore
import { ErrorResponseDto } from '../models';
// @ts-ignore
import { GenerateReportDto } from '../models';
// @ts-ignore
import { PaginatedDto } from '../models';
// @ts-ignore
import { RegenerateReportDto } from '../models';
// @ts-ignore
import { ReportContentFilterDto } from '../models';
// @ts-ignore
import { ReportDetailDto } from '../models';
// @ts-ignore
import { UpdateColumnSettingsDto } from '../models';
// @ts-ignore
import { UpdateReportDto } from '../models';
/**
 * ReportsApi - axios parameter creator
 * @export
 */
export const ReportsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Generate a report
         * @param {GenerateReportDto} generateReportDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateReport: async (generateReportDto: GenerateReportDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'generateReportDto' is not null or undefined
            assertParamExists('generateReport', 'generateReportDto', generateReportDto)
            const localVarPath = `/api/report/reports/generate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(generateReportDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get supported columns of the custom report
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCustomReportSupportedColumns: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/report/reports/custom-report-columns`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get generated report content by report id
         * @param {number} id 
         * @param {ReportContentFilterDto} reportContentFilterDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReportContent: async (id: number, reportContentFilterDto: ReportContentFilterDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getReportContent', 'id', id)
            // verify required parameter 'reportContentFilterDto' is not null or undefined
            assertParamExists('getReportContent', 'reportContentFilterDto', reportContentFilterDto)
            const localVarPath = `/api/report/reports/{id}/content`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(reportContentFilterDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get generated report detail by id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReportDetail: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getReportDetail', 'id', id)
            const localVarPath = `/api/report/reports/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Regenerate report
         * @param {number} id 
         * @param {RegenerateReportDto} regenerateReportDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        regenerateReport: async (id: number, regenerateReportDto: RegenerateReportDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('regenerateReport', 'id', id)
            // verify required parameter 'regenerateReportDto' is not null or undefined
            assertParamExists('regenerateReport', 'regenerateReportDto', regenerateReportDto)
            const localVarPath = `/api/report/reports/{id}/regenerate`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(regenerateReportDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update column settings of the generated report
         * @param {number} id 
         * @param {UpdateColumnSettingsDto} updateColumnSettingsDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateColumnSettings: async (id: number, updateColumnSettingsDto: UpdateColumnSettingsDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateColumnSettings', 'id', id)
            // verify required parameter 'updateColumnSettingsDto' is not null or undefined
            assertParamExists('updateColumnSettings', 'updateColumnSettingsDto', updateColumnSettingsDto)
            const localVarPath = `/api/report/reports/{id}/column-settings`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateColumnSettingsDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update generated report partially
         * @param {number} id 
         * @param {UpdateReportDto} updateReportDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateReport: async (id: number, updateReportDto: UpdateReportDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateReport', 'id', id)
            // verify required parameter 'updateReportDto' is not null or undefined
            assertParamExists('updateReport', 'updateReportDto', updateReportDto)
            const localVarPath = `/api/report/reports/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateReportDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ReportsApi - functional programming interface
 * @export
 */
export const ReportsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ReportsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Generate a report
         * @param {GenerateReportDto} generateReportDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async generateReport(generateReportDto: GenerateReportDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.generateReport(generateReportDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get supported columns of the custom report
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCustomReportSupportedColumns(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CustomReportSupportedColumnDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCustomReportSupportedColumns(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get generated report content by report id
         * @param {number} id 
         * @param {ReportContentFilterDto} reportContentFilterDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getReportContent(id: number, reportContentFilterDto: ReportContentFilterDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedDto & object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getReportContent(id, reportContentFilterDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get generated report detail by id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getReportDetail(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReportDetailDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getReportDetail(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Regenerate report
         * @param {number} id 
         * @param {RegenerateReportDto} regenerateReportDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async regenerateReport(id: number, regenerateReportDto: RegenerateReportDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReportDetailDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.regenerateReport(id, regenerateReportDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update column settings of the generated report
         * @param {number} id 
         * @param {UpdateColumnSettingsDto} updateColumnSettingsDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateColumnSettings(id: number, updateColumnSettingsDto: UpdateColumnSettingsDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ColumnSetting>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateColumnSettings(id, updateColumnSettingsDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update generated report partially
         * @param {number} id 
         * @param {UpdateReportDto} updateReportDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateReport(id: number, updateReportDto: UpdateReportDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReportDetailDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateReport(id, updateReportDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ReportsApi - factory interface
 * @export
 */
export const ReportsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ReportsApiFp(configuration)
    return {
        /**
         * 
         * @summary Generate a report
         * @param {GenerateReportDto} generateReportDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateReport(generateReportDto: GenerateReportDto, options?: any): AxiosPromise<number> {
            return localVarFp.generateReport(generateReportDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get supported columns of the custom report
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCustomReportSupportedColumns(options?: any): AxiosPromise<Array<CustomReportSupportedColumnDto>> {
            return localVarFp.getCustomReportSupportedColumns(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get generated report content by report id
         * @param {number} id 
         * @param {ReportContentFilterDto} reportContentFilterDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReportContent(id: number, reportContentFilterDto: ReportContentFilterDto, options?: any): AxiosPromise<PaginatedDto & object> {
            return localVarFp.getReportContent(id, reportContentFilterDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get generated report detail by id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReportDetail(id: number, options?: any): AxiosPromise<ReportDetailDto> {
            return localVarFp.getReportDetail(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Regenerate report
         * @param {number} id 
         * @param {RegenerateReportDto} regenerateReportDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        regenerateReport(id: number, regenerateReportDto: RegenerateReportDto, options?: any): AxiosPromise<ReportDetailDto> {
            return localVarFp.regenerateReport(id, regenerateReportDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update column settings of the generated report
         * @param {number} id 
         * @param {UpdateColumnSettingsDto} updateColumnSettingsDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateColumnSettings(id: number, updateColumnSettingsDto: UpdateColumnSettingsDto, options?: any): AxiosPromise<Array<ColumnSetting>> {
            return localVarFp.updateColumnSettings(id, updateColumnSettingsDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update generated report partially
         * @param {number} id 
         * @param {UpdateReportDto} updateReportDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateReport(id: number, updateReportDto: UpdateReportDto, options?: any): AxiosPromise<ReportDetailDto> {
            return localVarFp.updateReport(id, updateReportDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ReportsApi - object-oriented interface
 * @export
 * @class ReportsApi
 * @extends {BaseAPI}
 */
export class ReportsApi extends BaseAPI {
    /**
     * 
     * @summary Generate a report
     * @param {GenerateReportDto} generateReportDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsApi
     */
    public generateReport(generateReportDto: GenerateReportDto, options?: any) {
        return ReportsApiFp(this.configuration).generateReport(generateReportDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get supported columns of the custom report
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsApi
     */
    public getCustomReportSupportedColumns(options?: any) {
        return ReportsApiFp(this.configuration).getCustomReportSupportedColumns(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get generated report content by report id
     * @param {number} id 
     * @param {ReportContentFilterDto} reportContentFilterDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsApi
     */
    public getReportContent(id: number, reportContentFilterDto: ReportContentFilterDto, options?: any) {
        return ReportsApiFp(this.configuration).getReportContent(id, reportContentFilterDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get generated report detail by id
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsApi
     */
    public getReportDetail(id: number, options?: any) {
        return ReportsApiFp(this.configuration).getReportDetail(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Regenerate report
     * @param {number} id 
     * @param {RegenerateReportDto} regenerateReportDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsApi
     */
    public regenerateReport(id: number, regenerateReportDto: RegenerateReportDto, options?: any) {
        return ReportsApiFp(this.configuration).regenerateReport(id, regenerateReportDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update column settings of the generated report
     * @param {number} id 
     * @param {UpdateColumnSettingsDto} updateColumnSettingsDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsApi
     */
    public updateColumnSettings(id: number, updateColumnSettingsDto: UpdateColumnSettingsDto, options?: any) {
        return ReportsApiFp(this.configuration).updateColumnSettings(id, updateColumnSettingsDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update generated report partially
     * @param {number} id 
     * @param {UpdateReportDto} updateReportDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsApi
     */
    public updateReport(id: number, updateReportDto: UpdateReportDto, options?: any) {
        return ReportsApiFp(this.configuration).updateReport(id, updateReportDto, options).then((request) => request(this.axios, this.basePath));
    }
}
