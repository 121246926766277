import {
  FilterConditionDtoType,
  FilterConditionDtoKeyName,
  GenerateReportDtoType,
} from 'api-client';
import { useTranslation } from 'core/context/i18n.context';
import { WizardStepProps } from 'shared/components/wizard/index.type';
import { useReportType } from 'generate-report/hooks/use-report-type';
import { useCallback, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { ScreenLoading } from 'shared/components/screen-loading';
import { useErrorDialog } from 'shared/contexts/error-dialog.context';
import { useGenerateReport } from 'generate-report/hooks/use-generate-report';
import { Wizard } from 'shared/components/wizard';

interface GenerateReportByTypeProps {
  reportTypeId: string;
}

export const GenerateReportByType: React.FC<GenerateReportByTypeProps> = ({
  reportTypeId,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { showErrorDialog } = useErrorDialog();
  const generateReportMutation = useGenerateReport();

  const reportType = useReportType(reportTypeId);

  const getTitle = (keyName: string, conditionName?: string) => {
    switch (keyName) {
      case FilterConditionDtoKeyName.UsersAndGroups:
        return t('predefinedReport.dialog.usersOrGroups');
      case FilterConditionDtoKeyName.Credential:
        return t('predefinedReport.dialog.credentials');
      case FilterConditionDtoKeyName.CardTypes:
        return t('predefinedReport.dialog.cards');
      case FilterConditionDtoKeyName.DevicesAndGroups:
        return t('predefinedReport.dialog.device');
      case FilterConditionDtoKeyName.UserGroups:
        return t('predefinedReport.dialog.userGroups');
      case FilterConditionDtoKeyName.ShowPhoto:
      case FilterConditionDtoKeyName.ShowOnlyAssignedCard:
        return t('predefinedReport.dialog.checkbox');
      case FilterConditionDtoKeyName.Days:
        return t('predefinedReport.dialog.days');
      case FilterConditionDtoKeyName.Months:
        return t('predefinedReport.dialog.months');
      default:
        return;
    }
  };

  const wizardSteps = useMemo(() => {
    if (!reportType.data) return [];
    return (reportType.data.filterConditions || []).map((condition) => {
      const prop: WizardStepProps = {
        stepIndex: condition.defaultItemOrder - 1,
        type: condition.type as FilterConditionDtoType,
        value: condition.keyName,
        id: condition.id,
        rules: [],
        title: getTitle(condition.keyName, condition.name),
      };

      if (
        [
          FilterConditionDtoType.ObjectSingle,
          FilterConditionDtoType.ObjectMultiple,
          FilterConditionDtoType.Period,
          FilterConditionDtoType.Number,
        ].includes(condition.type)
      ) {
        prop.rules.push('required');
      }

      if (
        FilterConditionDtoType.Number &&
        (condition.keyName === 'days' || condition.keyName === 'months')
      ) {
        prop.rules.push('limit');
      }

      return prop;
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reportType.data]);

  const backToGenerateReportPage = useCallback(
    () => navigate('..'),
    [navigate]
  );
  const handleSuccessWizard = (id: number) => navigate(`../reports/${id}`);

  useEffect(() => {
    if (!reportType.data && reportType.isFetched) {
      return showErrorDialog({
        alertType: 'error',
        message: t('reportApp.reportTypeNotFound'),
        onClose: () => backToGenerateReportPage,
      });
    }
  }, [
    backToGenerateReportPage,
    reportType.data,
    reportType.isFetched,
    showErrorDialog,
    t,
  ]);

  const generateReport = useCallback(() => {
    if (!reportType.data) return;

    generateReportMutation.mutate(
      {
        type: GenerateReportDtoType.PredefinedReport,
        data: { id: reportType.data.id },
      },
      {
        onSuccess: (data: any) => handleSuccessWizard(data),
        onError: (error) =>
          showErrorDialog({
            alertType: 'error',
            message: error.message,
            onClose: () => backToGenerateReportPage,
          }),
      }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reportType.data]);

  useEffect(() => {
    if (reportType.data && !reportType.data.filterConditions?.length) {
      generateReport();
    }
  }, [reportType.data, generateReport]);

  if (reportType.isLoading || !reportType.data?.filterConditions?.length) {
    return <ScreenLoading />;
  }

  return (
    <Wizard
      open={!!reportType.data}
      onClose={backToGenerateReportPage}
      onSuccess={handleSuccessWizard}
      steps={wizardSteps}
      reportType={reportType.data}
    ></Wizard>
  );
};
