/* tslint:disable */
/* eslint-disable */
/**
 * BioStar 2 Report API
 * API Documentation for BioStar 2 Report
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { AddScheduleDto } from '../models';
// @ts-ignore
import { DeleteSchedulesDto } from '../models';
// @ts-ignore
import { EditScheduleDto } from '../models';
// @ts-ignore
import { ErrorResponseDto } from '../models';
// @ts-ignore
import { ScheduleDto } from '../models';
/**
 * SchedulesApi - axios parameter creator
 * @export
 */
export const SchedulesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Add new report schedule
         * @param {AddScheduleDto} addScheduleDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addSchedule: async (addScheduleDto: AddScheduleDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'addScheduleDto' is not null or undefined
            assertParamExists('addSchedule', 'addScheduleDto', addScheduleDto)
            const localVarPath = `/api/report/schedules`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(addScheduleDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete schedules
         * @param {DeleteSchedulesDto} deleteSchedulesDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSchedules: async (deleteSchedulesDto: DeleteSchedulesDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'deleteSchedulesDto' is not null or undefined
            assertParamExists('deleteSchedules', 'deleteSchedulesDto', deleteSchedulesDto)
            const localVarPath = `/api/report/schedules`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(deleteSchedulesDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Edit Schedules
         * @param {number} id 
         * @param {EditScheduleDto} editScheduleDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editSchedule: async (id: number, editScheduleDto: EditScheduleDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('editSchedule', 'id', id)
            // verify required parameter 'editScheduleDto' is not null or undefined
            assertParamExists('editSchedule', 'editScheduleDto', editScheduleDto)
            const localVarPath = `/api/report/schedules/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(editScheduleDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get a report schedule
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSchedule: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getSchedule', 'id', id)
            const localVarPath = `/api/report/schedules/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all report schedules
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSchedules: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/report/schedules`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SchedulesApi - functional programming interface
 * @export
 */
export const SchedulesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SchedulesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Add new report schedule
         * @param {AddScheduleDto} addScheduleDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addSchedule(addScheduleDto: AddScheduleDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addSchedule(addScheduleDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete schedules
         * @param {DeleteSchedulesDto} deleteSchedulesDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteSchedules(deleteSchedulesDto: DeleteSchedulesDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteSchedules(deleteSchedulesDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Edit Schedules
         * @param {number} id 
         * @param {EditScheduleDto} editScheduleDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async editSchedule(id: number, editScheduleDto: EditScheduleDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.editSchedule(id, editScheduleDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get a report schedule
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSchedule(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ScheduleDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSchedule(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get all report schedules
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSchedules(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ScheduleDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSchedules(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SchedulesApi - factory interface
 * @export
 */
export const SchedulesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SchedulesApiFp(configuration)
    return {
        /**
         * 
         * @summary Add new report schedule
         * @param {AddScheduleDto} addScheduleDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addSchedule(addScheduleDto: AddScheduleDto, options?: any): AxiosPromise<void> {
            return localVarFp.addSchedule(addScheduleDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete schedules
         * @param {DeleteSchedulesDto} deleteSchedulesDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSchedules(deleteSchedulesDto: DeleteSchedulesDto, options?: any): AxiosPromise<void> {
            return localVarFp.deleteSchedules(deleteSchedulesDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Edit Schedules
         * @param {number} id 
         * @param {EditScheduleDto} editScheduleDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editSchedule(id: number, editScheduleDto: EditScheduleDto, options?: any): AxiosPromise<void> {
            return localVarFp.editSchedule(id, editScheduleDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get a report schedule
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSchedule(id: number, options?: any): AxiosPromise<ScheduleDto> {
            return localVarFp.getSchedule(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get all report schedules
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSchedules(options?: any): AxiosPromise<Array<ScheduleDto>> {
            return localVarFp.getSchedules(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SchedulesApi - object-oriented interface
 * @export
 * @class SchedulesApi
 * @extends {BaseAPI}
 */
export class SchedulesApi extends BaseAPI {
    /**
     * 
     * @summary Add new report schedule
     * @param {AddScheduleDto} addScheduleDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SchedulesApi
     */
    public addSchedule(addScheduleDto: AddScheduleDto, options?: any) {
        return SchedulesApiFp(this.configuration).addSchedule(addScheduleDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete schedules
     * @param {DeleteSchedulesDto} deleteSchedulesDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SchedulesApi
     */
    public deleteSchedules(deleteSchedulesDto: DeleteSchedulesDto, options?: any) {
        return SchedulesApiFp(this.configuration).deleteSchedules(deleteSchedulesDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Edit Schedules
     * @param {number} id 
     * @param {EditScheduleDto} editScheduleDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SchedulesApi
     */
    public editSchedule(id: number, editScheduleDto: EditScheduleDto, options?: any) {
        return SchedulesApiFp(this.configuration).editSchedule(id, editScheduleDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get a report schedule
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SchedulesApi
     */
    public getSchedule(id: number, options?: any) {
        return SchedulesApiFp(this.configuration).getSchedule(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get all report schedules
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SchedulesApi
     */
    public getSchedules(options?: any) {
        return SchedulesApiFp(this.configuration).getSchedules(options).then((request) => request(this.axios, this.basePath));
    }
}
