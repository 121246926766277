import {
  WidgetItem,
  WidgetItemCategory1,
} from 'dashboard/types/widget-item.type';
import { ChartProps } from 'shared/components/chart';
// import { transformPeriod } from './transform-period';
import { format } from 'date-fns';

function constructChartType(
  chartType: string
): Pick<ChartProps, 'type' | 'horizontal'> {
  if (chartType === 'verticalBar') {
    return { type: 'bar' };
  }
  if (chartType === 'horizontalBar') {
    return { type: 'bar', horizontal: true };
  }
  if (chartType === 'line') {
    return { type: 'line' };
  }
  if (chartType === 'pie') {
    return { type: 'pie' };
  }
  if (chartType === 'doughnut') {
    // Add Doughnut chart type
    return { type: 'doughnut' };
  }
  return { type: 'bar' };
}

function constructLabels(props: WidgetItem): (string | string[])[] {
  return (props.categories || []).map((item) => {
    const category = item as WidgetItemCategory1;
    const isDaily =
      format(new Date(category.period.startTimeBack), 'dd MMM yyyy') ===
      format(new Date(category.period.endTimeBack), 'dd MMM yyyy');

    if (props.widgetBase === 'event') {
      // return [category.categoryName, `(${transformPeriod(category.period)})`];
      if (isDaily) {
        return format(new Date(category.period.startTimeBack), 'dd MMM yyyy');
      }

      return `${format(
        new Date(category.period.startTimeBack),
        'dd MMM yyyy'
      )} - ${format(new Date(category.period.endTimeBack), 'dd MMM yyyy')}`;
    }

    return ' ';
    // return category.categoryName;
  });
}

export function constructChartProps(props: WidgetItem): ChartProps {
  let responseProps: Partial<ChartProps> = {
    title: props.widgetName,
    chartColor: props.chartColor,
    widgetValue: props.widgetValue,
  };

  if (props.chartType) {
    responseProps = {
      ...responseProps,
      ...constructChartType(props.chartType),
    };
  }

  responseProps.data = {
    labels: constructLabels(props),
    datasets: props.datasets || [],
  };

  return responseProps as ChartProps;
}
