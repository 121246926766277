import React, { createContext, useCallback, useContext, useState } from 'react';
import { ErrorDialog } from 'shared/components/dialogs/error-dialog';

interface ShowErrorDialogParams {
  message: string;
  onClose?: () => void;
  alertType: string;
}

interface ContextProps {
  showErrorDialog: (params: ShowErrorDialogParams) => void;
}

const ErrorDialogContext = createContext<ContextProps>({
  showErrorDialog: () => {},
});

export const ErrorDialogProvider: React.FC = ({ children }) => {
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const [message, setMessage] = useState<string>('');
  const [onCloseFn, setOnCloseFn] = useState<Function>();
  const [alertType, setAlertType] = useState<string>('');

  const showErrorDialog = (params: ShowErrorDialogParams) => {
    setAlertType(params.alertType);
    setMessage(params.message);
    setDialogOpen(true);
    setOnCloseFn(params.onClose);
  };

  const handleCloseDialog = useCallback(() => {
    setMessage('');
    setDialogOpen(false);

    if (onCloseFn) {
      onCloseFn();
    }
  }, [onCloseFn]);

  return (
    <ErrorDialogContext.Provider value={{ showErrorDialog }}>
      {children}
      <ErrorDialog
        open={dialogOpen}
        onClose={handleCloseDialog}
        message={message}
        alertType={alertType}
        disableEscapeKeyDown
        disableBackdropClick
      ></ErrorDialog>
    </ErrorDialogContext.Provider>
  );
};

export function useErrorDialog() {
  return useContext(ErrorDialogContext);
}
