import { ButtonSecondary } from '../button-secondary';
import actionsIcon from 'core/assets/action-icon-small.png';
import styles from './index.module.css';
import { useState, useRef } from 'react';
import classNames from 'classnames';
import { useClickOutside } from 'generate-report/hooks/use-click-outside';

interface ButtonDropdownProps {
  data: any[];
  customClassName?: string;
  title: string;
  height?: number;
}

export const ButtonDropdown: React.FC<ButtonDropdownProps> = ({
  data,
  customClassName,
  title,
  height,
}) => {
  const [isActive, setIsActive] = useState<boolean>(false);
  const containerRef = useRef<any>(null);

  const onClick = () => setIsActive((prev) => !prev);
  const handleCloseDropdown = () => setIsActive(false);

  useClickOutside(containerRef, handleCloseDropdown);

  return (
    <div ref={containerRef} className={styles.buttonDropdownWrapper}>
      <ButtonSecondary
        size="smallest"
        imgSrc={actionsIcon}
        title={title}
        className={classNames(styles.buttonStyle, customClassName, {
          [styles.activeBackground]: isActive,
        })}
        onClick={onClick}
      />
      {isActive && (
        <div className={classNames(styles.dropdownContent)}>
          <ul className={styles.unorderedListStyle}>
            {data.map((d: any) => (
              <li
                key={d.id}
                className={styles.listStyle}
                onClick={() => {
                  d.onClick();
                  handleCloseDropdown();
                }}
              >
                {d.name}
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};
