import { Box, BoxProps } from '@mui/system';
import bulletItem from 'core/assets/bl_item.png';
import styles from './index.module.css';

interface InputLabelProps extends BoxProps {
  showBullet?: boolean;
}

export const InputLabel: React.FC<InputLabelProps> = ({
  children,
  showBullet,
  ...props
}) => {
  return (
    <Box
      mr="16px"
      fontSize={12}
      fontWeight={700}
      color="var(--neutral-color-1)"
      {...props}
    >
      {showBullet && (
        <div className={styles.bulletWrapper}>
          <img src={bulletItem} className={styles.bulletItem} alt="" />
        </div>
      )}
      {children}
    </Box>
  );
};
