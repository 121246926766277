import { Box } from '@mui/material';
import {
  ConfigCategoryTypeDto,
  EventCategoryTypeDto,
  WidgetDtoWidgetType,
} from 'api-client';
import { useTranslation } from 'core/context/i18n.context';
import { useGetDevices } from 'dashboard/hooks/use-get-devices';
// import { CategoryType } from 'dashboard/types/category-type.type';
import { useEffect, useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { DeviceIcons } from 'shared/components/device-icons';
import { ObjectFilterDataNode } from 'shared/components/filter-conditions/filter-object/type';
import { InputLabel } from 'shared/components/input-label';
import { InputWrapper } from 'shared/components/input-wrapper';
import { SelectDeviceExpandable } from 'shared/components/inputs/select-device-expandable';
import {
  SelectField,
  SelectFieldDataSourceItem,
} from 'shared/components/inputs/select-field';
import { SelectFieldExpandable } from 'shared/components/inputs/select-field-expandable';
import { TextField } from 'shared/components/inputs/text-field';
import { Textarea } from 'shared/components/inputs/textarea';
// import { CategoryType1 } from './category-type1';
import classNames from 'classnames';
import { useChartEvents } from 'dashboard/hooks/use-chart-events';
import { useCounterEvents } from 'dashboard/hooks/use-counter-events';
import { useDialog } from 'dashboard/hooks/use-dialog';
import { useGetDoorsAndGroups } from 'dashboard/hooks/use-get-doors-and-groups';
import { ErrorDialog } from 'shared/components/dialogs/error-dialog';
import { InputError } from 'shared/components/input-error';
import styles from './detail-section.module.css';

interface DetailSectionProps {
  form: ReturnType<typeof useForm>;
  widgetTextForm: ReturnType<typeof useForm>;
  categoryTypes?: {
    events?: EventCategoryTypeDto[];
    configurations?: ConfigCategoryTypeDto[];
  };
  isEdit?: boolean;
}

const DoorGroupIcon = () => (
  <div className={classNames(styles.icon, styles.doorGroupIcon)} />
);
const DoorIcon = () => (
  <div className={classNames(styles.icon, styles.doorIcon)} />
);

export const DetailSection: React.FC<DetailSectionProps> = ({
  form,
  widgetTextForm,
  // categoryTypes,
  isEdit,
}) => {
  const { t } = useTranslation();
  const getDoorsAndGroups = useGetDoorsAndGroups();
  const { openDialog, closeDialog, isOpen } = useDialog();
  const chartEvents = useChartEvents();
  const counterEvents = useCounterEvents();

  const [isActive, setIsActive] = useState(false);
  const handleIsActiveChange = (newIsActive: boolean) => {
    setIsActive(newIsActive);
  };

  const {
    register,
    watch,
    getValues,
    setValue,
    resetField,
    formState: { errors },
    clearErrors,
  } = form;

  const widgetBase = watch('widgetBase');
  const widgetType = watch('widgetType');
  const eventBaseWidgets = [
    WidgetDtoWidgetType.Chart,
    WidgetDtoWidgetType.Counter,
  ];

  // const categoryTypesData: CategoryType[] | undefined = useMemo(() => {
  //   if (!!widgetType && (widgetType === 'chart' || widgetType === 'counter')) {
  //     return categoryTypes?.events?.map<CategoryType>((item) => ({
  //       id: item.reportTemplateId.toString(),
  //       name: item.name,
  //       period: item.period,
  //     }));
  //   }
  //   return undefined;
  // }, [categoryTypes?.events, widgetType]);

  const widgetTypes = useMemo<SelectFieldDataSourceItem[]>(
    () => [
      { id: WidgetDtoWidgetType.Chart, name: t('reportApp.widgetTypes.chart') },
      {
        id: WidgetDtoWidgetType.Counter,
        name: t('reportApp.widgetTypes.counter'),
      },
      { id: WidgetDtoWidgetType.Text, name: t('reportApp.widgetTypes.text') },
      {
        id: WidgetDtoWidgetType.RealTimeEvents,
        name: t('reportApp.widgetTypes.realTimeEvents'),
      },
      {
        id: WidgetDtoWidgetType.Checkpoint,
        name: t('reportApp.widgetTypes.checkpoint'),
      },
      {
        id: WidgetDtoWidgetType.SystemUsage,
        name: t('reportApp.widgetTypes.systemUsage'),
      },
      {
        id: WidgetDtoWidgetType.DoorControl,
        name: t('reportApp.widgetTypes.doorControl'),
      },
      // { id: 'webpage', name: t('reportApp.widgetTypes.webpage') },
      // will uncomment later when the widgets available
      // { id: 'webpage', name: t('reportApp.widgetTypes.webpage') },
      // { id: 'zoneControl', name: t('reportApp.widgetTypes.zoneControl') },
      //   id: 'alertsAndAcknowledgement',
      //   name: t('reportApp.widgetTypes.alertsAndAcknowledgement'),
      // },
    ],
    [t]
  );

  const widgetTypesWithoutWidgetTitle = [
    WidgetDtoWidgetType.Text,
    WidgetDtoWidgetType.SystemUsage,
    WidgetDtoWidgetType.DoorControl,
  ];

  const systemUsageList = useMemo<SelectFieldDataSourceItem[]>(
    () => [
      { id: 'users', name: t('reportApp.systemUsage.users') },
      { id: 'cards', name: t('reportApp.systemUsage.cards') },
      { id: 'fingerprint', name: t('reportApp.systemUsage.fingerprint') },
      { id: 'visualFace', name: t('reportApp.systemUsage.visualFace') },
      { id: 'face', name: t('reportApp.systemUsage.face') },
      { id: 'qrBarcode', name: t('reportApp.systemUsage.qrBarcode') },
      {
        id: 'mobileAccessCard',
        name: t('reportApp.systemUsage.mobileAccessCard'),
      },
      { id: 'devices', name: t('reportApp.systemUsage.devices') },
      { id: 'doors', name: t('reportApp.systemUsage.doors') },
      { id: 'zones', name: t('reportApp.systemUsage.zones') },
      { id: 'accessGroups', name: t('reportApp.systemUsage.accessGroups') },
      { id: 'userGroups', name: t('reportApp.systemUsage.userGroups') },
      { id: 'deviceGroups', name: t('reportApp.systemUsage.deviceGroups') },
    ],
    [t]
  );

  const chartOrCounterDataLabel = useMemo(
    () =>
      widgetType === WidgetDtoWidgetType.Chart
        ? t('reportApp.widgetTypes.chartData')
        : t('reportApp.widgetTypes.counterData'),
    [t, widgetType]
  );

  const chartOrCounterSelectionLabel = useMemo(
    () =>
      widgetType === WidgetDtoWidgetType.Chart
        ? t('reportApp.widgetTypeRequired.chartData')
        : t('reportApp.widgetTypeRequired.counterData'),
    [t, widgetType]
  );

  const chartAndCounterData = useMemo<SelectFieldDataSourceItem[]>(
    () => chartEvents.map((data: any) => ({ id: data.id, name: data.name })),
    [chartEvents]
  );

  const widgetTypesDefaultValue = useMemo(
    () => getValues('widgetType') || '',
    [getValues]
  );

  const doorsAndGroups = useMemo(() => {
    if (!getDoorsAndGroups.isLoading && !!getDoorsAndGroups.isSuccess) {
      const doorsAndGroupsWithIcon = getDoorsAndGroups.data.map((door) => {
        let icon: any;
        if (door.id.startsWith('doorGroup')) {
          icon = <DoorGroupIcon />;
        } else {
          icon = <DoorIcon />;
        }

        return {
          ...door,
          icon,
        };
      });

      return doorsAndGroupsWithIcon;
    }
  }, [getDoorsAndGroups]);

  const onChangeWidgetType = (
    item: SelectFieldDataSourceItem,
    name?: string,
    e?: React.MouseEvent
  ) => {
    setValue(name!, item.id);

    if (eventBaseWidgets.includes(widgetType)) {
      setValue('widgetBase', 'event');
    } else {
      setValue('widgetBase', 'nonEvent');
      setValue('categories', []);
    }

    if (!!e) {
      if (
        widgetTypesWithoutWidgetTitle.includes(item.id as WidgetDtoWidgetType)
      ) {
        resetField('widgetName');
      }
      resetField('widgetValue');
    }
    clearErrors();
  };

  const onChangeSelectField = (
    item: SelectFieldDataSourceItem,
    name?: string
  ) => {
    setValue('widgetName', item.name);
    setValue(name!, item.id);
    clearErrors();
  };

  const onChangeSelectFieldExpandable = (item: ObjectFilterDataNode) => {
    if (widgetTypesWithoutWidgetTitle.includes(widgetType)) {
      setValue('widgetName', item.name);
    }
    setValue('widgetValue', item.originalId);
    clearErrors();
  };

  const onChangeChartAndCounterDropdown = (item: ObjectFilterDataNode) => {
    setValue('widgetValue', item.id);

    if (
      [WidgetDtoWidgetType.Chart, WidgetDtoWidgetType.Counter].includes(
        widgetType
      )
    ) {
      const eventData =
        widgetType === WidgetDtoWidgetType.Chart ? chartEvents : counterEvents;
      const eventConditions = eventData.find((data) => data.id === item.id);
      const constructCategories = eventConditions?.data.map((data, index) => ({
        categoryName: ' ',
        period: data.period,
        type: index,
        events: data.events,
      }));

      setValue('categories', constructCategories);
    }
  };

  const textWidgetInput = () => (
    <>
      <InputWrapper>
        <InputLabel className={styles.inputLabel} showBullet={true}>
          {t('reportApp.widgetTypes.text.label')}
        </InputLabel>
        <Box maxWidth="400px" width="100%">
          <Textarea
            name="textValue"
            inputProps={{
              autoComplete: 'off',
              ...widgetTextForm.register('textValue', {
                required: {
                  value: true,
                  message: t('reportApp.generalFieldRequired', ['Text']),
                },
              }),
              className: styles.widgetTextarea,
            }}
          ></Textarea>
          <InputError>
            {!!errors && !!errors.widgetValue && errors.widgetValue.message}
          </InputError>
        </Box>
      </InputWrapper>
      <Box height="13px"></Box>
      <InputWrapper>
        <InputLabel className={styles.inputLabel} showBullet={true}>
          {t('reportApp.widgetTypes.text.removeBackground')}
        </InputLabel>
        <Box maxWidth="400px" width="100%" height="17px" position="relative">
          <input
            type="checkbox"
            {...widgetTextForm.register('removeBgText')}
            className={styles.checkbox}
            value={widgetTextForm.getValues('removeBg')}
          />
          <span className={styles.checkmark} />
        </Box>
      </InputWrapper>
    </>
  );

  const fetchDevices = useGetDevices();

  const getDevices = useMemo(() => {
    if (!fetchDevices.isLoading && !!fetchDevices.isSuccess) {
      const devicesAndGroupsWithIcon = fetchDevices.data.map((device: any) => {
        let icon: any;
        if (device.id.startsWith('deviceGroup')) {
          icon = <DeviceIcons typeId={0} />;
        } else {
          icon = <DeviceIcons typeId={device.typeId.id} />;
        }

        return {
          ...device,
          icon,
        };
      });
      return devicesAndGroupsWithIcon;
    }
  }, [fetchDevices]);

  useEffect(() => {
    if (!fetchDevices.isFetched) {
      fetchDevices.refetch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchDevices.isFetched]);

  const checkpointDevices = () => (
    <InputWrapper sx={{ minHeight: '35px' }}>
      <InputLabel className={styles.inputLabel} showBullet={true}>
        {t('reportApp.widgetTypes.device.label')}
      </InputLabel>
      <Box maxWidth="400px" width="100%" position="relative">
        {!!getDevices?.length && (
          <SelectDeviceExpandable
            name="widgetValue"
            selectProps={{
              ...register('widgetValue', {
                required: {
                  value: true,
                  message: t('reportApp.widgetTypeRequired.endWithVowel', [
                    t('reportApp.widgetDeviceLabel'),
                  ]),
                },
              }),
              value: getValues('widgetValue'),
            }}
            onChange={onChangeSelectFieldExpandable}
            dataSource={getDevices}
            styleType="emboss"
            id={WidgetDtoWidgetType.Checkpoint}
          ></SelectDeviceExpandable>
        )}
        <InputError position="absolute">
          {!!errors && !!errors.widgetValue && errors.widgetValue.message}
        </InputError>
      </Box>
    </InputWrapper>
  );

  // const webpageWidgetInput = () => (
  //   <InputWrapper sx={{ height: '35px' }}>
  //     <InputLabel className={styles.inputLabel} showBullet={true} width="100px">
  //       {t('reportApp.widgetTypes.webpage.label')}
  //     </InputLabel>
  //     <Box maxWidth="400px" width="100%">
  //       <TextField
  //         name="widgetValue"
  //         inputProps={{
  //           autoComplete: 'off',
  //           ...register('widgetValue', {
  //             required: {
  //               value: true,
  //               message: t('reportApp.widgetNameRequired'),
  //             },
  //           }),
  //         }}
  //       ></TextField>
  //     </Box>
  //     <Box ml="4px">
  //       <Info message={t('reportApp.widgetWebpageNotes')} />
  //     </Box>
  //   </InputWrapper>
  // );

  const systemUsageWidgetDropdown = () => (
    <InputWrapper sx={{ minHeight: '35px' }}>
      <InputLabel className={styles.inputLabel} showBullet={true}>
        {t('reportApp.widgetTypes.systemUsage')}
      </InputLabel>
      <Box maxWidth="400px" width="100%" position="relative">
        <SelectField
          name="widgetValue"
          selectProps={{
            ...register('widgetValue', {
              required: {
                value: true,
                message: t('reportApp.widgetTypeRequired.endWithConsonant', [
                  t('reportApp.widgetTypes.systemUsage'),
                ]),
              },
            }),
            value: getValues('widgetValue'),
          }}
          dataSource={systemUsageList}
          onChange={onChangeSelectField}
        />
        <InputError position="absolute">
          {!!errors && !!errors.widgetValue && errors.widgetValue.message}
        </InputError>
      </Box>
    </InputWrapper>
  );

  const doorWidgetDropdown = () => (
    <InputWrapper sx={{ minHeight: '35px' }}>
      <InputLabel className={styles.inputLabel} showBullet={true}>
        {t('reportApp.widgetTypes.door.label')}
      </InputLabel>
      <Box maxWidth="400px" width="100%" position="relative">
        {!!doorsAndGroups?.length && (
          <SelectFieldExpandable
            name="widgetValue"
            selectProps={{
              ...register('widgetValue', {
                required: {
                  value: true,
                  message: t('reportApp.widgetTypeRequired.endWithConsonant', [
                    t('reportApp.widgetTypes.door.label'),
                  ]),
                },
              }),
              value: getValues('widgetValue'),
            }}
            onChange={onChangeSelectFieldExpandable}
            dataSource={doorsAndGroups}
            styleType="emboss"
            id={WidgetDtoWidgetType.DoorControl}
          ></SelectFieldExpandable>
        )}
        <InputError position="absolute">
          {!!errors && !!errors.widgetValue && errors.widgetValue.message}
        </InputError>
      </Box>
    </InputWrapper>
  );

  const chartAndCounterDropdown = () => (
    <InputWrapper sx={{ minHeight: '35px' }}>
      <InputLabel className={styles.inputLabel} showBullet={true}>
        {chartOrCounterDataLabel}
      </InputLabel>
      <Box maxWidth="400px" width="100%" position="relative">
        <SelectField
          name="widgetValue"
          selectProps={{
            ...register('widgetValue', {
              required: {
                value: true,
                message: chartOrCounterSelectionLabel,
              },
            }),
            value: getValues('widgetValue'),
          }}
          dataSource={chartAndCounterData}
          onChange={onChangeChartAndCounterDropdown}
        />
        <InputError position="absolute">
          {!!errors && !!errors.widgetValue && errors.widgetValue.message}
        </InputError>
      </Box>
    </InputWrapper>
  );

  const renderThirdComponent = () => {
    switch (widgetType) {
      case WidgetDtoWidgetType.RealTimeEvents:
        return;
      case WidgetDtoWidgetType.Text:
        return textWidgetInput();
      // case WidgetDtoWidgetType.Webpage:
      //   return webpageWidgetInput();
      case WidgetDtoWidgetType.DoorControl:
        return doorWidgetDropdown();
      case WidgetDtoWidgetType.SystemUsage:
        return systemUsageWidgetDropdown();
      case WidgetDtoWidgetType.Checkpoint:
        return checkpointDevices();
      case WidgetDtoWidgetType.Chart:
      case WidgetDtoWidgetType.Counter:
        return chartAndCounterDropdown();
      // default:
      //   return (
      //     <CategoryType1
      //       isCounter={widgetType === 'counter'}
      //       categoryTypeOptions={categoryTypesData}
      //     ></CategoryType1>
      //   );
    }
  };

  const onCloseErrorDialog = () => {
    closeDialog();
    clearErrors('categories');
  };

  useEffect(() => {
    if (widgetBase === 'event' && !widgetType) {
      setValue('widgetType', 'chart');
    }

    if (!!errors.categories) {
      openDialog();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [widgetBase, setValue, resetField, widgetType, errors.categories]);

  return (
    <Box>
      <FormProvider {...form}>
        <Box display="flex" flexDirection="column">
          <InputWrapper style={{ position: 'relative', zIndex: 5 }}>
            <InputLabel className={styles.inputLabel} showBullet={true}>
              {t('reportApp.widgetTypes')}
            </InputLabel>
            <Box maxWidth="400px" width="100%" position="relative" zIndex="4">
              <SelectField
                name="widgetType"
                selectProps={{
                  ...register('widgetType', {
                    required: {
                      value: true,
                      message: t('reportApp.widgetTypeRequired'),
                    },
                  }),
                  defaultValue: widgetTypesDefaultValue,
                  value: getValues('widgetType'),
                  disabled: isEdit,
                }}
                placeholder={t('reportApp.chooseChartType')}
                dataSource={widgetTypes}
                styleType="emboss"
                onChange={(value, name = 'widgetType', e) =>
                  onChangeWidgetType(value, name, e)
                }
                onIsActiveChange={handleIsActiveChange}
              ></SelectField>
            </Box>
          </InputWrapper>
        </Box>

        <Box height="13px"></Box>

        <InputWrapper>
          <InputLabel className={styles.inputLabel} showBullet={true}>
            {t('reportApp.widgetName')}
          </InputLabel>
          <Box maxWidth="400px" width="100%" position="relative">
            {!!errors && !!errors.widgetName?.message && (
              <div
                className={styles.warningTooltip}
                style={{ zIndex: isActive ? 4 : 10 }}
              >
                {errors.widgetName?.message}
              </div>
            )}
            <TextField
              name="widgetName"
              inputProps={{
                disabled:
                  !!widgetType &&
                  widgetTypesWithoutWidgetTitle.includes(widgetType),
                autoComplete: 'off',
                ...register('widgetName', {
                  required: {
                    value: !widgetTypesWithoutWidgetTitle.includes(widgetType),
                    message: t('reportApp.widgetNameRequired'),
                  },
                }),
              }}
            ></TextField>
          </Box>
        </InputWrapper>

        <Box height="13px"></Box>

        {renderThirdComponent()}

        <ErrorDialog
          open={isOpen}
          onClose={() => onCloseErrorDialog()}
          message={t('reportApp.categoryNameOrTypeRequired')}
          alertType="error"
          disableEscapeKeyDown
          disableBackdropClick
        />
      </FormProvider>
    </Box>
  );
};
