import { Box } from '@mui/material';
import {
  Layout,
  ReactGridLayoutProps,
  Responsive as ResponsiveGL,
  WidthProvider,
} from 'react-grid-layout';

const ResponsiveGridLayout = WidthProvider(ResponsiveGL);

interface GridLayoutProps extends ReactGridLayoutProps {
  onLayoutChange?: (layouts: Layout[]) => void;
}

export const GridLayout: React.FC<GridLayoutProps> = ({
  children,
  onLayoutChange,
  ...props
}) => {
  return (
    <ResponsiveGridLayout
      {...props}
      rowHeight={45}
      breakpoints={{ xl: 2200, lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0 }}
      cols={{ xl: 61, lg: 45, md: 45, sm: 5, xs: 4, xxs: 2 }}
      onLayoutChange={onLayoutChange}
      compactType={null}
      preventCollision={true}
    >
      {children}
    </ResponsiveGridLayout>
  );
};

interface GridLayoutItemProps {
  key: string;
  element?: JSX.Element;
  dataGrid?: Layout;
}

export const renderGridLayoutItem = (props: GridLayoutItemProps) => {
  return (
    <Box
      key={props.key}
      data-grid={props?.dataGrid}
      sx={{
        borderRadius: '4px',
      }}
    >
      {props?.element}
    </Box>
  );
};
