/* tslint:disable */
/* eslint-disable */
/**
 * BioStar 2 Report API
 * API Documentation for BioStar 2 Report
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { ErrorResponseDto } from '../models';
// @ts-ignore
import { PredefinedReportDto } from '../models';
/**
 * PredefinedReportsApi - axios parameter creator
 * @export
 */
export const PredefinedReportsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get one predefined report by id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReportType: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getReportType', 'id', id)
            const localVarPath = `/api/report/predefined-reports/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all predefined reports
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReportTypes: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/report/predefined-reports`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PredefinedReportsApi - functional programming interface
 * @export
 */
export const PredefinedReportsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PredefinedReportsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get one predefined report by id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getReportType(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PredefinedReportDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getReportType(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get all predefined reports
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getReportTypes(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PredefinedReportDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getReportTypes(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PredefinedReportsApi - factory interface
 * @export
 */
export const PredefinedReportsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PredefinedReportsApiFp(configuration)
    return {
        /**
         * 
         * @summary Get one predefined report by id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReportType(id: number, options?: any): AxiosPromise<PredefinedReportDto> {
            return localVarFp.getReportType(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get all predefined reports
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReportTypes(options?: any): AxiosPromise<Array<PredefinedReportDto>> {
            return localVarFp.getReportTypes(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PredefinedReportsApi - object-oriented interface
 * @export
 * @class PredefinedReportsApi
 * @extends {BaseAPI}
 */
export class PredefinedReportsApi extends BaseAPI {
    /**
     * 
     * @summary Get one predefined report by id
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PredefinedReportsApi
     */
    public getReportType(id: number, options?: any) {
        return PredefinedReportsApiFp(this.configuration).getReportType(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get all predefined reports
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PredefinedReportsApi
     */
    public getReportTypes(options?: any) {
        return PredefinedReportsApiFp(this.configuration).getReportTypes(options).then((request) => request(this.axios, this.basePath));
    }
}
