import { useTranslation } from 'core/context/i18n.context';
import { ButtonDefault } from 'shared/components/buttons/button-default';
import { BaseDialog, BaseDialogProps } from '../base-dialog';
import styles from './index.module.css';

export interface ErrorDialogProps
  extends Omit<BaseDialogProps, 'title' | 'actions'> {
  message: string;
  alertType: string;
}

export const ErrorDialog: React.FC<ErrorDialogProps> = ({
  alertType,
  message,
  ...props
}) => {
  const { t } = useTranslation();

  const alertIcons: Record<any, string> = {
    success: '0 0',
    confirm: '-60px 0',
    error: '-120px 0',
    warning: '-180px 0',
    info: '-240px 0',
  };

  const Actions = () => {
    return (
      <>
        <ButtonDefault
          title={t('reportApp.ok')}
          size="small"
          className={styles.actionButton}
          onClick={props.onClose}
        ></ButtonDefault>
      </>
    );
  };

  return (
    <BaseDialog {...props} title={t('reportApp.error')} actions={<Actions />}>
      <div className={styles.alertWrapper}>
        <div
          className={styles.alertIcon}
          style={{ backgroundPosition: alertIcons[alertType] }}
        />
        <p className={styles.message}>{message}</p>
      </div>
    </BaseDialog>
  );
};
