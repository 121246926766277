import React, { useEffect } from 'react';

export const SelectionCheckbox = React.forwardRef<any, any>(
  ({ indeterminate, ...rest }, ref) => {
    const defaultRef = React.useRef();
    const resolvedRef: any = ref || defaultRef;

    useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate;
    }, [resolvedRef, indeterminate]);

    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100%',
        }}
      >
        <input type="checkbox" ref={resolvedRef} {...rest} />
      </div>
    );
  }
);
