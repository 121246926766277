import { HistoryHeader } from 'history/components/header';
import { HistoryTable } from 'history/components/table';
import React from 'react';
import { LayoutContent } from 'shared/components/layouts/layout-content';
import { StandardLayout } from 'shared/components/layouts/standard-layout';

export const HistoryPage: React.FC = () => {
  const tableColumns = [
    {
      name: 'Datetime',
      fieldMapping: 'Datetime',
    },
    {
      name: 'User Group',
      fieldMapping: 'User Group',
    },
    {
      name: 'User',
      fieldMapping: 'User',
    },
    {
      name: 'Event',
      fieldMapping: 'Event',
    },
    {
      name: 'Report time',
      fieldMapping: 'Report time',
    },
  ];
  const dataSource: any[] = [];
  return (
    <StandardLayout>
      <LayoutContent>
        <HistoryHeader title="Report History"></HistoryHeader>
        <HistoryTable tableColumns={tableColumns} dataSource={dataSource} />
      </LayoutContent>
    </StandardLayout>
  );
};
