import classNames from 'classnames';
import { useTranslation } from 'core/context/i18n.context';
import { useMemo } from 'react';
import styles from './index.module.css';

interface ItemNotFoundProps {
  message?: string;
  lite?: boolean;
}

export const ItemNotFound: React.FC<ItemNotFoundProps> = ({
  message,
  lite,
}) => {
  const { t } = useTranslation();

  const currentMessage = useMemo(() => {
    return message || t('reportApp.notFound');
  }, [message, t]);

  return (
    <div className={styles.wrapper}>
      {lite && (
        <p className={classNames(styles.message, styles.liteMessage)}>
          {currentMessage}
        </p>
      )}

      {!lite && <h2 className={styles.message}>{currentMessage}</h2>}
    </div>
  );
};
