/* tslint:disable */
/* eslint-disable */
/**
 * BioStar 2 Report API
 * API Documentation for BioStar 2 Report
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { CategoryDto } from './category-dto';
import { ColumnDto } from './column-dto';
import { FilterConditionDto } from './filter-condition-dto';
import { HeaderDto } from './header-dto';
import { LayoutDto } from './layout-dto';

/**
 * 
 * @export
 * @interface PredefinedReportDto
 */
export interface PredefinedReportDto {
    /**
     * 
     * @type {number}
     * @memberof PredefinedReportDto
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof PredefinedReportDto
     */
    keyName: PredefinedReportDtoKeyName;
    /**
     * 
     * @type {string}
     * @memberof PredefinedReportDto
     */
    name: string;
    /**
     * 
     * @type {Array<CategoryDto>}
     * @memberof PredefinedReportDto
     */
    categories: Array<CategoryDto>;
    /**
     * 
     * @type {Array<HeaderDto>}
     * @memberof PredefinedReportDto
     */
    headers?: Array<HeaderDto>;
    /**
     * 
     * @type {Array<FilterConditionDto>}
     * @memberof PredefinedReportDto
     */
    filterConditions?: Array<FilterConditionDto>;
    /**
     * 
     * @type {Array<ColumnDto>}
     * @memberof PredefinedReportDto
     */
    columns: Array<ColumnDto>;
    /**
     * 
     * @type {LayoutDto}
     * @memberof PredefinedReportDto
     */
    layout: LayoutDto;
}

/**
    * @export
    * @enum {string}
    */
export enum PredefinedReportDtoKeyName {
    UsersInformation = 'usersInformation',
    UsersInDevice = 'usersInDevice',
    UserDetail = 'userDetail',
    UserPhotoGallery = 'userPhotoGallery',
    UsersWithoutCredential = 'usersWithoutCredential',
    PrivateAuthModeByUser = 'privateAuthModeByUser',
    NumberOfCredentialsByUser = 'numberOfCredentialsByUser',
    AllCards = 'allCards',
    UnassignedCards = 'unassignedCards',
    BlacklistCards = 'blacklistCards',
    ExpiredUsers = 'expiredUsers',
    UsersToBeExpiredInNDays = 'usersToBeExpiredInNDays',
    IdleUsersForTheLastNMonths = 'idleUsersForTheLastNMonths',
    InactiveUsers = 'inactiveUsers'
}



