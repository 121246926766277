import {
  ErrorResponseDto,
  RegenerateReportDto,
  ReportDetailDto,
  ReportsApi,
} from 'api-client';
import axios from 'axios';
import { useMutation } from 'react-query';
import { apiConfig } from 'shared/utils/api-config';

export function useRegenerateCustomReport(customReportId: number) {
  const api = new ReportsApi(apiConfig());

  return useMutation<ReportDetailDto, ErrorResponseDto, RegenerateReportDto>(
    (regenerateReportDto: RegenerateReportDto) =>
      api
        .regenerateReport(customReportId, regenerateReportDto)
        .then((res) => res.data)
        .catch((error) => {
          if (axios.isAxiosError(error)) {
            throw error.response!.data;
          }
          throw error;
        })
  );
}
