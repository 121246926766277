import { SchedulesApi } from 'api-client';
import { useQuery } from 'react-query';
import { apiConfig } from 'shared/utils/api-config';

export function useGetSchedules() {
  const api = new SchedulesApi(apiConfig());

  return useQuery(['schedules'], async () => {
    const { data } = await api.getSchedules();
    return data;
  });
}
