import { CircularProgress } from '@mui/material';
import styles from './index.module.css';

export const ScreenLoading = () => {
  return (
    <div className={styles.wrapper}>
      <CircularProgress />
    </div>
  );
};
