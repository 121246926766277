/* tslint:disable */
/* eslint-disable */
/**
 * BioStar 2 Report API
 * API Documentation for BioStar 2 Report
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { DashboardSettingDto } from '../models';
// @ts-ignore
import { ErrorResponseDto } from '../models';
// @ts-ignore
import { SaveDashboardSettingDto } from '../models';
/**
 * DashboardApi - axios parameter creator
 * @export
 */
export const DashboardApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get refresh interval by current logged-in user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCurrentRefreshInterval: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/report/dashboard/settings/current-refresh-interval`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get dashboard setting by current logged-in user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCurrentUserSetting: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/report/dashboard/settings/current`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Save dashboard setting
         * @param {SaveDashboardSettingDto} saveDashboardSettingDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveSetting: async (saveDashboardSettingDto: SaveDashboardSettingDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'saveDashboardSettingDto' is not null or undefined
            assertParamExists('saveSetting', 'saveDashboardSettingDto', saveDashboardSettingDto)
            const localVarPath = `/api/report/dashboard/settings`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(saveDashboardSettingDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DashboardApi - functional programming interface
 * @export
 */
export const DashboardApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DashboardApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get refresh interval by current logged-in user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCurrentRefreshInterval(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCurrentRefreshInterval(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get dashboard setting by current logged-in user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCurrentUserSetting(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DashboardSettingDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCurrentUserSetting(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Save dashboard setting
         * @param {SaveDashboardSettingDto} saveDashboardSettingDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async saveSetting(saveDashboardSettingDto: SaveDashboardSettingDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.saveSetting(saveDashboardSettingDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DashboardApi - factory interface
 * @export
 */
export const DashboardApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DashboardApiFp(configuration)
    return {
        /**
         * 
         * @summary Get refresh interval by current logged-in user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCurrentRefreshInterval(options?: any): AxiosPromise<number> {
            return localVarFp.getCurrentRefreshInterval(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get dashboard setting by current logged-in user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCurrentUserSetting(options?: any): AxiosPromise<DashboardSettingDto> {
            return localVarFp.getCurrentUserSetting(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Save dashboard setting
         * @param {SaveDashboardSettingDto} saveDashboardSettingDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveSetting(saveDashboardSettingDto: SaveDashboardSettingDto, options?: any): AxiosPromise<void> {
            return localVarFp.saveSetting(saveDashboardSettingDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DashboardApi - object-oriented interface
 * @export
 * @class DashboardApi
 * @extends {BaseAPI}
 */
export class DashboardApi extends BaseAPI {
    /**
     * 
     * @summary Get refresh interval by current logged-in user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DashboardApi
     */
    public getCurrentRefreshInterval(options?: any) {
        return DashboardApiFp(this.configuration).getCurrentRefreshInterval(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get dashboard setting by current logged-in user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DashboardApi
     */
    public getCurrentUserSetting(options?: any) {
        return DashboardApiFp(this.configuration).getCurrentUserSetting(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Save dashboard setting
     * @param {SaveDashboardSettingDto} saveDashboardSettingDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DashboardApi
     */
    public saveSetting(saveDashboardSettingDto: SaveDashboardSettingDto, options?: any) {
        return DashboardApiFp(this.configuration).saveSetting(saveDashboardSettingDto, options).then((request) => request(this.axios, this.basePath));
    }
}
