import { InputAdornment, InputBase, InputBaseProps } from '@mui/material';
import classNames from 'classnames';
import { useRef, useState } from 'react';
import { TextField } from '../text-field';
import styles from './index.module.css';
import { InputWrapper } from 'shared/components/input-wrapper';
import { InputLabel } from 'shared/components/input-label';
import { useTranslation } from 'core/context/i18n.context';
import { useClickOutside } from 'generate-report/hooks/use-click-outside';
import { useLocation } from 'react-router-dom';
import filterIcon from 'core/assets/monitoring_filter_button.png';

interface PlainSearchFieldProps {
  handleSearch?: InputBaseProps['onChange'];
  handleUserGroupSearch?: InputBaseProps['onChange'];
  handleEmailSearch?: InputBaseProps['onChange'];
  handleDepartmentSearch?: InputBaseProps['onChange'];
  handleTitleSearch?: InputBaseProps['onChange'];
  className?: string;
  enableAdditionalFilters?: boolean;
  userGroupSearchValue?: string;
  departmentSearchValue?: string;
  titleSearchValue?: string;
  emailSearchValue?: string;
  id?: string;
}

export const PlainSearchField: React.FC<PlainSearchFieldProps> = ({
  handleSearch,
  handleUserGroupSearch,
  handleEmailSearch,
  handleDepartmentSearch,
  handleTitleSearch,
  className,
  enableAdditionalFilters,
  userGroupSearchValue,
  departmentSearchValue,
  titleSearchValue,
  emailSearchValue,
  id,
}) => {
  const { t } = useTranslation();
  const containerRef = useRef<any>(null);
  const [showDropdown, setShowDropdown] = useState(false);
  const handleClick = () => setShowDropdown(!showDropdown);
  const handleClose = () => setShowDropdown(false);
  const location = useLocation();
  const isCustomReport = location.search.includes('true');

  useClickOutside(containerRef, handleClose);

  return (
    <div ref={containerRef}>
      <InputBase
        fullWidth
        endAdornment={
          <InputAdornment position="end">
            <div className={styles.iconSearch} />
            {enableAdditionalFilters && (
              <button onClick={handleClick} className={styles.btnFilters}>
                <img
                  src={filterIcon}
                  alt="filter icon"
                  className={styles.iconFilter}
                />
              </button>
            )}
          </InputAdornment>
        }
        className={classNames(styles.searchField, className)}
        onChange={handleSearch}
        id={id}
      ></InputBase>
      {showDropdown && (
        <div
          className={classNames(styles.dropdown, {
            [styles.customReportDropdown]: isCustomReport,
          })}
          ref={containerRef}
        >
          <InputWrapper className={styles.fieldWrapper}>
            <InputLabel display="flex" showBullet={true}>
              {t('reportApp.userGroup')}
            </InputLabel>
            <TextField
              name="userGroup"
              inputProps={{
                type: 'text',
                style: { width: 200 },
                onChange: handleUserGroupSearch,
                value: userGroupSearchValue,
              }}
            ></TextField>
          </InputWrapper>
          <InputWrapper className={styles.fieldWrapper}>
            <InputLabel display="flex" showBullet={true}>
              {t('reportApp.department')}
            </InputLabel>
            <TextField
              name="department"
              inputProps={{
                type: 'text',
                style: { width: 200 },
                onChange: handleDepartmentSearch,
                value: departmentSearchValue,
              }}
            ></TextField>
          </InputWrapper>
          <InputWrapper className={styles.fieldWrapper}>
            <InputLabel display="flex" showBullet={true}>
              {t('reportApp.searchField.title')}
            </InputLabel>
            <TextField
              name="title"
              inputProps={{
                type: 'text',
                style: { width: 200 },
                onChange: handleTitleSearch,
                value: titleSearchValue,
              }}
            ></TextField>
          </InputWrapper>
          <InputWrapper className={styles.fieldWrapper}>
            <InputLabel display="flex" showBullet={true}>
              {t('reportApp.email')}
            </InputLabel>
            <TextField
              name="email"
              inputProps={{
                type: 'text',
                style: { width: 200 },
                onChange: handleEmailSearch,
                value: emailSearchValue,
              }}
            ></TextField>
          </InputWrapper>
        </div>
      )}
    </div>
  );
};
