import { useCallback, useEffect } from 'react';

export function useFinishOnEnter(cb: () => void) {
  const handleFinish = useCallback(
    (event: KeyboardEvent) => {
      if ([event.key, event.code].includes('Enter')) {
        cb?.();
      }
    },
    [cb]
  );

  useEffect(() => {
    document.addEventListener('keypress', handleFinish, false);
    return () => {
      document.removeEventListener('keypress', handleFinish, false);
    };
  }, [handleFinish]);
}
