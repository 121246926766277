/* tslint:disable */
/* eslint-disable */
/**
 * BioStar 2 Report API
 * API Documentation for BioStar 2 Report
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { CreatePreferenceDto } from '../models';
// @ts-ignore
import { ErrorResponseDto } from '../models';
// @ts-ignore
import { ReportMenuDto } from '../models';
// @ts-ignore
import { SaveMenuItemsTreeStatus } from '../models';
/**
 * UserInterfacesApi - axios parameter creator
 * @export
 */
export const UserInterfacesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get report menu that can be used in generate report page sidebar
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReportMenu: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/report/user-interfaces/report-menu`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Save report menu items tree status (expanded/collapsed)
         * @param {SaveMenuItemsTreeStatus} saveMenuItemsTreeStatus 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveReportMenuItemsStatus: async (saveMenuItemsTreeStatus: SaveMenuItemsTreeStatus, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'saveMenuItemsTreeStatus' is not null or undefined
            assertParamExists('saveReportMenuItemsStatus', 'saveMenuItemsTreeStatus', saveMenuItemsTreeStatus)
            const localVarPath = `/api/report/user-interfaces/report-menu-tree-status`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(saveMenuItemsTreeStatus, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UserInterfacesApi - functional programming interface
 * @export
 */
export const UserInterfacesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UserInterfacesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get report menu that can be used in generate report page sidebar
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getReportMenu(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReportMenuDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getReportMenu(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Save report menu items tree status (expanded/collapsed)
         * @param {SaveMenuItemsTreeStatus} saveMenuItemsTreeStatus 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async saveReportMenuItemsStatus(saveMenuItemsTreeStatus: SaveMenuItemsTreeStatus, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CreatePreferenceDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.saveReportMenuItemsStatus(saveMenuItemsTreeStatus, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * UserInterfacesApi - factory interface
 * @export
 */
export const UserInterfacesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UserInterfacesApiFp(configuration)
    return {
        /**
         * 
         * @summary Get report menu that can be used in generate report page sidebar
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReportMenu(options?: any): AxiosPromise<ReportMenuDto> {
            return localVarFp.getReportMenu(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Save report menu items tree status (expanded/collapsed)
         * @param {SaveMenuItemsTreeStatus} saveMenuItemsTreeStatus 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveReportMenuItemsStatus(saveMenuItemsTreeStatus: SaveMenuItemsTreeStatus, options?: any): AxiosPromise<Array<CreatePreferenceDto>> {
            return localVarFp.saveReportMenuItemsStatus(saveMenuItemsTreeStatus, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UserInterfacesApi - object-oriented interface
 * @export
 * @class UserInterfacesApi
 * @extends {BaseAPI}
 */
export class UserInterfacesApi extends BaseAPI {
    /**
     * 
     * @summary Get report menu that can be used in generate report page sidebar
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserInterfacesApi
     */
    public getReportMenu(options?: any) {
        return UserInterfacesApiFp(this.configuration).getReportMenu(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Save report menu items tree status (expanded/collapsed)
     * @param {SaveMenuItemsTreeStatus} saveMenuItemsTreeStatus 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserInterfacesApi
     */
    public saveReportMenuItemsStatus(saveMenuItemsTreeStatus: SaveMenuItemsTreeStatus, options?: any) {
        return UserInterfacesApiFp(this.configuration).saveReportMenuItemsStatus(saveMenuItemsTreeStatus, options).then((request) => request(this.axios, this.basePath));
    }
}
