import React, { useEffect } from 'react';
import styles from './index.module.css';
import { PlainSearchField } from '../../inputs/plain-search-field';
import { useFirstRender } from 'shared/hooks/use-first-render';
import { useSearchItems } from 'shared/hooks/use-search-items';
import {
  NestedCheckboxItem,
  useCustomReportNestedCheckbox,
} from 'shared/hooks/use-custom-report-nested-checkbox';
import { Tree } from 'shared/components/tree';
import { TreeProps } from 'antd';

export interface FilterObjectDataItem extends NestedCheckboxItem {
  name: string;
  children?: FilterObjectDataItem[];
  id: string;
  parentId?: string;
  categories?: any;
}

export interface FilterObjectProps {
  dataSource: FilterObjectDataItem[];
  value?: FilterObjectDataItem[];
  onChange?: (data: FilterObjectDataItem[]) => void;
  multiple?: boolean;
}

export const FilterObjectCustomReport: React.FC<FilterObjectProps> = ({
  dataSource = [],
  value = [],
  onChange,
  multiple,
}) => {
  const isFirstRender = useFirstRender();
  const nestedCheckbox =
    useCustomReportNestedCheckbox<FilterObjectDataItem>(dataSource);
  const { setItems, onSearch, filteredItems } =
    useSearchItems<FilterObjectDataItem>();

  useEffect(() => {
    setItems(dataSource);
  }, [dataSource, setItems]);

  useEffect(() => {
    nestedCheckbox.setInitialItems(value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSearch: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    onSearch(event.target.value);
  };

  const onChangeObjectItem: TreeProps['onCheck'] = (_, info) => {
    info.nativeEvent.stopPropagation();
    nestedCheckbox.onChangeItem(info.node as any, info.checked);
  };

  const removeSelectedItem = (item: FilterObjectDataItem) => () => {
    nestedCheckbox.removeItem(item);
  };

  useEffect(() => {
    if (onChange && !isFirstRender) {
      onChange(nestedCheckbox.selectedItems);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nestedCheckbox.selectedItems]);

  const renderSelectedItem = (item: FilterObjectDataItem) => (
    <li key={item.id} className={styles.selectedItem}>
      <span className={styles.selectedItemText} title={item.name}>
        {item.name}
      </span>
      <button
        className={styles.btnDelete}
        onClick={removeSelectedItem(item)}
      ></button>
    </li>
  );

  return (
    <div className={styles.mainWrapper}>
      <div className={styles.boxWrapper}>
        <div>
          <PlainSearchField handleSearch={handleSearch} />
          <div className={styles.horizontalDivider}></div>
        </div>
        <div className={styles.treeWrapper}>
          {!!filteredItems?.length && (
            <Tree
              checkable
              treeData={filteredItems}
              defaultExpandedKeys={nestedCheckbox.parentItemIds}
              onCheck={onChangeObjectItem}
              checkedKeys={nestedCheckbox.selectedItemIds}
              selectable={false}
            ></Tree>
          )}
        </div>
      </div>
      <div className={styles.spacer}></div>
      <div className={styles.boxWrapper}>
        <ul className={styles.boxResult}>
          {nestedCheckbox.selectedItems.map((item) => renderSelectedItem(item))}
        </ul>
      </div>
    </div>
  );
};
