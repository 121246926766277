/* tslint:disable */
/* eslint-disable */
/**
 * BioStar 2 Report API
 * API Documentation for BioStar 2 Report
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { ConfigCategoryTypeDto } from '../models';
// @ts-ignore
import { DoorStatusDto } from '../models';
// @ts-ignore
import { ErrorResponseDto } from '../models';
// @ts-ignore
import { EventCategoryTypeDto } from '../models';
// @ts-ignore
import { RealTimeEventsColumnDto } from '../models';
// @ts-ignore
import { RefreshWidgetsDto } from '../models';
// @ts-ignore
import { SaveWidgetsDto } from '../models';
// @ts-ignore
import { UserDto } from '../models';
// @ts-ignore
import { UsersParamsDto } from '../models';
// @ts-ignore
import { WidgetConditionDto } from '../models';
// @ts-ignore
import { WidgetDto } from '../models';
/**
 * WidgetsApi - axios parameter creator
 * @export
 */
export const WidgetsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * It will only save the provided widgets and remove the rest. It means if array is empty then it wil remove all user\'s widgets
         * @summary Save widgets based on the provided data. 
         * @param {SaveWidgetsDto} saveWidgetsDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        applyWidgets: async (saveWidgetsDto: SaveWidgetsDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'saveWidgetsDto' is not null or undefined
            assertParamExists('applyWidgets', 'saveWidgetsDto', saveWidgetsDto)
            const localVarPath = `/api/report/widgets/apply`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(saveWidgetsDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Clear the Door APB Violation
         * @param {string} doorId 
         * @param {Array<string>} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        doorClearAPB: async (doorId: string, requestBody: Array<string>, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'doorId' is not null or undefined
            assertParamExists('doorClearAPB', 'doorId', doorId)
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('doorClearAPB', 'requestBody', requestBody)
            const localVarPath = `/api/report/widgets/door/clear-apb/{doorId}`
                .replace(`{${"doorId"}}`, encodeURIComponent(String(doorId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Clear the Door Alarm
         * @param {string} doorId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        doorClearAlarm: async (doorId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'doorId' is not null or undefined
            assertParamExists('doorClearAlarm', 'doorId', doorId)
            const localVarPath = `/api/report/widgets/door/clear-alarm/{doorId}`
                .replace(`{${"doorId"}}`, encodeURIComponent(String(doorId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Lock Door
         * @param {string} doorId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        doorLock: async (doorId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'doorId' is not null or undefined
            assertParamExists('doorLock', 'doorId', doorId)
            const localVarPath = `/api/report/widgets/door/lock/{doorId}`
                .replace(`{${"doorId"}}`, encodeURIComponent(String(doorId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Open Door
         * @param {string} doorId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        doorOpen: async (doorId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'doorId' is not null or undefined
            assertParamExists('doorOpen', 'doorId', doorId)
            const localVarPath = `/api/report/widgets/door/open/{doorId}`
                .replace(`{${"doorId"}}`, encodeURIComponent(String(doorId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Release or Normalize Door
         * @param {string} doorId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        doorRelease: async (doorId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'doorId' is not null or undefined
            assertParamExists('doorRelease', 'doorId', doorId)
            const localVarPath = `/api/report/widgets/door/release/{doorId}`
                .replace(`{${"doorId"}}`, encodeURIComponent(String(doorId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get the door status
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        doorStatus: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/report/widgets/door/status`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Unlock Door
         * @param {string} doorId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        doorUnlock: async (doorId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'doorId' is not null or undefined
            assertParamExists('doorUnlock', 'doorId', doorId)
            const localVarPath = `/api/report/widgets/door/unlock/{doorId}`
                .replace(`{${"doorId"}}`, encodeURIComponent(String(doorId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Start real-time events
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        eventsStart: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/report/widgets/start-event`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Stop real-time events
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        eventsStop: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/report/widgets/stop-event`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Count the selected events based on Biostar 2 events
         * @param {Array<WidgetConditionDto>} widgetConditionDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateEventsData: async (widgetConditionDto: Array<WidgetConditionDto>, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'widgetConditionDto' is not null or undefined
            assertParamExists('generateEventsData', 'widgetConditionDto', widgetConditionDto)
            const localVarPath = `/api/report/widgets/generate-events`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(widgetConditionDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get a list of configuration category types
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCategoryTypes: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/report/widgets/category-types/configurations`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get a list of event category types
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEventCategoryTypes: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/report/widgets/category-types/events`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get supported columns for real-time events widget
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRealTimeEventsSupportedColumns: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/report/widgets/real-time-events-widget-columns`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get system usage data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSystemUsageData: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/report/widgets/system-usage`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get a list of widgets
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWidgets: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/report/widgets`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Refresh widgets data
         * @param {RefreshWidgetsDto} refreshWidgetsDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        refreshWidgets: async (refreshWidgetsDto: RefreshWidgetsDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'refreshWidgetsDto' is not null or undefined
            assertParamExists('refreshWidgets', 'refreshWidgetsDto', refreshWidgetsDto)
            const localVarPath = `/api/report/widgets/refresh`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(refreshWidgetsDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get User Image Log
         * @param {string} imageData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userImageLog: async (imageData: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'imageData' is not null or undefined
            assertParamExists('userImageLog', 'imageData', imageData)
            const localVarPath = `/api/report/widgets/user-image-log/{imageData}`
                .replace(`{${"imageData"}}`, encodeURIComponent(String(imageData)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get the users with limit and offset
         * @param {UsersParamsDto} usersParamsDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersWithLimitAndOffset: async (usersParamsDto: UsersParamsDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'usersParamsDto' is not null or undefined
            assertParamExists('usersWithLimitAndOffset', 'usersParamsDto', usersParamsDto)
            const localVarPath = `/api/report/widgets/users/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(usersParamsDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * WidgetsApi - functional programming interface
 * @export
 */
export const WidgetsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = WidgetsApiAxiosParamCreator(configuration)
    return {
        /**
         * It will only save the provided widgets and remove the rest. It means if array is empty then it wil remove all user\'s widgets
         * @summary Save widgets based on the provided data. 
         * @param {SaveWidgetsDto} saveWidgetsDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async applyWidgets(saveWidgetsDto: SaveWidgetsDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.applyWidgets(saveWidgetsDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Clear the Door APB Violation
         * @param {string} doorId 
         * @param {Array<string>} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async doorClearAPB(doorId: string, requestBody: Array<string>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.doorClearAPB(doorId, requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Clear the Door Alarm
         * @param {string} doorId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async doorClearAlarm(doorId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.doorClearAlarm(doorId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Lock Door
         * @param {string} doorId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async doorLock(doorId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.doorLock(doorId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Open Door
         * @param {string} doorId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async doorOpen(doorId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.doorOpen(doorId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Release or Normalize Door
         * @param {string} doorId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async doorRelease(doorId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.doorRelease(doorId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get the door status
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async doorStatus(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DoorStatusDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.doorStatus(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Unlock Door
         * @param {string} doorId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async doorUnlock(doorId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.doorUnlock(doorId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Start real-time events
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async eventsStart(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.eventsStart(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Stop real-time events
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async eventsStop(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.eventsStop(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Count the selected events based on Biostar 2 events
         * @param {Array<WidgetConditionDto>} widgetConditionDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async generateEventsData(widgetConditionDto: Array<WidgetConditionDto>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.generateEventsData(widgetConditionDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get a list of configuration category types
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCategoryTypes(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ConfigCategoryTypeDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCategoryTypes(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get a list of event category types
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getEventCategoryTypes(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<EventCategoryTypeDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getEventCategoryTypes(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get supported columns for real-time events widget
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRealTimeEventsSupportedColumns(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<RealTimeEventsColumnDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRealTimeEventsSupportedColumns(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get system usage data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSystemUsageData(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<WidgetDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSystemUsageData(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get a list of widgets
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getWidgets(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<WidgetDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getWidgets(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Refresh widgets data
         * @param {RefreshWidgetsDto} refreshWidgetsDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async refreshWidgets(refreshWidgetsDto: RefreshWidgetsDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.refreshWidgets(refreshWidgetsDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get User Image Log
         * @param {string} imageData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userImageLog(imageData: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userImageLog(imageData, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get the users with limit and offset
         * @param {UsersParamsDto} usersParamsDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersWithLimitAndOffset(usersParamsDto: UsersParamsDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<UserDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersWithLimitAndOffset(usersParamsDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * WidgetsApi - factory interface
 * @export
 */
export const WidgetsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = WidgetsApiFp(configuration)
    return {
        /**
         * It will only save the provided widgets and remove the rest. It means if array is empty then it wil remove all user\'s widgets
         * @summary Save widgets based on the provided data. 
         * @param {SaveWidgetsDto} saveWidgetsDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        applyWidgets(saveWidgetsDto: SaveWidgetsDto, options?: any): AxiosPromise<void> {
            return localVarFp.applyWidgets(saveWidgetsDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Clear the Door APB Violation
         * @param {string} doorId 
         * @param {Array<string>} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        doorClearAPB(doorId: string, requestBody: Array<string>, options?: any): AxiosPromise<void> {
            return localVarFp.doorClearAPB(doorId, requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Clear the Door Alarm
         * @param {string} doorId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        doorClearAlarm(doorId: string, options?: any): AxiosPromise<void> {
            return localVarFp.doorClearAlarm(doorId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Lock Door
         * @param {string} doorId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        doorLock(doorId: string, options?: any): AxiosPromise<void> {
            return localVarFp.doorLock(doorId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Open Door
         * @param {string} doorId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        doorOpen(doorId: string, options?: any): AxiosPromise<void> {
            return localVarFp.doorOpen(doorId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Release or Normalize Door
         * @param {string} doorId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        doorRelease(doorId: string, options?: any): AxiosPromise<void> {
            return localVarFp.doorRelease(doorId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get the door status
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        doorStatus(options?: any): AxiosPromise<Array<DoorStatusDto>> {
            return localVarFp.doorStatus(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Unlock Door
         * @param {string} doorId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        doorUnlock(doorId: string, options?: any): AxiosPromise<void> {
            return localVarFp.doorUnlock(doorId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Start real-time events
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        eventsStart(options?: any): AxiosPromise<void> {
            return localVarFp.eventsStart(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Stop real-time events
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        eventsStop(options?: any): AxiosPromise<void> {
            return localVarFp.eventsStop(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Count the selected events based on Biostar 2 events
         * @param {Array<WidgetConditionDto>} widgetConditionDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateEventsData(widgetConditionDto: Array<WidgetConditionDto>, options?: any): AxiosPromise<void> {
            return localVarFp.generateEventsData(widgetConditionDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get a list of configuration category types
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCategoryTypes(options?: any): AxiosPromise<Array<ConfigCategoryTypeDto>> {
            return localVarFp.getCategoryTypes(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get a list of event category types
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEventCategoryTypes(options?: any): AxiosPromise<Array<EventCategoryTypeDto>> {
            return localVarFp.getEventCategoryTypes(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get supported columns for real-time events widget
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRealTimeEventsSupportedColumns(options?: any): AxiosPromise<Array<RealTimeEventsColumnDto>> {
            return localVarFp.getRealTimeEventsSupportedColumns(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get system usage data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSystemUsageData(options?: any): AxiosPromise<Array<WidgetDto>> {
            return localVarFp.getSystemUsageData(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get a list of widgets
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWidgets(options?: any): AxiosPromise<Array<WidgetDto>> {
            return localVarFp.getWidgets(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Refresh widgets data
         * @param {RefreshWidgetsDto} refreshWidgetsDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        refreshWidgets(refreshWidgetsDto: RefreshWidgetsDto, options?: any): AxiosPromise<void> {
            return localVarFp.refreshWidgets(refreshWidgetsDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get User Image Log
         * @param {string} imageData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userImageLog(imageData: string, options?: any): AxiosPromise<void> {
            return localVarFp.userImageLog(imageData, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get the users with limit and offset
         * @param {UsersParamsDto} usersParamsDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersWithLimitAndOffset(usersParamsDto: UsersParamsDto, options?: any): AxiosPromise<Array<UserDto>> {
            return localVarFp.usersWithLimitAndOffset(usersParamsDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * WidgetsApi - object-oriented interface
 * @export
 * @class WidgetsApi
 * @extends {BaseAPI}
 */
export class WidgetsApi extends BaseAPI {
    /**
     * It will only save the provided widgets and remove the rest. It means if array is empty then it wil remove all user\'s widgets
     * @summary Save widgets based on the provided data. 
     * @param {SaveWidgetsDto} saveWidgetsDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WidgetsApi
     */
    public applyWidgets(saveWidgetsDto: SaveWidgetsDto, options?: any) {
        return WidgetsApiFp(this.configuration).applyWidgets(saveWidgetsDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Clear the Door APB Violation
     * @param {string} doorId 
     * @param {Array<string>} requestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WidgetsApi
     */
    public doorClearAPB(doorId: string, requestBody: Array<string>, options?: any) {
        return WidgetsApiFp(this.configuration).doorClearAPB(doorId, requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Clear the Door Alarm
     * @param {string} doorId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WidgetsApi
     */
    public doorClearAlarm(doorId: string, options?: any) {
        return WidgetsApiFp(this.configuration).doorClearAlarm(doorId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Lock Door
     * @param {string} doorId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WidgetsApi
     */
    public doorLock(doorId: string, options?: any) {
        return WidgetsApiFp(this.configuration).doorLock(doorId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Open Door
     * @param {string} doorId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WidgetsApi
     */
    public doorOpen(doorId: string, options?: any) {
        return WidgetsApiFp(this.configuration).doorOpen(doorId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Release or Normalize Door
     * @param {string} doorId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WidgetsApi
     */
    public doorRelease(doorId: string, options?: any) {
        return WidgetsApiFp(this.configuration).doorRelease(doorId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get the door status
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WidgetsApi
     */
    public doorStatus(options?: any) {
        return WidgetsApiFp(this.configuration).doorStatus(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Unlock Door
     * @param {string} doorId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WidgetsApi
     */
    public doorUnlock(doorId: string, options?: any) {
        return WidgetsApiFp(this.configuration).doorUnlock(doorId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Start real-time events
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WidgetsApi
     */
    public eventsStart(options?: any) {
        return WidgetsApiFp(this.configuration).eventsStart(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Stop real-time events
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WidgetsApi
     */
    public eventsStop(options?: any) {
        return WidgetsApiFp(this.configuration).eventsStop(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Count the selected events based on Biostar 2 events
     * @param {Array<WidgetConditionDto>} widgetConditionDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WidgetsApi
     */
    public generateEventsData(widgetConditionDto: Array<WidgetConditionDto>, options?: any) {
        return WidgetsApiFp(this.configuration).generateEventsData(widgetConditionDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get a list of configuration category types
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WidgetsApi
     */
    public getCategoryTypes(options?: any) {
        return WidgetsApiFp(this.configuration).getCategoryTypes(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get a list of event category types
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WidgetsApi
     */
    public getEventCategoryTypes(options?: any) {
        return WidgetsApiFp(this.configuration).getEventCategoryTypes(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get supported columns for real-time events widget
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WidgetsApi
     */
    public getRealTimeEventsSupportedColumns(options?: any) {
        return WidgetsApiFp(this.configuration).getRealTimeEventsSupportedColumns(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get system usage data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WidgetsApi
     */
    public getSystemUsageData(options?: any) {
        return WidgetsApiFp(this.configuration).getSystemUsageData(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get a list of widgets
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WidgetsApi
     */
    public getWidgets(options?: any) {
        return WidgetsApiFp(this.configuration).getWidgets(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Refresh widgets data
     * @param {RefreshWidgetsDto} refreshWidgetsDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WidgetsApi
     */
    public refreshWidgets(refreshWidgetsDto: RefreshWidgetsDto, options?: any) {
        return WidgetsApiFp(this.configuration).refreshWidgets(refreshWidgetsDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get User Image Log
     * @param {string} imageData 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WidgetsApi
     */
    public userImageLog(imageData: string, options?: any) {
        return WidgetsApiFp(this.configuration).userImageLog(imageData, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get the users with limit and offset
     * @param {UsersParamsDto} usersParamsDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WidgetsApi
     */
    public usersWithLimitAndOffset(usersParamsDto: UsersParamsDto, options?: any) {
        return WidgetsApiFp(this.configuration).usersWithLimitAndOffset(usersParamsDto, options).then((request) => request(this.axios, this.basePath));
    }
}
