import { SaveWidgetsDto, WidgetsApi } from 'api-client';
import axios from 'axios';
import { useMutation } from 'react-query';
import { apiConfig } from 'shared/utils/api-config';

export function useSaveWidgets() {
  const api = new WidgetsApi(apiConfig());

  return useMutation(async (widgets: SaveWidgetsDto['widgets']) => {
    try {
      await api.applyWidgets({ widgets });
    } catch (error) {
      if (axios.isAxiosError(error)) {
        throw error.response!.data;
      }
      throw error;
    }
  });
}
