import { ReportsApi } from 'api-client';
import { useMemo } from 'react';
import { useQuery } from 'react-query';
import { usePagination } from 'shared/hooks/use-pagination';
import { useSortBy } from 'shared/hooks/use-sort-by';
import { apiConfig } from 'shared/utils/api-config';

export function useReportContent(
  reportId: string,
  pagination: ReturnType<typeof usePagination>,
  sortBy?: ReturnType<typeof useSortBy>
) {
  const reportsApi = new ReportsApi(apiConfig());

  const id = useMemo(() => {
    if (!reportId) return undefined;

    const value = parseInt(reportId);
    if (isNaN(value)) return undefined;

    return value;
  }, [reportId]);

  const fetchReport = async () => {
    const { data } = await reportsApi.getReportContent(id!, {
      limit: pagination.limit,
      offset: pagination.offset,
      search: pagination.search,
      sorters: sortBy?.values,
    });
    return data;
  };

  return useQuery(
    [
      'reportContent',
      id,
      sortBy?.valueString,
      pagination.rowsPerPage,
      pagination.page,
      pagination.search,
    ],
    fetchReport,
    { enabled: !!id }
  );
}
