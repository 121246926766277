import { useCallback, useMemo, useState } from 'react';

export interface SearchItem {
  name: string;
  children?: object[];
}

export function useSearchItems<Item extends SearchItem>() {
  const [items, setItems] = useState<Item[]>([]);
  const [textSearch, setTextSearch] = useState<string>();

  const onSearch = (value: string) => setTextSearch(value);

  const searchName = useCallback(
    (name: string) => {
      if (!textSearch?.trim()) return true;

      const normalizedName = name.toLocaleLowerCase();

      const normalizedTextSearch = textSearch
        ?.replaceAll(/\s+/g, ' ')
        .toLocaleLowerCase()
        .trim();

      return normalizedName.includes(normalizedTextSearch);
    },
    [textSearch]
  );

  /**
   * Filter items by text search.
   *
   * This will modify the object reference directly.
   */
  const filterItemsByKeyword = useCallback(
    (items: Item[]) => {
      items.forEach((item, index) => {
        const children = item.children as Item[] | undefined;

        if (children) {
          // Current item have children, check the children.
          filterItemsByKeyword(children);
        } else {
          // Current item doesn't have children,
          // it means this item is report type, not a category.
          //
          // It will delete the item if the name doesn't match the search keyword.
          if (!searchName(items[index].name)) {
            delete items[index];
          }
        }

        // Not affect the report type item. Only for the category item.
        //
        // After the children items are deleted, the array is not cleaned up.
        // The index still exists, but with empty value.
        // So that we need to filter the empty items.
        const actualChildren = children?.filter(
          (childItem) => childItem
        )?.length;

        // Check if the current item is a category.
        //
        // It will remove the category if all the children items are removed.
        if (children && !actualChildren) {
          delete items[index];
        }
      });
    },
    [searchName]
  );

  const filteredItems = useMemo(() => {
    const itemsClone: Item[] = JSON.parse(JSON.stringify(items));

    filterItemsByKeyword(itemsClone);

    return itemsClone.filter((item: Item) => item);
  }, [items, filterItemsByKeyword]);

  return { items, setItems, onSearch, filteredItems };
}
