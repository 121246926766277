import { Menu, MenuProps, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: Theme) => ({
  contextMenuWrapper: {
    paddingTop: '0',
    paddingBottom: '0',
    border: '2px solid #19a5a0',
  },
}));

export const ContextMenu: React.FC<MenuProps> = ({ ...props }) => {
  const classes = useStyles();

  return (
    <Menu
      {...props}
      MenuListProps={{
        classes: { padding: classes.contextMenuWrapper },
      }}
    ></Menu>
  );
};
