/* tslint:disable */
/* eslint-disable */
/**
 * BioStar 2 Report API
 * API Documentation for BioStar 2 Report
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @interface LayoutDto
 */
export interface LayoutDto {
    /**
     * 
     * @type {number}
     * @memberof LayoutDto
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof LayoutDto
     */
    keyName: LayoutDtoKeyName;
}

/**
    * @export
    * @enum {string}
    */
export enum LayoutDtoKeyName {
    Standard = 'standard',
    Particular1 = 'particular1',
    Particular2 = 'particular2'
}



