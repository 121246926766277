import { WidgetDialogContent } from './dialog-content';
import React, { Suspense } from 'react';
import { OverlayLoading } from 'shared/components/overlay-loading';

export interface WidgetDialogProps {
  open: boolean;
  onClose: () => void;
  onApply?: (data: any) => Promise<void>;
  value?: any;
  isEdit?: boolean;
}

export const WidgetDialog: React.FC<WidgetDialogProps> = (props) => {
  return (
    <Suspense fallback={<OverlayLoading />}>
      {!!props.open && <WidgetDialogContent {...props} />}
    </Suspense>
  );
};
