import { BrowserRouter, useRoutes } from 'react-router-dom';
import { withRoutesExtended } from 'core/utils/with-routes-extended';
import { appRoutes } from './app.routes';
import { QueryClientProvider } from 'react-query';
import { queryClient } from 'core/utils/query-client';
import { Helmet } from 'react-helmet';
import { AuthProvider } from 'core/context/auth.context';
import { ErrorDialogProvider } from 'shared/contexts/error-dialog.context';
import { I18nProvider, useTranslation } from 'core/context/i18n.context';
import './app.css';
import { SuccessDialogProvider } from 'shared/contexts/success-dialog.context';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { PreferenceProvider } from 'core/context/preference.context';

const AppRoutes = () => useRoutes(withRoutesExtended(appRoutes));

export function App() {
  const { activeLanguage } = useTranslation();

  return (
    <QueryClientProvider client={queryClient}>
      <Helmet>
        <html lang={activeLanguage} />
      </Helmet>
      <BrowserRouter>
        <I18nProvider>
          <AuthProvider>
            <PreferenceProvider>
              <DndProvider backend={HTML5Backend}>
                <ErrorDialogProvider>
                  <SuccessDialogProvider>
                    <AppRoutes />
                  </SuccessDialogProvider>
                </ErrorDialogProvider>
              </DndProvider>
            </PreferenceProvider>
          </AuthProvider>
        </I18nProvider>
      </BrowserRouter>
    </QueryClientProvider>
  );
}
