import { Box } from '@mui/material';
import React from 'react';

interface DashboardOptionItemProps {
  tooltip?: string;
  onClick?: () => void;
}

export const DashboardOptionItem: React.FC<DashboardOptionItemProps> = ({
  children,
  tooltip,
  onClick,
}) => {
  return (
    <Box mx="4px" sx={{ cursor: 'pointer' }} onClick={onClick} title={tooltip}>
      {children}
    </Box>
  );
};
